import {
  FormModel,
  VerificationForm,
} from "Components/Auth/SignUp/VerificationForm";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetError } from "State/Auth/AuthReducer";
import { verifyEmailAsync } from "State/Auth/Verifications/EmailVerificationState";
import { resendVerificationEmailAsync } from "State/Auth/Verifications/ResendVerificationEmailState";
import { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { getPath, AppRouting } from "Utils/UrlUtils";

export const AwaitingEmailVerificationPage: React.FunctionComponent = _ => {
  const { t } = useResource();
  const { emailVerification, error } = useSelector(
    (e: RootStateType) => e.auth,
  );
  const dispatch = useDispatch();
  const { canUseBiometrics } = useBiometricsCanBeUsed();
  const [wasResend, setWasResend] = React.useState(false);

  useEffect(() => {
    dispatch(resetError());

    return () => {
      dispatch(resetError());
    };
  }, [dispatch]);

  const resend = () => {
    if (!!emailVerification?.email && !wasResend) {
      dispatch(
        resendVerificationEmailAsync.request({
          email: emailVerification.email,
        }),
      );
      setWasResend(true);
    }
  };

  const onSubmit = ({ code }: FormModel) => {
    if (!!emailVerification?.tokenID) {
      dispatch(
        verifyEmailAsync.request({
          command: {
            code,
            idToken: emailVerification.tokenID,
          },
          navigate,
          canUseBiometrics,
        }),
      );
    }
  };

  const navigate = useNavigate();

  return (
    <UnauthenticatedLayout
      title={t(Resources.SignUp.EmailVerification.Title)}
      onBackClick={() => navigate(getPath(AppRouting.SignIn))}
    >
      <VerificationForm
        resend={resend}
        onSubmit={onSubmit}
        serverError={error}
        email={emailVerification?.email ?? ""}
      />
    </UnauthenticatedLayout>
  );
};

export default AwaitingEmailVerificationPage;
