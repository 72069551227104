import { CurrencyCode } from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  getExchangeRateAsync,
  resetGetExchangeRateState,
} from "State/ExchangeRate/Api/ExchangeRateState";
import { useEffect } from "react";

export type ExchangeRateProps = {
  currencyFrom: CurrencyCode | null | undefined;
  currencyTo: CurrencyCode | null | undefined;
};

export const useExchangeRate = ({
  currencyFrom,
  currencyTo,
}: ExchangeRateProps) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(s => s.exchangeRate.exchangeRate);

  useEffect(() => {
    if (!currencyFrom || !currencyTo) {
      return;
    }

    const payload = {
      currencyFrom,
      currencyTo,
    };

    dispatch(getExchangeRateAsync.request(payload));
  }, [dispatch, currencyFrom, currencyTo]);

  useEffect(() => {
    return () => {
      dispatch(resetGetExchangeRateState());
    };
  }, [dispatch]);

  return state;
};
