import { CreateContractCommandResult, postContract } from "Api/Api";
import { logError } from "ErrorService";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import {
  setBiometricsSignatureType,
  BiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { setIsBiometrySignatureInProcess } from "State/Contracts/Create/CreateState";
import { FormModelType as ContractFormModelType } from "State/Contracts/Create/Models";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import { FormModelType as ModelingFormModelType } from "State/Contracts/Modeling/Models";
import { mapFormDataToApi } from "State/Contracts/Utils";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import { safeApiCall } from "State/Utils";

import { call, put, takeLatest } from "typed-redux-saga";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";

export type CreateContractStateType =
  FetchStateType<CreateContractCommandResult>;

export const createContractState = (): CreateContractStateType =>
  getFetchStateDefaultValue();

export type CreateContractActionType = ActionType<typeof createContractAsync>;

export const createContractAsync = createAsyncAction(
  "@contract/CREATE_CONTRACT_REQUEST",
  "@contract/CREATE_CONTRACT_SUCCESS",
  "@contract/CREATE_CONTRACT_FAILURE",
)<
  {
    modelingData: ModelingFormModelType;
    contractData: ContractFormModelType;
    isBankIDVerified: boolean;
    isDip: boolean;
    canUseBiometrics: boolean;
  },
  CreateContractCommandResult,
  Error
>();

function* createContract(
  action: ActionType<typeof createContractAsync.request>,
): Generator {
  try {
    const {
      modelingData,
      contractData,
      isBankIDVerified,
      isDip,
      canUseBiometrics,
    } = action.payload;

    if (canUseBiometrics) {
      yield put(setIsBiometrySignatureInProcess(true));
      yield put(setBiometricsSignatureType(BiometricsSignatureType.Contract));
    } else {
      yield put(setIsBiometrySignatureInProcess(false));
    }

    const data = yield* call(
      mapFormDataToApi,
      modelingData,
      contractData,
      isBankIDVerified,
      isDip,
      canUseBiometrics,
    );

    const { response, error } = yield* safeApiCall(postContract, data);

    if (error) {
      yield put(createContractAsync.failure(error));
      console.log(
        "Reseting biometrics signature type from CreateContractState.ts because of error",
      );

      yield put(setIsBiometrySignatureInProcess(false));
      yield put(setBiometricsSignatureType(null));
      return;
    }

    // We expect only one signature hash, otherwise we have an error
    if (response.signatureHashList?.length !== 1) {
      yield put(
        createContractAsync.failure(
          new ApplicationError(
            "More than one signature hash was returned",
            ErrorLevel.Critical,
          ),
        ),
      );
      console.log(
        "Reseting biometrics signature type from CreateContractState.ts because of multiple signature hashes",
      );
      yield put(setIsBiometrySignatureInProcess(false));
      yield put(setBiometricsSignatureType(null));
    }

    yield put(createContractAsync.success(response));

    try {
      const signatureHash = response.signatureHashList[0];
      if (canUseBiometrics) {
        yield* put(
          initializeBiometricSignatureAsync.request({
            signatureHash,
          }),
        );
      } else {
        yield* put(
          initializeSmsSignatureAsync.request({
            signatureHash,
          }),
        );
      }
    } catch (initializeSignatureError) {
      logError(
        new ApplicationError(
          "Caught an error after creating a contract during signature initialization.",
          ErrorLevel.Critical,
          { error: initializeSignatureError },
          null,
          true,
        ),
      );
    }
  } catch (err) {
    console.log(
      "Reseting biometrics signature type from CreateContractState.ts because of error at the end of saga",
    );
    yield put(setIsBiometrySignatureInProcess(false));
    yield put(setBiometricsSignatureType(null));
    yield put(createContractAsync.failure(err as Error));
  }
}

export function* createContractSaga() {
  yield takeLatest(getType(createContractAsync.request), createContract);
}
