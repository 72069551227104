import { Box, IconButton, Stack } from "@mui/material";

import { MenuChip } from "Components/ContractDetail/MenuChip";
import { Overview } from "Components/ContractDetail/Overview/Overview";
import { Transactions } from "Components/ContractDetail/Transactions/Transactions";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PageTitle } from "Components/Shared/PageTitle";

import { AppRouting, getPath } from "Utils/UrlUtils";
import {
  type FunctionComponent,
  useEffect,
  useRef,
  MouseEvent,
  useMemo,
} from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { Contract } from "Components/ContractDetail/Contract/Contract";
import {
  InvestmentDetailTabs,
  useMenuTabs,
} from "Hooks/ContractDetail/useMenuTabs";
import { Dealer } from "Components/ContractDetail/Dealer/Dealer";
import { PaymentInformation } from "Components/ContractDetail/PaymentInformation/PaymentInformation";
import { BankAccounts } from "Components/ContractDetail/BankAccounts/BankAccounts";
import { CashMovements } from "Components/ContractDetail/CashMovements/CashMovements";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { useAppSelector } from "Hooks/useAppSelector";
import { UnsettledTrades } from "Components/ContractDetail/UnsettledTrades/UnsettledTrades";
import { cleanupInvestmentState } from "State/InvestmentsDetail/Shared/Actions";
import { Resources, useResource } from "Translations/Resources";
import { useInvestmentName } from "Hooks/Investment/useInvestmentName";
import { LAYOUT_PADDING_X } from "Constants/Layout";
import { useDraggable } from "react-use-draggable-scroll";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { useDesktop } from "Hooks/useDesktop";
import { ContractGuideWrapper } from "Components/Shared/ContractGuideWrapper";
import { useActualTab } from "Hooks/ContractDetail/useActualTab";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { EditIcon } from "Components/Shared/Icons";
import { track } from "Utils/TrackingUtils";
import { Colors } from "Components/Layout/Themes/Colors";

export enum DetailPageSections {
  Overview,
}

const TruncatedText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const StyledStack = styled(Stack)`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export type TabProps = { contractID: number };

const Components: {
  [key in InvestmentDetailTabs]: FunctionComponent<TabProps>;
} = {
  [InvestmentDetailTabs.Overview]: Overview,
  [InvestmentDetailTabs.Transactions]: Transactions,
  [InvestmentDetailTabs.CashMovement]: CashMovements,
  [InvestmentDetailTabs.UnsettledTrades]: UnsettledTrades,
  [InvestmentDetailTabs.Dealer]: Dealer,
  [InvestmentDetailTabs.Contract]: Contract,
  [InvestmentDetailTabs.Accounts]: BankAccounts,
  [InvestmentDetailTabs.PaymentInformation]: PaymentInformation,
};

export const DetailPage: FunctionComponent = _ => {
  const { t } = useResource();
  const menuTabs = useMenuTabs();
  const { id } = useParams();
  const contractId = Number.parseInt(id as string);
  const dispatch = useAppDispatch();
  const menuStackRef = useRef<HTMLDivElement>(null);
  const { isDesktop } = useDesktop();

  const { events } = useDraggable(
    // This force typing is from docs and ts is not working without it
    menuStackRef as React.MutableRefObject<HTMLDivElement>,
  );

  const navigate = useNavigate();

  const [actualTab, setActualTab] = useActualTab();

  const { isLoading: loading, data } = useAppSelector(
    s => s.investmentsDetail.overview,
  );
  const isLoading = loading && !data;

  const investmentName = useInvestmentName({ ...data });

  useEffect(() => {
    if (id) {
      dispatch(getOverviewAsync.request(Number.parseInt(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(cleanupInvestmentState());
    };
  }, [dispatch]);

  const onBackClick = () => {
    navigate(getPath(AppRouting.Dashboard));
  };

  useEffect(() => {
    if (!Object.values(InvestmentDetailTabs).includes(actualTab)) {
      navigate(getPath(AppRouting.Error404));
    } else {
      menuStackRef.current
        ?.querySelector(`[data-value="${actualTab}"]`)
        ?.scrollIntoView?.({
          block: "nearest",
          behavior: "smooth",
        });
    }
  }, [actualTab, navigate]);

  const contentWithoutPadding = [
    InvestmentDetailTabs.Transactions,
    InvestmentDetailTabs.CashMovement,
    InvestmentDetailTabs.Accounts,
    InvestmentDetailTabs.UnsettledTrades,
  ].includes(actualTab);

  const Component = Components[actualTab];

  const onMenuItemClick = (
    event: MouseEvent<HTMLDivElement>,
    tab: InvestmentDetailTabs,
  ) => {
    event.stopPropagation();

    const parent = menuStackRef.current;

    if (parent) {
      // we need to wait for the browser to scroll the element into view
      setTimeout(() => {
        const child = event.currentTarget ?? event.target;

        const parentRect = parent.getBoundingClientRect();
        const childRect = child.getBoundingClientRect();

        // ignore for the latest element - it's causing bad UX on safari
        if (parentRect.right === childRect.right) {
          return;
        }

        const scrollToX =
          childRect.left +
          childRect.width / 2 -
          parentRect.left -
          parentRect.width / 2 +
          parent.scrollLeft;

        parent.scrollTo({
          left: Math.max(
            0,
            Math.min(scrollToX, parent.scrollWidth - parent.clientWidth),
          ),
          behavior: "smooth",
        });
      }, 0);
    }

    setActualTab(tab);
  };

  const titleComponent = useMemo(() => {
    return (
      <StyledFlex
        $gap={2}
        $justifyContent={"space-between"}
        $alignItems="center"
      >
        <TruncatedText>{investmentName}</TruncatedText>
        <StyledIconButton
          onClick={() => {
            track({
              category: "Button",
              event: "Click",
            });
            navigate(getPath(AppRouting.ContractEdit, contractId));
          }}
          disabled={isLoading}
        >
          <EditIcon size={30} color={Colors.PrimaryText} />
        </StyledIconButton>
      </StyledFlex>
    );
  }, [isLoading, contractId, investmentName, navigate]);

  return (
    <AuthenticatedLayout
      title={t(Resources.Contract.Detail.PageTitle)}
      onBackClick={onBackClick}
      disableContentPadding
      headerChildren={
        data?.isDip && !isDesktop ? <DipTagWrapper inverse /> : undefined
      }
      isTitleShown={!isDesktop}
      titleComponent={titleComponent}
    >
      <div>
        <Box paddingX={LAYOUT_PADDING_X}>
          {isDesktop && (
            <ContractGuideWrapper isDipWrapped={!!data?.isDip}>
              <LoadingWrapper wrapChildren isLoading={isLoading}>
                <PageTitle noWrap>{titleComponent}</PageTitle>
              </LoadingWrapper>
            </ContractGuideWrapper>
          )}
          <StyledStack
            role="menubar"
            direction="row"
            gap={2}
            marginBottom={6}
            overflow="auto"
            position="relative"
            ref={menuStackRef}
            {...events}
          >
            {menuTabs.map(({ value, label }) => (
              <MenuChip
                data-value={value}
                key={value}
                label={label}
                onClick={e => onMenuItemClick(e, value)}
                active={value === actualTab}
              />
            ))}
          </StyledStack>
        </Box>
        <Box paddingX={contentWithoutPadding ? undefined : LAYOUT_PADDING_X}>
          {!!Component && <Component contractID={contractId} />}
        </Box>
      </div>
    </AuthenticatedLayout>
  );
};

export default DetailPage;
