import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { WebViewMessage, WebViewMessageTypes } from "Models/WebViewModels";
import { safeApiCall } from "State/Utils";
import {
  SignInCommandResultStatus,
  SignInResult,
  postAuthSignInBiometrics,
} from "Api/Api";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { call } from "typed-redux-saga";
import { afterSignInSuccess } from "Utils/AuthUtils";
import { NavigateFunction } from "react-router";
import { logError } from "ErrorService";
import { setError } from "State/Auth/AuthReducer";

export const signInBiometricsAsync = createAsyncAction(
  "@auth/SIGN_IN_BIOMETRICS_REQUEST",
  "@auth/SIGN_IN_BIOMETRICS_SUCCESS",
  "@auth/SIGN_IN_BIOMETRICS_FAILURE",
)<
  Extract<
    WebViewMessage,
    { type: WebViewMessageTypes.BIOMETRIC_SIGN_IN_RESPONSE }
  >["payload"] & {
    trackingSessionID: string;
    navigate: NavigateFunction;
  },
  SignInResult,
  Error
>();

function* signInBiometrics(
  action: ReturnType<typeof signInBiometricsAsync.request>,
): Generator {
  try {
    if (action.payload.isSuccess === false) {
      yield put(
        signInBiometricsAsync.failure(
          new ApplicationError(
            "Biometrics sign in failed. Probably because face id or touch id was not successful.",
            ErrorLevel.Error,
            { payload: action.payload },
          ),
        ),
      );
      return;
    }

    const { response, error } = yield* safeApiCall(postAuthSignInBiometrics, {
      challenge: action.payload.challenge,
      signedChallenge: action.payload.signedChallenge,
      login: action.payload.login,
      publicKey: action.payload.publicKey,
      trackingSessionID: action.payload.trackingSessionID,
    });

    if (error) {
      yield put(signInBiometricsAsync.failure(error));
      console.error("biometricSignInVerifyAuthentication error", error);
      logError(
        new ApplicationError(
          "Biometrics sign in failed. Error returned.",
          ErrorLevel.Critical,
        ),
        { response, error },
        false,
      );
      return;
    }

    if (
      response.status === SignInCommandResultStatus.DealerAccessRoleNotAllowed
    ) {
      yield put(setError(new ApplicationError(response.signInResult.error)));
      yield put(
        signInBiometricsAsync.failure(
          new ApplicationError(response.signInResult.error),
        ),
      );
      return;
    }

    if (response.status !== SignInCommandResultStatus.Success) {
      console.error("biometricSignInVerifyAuthentication error", response);
      yield put(
        signInBiometricsAsync.failure(
          new ApplicationError(response.status, ErrorLevel.Critical),
        ),
      );
      logError(
        new ApplicationError("Biometrics sign in failed. Response returned."),
        {
          response: {
            status: response.status,
            signInResult: {
              status: response.signInResult.status,
              error: response.signInResult.error,
              errorMessage: response.signInResult.errorMessage,
            },
            error: response.error,
          },
          error,
        },
        false,
      );
      return;
    }

    console.log("Biometrics sign up successful");
    yield put(signInBiometricsAsync.success(response.signInResult));
    yield* call(
      afterSignInSuccess,
      response.signInResult,
      action.payload.navigate,
    );
  } catch (err: any) {
    console.error(err);
    logError(err, {
      payload: action.payload,
      error: err,
    });
    yield put(signInBiometricsAsync.failure(err as Error));
  }
}
export function* signInBiometricsSaga() {
  yield takeLatest(getType(signInBiometricsAsync.request), signInBiometrics);
}
