import { MenuList, Stack, Typography } from "@mui/material";
import { InstructionValueType, InvestmentType } from "Api/Api";
import { FilterButton } from "Components/ContractDetail/Transactions/components/FilterButton";
import { FilterDialog } from "Components/ContractDetail/Transactions/components/FilterDialog";
import { MenuItem } from "Components/ContractDetail/Transactions/components/MenuItem";
import { MenuItemLoader } from "Components/ContractDetail/Transactions/components/MenuItemLoader";
import {
  ExchangeIcon,
  PurchaseIcon,
  RedeemIcon,
} from "Components/Shared/Icons";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { LAYOUT_PADDING_X } from "Constants/Layout";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { TabProps } from "Pages/Contracts/DetailPage";
import {
  getTransactionsAsync,
  initialTransactionsState,
} from "State/InvestmentsDetail/Transactions/TransactionsState";
import { Resources, useResource } from "Translations/Resources";
import { isEqual } from "lodash-es";
import { useEffect, useState, type FunctionComponent } from "react";

const PageResources = Resources.Contract.Detail.Transactions;

const Icons: { [key in InvestmentType]: JSX.Element | null | undefined } = {
  [InvestmentType.Repurchase]: <RedeemIcon />,
  [InvestmentType.Issue]: <PurchaseIcon />,
  [InvestmentType.Transfer]: <ExchangeIcon />,
  [InvestmentType.Exchange]: <ExchangeIcon />,
};

type Props = TabProps;

export const Transactions: FunctionComponent<Props> = ({ contractID }) => {
  const dispatch = useAppDispatch();

  const { t } = useResource();
  const {
    isLoading: isLoadingTransactions,
    data: transactions,
    metadata: pagination,
    filters,
  } = useAppSelector(s => s.investmentsDetail.transactions);

  useEffect(() => {
    if (!transactions) {
      dispatch(
        getTransactionsAsync.request({ ...filters, contractID, pageSize: 100 }),
      );
    }
  }, [contractID, transactions, filters, dispatch]);

  const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);

  const isTransactionsVisible = !!transactions?.length;
  const isLoading = isLoadingTransactions && !transactions;
  const isDataFiltered = !isEqual(filters, initialTransactionsState.filters);

  return (
    <>
      {!isLoading && (
        <>
          <FilterButton
            isDataFiltered={isDataFiltered}
            onClick={() => setIsFilterDialogVisible(true)}
          />

          <FilterDialog
            isOpened={isFilterDialogVisible}
            onClose={() => setIsFilterDialogVisible(false)}
          />

          {!isTransactionsVisible && (
            <Typography paddingX={LAYOUT_PADDING_X}>
              {t(PageResources.NoneTransactionsFoundPlaceholder)}
            </Typography>
          )}
        </>
      )}

      <MenuList disablePadding>
        <LoadingWrapper
          isLoading={isLoading}
          skeleton={
            <Stack gap={2} marginX={LAYOUT_PADDING_X}>
              <StyledFlex $justifyContent="flex-end">
                <PrimarySkeleton width={100} height={25} />
              </StyledFlex>
              <PrimarySkeleton height={100} />
              <PrimarySkeleton height={100} />
              <PrimarySkeleton height={100} />
              <PrimarySkeleton height={100} />
            </Stack>
          }
        >
          {isTransactionsVisible &&
            transactions.map(
              (
                { investmentType, date, currency, transactionID, ...props },
                index,
              ) => (
                <MenuItem
                  key={transactionID ?? index}
                  dateFormat="P"
                  icon={Icons[investmentType!]}
                  date={new Date(date)}
                  currency={currency}
                  instructionValueType={InstructionValueType.Amount}
                  {...props}
                />
              ),
            )}

          <MenuItemLoader
            isLoading={isLoadingTransactions && !!transactions}
            onElementVisible={() => {
              if (pagination?.hasNext) {
                dispatch(
                  getTransactionsAsync.request({
                    ...filters,
                    contractID,
                    pageSize: pagination.pageSize,
                    pageNumber: pagination.currentPage + 1,
                  }),
                );
              }
            }}
          />
        </LoadingWrapper>
      </MenuList>
    </>
  );
};
