import { LogoStartScreenIcon } from "Components/Shared/Icons/LogoStartScreen";
import styled from "styled-components";

const LogoWrapper = styled.div`
  position: relative;
`;

export const Logo: React.FunctionComponent = () => {
  return (
    <div>
      <LogoWrapper>
        <LogoStartScreenIcon height={120} />
      </LogoWrapper>
    </div>
  );
};
