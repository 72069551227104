import { SignatureForm } from "Components/ContractBankConnections/Shared/SignatureForm";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import {
  RedemptionStep,
  setRedemptionStep,
} from "State/Contracts/Redemption/RedemptionState";
import { Resources, useResource } from "Translations/Resources";
import { getProductColor } from "Utils/Products";

type Props = { isin: string };

const ComponentResources = Resources.Contract.Detail.Redemption.Confirmation;

export const RedemptionSignatureFormStep: React.FunctionComponent<Props> = ({
  isin,
}) => {
  const dispatch = useAppDispatch();

  const { t } = useResource();
  const { color, contrastColor } = getProductColor(isin);
  const { isLoading: isLoadingRedemption, result } = useAppSelector(
    s => s.contracts.redemption,
  );

  const signatureHash = result?.signatureHash;

  return (
    <SignatureForm
      isLoading={isLoadingRedemption}
      contractID={null}
      signatureHash={signatureHash}
      productColor={color}
      productContrastColor={contrastColor}
      sms={{
        phone: (result?.type === "SMS" ? result.phone : null) ?? "",
      }}
      biometrics={{
        signatureType: BiometricsSignatureType.Redemption,
      }}
      translations={{
        isLoading: t(ComponentResources.WorkingText),
        sms: {
          title: t(ComponentResources.Title),
          codeSent: t(Resources.Signature.SmsSent),
          codeDetermination: t(ComponentResources.CodeDetermination),
          verificationError: t(ComponentResources.VerificationError),
          button: t(ComponentResources.Submit),
        },
        biometrics: {
          title: t(ComponentResources.Biometrics),
          signatureError: t(ComponentResources.BiometricsError),
          signatureInProcessError: t(
            ComponentResources.BiometricsInProcessError,
          ),
        },
        signatureHashNotFoundError: t(
          ComponentResources.SignatureHashNotFoundError,
        ),
      }}
      onSuccess={() => {
        dispatch(setRedemptionStep(RedemptionStep.Success));
      }}
    />
  );
};
