import { produce } from "immer";
import { MobileMetadata } from "Models/MetadataModels";
import { ActionType, createAction, createReducer } from "typesafe-actions";

export type MetadataState = {
  mobile: MobileMetadata;
};

const initialMetadataState: MetadataState = {
  mobile: {
    app: {
      version: null,
    },
    device: {
      os: null,
    },
  },
};

export const setMobileMetadata = createAction(
  "@metadata/SET_MOBILE_METADATA",
)<MobileMetadata>();

export type MetadataAction = ActionType<typeof setMobileMetadata>;

export const metadataReducer = createReducer<MetadataState, MetadataAction>(
  initialMetadataState,
).handleAction(setMobileMetadata, (state, action) => {
  return produce(state, draft => {
    draft.mobile = action.payload;
    return draft;
  });
});
