import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { ProductSectionItemDto, getProductSectionsIsin } from "Api/Api";

export type GetProductDetailStateType = FetchStateType<ProductSectionItemDto[]>;

export const getProductDetailState = (): GetProductDetailStateType =>
  getFetchStateDefaultValue();

export type GetProductDetailActionType =
  | ActionType<typeof getProductDetailAsync>
  | ActionType<typeof clearProductDetail>;

export const getProductDetailAsync = createAsyncAction(
  "@products/GET_PRODUCT_DETAIL_REQUEST",
  "@products/GET_PRODUCT_DETAIL_SUCCESS",
  "@products/GET_PRODUCT_DETAIL_FAILURE",
)<string, ProductSectionItemDto[], Error>();

export const clearProductDetail = createAction(
  "@products/CLEAR_PRODUCT_DETAIL",
)();

function* getProductDetail(
  action: ReturnType<typeof getProductDetailAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getProductSectionsIsin,
      action.payload,
    );

    if (error) {
      yield put(getProductDetailAsync.failure(error));
      return;
    }

    yield put(getProductDetailAsync.success(response));
  } catch (err) {
    yield put(getProductDetailAsync.failure(err as Error));
  }
}

export function* getProductDetailSaga() {
  yield takeLatest(getType(getProductDetailAsync.request), getProductDetail);
}

export const getProductDetailReducer = createReducer<
  GetProductDetailStateType,
  GetProductDetailActionType
>(getProductDetailState())
  .handleAction(getProductDetailAsync.request, handleActionRequest)
  .handleAction(getProductDetailAsync.failure, handleActionFailure)
  .handleAction(getProductDetailAsync.success, handleActionSuccess)
  .handleAction(clearProductDetail, () => getProductDetailState());
