import {
  ContractTransactionsQueryResult,
  getContractTransactionsContractID,
} from "Api/Api";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import {
  CleanupInvestmentStateActionType,
  cleanupInvestmentState,
} from "State/InvestmentsDetail/Shared/Actions";
import { produce } from "immer";
import { takeLeading } from "typed-redux-saga";

interface Params {
  contractID: number;
  dateFrom?: string;
  dateTo?: string;
  includePurchases?: boolean;
  includeRedemptions?: boolean;
  includeAssetsExchanges?: boolean;
  includeAssetsTransfers?: boolean;
  pageNumber?: number;
  pageSize?: number;
}

export type TransactionsFiltersFormModel = Pick<
  Params,
  | "includePurchases"
  | "includeRedemptions"
  | "includeAssetsExchanges"
  | "includeAssetsTransfers"
  | "dateFrom"
  | "dateTo"
>;

export type TransactionsState = {
  isLoading: boolean;
  filters: TransactionsFiltersFormModel;
  error?: Error | null;
} & Pick<ContractTransactionsQueryResult, "data" | "metadata">;

export const initialTransactionsState: TransactionsState = {
  isLoading: false,
  data: null,
  filters: {
    dateFrom: "",
    dateTo: "",
    includePurchases: false,
    includeRedemptions: false,
    includeAssetsExchanges: false,
    includeAssetsTransfers: false,
  },
  metadata: null,
  error: null,
};

export const setTransactionsFilters = createAction(
  "@investments-detail/SET_TRANSACTIONS_FILTERS",
)<TransactionsFiltersFormModel>();

export type TransactionsActionType =
  | ActionType<typeof getTransactionsAsync>
  | ActionType<typeof setTransactionsFilters>
  | CleanupInvestmentStateActionType;

export const getTransactionsAsync = createAsyncAction(
  "@investments-detail/GET_TRANSACTIONS_REQUEST",
  "@investments-detail/GET_TRANSACTIONS_SUCCESS",
  "@investments-detail/GET_TRANSACTIONS_FAILURE",
)<Params, ContractTransactionsQueryResult, Error>();

function* getTransactions(
  action: ReturnType<typeof getTransactionsAsync.request>,
): Generator {
  try {
    const {
      contractID,
      dateFrom,
      dateTo,
      includePurchases,
      includeRedemptions,
      includeAssetsExchanges,
      includeAssetsTransfers,
      pageNumber,
      pageSize,
    } = action.payload;

    const { response, error } = yield* safeApiCall(
      getContractTransactionsContractID,
      contractID,
      dateFrom || undefined,
      dateTo || undefined,
      includePurchases || undefined,
      includeRedemptions || undefined,
      includeAssetsExchanges || undefined,
      includeAssetsTransfers || undefined,
      pageNumber,
      pageSize,
    );

    if (error) {
      yield put(getTransactionsAsync.failure(error));
      return;
    }

    yield put(getTransactionsAsync.success(response));
  } catch (err) {
    yield put(getTransactionsAsync.failure(err as Error));
  }
}

export function* watchTransactionsSaga() {
  yield takeLeading(getType(getTransactionsAsync.request), getTransactions);
}

export const transactionsReducer = createReducer<
  TransactionsState,
  TransactionsActionType
>(initialTransactionsState)
  .handleAction(getTransactionsAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;

      return draft;
    });
  })
  .handleAction(getTransactionsAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = state.error;

      return draft;
    });
  })
  .handleAction(getTransactionsAsync.success, (state, action) => {
    return produce(state, draft => {
      const { data: newData, metadata } = action.payload;

      draft.isLoading = false;
      draft.data = [...(draft.data || []), ...(newData || [])];
      draft.metadata = metadata;

      return draft;
    });
  })
  .handleAction(cleanupInvestmentState, state => {
    return produce(state, draft => {
      return initialTransactionsState;
    });
  })
  .handleAction(setTransactionsFilters, (state, action) => {
    return produce(state, draft => {
      return { ...initialTransactionsState, filters: action.payload };
    });
  });
