import * as React from "react";
export const OtherIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 9.5C2.8457 9.5 1.5 10.8457 1.5 12.5C1.5 14.1543 2.8457 15.5 4.5 15.5C6.1543 15.5 7.5 14.1543 7.5 12.5C7.5 10.8457 6.1543 9.5 4.5 9.5ZM12.5 9.5C10.8457 9.5 9.5 10.8457 9.5 12.5C9.5 14.1543 10.8457 15.5 12.5 15.5C14.1543 15.5 15.5 14.1543 15.5 12.5C15.5 10.8457 14.1543 9.5 12.5 9.5ZM20.5 9.5C18.8457 9.5 17.5 10.8457 17.5 12.5C17.5 14.1543 18.8457 15.5 20.5 15.5C22.1543 15.5 23.5 14.1543 23.5 12.5C23.5 10.8457 22.1543 9.5 20.5 9.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
