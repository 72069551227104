import { type FunctionComponent } from "react";

import { useNavigate } from "react-router-dom";

import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";

import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { Box } from "@mui/material";
import { StyledButtonBox } from "Components/Shared/StyledComponents";
import { Logo } from "Components/Layout/Logo";
import { Resources, useResource } from "Translations/Resources";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { useBiometricSignIn } from "Hooks/Auth/useBiometricSignIn";

export const StartPage: FunctionComponent = _ => {
  const { t } = useResource();

  const navigate = useNavigate();
  useBiometricSignIn();

  return (
    <UnauthenticatedLayout title="Přihlášení">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexGrow="0.7"
      >
        <Logo />
      </Box>
      <StyledButtonBox>
        <PrimaryButton
          color="primary"
          onClick={() => navigate(getPath(AppRouting.SignUp))}
        >
          {t(Resources.StartPage.CreateAccount)}
        </PrimaryButton>
        <PrimaryButton
          color="secondary"
          onClick={() => navigate(getPath(AppRouting.SignIn))}
        >
          {t(Resources.StartPage.SignIn)}
        </PrimaryButton>
      </StyledButtonBox>
    </UnauthenticatedLayout>
  );
};

export default StartPage;
