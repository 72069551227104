import { FormModel, SignInForm } from "Components/Auth/SignIn/SignInForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { resetError, setSignInStep } from "State/Auth/AuthReducer";
import { SignInStep } from "State/Auth/Models/AuthStateModels";
import { signInAsync } from "State/Auth/SignIn/SignInState";
import { FunctionComponent, useEffect, useState } from "react";
import { getTrackingSessionID } from "Utils/TrackingUtils";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { useBiometricSignIn } from "Hooks/Auth/useBiometricSignIn";

export const SignInPage: FunctionComponent = _ => {
  const { t } = useResource();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetError());

    return () => {
      dispatch(setSignInStep(SignInStep.Credentials));
      dispatch(resetError());
    };
  }, [dispatch]);

  const {
    error,
    signInStep: step,
    twoFactor: { tokenID },
    isLoading,
  } = useSelector((e: RootStateType) => e.auth);

  const navigate = useNavigate();

  const onSubmit = ({ login, password, token }: FormModel) => {
    dispatch(resetError());

    dispatch(
      signInAsync.request({
        model: {
          login,
          password,
          token,
          tokenID: tokenID,
          trackingSessionID: getTrackingSessionID(),
        },
        navigate,
      }),
    );
  };
  const [resetValue, toggleReset] = useState(true);
  const onFormReset = () => {
    toggleReset(!resetValue);
    dispatch(setSignInStep(SignInStep.Credentials));
  };

  useBiometricSignIn();

  return (
    <UnauthenticatedLayout
      title={t(Resources.SignIn.Title)}
      key={`${resetValue}`}
      onBackClick={() => navigate(getPath(AppRouting.StartPage))}
    >
      <SignInForm
        step={step}
        isLoading={isLoading}
        onSubmit={onSubmit}
        serverError={error}
        onReset={onFormReset}
      />
    </UnauthenticatedLayout>
  );
};

export default SignInPage;
