import { isNoU } from "Utils/ObjectUtils";

export const formatNumber = (
  number: number | null | undefined,
  emptyText?: string,
) => {
  if (isNoU(number)) {
    return emptyText ?? "";
  }

  const formatter = new Intl.NumberFormat("cs-CZ", {
    maximumFractionDigits: 5,
  });
  return formatter.format(number ?? 0);
};

export const int2roman = (original: number): string => {
  if (original < 1 || original > 3999) {
    throw new Error("Error: Input integer limited to 1 through 3,999");
  }

  const numerals = [
    ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"], // 1-9
    ["X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC"], // 10-90
    ["C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM"], // 100-900
    ["M", "MM", "MMM"], // 1000-3000
  ];

  const digits = Math.round(original).toString().split("");
  let position = digits.length - 1;

  return digits.reduce((roman, digit) => {
    if (digit !== "0") {
      roman += numerals[position][parseInt(digit) - 1];
    }

    position -= 1;

    return roman;
  }, "");
};

export const convertNanToNull = (value: unknown) =>
  Number.isNaN(value) ? null : value;

export const int2readableString = (value: number | null | undefined) => {
  if (value === null || value === undefined) {
    return "";
  }

  const digits = Math.floor(Math.log10(Math.round(value)));

  if (digits < 3) {
    return String(Math.round(value));
  }

  if (digits > 8) {
    return `${formatNumber(value / 1_000_000_000).toLocaleString()} mld.`;
  }

  if (digits > 5) {
    return `${formatNumber(value / 1_000_000).toLocaleString()} mil.`;
  }

  return `${formatNumber(value / 1_000).toLocaleString()} tis.`;
};

export const roundNumberToDecimalPlaces = (
  value: number | null | undefined,
  decimalPlaces: number,
) => {
  if (!value) {
    return 0;
  }

  const roundedValue = Number(value.toFixed(decimalPlaces));
  if (roundedValue === 0) {
    return 0;
  }

  return roundedValue;
};
