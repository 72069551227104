import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { Resources, useResource } from "Translations/Resources";
import {
  FunctionComponent,
  LazyExoticComponent,
  Suspense,
  lazy,
  useEffect,
} from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { useNavigate, useParams } from "react-router";
import { TransferDipStep } from "State/Contracts/TransferDip/TransferDipModels";
import {
  resetTransferDipState,
  setTransferDipContractID,
  setTransferDipStep,
} from "State/Contracts/TransferDip/TransferDipReducer";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { PageTitle } from "Components/Shared/PageTitle";
import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useSettings } from "Hooks/App/Settings/useSettings";
import { clearAppSettings } from "State/App/Settings/GetSettingsState";
import { useIsSelfNegotiatedContract } from "Hooks/Contract/Detail/useIsSelfNegotiatedContract";
import { useDesktop } from "Hooks/useDesktop";

const PageResources = Resources.Contract.DipTransfer;

const TransferInformation = lazy(() =>
  import("Components/ContractTransferDip/TransferInfo/TransferInfo").then(
    ({ TransferInfo }) => ({ default: TransferInfo }),
  ),
);
const TransferPreview = lazy(() =>
  import("Components/ContractTransferDip/TransferPreview/TransferPreview").then(
    ({ TransferPreview }) => ({ default: TransferPreview }),
  ),
);
const TransferSignature = lazy(() =>
  import(
    "Components/ContractTransferDip/TransferSignature/TransferSignature"
  ).then(({ TransferSignature }) => ({ default: TransferSignature })),
);
const FinalPage = lazy(() =>
  import("Components/ContractTransferDip/FinalPage/TransferFinalPage").then(
    ({ TransferFinalPage: FinalPage }) => ({ default: FinalPage }),
  ),
);

const Components: {
  [key in TransferDipStep]: LazyExoticComponent<FunctionComponent>;
} = {
  [TransferDipStep.TransferInformation]: TransferInformation,
  [TransferDipStep.TransferPreview]: TransferPreview,
  [TransferDipStep.TransferSignature]: TransferSignature,
  [TransferDipStep.FinalPage]: FinalPage,
};

const Fallback = () => <PrimarySkeleton fullHeight fullWidth />;

export const TransferDip: FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const contractID = Number.parseInt(params.id as string);
  const { actualStep, error } = useAppSelector(s => s.contracts.transferDip);
  const { settings, isLoading: isLoadingSettings } = useSettings();
  const { isDesktop } = useDesktop();

  const { isLoading: isLoadingOverview, data: overview } = useAppSelector(
    state => state.investmentsDetail.overview,
  );

  const isSelfNegotiatedContract = useIsSelfNegotiatedContract(overview?.type);

  useEffect(() => {
    dispatch(resetTransferDipState());
    dispatch(clearAppSettings());
    dispatch(setTransferDipContractID(contractID));
    dispatch(getOverviewAsync.request(contractID));
  }, [dispatch, contractID]);

  const isLoading =
    (isLoadingOverview && !overview) || (isLoadingSettings && !settings);

  const Component = Components[actualStep];

  return (
    <AuthenticatedLayout
      title={t(Resources.Contract.DipTransfer.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => {
        if (actualStep === TransferDipStep.TransferSignature) {
          dispatch(setTransferDipStep(TransferDipStep.TransferPreview));
          return;
        }

        navigate(getPath(AppRouting.ContractDetail, contractID));
      }}
    >
      <LoadingWrapper
        isLoading={isLoading}
        skeleton={<LoadingSkeleton />}
        error={error}
      >
        {!overview?.isDip &&
          settings?.isDIPEnabled &&
          isSelfNegotiatedContract && (
            <Suspense fallback={<Fallback />}>
              <Component />
            </Suspense>
          )}
        {(overview?.isDip ||
          !settings?.isDIPEnabled ||
          !isSelfNegotiatedContract) && (
          <>
            <div>
              <PageTitle>{t(PageResources.Title)}</PageTitle>
              <Typography>
                {overview?.isDip
                  ? t(PageResources.AlreadyTransferredMessage)
                  : t(PageResources.UnavailableMessage)}
              </Typography>
            </div>
            <PrimaryButton
              color="primary"
              onClick={() =>
                contractID
                  ? navigate(getPath(AppRouting.ContractDetail, contractID))
                  : navigate(getPath(AppRouting.Dashboard))
              }
            >
              {t(PageResources.FinalPage.ButtonText)}
            </PrimaryButton>
          </>
        )}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};

const LoadingSkeleton = () => (
  <>
    <div>
      <PrimarySkeleton
        height={30}
        fullHeight={false}
        width="50%"
        marginBottom={10}
      />
      <PrimarySkeleton height={60} fullHeight={false} marginBottom={30} />
      <PrimarySkeleton
        height={20}
        fullHeight={false}
        width="50%"
        marginBottom={20}
      />
      <PrimarySkeleton
        height={60}
        fullHeight={false}
        width="75%"
        marginBottom={30}
      />
      <PrimarySkeleton
        height={20}
        fullHeight={false}
        width="50%"
        marginBottom={20}
      />
      <PrimarySkeleton
        height={130}
        fullHeight={false}
        width="75%"
        marginBottom={20}
      />
      <Box display="flex" justifyContent="end">
        <PrimarySkeleton
          height={20}
          fullHeight={false}
          width={140}
          marginBottom={20}
        />
      </Box>
      <PrimarySkeleton
        height={20}
        fullHeight={false}
        width="50%"
        marginBottom={10}
      />
      <PrimarySkeleton height={60} fullHeight={false} marginBottom={20} />
      <PrimarySkeleton height={90} fullHeight={false} />
    </div>
    <PrimarySkeleton height={60} fullHeight={false} />
  </>
);
