import { ArrowRightFatIcon } from "Components/Shared/Icons";
import { FunctionComponent } from "react";

type Props = {
  fondName: string;
  targetFondName?: string;
};

export const FondName: FunctionComponent<Props> = ({
  fondName,
  targetFondName,
}) => (
  <div>
    <div>{fondName}</div>
    {targetFondName && (
      <>
        <ArrowRightFatIcon />
        <div>{targetFondName}</div>
      </>
    )}
  </div>
);
