import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { DipDescriptionDialogCzech } from "Components/ContractModeling/FundSelector/Components/DipDescriptionDialogCzech";
import { CloseIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { DESKTOP_BREAKPOINT } from "Constants/Layout";
import { useDesktop } from "Hooks/useDesktop";
import { useResource, Resources } from "Translations/Resources";
import { FunctionComponent } from "react";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    @media (max-width: ${props =>
        props.theme.breakpoints.values[DESKTOP_BREAKPOINT]}px) {
      height: calc(100vh - 90px);
      margin-top: 90px;
      width: 90%;
    }

    @media (min-width: ${props =>
        props.theme.breakpoints.values[DESKTOP_BREAKPOINT]}px) {
      max-height: 80vh;
      max-width: calc(
        ${props => props.theme.breakpoints.values[DESKTOP_BREAKPOINT]}px * 0.8
      );
    }

    border-radius: 16px;
    border: 1px solid ${props => props.theme.colors.border}60;
  }

  .MuiDialog-paper {
    box-shadow: none;
    background-color: ${props => props.theme.colors.gray};
  }
`;

const StyledCloseIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: ${props => props.theme.palette.secondary.main}25;
  border-radius: 50%;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 21px;
  height: 21px;
  color: ${props => props.theme.palette.text.primary};
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ComponentResources = Resources.Investments.FundSelection.DIP.Dialog;

export const DipDescriptionDialog: FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  return (
    <StyledDialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      hideBackdrop={!isDesktop}
    >
      <DialogContent>
        <Box marginBottom={3}>
          <StyledFlex $justifyContent="space-between" $alignItems="center">
            <Typography component="span" fontWeight={700} fontSize={22}>
              {t(ComponentResources.Title)}
            </Typography>
            <Box>
              <StyledCloseIconWrapper onClick={onClose}>
                <StyledCloseIcon />
              </StyledCloseIconWrapper>
            </Box>
          </StyledFlex>
        </Box>

        <DipDescriptionDialogCzech></DipDescriptionDialogCzech>
      </DialogContent>
    </StyledDialog>
  );
};
