import { NetworkErrorFallbackDialog } from "../Errors/NetworkErrorFallbackDialog";
import { debounce } from "lodash-es";

import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useDesktop } from "Hooks/useDesktop";

export const NetworkStatusWrapper: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { isDesktop } = useDesktop();
  const [isOnline, setIsOnline] = useState(true);
  const [isDialogVisible, setIsDialogVisible] = useState(true);

  useEffect(() => {
    const debouncedSetIsOnline = debounce((value: boolean) => {
      setIsOnline(value);
      setIsDialogVisible(!value);
    }, 500);

    function changeNetworkStatus() {
      debouncedSetIsOnline(navigator.onLine);
    }

    window.addEventListener("online", changeNetworkStatus);
    window.addEventListener("offline", changeNetworkStatus);

    return () => {
      window.removeEventListener("online", changeNetworkStatus);
      window.removeEventListener("offline", changeNetworkStatus);
    };
  }, []);

  return (
    <>
      {children}
      <NetworkErrorFallbackDialog
        isOpen={!isOnline && (!isDesktop || isDialogVisible)}
        onClose={isDesktop ? () => setIsDialogVisible(false) : undefined}
      />
    </>
  );
};
