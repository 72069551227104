import { SourceOfIncomeType } from "Api/Api";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { useFormOptions } from "Hooks/Contract/AMLQuestions/useFormOptions";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";

const ComponentResources = Resources.Contract.NewContract.AMLQuestions;

export const SourceOfIncome: FunctionComponent = _ => {
  const { t } = useResource();
  const { sourceOfIncomeOptions } = useFormOptions();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<AMLQuestionsFormModel>();

  const income = watch("sourceOfIncome");

  return (
    <div>
      <RadioButtons
        label={t(ComponentResources.IncomeSource)}
        control={control}
        errors={errors}
        name="sourceOfIncome"
        codeList={sourceOfIncomeOptions}
      />
      {income === SourceOfIncomeType.Other && (
        <BlInputFormField
          control={control}
          errors={errors}
          name="sourceOfIncomeOther"
          variant="standard"
          placeholder="Popište"
        />
      )}
    </div>
  );
};
