import { Colors } from "Components/Layout/Themes/Colors";
import { ProductsIsinDetail } from "Constants/Products";
import {
  ProductTradingFrequencyCode,
  ProductsIsin,
  ProductsIsinValues,
} from "Models/Products";
import { Resources } from "Translations/Resources";
import { isNoU } from "Utils/ObjectUtils";

export const isKnownIsin = (
  value?: string | null | undefined,
): value is ProductsIsin => {
  return !!(value && value in ProductsIsinDetail);
};

export const getProductsClassCode = (
  isin?: string | null | undefined,
): string => {
  const knownIsin = isKnownIsin(isin);

  if (knownIsin) {
    const classCode = ProductsIsinDetail[isin]?.class as string;
    const formatted = classCode.replaceAll("_", " ");

    return formatted;
  }

  return "";
};

export const getLocalizedFundDescription = (
  code: string | null | undefined,
  t: (resourcePath: string, options?: any) => string,
): string | undefined => {
  if (isNoU(code)) {
    return undefined;
  }

  switch (code) {
    case "IRF":
      return t(
        Resources.Investments.FundSelection.FundSelectOptions.Investika
          .Description,
      );
    case "MON":
      return t(
        Resources.Investments.FundSelection.FundSelectOptions.Monetika
          .Description,
      );
    case "EFE":
      return t(
        Resources.Investments.FundSelection.FundSelectOptions.Efektika
          .Description,
      );
    case "EURMON":
      return t(
        Resources.Investments.FundSelection.FundSelectOptions.Euromonetika
          .Description,
      );
    default:
      return undefined;
  }
};

export const getContractWaitingInvestedTooltip = (
  isin: string | null | undefined,
  t: (resourcePath: string, options?: any) => string,
): string | undefined => {
  const functionResources =
    Resources.Contract.Detail.Overview.UninvestedDepositsTooltip;

  if (isKnownIsin(isin)) {
    const productDetail = ProductsIsinDetail[isin];

    if (productDetail) {
      switch (productDetail.tradingFrequency) {
        case ProductTradingFrequencyCode.DAILY:
          return t(functionResources.DailyTradeFrequency);
        case ProductTradingFrequencyCode.MONTHLY:
          return t(functionResources.MonthlyTradeFrequency);
        default:
          return undefined;
      }
    }
  }

  return undefined;
};

export const getFundDescription = (
  isin: string | null | undefined,
  t: (resourcePath: string, options?: any) => string,
  minPerformance: number | null | undefined,
  maxPerformance: number | null | undefined,
): string => {
  const functionResources =
    Resources.Investments.FundSelection.ExpectedAppreciation;

  if (isKnownIsin(isin)) {
    switch (isin) {
      case "CZ0008477650": // EFE CZK
      case "CZ0008477676": // EFE HEDGED
      case "CZ0008477668": // EFE EUR
        return t(functionResources.Efektika);
      default:
        return t(functionResources.Default, {
          min: minPerformance,
          max: maxPerformance,
        });
    }
  }

  return t(functionResources.Default, {
    min: minPerformance,
    max: maxPerformance,
  });
};

export const getProductDetail = (isin: string | null | undefined) => {
  if (isKnownIsin(isin)) {
    return ProductsIsinDetail[isin];
  }

  return undefined;
};

export const getProductColor = (isin: string | null | undefined) => {
  const product = getProductDetail(isin);
  if (product) {
    return { color: product.color, contrastColor: product.contrastColor };
  }

  return { color: Colors.White, contrastColor: Colors.Black };
};

export const getProductHeaderBackground = (isin: string | null | undefined) => {
  if (isKnownIsin(isin)) {
    const product = ProductsIsinDetail[isin];
    if (product) {
      return product.headerImage;
    }
  }

  return undefined;
};

export const getFundColor = (code: string | null | undefined) => {
  for (const productIsin of ProductsIsinValues) {
    const product = getProductDetail(productIsin);
    if (product && product.code === code) {
      return { color: product.color, contrastColor: product.contrastColor };
    }
  }

  return { color: Colors.White, contrastColor: Colors.Black };
};
