import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { VALIDATION_POSTAL_CODE_COUNTRIES } from "Constants/Inputs";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { type ContactInformationFormModel } from "Models/Forms";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  formType: keyof ContactInformationFormModel["addresses"];
  disabled?: boolean;
};

const PageResources = Resources.Contract.NewContract.ContactInformation;
const FormResources = Resources.Forms.PersonalData;

export const ContantForm: FunctionComponent<Props> = ({
  formType,
  disabled,
}) => {
  const { t } = useResource();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<ContactInformationFormModel>();
  const countriesList = useCountriesList();

  const country = watch(`addresses.${formType}.country`);
  const isValidatedPostalCode =
    VALIDATION_POSTAL_CODE_COUNTRIES.includes(country);

  return (
    <>
      <BlInputFormField
        control={control}
        name={`addresses.${formType}.street`}
        errors={errors}
        label={t(FormResources.Street)}
        disabled={disabled}
      />

      <StyledFlex $gap={2}>
        <BlInputFormField
          control={control}
          name={`addresses.${formType}.streetConscriptionNumber`}
          errors={errors}
          label={t(FormResources.StreetConscriptionNumber)}
          disabled={disabled}
        />
        <BlInputFormField
          control={control}
          name={`addresses.${formType}.streetNumber`}
          errors={errors}
          label={t(FormResources.StreetNumber)}
          disabled={disabled}
        />
      </StyledFlex>

      <BlInputFormField
        control={control}
        name={`addresses.${formType}.city`}
        errors={errors}
        label={t(PageResources.City)}
        disabled={disabled}
      />
      <BlInputFormField
        control={control}
        name={`addresses.${formType}.postalCode`}
        isMaskLazy={false}
        errors={errors}
        label={t(PageResources.PostalCode)}
        inputMode="numeric"
        mask={isValidatedPostalCode ? "000 00" : new RegExp("^[0-9]*$")}
        disabled={disabled}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
      <DropdownInput
        options={countriesList}
        control={control}
        name={`addresses.${formType}.country`}
        errors={errors}
        label={t(PageResources.Country)}
        disabled={disabled}
      />
    </>
  );
};
