import * as React from "react";
export const InfoIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 1C6.15465 1 1 6.15465 1 12.5C1 18.8454 6.15465 24 12.5 24C18.8454 24 24 18.8454 24 12.5C24 6.15465 18.8454 1 12.5 1ZM12.5 2C18.3049 2 23 6.69509 23 12.5C23 18.3049 18.3049 23 12.5 23C6.69509 23 2 18.3049 2 12.5C2 6.69509 6.69509 2 12.5 2ZM12.5 5.5C12.1022 5.5 11.7206 5.65804 11.4393 5.93934C11.158 6.22064 11 6.60218 11 7C11 7.39782 11.158 7.77936 11.4393 8.06066C11.7206 8.34196 12.1022 8.5 12.5 8.5C12.8978 8.5 13.2794 8.34196 13.5607 8.06066C13.842 7.77936 14 7.39782 14 7C14 6.60218 13.842 6.22064 13.5607 5.93934C13.2794 5.65804 12.8978 5.5 12.5 5.5ZM10.5 10.5V11.5H11H11.5V18H11H10.5V19H11H11.5H13.5H14H14.5V18H14H13.5V10.5H13H11H10.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
