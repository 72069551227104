import {
  GetContractsStateType,
  getContractsReducer,
  getContractsSaga,
  getContractsState,
} from "State/Dashboard/Contracts/GetContractsState";
import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type DashboardState = {
  contracts: GetContractsStateType;
};

export const getInitialState = (): DashboardState => ({
  contracts: getContractsState(),
});

export type DashboardAction = unknown;

export function* watchDashboardSaga() {
  yield all([getContractsSaga()]);
}

export const dashboardReducer = combineReducers<DashboardState>({
  contracts: getContractsReducer,
});
