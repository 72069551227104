import { useSaveContractDraft } from "Hooks/Contract/useSaveContractDraft";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  setNextStep,
  setPreviousStep,
} from "State/Contracts/Create/CreateState";
import { FormModels } from "State/Contracts/Create/Models";
import { useCallback } from "react";

type NextStepProps = {
  formData?: FormModels;
  isContractCreated?: boolean;
  isContractSigned?: boolean;
  saveDraft?: boolean;
};

export const useCreateContractStep = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(s => s.contracts.create.processInformation);
  const { postDraft } = useSaveContractDraft();

  const handleNextStep = useCallback(
    ({
      formData,
      isContractCreated = false,
      isContractSigned = false,
      saveDraft = true,
    }: NextStepProps = {}) => {
      if (!data?.steps) {
        return;
      }

      dispatch(setNextStep({ steps: data?.steps, formData }));

      if (saveDraft) {
        postDraft({ isContractCreated, isContractSigned });
      }
    },
    [dispatch, data?.steps, postDraft],
  );

  const handlePreviousStep = useCallback(() => {
    if (!data?.steps) {
      return;
    }

    dispatch(setPreviousStep({ steps: data.steps }));
    postDraft({ isContractCreated: false, isContractSigned: false });
  }, [dispatch, data?.steps, postDraft]);

  return {
    nextStep: handleNextStep,
    previousStep: handlePreviousStep,
    steps: data?.steps,
  };
};
