import { usePasswordValidation } from "Hooks/YupValidations/usePasswordValidation";
import { Resources, useResource } from "Translations/Resources";
import { object, string } from "yup";

export const useChangePasswordSchema = () => {
  const { t } = useResource();
  const passwordValidation = usePasswordValidation();

  return object().shape({
    oldPassword: string().required(),
    newPassword: passwordValidation,
    confirmPassword: string()
      .required()
      .test(
        "confirmPassword",
        t(Resources.Profile.Settings.PasswordNotMatch),
        function (value) {
          return value === this.parent.newPassword;
        },
      ),
  });
};
