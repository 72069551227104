import * as React from "react";
export const GrandfatherIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 1C11.1191 1 10 2.11914 10 3.5C10 4.88086 11.1191 6 12.5 6C13.8809 6 15 4.88086 15 3.5C15 2.11914 13.8809 1 12.5 1ZM10.5 7C8.56641 7 7 8.56641 7 10.5V15.5L8.45312 16.1094L9 23.5H12L12.9062 11.2578L15.4121 15.4766C15.1582 15.7461 15 16.1055 15 16.5V17H16V16.5C16 16.2168 16.2168 16 16.5 16C16.7832 16 17 16.2168 17 16.5V24H18V16.5C18 15.8535 17.5781 15.3027 17 15.0938V15L14.2012 9.48438C13.6035 8.02734 12.1719 7 10.5 7Z"
        fill="currentColor"
      />
    </svg>
  );
};
