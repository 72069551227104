import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import {
  RadioButtons,
  type Props as RaddioButtonsProps,
} from "Components/Shared/Inputs/Form/RadioButtons";
import { type FieldValues } from "react-hook-form";
import { CodeListItem } from "Models/CodeListItem";

type Props<T extends FieldValues> = Omit<
  RaddioButtonsProps<T, boolean>,
  "codeList"
>;

export const BooleanRadioButtons: FunctionComponent<any> = <
  T extends FieldValues,
>(
  props: Props<T>,
) => {
  const { t } = useResource();

  const codeList: CodeListItem<boolean>[] = [
    {
      code: true,
      name: t(Resources.Common.Yes),
    },
    {
      code: false,
      name: t(Resources.Common.No),
    },
  ];

  return <RadioButtons<T, boolean> {...props} codeList={codeList} />;
};
