export const DashboardLogo = (
  props: {
    height?: number;
    width?: number;
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
  };
  return (
    <svg
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      fill="none"
      viewBox="137.5 254.5 566.9 86.1"
    >
      <g>
        <polygon
          fill="white"
          points="296.8,338.6 277,338.6 246,256.6 268.2,256.6 287.2,314.8 287.6,314.8 306.5,256.6 328.4,256.6  "
        />
        <polygon
          fill="white"
          points="332,338.6 332,256.6 387.1,256.6 387.1,273.4 351.1,273.4 351.1,288.8 385.2,288.8 385.2,304.7    351.1,304.7 351.1,321.7 389.2,321.7 389.2,338.6  "
        />
        <g>
          <path
            fill="white"
            d="M442.5,277.9c-3.4-4.3-9.5-7.1-14.6-7.1c-5.1,0-11.5,1.7-11.5,8.2c0,5.4,4.9,7.2,12.6,9.6    c11.1,3.6,25.5,8.3,25.5,24.7c0,18.9-15.2,27.3-31.4,27.3c-11.7,0-23.5-4.3-30.7-11.8l13-13.2c3.9,5,11.2,8.7,17.7,8.7    c6,0,11.3-2.3,11.3-8.9c0-6.3-6.3-8.2-17-11.7c-10.4-3.4-21-8.7-21-23.5c0-18.2,16.4-25.7,31.7-25.7c9.3,0,19.7,3.5,26.9,10.1    L442.5,277.9z"
          />
          <path
            fill="white"
            d="M138.8,287.5v51.1h19.9v-51.3c-3,1.6-6.5,2.4-10.1,2.4C145,289.8,141.7,289,138.8,287.5 M223.1,256.6    l0.5,53.5h-0.3l-32.9-53.5h-22.6v0c1.9,3.2,2.9,7,2.9,11c0,4-1.1,7.8-2.9,11v60H187l-0.5-53.6h0.3l33,53.6h22.5v-82H223.1z"
          />
        </g>
        <path
          fill="white"
          d="M148.6,256.6c-6.1,0-11.1,5-11.1,11.1c0,6.1,5,11.1,11.1,11.1s11.1-5,11.1-11.1   C159.7,261.5,154.7,256.6,148.6,256.6z"
        />
        <g>
          <path
            fill="white"
            d="M670.4,256.6h-19.2l-27.3,65l-23-27.2l35.3-37.8h-24.8L582.1,290h-0.3v-33.4h-19.1c1.9,3.2,2.9,7,2.9,11    c0,4.3-1.2,8.2-3.3,11.6v59.4h19.5v-37.8h0.2l30.5,37.8h25.8l6-16.1H676l6.4,16.1h22L670.4,256.6z M650,306.7l10.4-28.1l10.2,28.1    H650z M458.2,256.6v16.9h23.2v65.1h19.8v-65.1h20.9c-0.5-1.9-0.8-3.9-0.8-5.9c0-4,1.1-7.8,2.9-11H458.2z M533.3,287.3v51.3h19.9    v-51.1c-2.9,1.5-6.3,2.3-9.8,2.3C539.8,289.8,536.3,288.9,533.3,287.3"
          />
        </g>
        <path
          fill="white"
          d="M543.4,256.6c-6.1,0-11.1,5-11.1,11.1c0,6.1,5,11.1,11.1,11.1c6.1,0,11.1-5,11.1-11.1   C554.5,261.5,549.6,256.6,543.4,256.6z"
        />
      </g>
    </svg>
  );
};
