import { JWT_KEY } from "Utils/AuthUtils";
import { logError } from "ErrorService";
import { LogDto, postTracking } from "Api/Api";
import { v4 as uuidv4 } from "uuid";
import { setTag, setUser, withScope } from "@sentry/react";
import { appWindow } from "Utils/WindowUtils";

const isSentryEnabled = import.meta.env.VITE_SENTRY_IS_ENABLED === "true";

export function trackUserIdentification() {
  setSentryIdentification();
}

function setSentryIdentification() {
  if (!isSentryEnabled) {
    return;
  }

  try {
    const user = parseSentryUserFromJWT();

    if (!!user) {
      setUser(user);
      setTag("user.login", user.username);

      withScope(scope => {
        scope.setUser(user);
        scope.setTag("user.login", user.username);
      });
    }
  } catch (error) {
    logError(error as Error);
  }
}

export function parseSentryUserFromJWT() {
  var token = localStorage.getItem(JWT_KEY);

  if (!token) {
    return;
  }

  var login = decodeJWT(token).nameid;

  const user = {
    id: login,
    email: login,
    username: login,
  };

  return user;
}

type JwtToken = {
  nameid: string;
  unique_name: string;
  email: string;
  nbf: number;
  exp: number;
  iat: number;
  aud: string;
};

function decodeJWT(token: string): JwtToken {
  return JSON.parse(window.atob(token.split(".")[1]));
}

export type TrackingCategory =
  | "AppState"
  | "PageView"
  | "ModalView"
  | "Auth"
  | "SMSVerification"
  | "ExternalUrl"
  | "Button"
  | "Input"
  | "RadioButtons"
  | "Banner"
  | "CategoryClick"
  | "Document"
  | "IDblock"
  | "InsufficientNecessarilyData"
  | "IDinvalidDateValidTo"
  | "User"
  | "InvestmentType"
  | "Dropdown"
  | "Checkbox"
  | "Switch"
  | "DeviceType"
  | "RestrictedAccess";

export type TrackingEvent =
  | "SignIn"
  | "SignOut"
  | "Submit"
  | "Open"
  | "Close"
  | "Change"
  | "ChangeByApp"
  | "Click"
  | "Toggle"
  | "FundSelectorDescriptionDialog"
  | "FundClassSelectorDialog"
  | "PreContractualInformationAgreement"
  | "BankID"
  | "MenuClick"
  | "DIP"
  | "FundDealerDialog"
  | "Notification"
  | "CategoryClick"
  | "NotificationBanner"
  | "DoubleClient"
  | "FundNotAllowedDialog"
  | "GoBack"
  | "SlideComplete"
  | "Distribution";

export type TrackingAction =
  | "Open"
  | "Close"
  | "NextStep"
  | "Click"
  | "GetProfile"
  | "Download"
  | "Start";

export type TrackingPayload = {
  url?: string | null;
  category: TrackingCategory;
  event: TrackingEvent;
  action?: TrackingAction;
  channelID?: string | null;
  tag?: string | null;
  value?: string | null;
};

export function getTrackingSessionID() {
  const sessionID = sessionStorage.getItem("sessionID");

  if (!!sessionID) {
    return sessionID;
  }

  const newSesssionID = uuidv4();
  sessionStorage.setItem("sessionID", newSesssionID);

  return newSesssionID;
}

function getUserID() {
  const token = localStorage.getItem(JWT_KEY);

  if (!token) {
    return null;
  }

  return decodeJWT(token).nameid;
}

export function setTracking(isEnabled: boolean) {
  appWindow.isTrackingEnabled = isEnabled;
}

export function isTrackingEnabled() {
  return appWindow.isTrackingEnabled ?? true;
}

const trackingQueue: LogDto[] = [];

setInterval(() => {
  if (isTrackingSet()) {
    processTrackingQueue();
  }
}, 5000);

export function track(payload: TrackingPayload) {
  const channelID = new URLSearchParams(window.location.search).get(
    "channelID",
  );

  const logData: LogDto = {
    sessionID: getTrackingSessionID(),
    userID: getTrackingUserID(),
    dateCreatedUtc: new Date().toISOString(),
    url: payload.url ?? getDefaultTrackingUrl(),
    event: payload.event,
    category: payload.category,
    action: payload.action,
    channelID: channelID ?? payload.channelID,
    tag: payload.tag,
    value: payload.value,
  };
  if (!isTrackingSet()) {
    trackingQueue.push(logData);
    return;
  }

  postTracking(logData).catch(error => {
    logError(error, null, false);
  });
}

function getTrackingUserID(): string | null | undefined {
  return isTrackingEnabled() ? getUserID() : "USER_WITH_DISABLED_TRACKING";
}

function processTrackingQueue() {
  while (trackingQueue.length > 0) {
    const logData = trackingQueue.shift();
    if (!!logData) {
      logData.userID = getTrackingUserID();

      postTracking(logData).catch(error => {
        logError(error, null, false);
      });
    }
  }
}
function isTrackingSet() {
  return appWindow.isTrackingEnabled !== undefined;
}

appWindow.track = track;

export function setCustomDefaultUrlPostfix(url: string | null) {
  appWindow.customDefaultUrlPostfix = url;
}

export function getDefaultTrackingUrlBase(): string {
  return `${window.location.pathname}${window.location.search}${window.location.hash}`;
}

export function getDefaultTrackingUrl(): string {
  return `${getDefaultTrackingUrlBase()}${
    appWindow.customDefaultUrlPostfix ?? ""
  }`;
}

export function openExternalLinkTracked(url: string, target?: string) {
  track({
    category: "ExternalUrl",
    event: "Open",
    action: "Open",
    value: url,
  });

  window.open(url, target);
}
