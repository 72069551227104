import { Box, Stack, Typography } from "@mui/material";
import { Step } from "Components/ContractCreate/Shared/Step";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { PageTitle } from "../Shared/PageTitle";
import { useFormOptions } from "Hooks/Contract/AMLQuestions/useFormOptions";
import { useFormData } from "Hooks/Contract/useFormData";
import {
  AMLQuestionsFormModel,
  NewContractStep,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useYupFormSchema } from "Hooks/Contract/AMLQuestions/useYupFormSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { SourceOfIncome } from "Components/ContractCreate/AMLQuestions/components/SourceOfIncome";
import { TaxResidency } from "Components/ContractCreate/AMLQuestions/components/TaxResidency";
import { SourceOfFunds } from "Components/ContractCreate/AMLQuestions/components/SourceOfFunds";
import { PoliticallyExposed } from "Components/ContractCreate/AMLQuestions/components/PoliticallyExposed";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { USA_NATIONALITY_FORBID_TYPE } from "Constants/Contracts/Create";
import { CurrencyCode } from "Api/Api";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const PageResources = Resources.Contract.NewContract.AMLQuestions;

export const AMLQuestions: FunctionComponent = () => {
  const { t } = useResource();
  const { defaultValues } = useFormData<NewContractStep.AMLQuestions>({
    step: NewContractStep.AMLQuestions,
  });
  const { nextStep } = useCreateContractStep();

  const { color, contrastColor } = useModelingColor();
  const schema = useYupFormSchema();
  const form = useForm<AMLQuestionsFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      fundsVolumeCurrency: CurrencyCode.CZK,
      sourceOfFunds: [],
      ...defaultValues,
    },
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const { annualIncomeOptions, businessSectorOptions, jobPositionOptions } =
    useFormOptions();

  const onSubmit = (formData: AMLQuestionsFormModel) => {
    nextStep({ formData });
  };

  const [displaySourceOfIncome, setDisplaySourceOfIncome] = useState(false);

  const onSourceOfIncomeChange = (shouldDisplaySourceOfIncome: boolean) => {
    setDisplaySourceOfIncome(shouldDisplaySourceOfIncome);
  };

  return (
    <>
      <div>
        <Step actualStep={4} />

        <PageTitle>{t(PageResources.Title)}</PageTitle>
        <Typography marginBottom={4}>{t(PageResources.Label)}</Typography>

        <Stack spacing={2}>
          <FormProvider {...form}>
            <RadioButtons
              label={t(PageResources.AnnualIncome)}
              control={control}
              errors={errors}
              name="annualIncome"
              codeList={annualIncomeOptions}
            />

            <DropdownInput
              label={t(PageResources.FieldOfActivity)}
              control={control}
              errors={errors}
              name="businessSector"
              options={businessSectorOptions}
              placeholder={t(Resources.Common.Choose)}
            />

            <SourceOfIncome />

            <RadioButtons
              label={
                <>
                  {t(PageResources.JobPosition)}{" "}
                  <BlInfoPopper>
                    {t(PageResources.JobPositionPopper)}
                  </BlInfoPopper>
                </>
              }
              control={control}
              errors={errors}
              name="jobPosition"
              codeList={jobPositionOptions}
            />

            <SourceOfFunds onSelect={onSourceOfIncomeChange} />

            {displaySourceOfIncome && (
              <Box display="flex" marginBottom={2}>
                <BlInputFormField
                  control={control}
                  fullWidth
                  name="fundsVolume"
                  errors={errors}
                  placeholder={t(PageResources.Roughly)}
                  label={t(PageResources.FundsVolume)}
                  mask={Number}
                  max={100_000_000}
                  min={0}
                  inputProps={{
                    max: 100_000_000,
                    min: 0,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
                <Box width={160}>
                  <DropdownInput
                    label="Měna"
                    control={control}
                    name="fundsVolumeCurrency"
                    options={[
                      { Name: "CZK", Value: "CZK" },
                      { Name: "EUR", Value: "EUR" },
                    ]}
                    errors={errors}
                  />
                </Box>
              </Box>
            )}

            <PoliticallyExposed />

            <TaxResidency />
          </FormProvider>
        </Stack>
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={
          errors?.taxResidencyCountry?.type === USA_NATIONALITY_FORBID_TYPE
        }
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
