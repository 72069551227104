import { SourceOfResourcesType } from "Api/Api";
import { BlCheckboxesFormField } from "Components/Shared/Inputs/Form/BlCheckboxesFormField";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { useFormOptions } from "Hooks/Contract/AMLQuestions/useFormOptions";
import { useEffect, type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources = Resources.Contract.NewContract.AMLQuestions;

type Props = {
  onSelect: (shouldDisplaySourceOfIncome: boolean) => void;
};

export const SourceOfFunds: FunctionComponent<Props> = props => {
  const { onSelect } = props;
  const { t } = useResource();
  const { sourceOfFundsOptions } = useFormOptions();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<AMLQuestionsFormModel>();

  const funds = watch("sourceOfFunds");

  useEffect(() => {
    if (funds.some(fund => fund !== SourceOfResourcesType.Savings)) {
      onSelect(true);
      return;
    }

    onSelect(false);
  }, [funds, onSelect]);

  return (
    <div>
      <BlCheckboxesFormField
        control={control}
        name="sourceOfFunds"
        codeList={sourceOfFundsOptions}
        errors={errors}
        label={t(ComponentResources.SourceOfFunds)}
      />
      {funds?.includes(SourceOfResourcesType.Other) && (
        <BlInputFormField
          control={control}
          errors={errors}
          name="sourceOfFundsOther"
          variant="standard"
          placeholder="Popište"
        />
      )}
    </div>
  );
};
