import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { authReducer, AuthAction, AuthState } from "State/Auth/AuthReducer";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { nameof } from "Utils/ObjectUtils";
import { logError } from "ErrorService";
import { signInSaga } from "State/Auth/SignIn/SignInState";
import { signUpSaga } from "State/Auth/SignUp/SignUpState";
import { verifyEmailSaga } from "State/Auth/Verifications/EmailVerificationState";
import {
  codeListReducer,
  watchCodeListSaga,
} from "State/CodeLists/CodeListReducer";
import { resetPasswordSaga } from "State/Auth/Passwords/ResetPasswordState";
import { uploadReducer } from "State/Upload/UploadReducer";
import { resetUserSaga } from "State/Auth/ResetUser/ResetUserState";
import { resendVerificationEmailSaga } from "State/Auth/Verifications/ResendVerificationEmailState";
import { all } from "redux-saga/effects";
import { getBankIDProfileSaga } from "State/BankID/GetBankIDProfileState";
import { bankIDReducer } from "State/BankID/BankIDReducer";
import {
  investmentsDetailReducer,
  watchInvestmentsDetailSaga,
} from "State/InvestmentsDetail/InvestmentsDetailReducer";
import {
  dashboardReducer,
  watchDashboardSaga,
} from "State/Dashboard/DashboardReducer";
import { errorSaga } from "State/Errors/ErrorsSaga";
import {
  productsReducer,
  watchProductsSaga,
} from "State/Products/ProductsReducer";
import { handleOnWebViewMessageSaga } from "State/WebView/WebViewListenerSaga";
import { biometricSignUpChallengeSaga } from "State/Auth/Biometrics/BiometricSignUpChallengeState";
import { signUpBiometricsSaga } from "State/Auth/Biometrics/BiometricSignUpState";
import {
  documentsReducer,
  watchDocumentsSaga,
} from "State/Documents/DocumentsReducer";
import { getBiometricSignInChallengeSaga } from "State/Auth/Biometrics/BiometricSignInChallengeState";
import { signInBiometricsSaga } from "State/Auth/Biometrics/BiometricSignInState";
import {
  contractsReducer,
  watchContractsSaga,
} from "State/Contracts/ContractsReducer";
import { acceptTermsAndConditionsSaga } from "State/Auth/TermsAndConditions/AcceptTermsAndConditionsState";
import { updateClientNotificationStatusSaga } from "State/Auth/AppData/UpdateClientNotificationStatusState";
import { updateClientAnalyticsStatusSaga } from "State/Auth/AppData/UpdateClientAnalyticsStatusState";
import { watchCreateContractsSaga } from "State/Contracts/Create/CreateState";
import {
  paymentsReducer,
  watchPaymentsSaga,
} from "State/Payments/PaymentsReducer";
import { changePasswordSaga } from "State/Auth/Passwords/ChangePasswordState";
import { setPasswordSaga } from "State/Auth/Passwords/SetPasswordState";
import {
  checkAndProlongTokenAsync,
  checkAndProlongTokenSaga,
} from "State/Auth/Tokens/CheckAndProlongTokenState";
import { handleWebViewInitSaga } from "State/Auth/Biometrics/HandleWebViewInitState";
import {
  BiometricsState,
  biometricsReducer,
} from "State/Biometrics/BiometricsReducer";
import { deleteBiometricsSaga } from "State/Biometrics/Disable/DeleteBiometricsState";
import { signWithBiometrySaga } from "State/Contracts/Biometrics/SignWithBiometryState";
import { clientReducer, watchClientSaga } from "State/Client/ClientReducer";
import {
  getApiHealthStatusAsync,
  getApiHealthStatusSaga,
} from "State/Health/Api/ApiHealthState";
import { healthReducer } from "State/Health/HealthReducer";
import { watchPurchaseStateSagas } from "State/Contracts/Purchase/PurchaseState";
import { watchRedemptionStateSagas } from "State/Contracts/Redemption/RedemptionState";
import { watchUninvestedStateSagas } from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";
import { checkTokenExpirationSaga } from "State/Auth/Tokens/CheckTokenExpirationState";
import { createReduxEnhancer } from "@sentry/react";
import { SentryEnhancerOptions } from "@sentry/react/types/redux";
import { getType } from "typesafe-actions";
import { postPreviewAsync } from "State/Contracts/Create/PostPreview/PostPreviewState";
import { produce } from "immer";
import { initializeBiometricSignatureSaga } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { postDocumentsAsync } from "State/Contracts/Shared/Draft";
import {
  appSettingsReducer,
  watchAppSettingsSaga,
} from "State/App/Settings/GetSettingsReducer";
import {
  exchangeRatesReducer,
  watchExchangeRatesSaga,
} from "State/ExchangeRate/ExchangeRateReducer";
import { watchTransferDipStateSagas } from "State/Contracts/TransferDip/TransferDipReducer";
import { revokeTokenSaga } from "State/Auth/Tokens/RevokeTokenState";
import {
  notificationsReducer,
  NotificationsState,
  watchNotificationsStateSagas,
} from "State/Notifications/NotificationsReducer";
import {
  campaignsReducer,
  watchCampaignsStateSagas,
} from "State/Campaigns/CampaignsReducer";
import { metadataReducer } from "State/App/Metadata/MetadataReducer";

const authPersistConfig: PersistConfig<any> = {
  key: "auth",
  storage: storage,
  whitelist: [
    nameof<AuthState>("user"),
    nameof<AuthState>("unauthenticatedUrl"),
    nameof<AuthState>("emailVerification"),
    nameof<AuthState>("tokenExpiration"),
    nameof<AuthState>("isAuthenticated"),
    nameof<AuthState>("signInStep"),
    nameof<AuthState>("twoFactor"),
  ],
};

const authPersistReducer = persistReducer(
  authPersistConfig,
  authReducer,
) as unknown;

/**
 * Create global state of application
 */
const combinedReducer = combineReducers({
  auth: authPersistReducer as typeof authReducer,
  codeList: persistReducer(
    { key: "codeList", storage: storage },
    codeListReducer,
  ),
  upload: uploadReducer,
  contracts: contractsReducer,
  investmentsDetail: investmentsDetailReducer,
  notifications: persistReducer(
    {
      key: "notifications",
      storage: storage,
      whitelist: [
        nameof<NotificationsState>("isPermissionGranted").toString(),
        nameof<NotificationsState>("isEnabled").toString(),
        nameof<NotificationsState>("fcmToken").toString(),
      ],
    },
    notificationsReducer,
  ),
  metadata: metadataReducer,
  campaigns: campaignsReducer,
  bankID: bankIDReducer,
  dashboard: dashboardReducer,
  products: productsReducer,
  documents: documentsReducer,
  payments: paymentsReducer,
  biometrics: persistReducer(
    {
      key: "biometrics",
      storage: storage,
      whitelist: [
        nameof<BiometricsState>("canBeUsed").toString(),
        nameof<BiometricsState>("isBiometricsEnabled").toString(),
        nameof<BiometricsState>("wasSignedOutManually").toString(),
      ],
    },
    biometricsReducer,
  ),
  client: clientReducer,
  settings: appSettingsReducer,
  exchangeRate: exchangeRatesReducer,
  health: healthReducer,
});

/**
 * Type of global application state
 */
export type RootStateType = ReturnType<typeof combinedReducer>;

/**
 * All changes, that global application state can handle
 */
export type RootActionType = AuthAction;

export type AppDispatch = typeof store.dispatch;

const rootReducer = (state: RootStateType, action: RootActionType) =>
  combinedReducer(state, action);

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer<RootStateType>(
  persistConfig,
  rootReducer as any,
);

const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => {
    logError(error, errorInfo);
  },
});

const sentryReduxEnhancer = createReduxEnhancer({
  actionTransformer(action) {
    if (
      action.type === getType(postPreviewAsync.success) ||
      action.type === getType(postDocumentsAsync.request) ||
      action.type === getType(postDocumentsAsync.success)
    ) {
      return {
        ...action,
        payload: {
          data: "REMOVED_BY_ENHANCER",
        },
      };
    }

    if (
      action.type === getType(getApiHealthStatusAsync.request) ||
      action.type === getType(getApiHealthStatusAsync.success) ||
      action.type === getType(checkAndProlongTokenAsync.request) ||
      action.type === getType(checkAndProlongTokenAsync.success)
    ) {
      return null;
    }

    return action;
  },
  stateTransformer(state) {
    return produce(state, draftState => {
      if (!draftState) {
        return;
      }

      draftState.contracts.create.preview.data = "REMOVED_BY_ENHANCER" as any;
      draftState.codeList.banks.data = "REMOVED_BY_ENHANCER" as any;
      draftState.codeList.countries.data = "REMOVED_BY_ENHANCER" as any;
      draftState.codeList.currencies.data = "REMOVED_BY_ENHANCER" as any;
      draftState.codeList.businessSector.data = "REMOVED_BY_ENHANCER" as any;
    });
  },
} as SentryEnhancerOptions<RootStateType>);

const middlewares = [sagaMiddleware];
const composeEnhancers: <R>(a: R) => R =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const combinedEnhancers = compose(
  applyMiddleware(...middlewares),
  sentryReduxEnhancer,
);

const store = createStore(
  persistedReducer,
  composeEnhancers(combinedEnhancers),
);

function* rootSaga() {
  yield all([
    errorSaga(),
    signUpSaga(),
    signInSaga(),
    resetPasswordSaga(),
    verifyEmailSaga(),
    resetUserSaga(),
    resendVerificationEmailSaga(),
    revokeTokenSaga(),
    getBankIDProfileSaga(),
    watchContractsSaga(),
    watchInvestmentsDetailSaga(),
    watchDashboardSaga(),
    watchProductsSaga(),
    watchCodeListSaga(),
    handleOnWebViewMessageSaga(),
    biometricSignUpChallengeSaga(),
    signUpBiometricsSaga(),
    getBiometricSignInChallengeSaga(),
    signInBiometricsSaga(),
    watchDocumentsSaga(),
    acceptTermsAndConditionsSaga(),
    updateClientNotificationStatusSaga(),
    updateClientAnalyticsStatusSaga(),
    watchCreateContractsSaga(),
    watchPaymentsSaga(),
    changePasswordSaga(),
    setPasswordSaga(),
    checkAndProlongTokenSaga(),
    handleWebViewInitSaga(),
    deleteBiometricsSaga(),
    signWithBiometrySaga(),
    initializeBiometricSignatureSaga(),
    watchClientSaga(),
    getApiHealthStatusSaga(),
    watchPurchaseStateSagas(),
    watchRedemptionStateSagas(),
    watchUninvestedStateSagas(),
    watchTransferDipStateSagas(),
    checkTokenExpirationSaga(),
    watchAppSettingsSaga(),
    watchExchangeRatesSaga(),
    watchNotificationsStateSagas(),
    watchCampaignsStateSagas(),
  ]);
}

sagaMiddleware.run(rootSaga);

export const buildAppStore = () => {
  // (window as any).applicationStore = store;
  let persistor = persistStore(store as any);
  return { store, persistor };
};

const appStore = buildAppStore();

export const getAppStoreState = () => appStore.store.getState();

export default appStore;
