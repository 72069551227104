import { Stack } from "@mui/material";
import { Document } from "Components/Shared/Document";
import { LabelValue } from "Components/ContractDetail/Contract/components/LabelValue";
import { ComponentTitleWrapper } from "Components/Shared/ComponentTitleWrapper";
import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getDetailAsync } from "State/InvestmentsDetail/Detail/GetDetailState";
import { TabProps } from "Pages/Contracts/DetailPage";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { ContractStatusType, ContractTypeCode } from "Api/Api";
import { getKidTariffDocumentLink } from "Utils/DocumentUtils";
import { useSettings } from "Hooks/App/Settings/useSettings";
import { formatDate } from "Utils/DateUtils";

const PageResources = Resources.Contract.Detail.Contract;

const StatusResources: { [key in ContractStatusType]: string } = {
  [ContractStatusType.Active]: Resources.Common.Active,
  [ContractStatusType.Blocked]: Resources.Common.Blocked,
  [ContractStatusType.Canceled]: Resources.Common.Canceled,
  [ContractStatusType.Ended]: Resources.Common.Ended,
};

type Props = TabProps;

export const Contract: React.FunctionComponent<Props> = ({ contractID }) => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const { data, isLoading: loading } = useAppSelector(
    s => s.investmentsDetail.detail,
  );

  const { settings, isLoading: isLoadingSettings } = useSettings();
  const isLoading = (loading && !data) || isLoadingSettings;

  const displayContractManagement =
    data &&
    data.type !== ContractTypeCode.RS_INVCZKSELF &&
    data.type !== ContractTypeCode.RS_INVEURSELF;

  useEffect(() => {
    dispatch(getDetailAsync.request(contractID));
  }, [dispatch, contractID]);

  const kidTariffLink = getKidTariffDocumentLink(data?.type);
  const isDipEnabled = settings?.isDIPEnabled && data?.isDip;

  return (
    <div>
      <ComponentTitleWrapper title={t(PageResources.Details)} marginTop={0}>
        <LoadingWrapper isLoading={isLoading} wrapChildren fullWidth>
          <Stack gap={2}>
            <LabelValue
              label={t(PageResources.ContractStatus)}
              value={
                data?.status && StatusResources[data.status]
                  ? t(StatusResources[data.status])
                  : ""
              }
            />
            <LabelValue
              label={t(PageResources.ContractType)}
              value={data?.type ?? ""}
            />
            <LabelValue
              label={t(PageResources.ContractNumber)}
              value={data?.contractNo ?? ""}
            />
            {isDipEnabled && data?.dipOpeningDate && (
              <LabelValue
                label={t(PageResources.DipOpeningDateLabel)}
                value={formatDate(data.dipOpeningDate)}
              />
            )}
            {displayContractManagement && (
              <LabelValue
                label={t(PageResources.ManagementLabel)}
                value={t(PageResources.ManagementText)}
              />
            )}
          </Stack>
        </LoadingWrapper>
      </ComponentTitleWrapper>
      <ComponentTitleWrapper title={t(PageResources.Documents)}>
        <Stack marginTop={2}>
          <LoadingWrapper
            isLoading={isLoading}
            skeleton={
              <>
                <PrimarySkeleton height={50} />
                <PrimarySkeleton height={50} />
              </>
            }
          >
            {data?.documents?.map(({ fileName }) => (
              <Document
                key={fileName}
                name={fileName}
                contractID={contractID}
              />
            ))}
            {kidTariffLink && (
              <Document
                name={t(Resources.Common.KidTariffDocumentLink)}
                href={kidTariffLink}
              />
            )}
          </LoadingWrapper>
        </Stack>
      </ComponentTitleWrapper>
    </div>
  );
};
