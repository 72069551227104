import { Box } from "@mui/material";
import { FunctionComponent, ReactNode, useState } from "react";

type Props = {
  activator: ReactNode;
  deactivator?: ReactNode;
  children: ReactNode;
  initialVisibility?: boolean;
};

export const CollapsibleWrapper: FunctionComponent<Props> = ({
  activator,
  deactivator,
  children,
  initialVisibility = false,
}) => {
  const [isVisible, setIsVisible] = useState(initialVisibility);

  return (
    <>
      {!isVisible && (
        <Box display="inline-block" onClick={() => setIsVisible(true)}>
          {activator}
        </Box>
      )}
      {isVisible && deactivator}
      {isVisible && children}
    </>
  );
};
