import { Box } from "@mui/material";
import { TextButton } from "Components/Shared/Buttons/TextButton";
import { type FunctionComponent } from "react";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources = Resources.Components.InvoiceQRCode;

type Props = {
  onDownload: () => void;
};

export const SaveButton: FunctionComponent<Props> = ({ onDownload }) => {
  const { t } = useResource();
  return (
    <Box display="flex" justifyContent="center">
      <TextButton color="primary" onClick={onDownload}>
        {t(ComponentResources.SaveImage)}
      </TextButton>
    </Box>
  );
};
