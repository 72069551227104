import { ContractOverviewDto, getContractContractID } from "Api/Api";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { takeLeading } from "typed-redux-saga";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  handleCleanupAction,
  safeApiCall,
} from "State/Utils";
import {
  CleanupInvestmentStateActionType,
  cleanupInvestmentState,
} from "State/InvestmentsDetail/Shared/Actions";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";

export type GetOverviewStateType = FetchStateType<ContractOverviewDto>;

export const getOverviewState = (): GetOverviewStateType =>
  getFetchStateDefaultValue();

export type GetOverviewActionType =
  | ActionType<typeof getOverviewAsync>
  | CleanupInvestmentStateActionType;

export const getOverviewAsync = createAsyncAction(
  "@investments-detail/GET_OVERVIEW_REQUEST",
  "@investments-detail/GET_OVERVIEW_SUCCESS",
  "@investments-detail/GET_OVERVIEW_FAILURE",
)<Parameters<typeof getContractContractID>[0], ContractOverviewDto, Error>();

function* getOverview(
  action: ReturnType<typeof getOverviewAsync.request>,
): Generator {
  try {
    const { status, response, error } = yield* safeApiCall(
      getContractContractID,
      action.payload,
    );

    if (error) {
      yield put(getOverviewAsync.failure(error));
      return;
    }

    if (!response.contractOverview) {
      yield put(
        getOverviewAsync.failure(
          new ApplicationError(
            `Get contracts request ended with HTTP status code ${status}, request result status ${response?.status}`,
            ErrorLevel.Critical,
            {
              status,
              response: {
                status: response.status,
                error: response.error,
              },
              error,
            },
          ),
        ),
      );

      return;
    }

    yield put(getOverviewAsync.success(response.contractOverview));
  } catch (err) {
    yield put(getOverviewAsync.failure(err as Error));
  }
}

export function* getOverviewSaga() {
  yield takeLeading(getType(getOverviewAsync.request), getOverview);
}

export const getOverviewReducer = createReducer<
  GetOverviewStateType,
  GetOverviewActionType
>(getOverviewState())
  .handleAction(getOverviewAsync.request, handleActionRequest)
  .handleAction(getOverviewAsync.failure, handleActionFailure)
  .handleAction(getOverviewAsync.success, handleActionSuccess)
  .handleAction(cleanupInvestmentState, handleCleanupAction(getOverviewState));
