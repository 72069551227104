import * as React from "react";
export const CloseIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.57812 3.15625L3.15625 4.57812L11.0781 12.5L3.10938 20.4844L4.51562 21.8906L12.5 13.9219L20.4688 21.8906L21.8906 20.4688L13.9219 12.5L21.8438 4.57812L20.4219 3.15625L12.5 11.0781L4.57812 3.15625Z"
        fill="currentColor"
      />
    </svg>
  );
};
