import { SignUpForm, FormModel } from "Components/Auth/SignUp/SignUpForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { resetError, setSignInStep } from "State/Auth/AuthReducer";
import { SignInStep } from "State/Auth/Models/AuthStateModels";
import { signUpAsync } from "State/Auth/SignUp/SignUpState";

import { useEffect, useState } from "react";
import { getPath, AppRouting } from "Utils/UrlUtils";

export const SignUpPage: React.FunctionComponent = _ => {
  const { t } = useResource();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  const { error, isLoading } = useSelector((e: RootStateType) => e.auth);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const onSubmit = ({ login, password, agreeWithAnalytics }: FormModel) => {
    dispatch(resetError());
    dispatch(
      signUpAsync.request({
        model: {
          login,
          password,
          isAnalyticsEnabled: agreeWithAnalytics,
        },
        navigate,
      }),
    );
  };
  const [resetValue, toggleReset] = useState(true);
  const onFormReset = () => {
    toggleReset(!resetValue);
    dispatch(setSignInStep(SignInStep.Credentials));
  };

  const onBackClick = () => {
    navigate(getPath(AppRouting.StartPage));
  };

  return (
    <UnauthenticatedLayout
      title={t(Resources.SignUp.Title)}
      key={`${resetValue}`}
      onBackClick={onBackClick}
    >
      <SignUpForm
        key={email}
        isLoading={isLoading}
        onSubmit={onSubmit}
        serverError={error}
        onReset={onFormReset}
        defaultLogin={email}
      />
    </UnauthenticatedLayout>
  );
};

export default SignUpPage;
