import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { Resources, useResource } from "Translations/Resources";
import { useEffect, type FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router";
import { SuccessStep } from "Components/ContractRedemption/SuccessStep";
import { RedemptionSignatureFormStep } from "Components/ContractRedemption/RedemptionSignatureFormStep";
import { RedemptionConfigurationStep } from "Components/ContractRedemption/RedemptionConfigurationStep";
import { RedemptionNotAllowedDialog } from "Components/ContractRedemption/RedemptionNotAllowedDialog";
import { useIsRedemptionAllowed } from "Hooks/Contract/Detail/useIsRedemptionAllowed";
import {
  RedemptionStep,
  resetRedemptionState,
} from "State/Contracts/Redemption/RedemptionState";
import { useAppSelector } from "Hooks/useAppSelector";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useDesktop } from "Hooks/useDesktop";
import { useRedemptionTitle } from "Hooks/Contract/useRedemtionTitle";

const PageResources = Resources.Contract.Detail.Redemption;

export const RedemptionPage: FunctionComponent = _ => {
  const navigate = useNavigate();
  const params = useParams();

  const contractID = Number.parseInt(params.id as string);
  const isin = params.isin!;

  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const pageTitle = useRedemptionTitle();
  const isTitleShown = !isDesktop && !!pageTitle;

  const isRedemptionAllowed = useIsRedemptionAllowed(isin);

  const step = useAppSelector(s => s.contracts.redemption.step);

  const dispatch = useAppDispatch();

  const { data: overview, isLoading: isLoadingOverview } = useAppSelector(
    s => s.investmentsDetail.overview,
  );

  useEffect(() => {
    dispatch(resetRedemptionState());
    dispatch(getOverviewAsync.request(contractID));
  }, [contractID, dispatch]);

  const isLoading = isLoadingOverview && !overview;

  return (
    <AuthenticatedLayout
      title={t(PageResources.Configuration.Title)}
      isTitleShown={isTitleShown}
      productIsin={isin}
      titleComponent={pageTitle}
      onBackClick={
        step === RedemptionStep.Success
          ? undefined
          : () => navigate(getPath(AppRouting.ContractDetail, contractID))
      }
    >
      {!isLoading && isRedemptionAllowed && (
        <>
          {step === RedemptionStep.Configuration && (
            <RedemptionConfigurationStep contractID={contractID} isin={isin} />
          )}

          {step === RedemptionStep.Signature && (
            <RedemptionSignatureFormStep isin={isin} />
          )}

          {step === RedemptionStep.Success && (
            <SuccessStep contractID={contractID} isin={isin} />
          )}
        </>
      )}

      {!isLoading && !isRedemptionAllowed && (
        <RedemptionNotAllowedDialog
          isDip={overview?.isDip ?? false}
          isOpened={true}
          onClose={() =>
            navigate(getPath(AppRouting.ContractDetail, contractID))
          }
        />
      )}

      {isLoading && <LoadingSkeleton></LoadingSkeleton>}
    </AuthenticatedLayout>
  );
};

export default RedemptionPage;

const LoadingSkeleton = () => (
  <div>
    <PrimarySkeleton height={150} fullHeight={false} marginBottom={40} />
    <PrimarySkeleton height={100} fullHeight={false} marginBottom={20} />
    <PrimarySkeleton height={100} fullHeight={false} marginBottom={20} />
    <PrimarySkeleton height={100} fullHeight={false} marginBottom={20} />
    <PrimarySkeleton height={100} fullHeight={false} marginBottom={20} />
  </div>
);
