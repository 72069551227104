import { type ChartDataProps } from "Components/Charts/Chart";
import { calculateTargetAmounts } from "Utils/ModelationCalculators";
import { notNoU } from "Utils/ObjectUtils";
import { useEffect, useState } from "react";

type Props = {
  /**
   * Počáteční jednorázový vklad
   */
  singleInvestment: number | null | undefined;

  /**
   * poplatek za jednorázový vklad
   * [%]
   */
  singleInvestmentFeeRate: number | null | undefined;

  /**
   * Pravidelný vklad na začátku každého období
   */
  periodicalInvestment: number | null | undefined;

  /**
   * poplatek za pravidelný vklad
   * [%]
   */
  periodicalInvestmentFeeRate: number | null | undefined;
  /**
   * n je počet měsíců investice, př. při investičním horizontu 10 let = 120 měsíců
   */
  investmentLengthYears: number | null | undefined;

  /**
   * maximální předpokládaném ročním zhodnocení
   * [%]
   */
  appreciationPercentsMax: number | null | undefined;

  /**
   * minimální předpokládaném ročním zhodnocení
   * [%]
   */
  appreciationPercentsMin: number | null | undefined;

  isLoading?: boolean;
};

export const useChartData = (props: Props): ChartDataProps[] => {
  const [chartData, setChartData] = useState<ChartDataProps[]>([]);

  const {
    singleInvestment,
    singleInvestmentFeeRate,
    periodicalInvestment,
    periodicalInvestmentFeeRate,
    investmentLengthYears,
    appreciationPercentsMax,
    appreciationPercentsMin,
    isLoading = false,
  } = props;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const timeout = setTimeout(() => {
      if (
        notNoU(singleInvestment) &&
        notNoU(singleInvestmentFeeRate) &&
        notNoU(periodicalInvestment) &&
        notNoU(periodicalInvestmentFeeRate) &&
        notNoU(investmentLengthYears) &&
        notNoU(appreciationPercentsMax) &&
        notNoU(appreciationPercentsMin)
      ) {
        const result = calculateTargetAmounts(
          singleInvestment,
          singleInvestmentFeeRate,
          periodicalInvestment,
          periodicalInvestmentFeeRate,
          investmentLengthYears,
          appreciationPercentsMax,
          appreciationPercentsMin,
        );

        setChartData(result);
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [
    singleInvestment,
    singleInvestmentFeeRate,
    periodicalInvestment,
    periodicalInvestmentFeeRate,
    investmentLengthYears,
    appreciationPercentsMax,
    appreciationPercentsMin,
    isLoading,
  ]);

  return chartData;
};
