import { createReducer, ActionType, createAction } from "typesafe-actions";
import { produce } from "immer";
import {
  redeemAsync,
  redeemSaga,
} from "State/Contracts/Redemption/Redeem/RedeemState";
import { all } from "typed-redux-saga";
import { Prettify } from "Models/TypeExtensions";
import { ContractRedemptionCommand } from "Api/Api";

export enum RedemptionStep {
  Configuration,
  Signature,
  Success,
}

type Result = Prettify<
  {
    signatureHash: string;
  } & (
    | {
        type: "SMS";
        phone?: string;
        signatureHash: string;
      }
    | {
        type: "BIOMETRICS";
      }
  )
>;

export type RedemptionState = {
  isLoading: boolean;
  result: Result | null;
  step: RedemptionStep;
  lastRequest: ContractRedemptionCommand | null;
};

const initialRedemptionState = {
  isLoading: false,
  result: null,
  step: RedemptionStep.Configuration,
  lastRequest: null,
};

export const resetRedemptionState = createAction(
  "@contract/REDEMPTION_RESET",
)<void>();

export const setRedemptionStep = createAction(
  "@contract/REDEMPTION_SET_STEP",
)<RedemptionStep>();

export type RedemptionAction =
  | ActionType<typeof redeemAsync>
  | ActionType<typeof resetRedemptionState>
  | ActionType<typeof setRedemptionStep>;

export function* watchRedemptionStateSagas() {
  yield all([redeemSaga()]);
}

export const redemptionReducer = createReducer<
  RedemptionState,
  RedemptionAction
>(initialRedemptionState)
  .handleAction(redeemAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.result = null;
      draft.lastRequest = action.payload;
      return draft;
    });
  })
  .handleAction(redeemAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.result = action.payload;
      draft.step = RedemptionStep.Signature;
      return draft;
    });
  })
  .handleAction(redeemAsync.failure, state => {
    return produce(state, draft => {
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(resetRedemptionState, state => {
    return produce(state, _ => {
      return initialRedemptionState;
    });
  })
  .handleAction(setRedemptionStep, (state, action) => {
    return produce(state, draft => {
      draft.step = action.payload;
      draft.isLoading = false;

      if (action.payload === RedemptionStep.Configuration) {
        draft.result = null;
      }

      return draft;
    });
  });
