import {
  ContractCashFlowQueryResult,
  getContractCashFlowContractID,
} from "Api/Api";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import {
  CleanupInvestmentStateActionType,
  cleanupInvestmentState,
} from "State/InvestmentsDetail/Shared/Actions";
import { produce } from "immer";
import { takeLeading } from "typed-redux-saga";

interface Params {
  contractID: number;
  dateFrom?: string;
  dateTo?: string;
  includeFees?: boolean;
  includeDeposits?: boolean;
  includeWithdrawals?: boolean;
  pageNumber?: number;
  pageSize?: number;
}

export type CashMovementsFiltersFormModel = Pick<
  Params,
  | "includeFees"
  | "includeDeposits"
  | "includeWithdrawals"
  | "dateFrom"
  | "dateTo"
>;

export type CashMovementsState = {
  isLoading: boolean;
  filters: CashMovementsFiltersFormModel;
  error?: Error | null;
} & Pick<ContractCashFlowQueryResult, "data" | "metadata">;

export const initialCashMovementsState: CashMovementsState = {
  isLoading: false,
  data: null,
  metadata: null,
  filters: {
    includeFees: false,
    includeDeposits: false,
    includeWithdrawals: false,
    dateFrom: "",
    dateTo: "",
  },
  error: null,
};

export const setCashMovementsFilters = createAction(
  "@investments-detail/SET_CASH_MOVEMENTS_FILTERS",
)<CashMovementsFiltersFormModel>();

export type CashMovementsActionType =
  | ActionType<typeof getCashMovementsAsync>
  | ActionType<typeof setCashMovementsFilters>
  | CleanupInvestmentStateActionType;

export const getCashMovementsAsync = createAsyncAction(
  "@investments-detail/GET_CASH_MOVEMENTS_REQUEST",
  "@investments-detail/GET_CASH_MOVEMENTS_SUCCESS",
  "@investments-detail/GET_CASH_MOVEMENTS_FAILURE",
)<Params, ContractCashFlowQueryResult, Error>();

function* getCashMovements(
  action: ReturnType<typeof getCashMovementsAsync.request>,
): Generator {
  try {
    const {
      contractID,
      dateFrom,
      dateTo,
      includeFees,
      includeDeposits,
      includeWithdrawals,
      pageNumber,
      pageSize,
    } = action.payload;

    const { response, error } = yield* safeApiCall(
      getContractCashFlowContractID,
      contractID,
      dateFrom || undefined,
      dateTo || undefined,
      includeFees || undefined,
      includeDeposits || undefined,
      includeWithdrawals || undefined,
      pageNumber,
      pageSize,
    );

    if (error) {
      yield put(getCashMovementsAsync.failure(error));
      return;
    }

    yield put(getCashMovementsAsync.success(response));
  } catch (err) {
    yield put(getCashMovementsAsync.failure(err as Error));
  }
}

export function* watchCashMovementsSaga() {
  yield takeLeading(getType(getCashMovementsAsync.request), getCashMovements);
}

export const cashMovementsReducer = createReducer<
  CashMovementsState,
  CashMovementsActionType
>(initialCashMovementsState)
  .handleAction(getCashMovementsAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;

      return draft;
    });
  })
  .handleAction(getCashMovementsAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = state.error;

      return draft;
    });
  })
  .handleAction(getCashMovementsAsync.success, (state, action) => {
    return produce(state, draft => {
      const { data: newData, metadata } = action.payload;

      draft.isLoading = false;
      draft.data = [...(draft.data || []), ...(newData || [])];
      draft.metadata = metadata;

      return draft;
    });
  })
  .handleAction(cleanupInvestmentState, state => {
    return produce(state, draft => {
      return initialCashMovementsState;
    });
  })
  .handleAction(setCashMovementsFilters, (state, action) => {
    return produce(state, draft => {
      return { ...initialCashMovementsState, filters: action.payload };
    });
  });
