import { Resources, useResource } from "Translations/Resources";
import { getBankConnectionSchema } from "Utils/Contracts/NewContract/ValidationSchemas/BankConnection";

export const useYupFormSchema = () => {
  const { t } = useResource();

  return getBankConnectionSchema({
    translates: {
      required: t(Resources.Validation.Required),
      invalidFormat: t(Resources.Validation.InvalidFormat),
      invalidBankAccount: t(Resources.Validation.InvalidBankAccount),
      czIbanDenied: t(
        Resources.Contract.NewContract.BankConnection.CzIbanDenied,
      ),
    },
  });
};
