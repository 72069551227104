import { BlIconTextButton } from "Components/Shared/Buttons/BlIconTextButton";
import { ErrorTypography, StyledError } from "Components/Shared/FormStyles";
import { PlusSlimIcon } from "Components/Shared/Icons";
import { Dropdown } from "Components/Shared/Inputs/Dropdown";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { LookupItem } from "Models/LookupItem";
import { PersonalDataFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { useMemo, type FunctionComponent } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const ComponentResources = Resources.Contract.NewContract.PersonalInformation;

const DELETE_VALUE = "-1";

const StyledBlIconTextButton = styled(BlIconTextButton)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const CountriesDropdown: FunctionComponent = _ => {
  const { t } = useResource();
  const {
    control,
    formState: { errors },
  } = useFormContext<PersonalDataFormModel>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "nationalities",
  });
  const isBankIDVerified = useIsBankIDVerified();

  const countries = useCountriesList();

  const countriesMap = useMemo(() => {
    // convert countries to map
    const countriesMap = new Map();
    countries.forEach(country => {
      countriesMap.set(String(country.Value), country);
    });
    return countriesMap;
  }, [countries]);

  const filteredCountries = useMemo(() => {
    const selectedCountries = fields?.map(({ value }) => value) ?? [];
    return (
      countries?.filter(
        country => !selectedCountries.includes(String(country.Value)),
      ) ?? []
    );
  }, [countries, fields]);

  const deleteValue: LookupItem<string> = {
    Name: t(ComponentResources.DeleteNationality).toLocaleUpperCase(),
    Value: DELETE_VALUE,
  };

  const onChange = (value: ValueType, index?: number) => {
    if (value === DELETE_VALUE) {
      remove(index);
    } else if (index === undefined) {
      append({ value: String(value) });
    } else {
      update(index, { value: String(value) });
    }
  };

  const hasError = !!errors?.nationalities?.message;

  return (
    <>
      {fields?.map(({ id, value, bankId }, index) => (
        <Dropdown
          key={id}
          options={[
            ...(fields.length > 1 ? [deleteValue] : []),
            ...(countriesMap.has(value) ? [countriesMap.get(value)] : []),
            ...filteredCountries,
          ]}
          name="countries"
          value={value}
          onChange={v => onChange(v, index)}
          label={
            index
              ? t(ComponentResources.AnotherNationality)
              : t(ComponentResources.Nationality)
          }
          disabled={bankId && isBankIDVerified}
        />
      ))}
      {!fields.length && (
        <Dropdown
          options={filteredCountries}
          name="countries"
          onChange={onChange}
          key={uuidv4()}
          value=""
          label={
            fields.length
              ? t(ComponentResources.AnotherNationality)
              : t(ComponentResources.Nationality)
          }
        />
      )}

      {hasError && (
        <StyledError>
          <ErrorTypography>{errors?.nationalities?.message}</ErrorTypography>
        </StyledError>
      )}

      <StyledBlIconTextButton
        icon={<PlusSlimIcon />}
        onClick={() => onChange("")}
        disabled={fields.length === countries.length}
      >
        {t(ComponentResources.AddNationality)}
      </StyledBlIconTextButton>
    </>
  );
};
