import styled from "styled-components";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { Box, Card, Dialog, Slider, Switch, Typography } from "@mui/material";
import { BackIcon } from "Components/Shared/Icons";
import { DESKTOP_BREAKPOINT } from "Constants/Layout";
import { isNoU } from "Utils/ObjectUtils";

type AlignItemsType = "center" | "flex-end" | "flex-start";
type JustifyContentType =
  | "center"
  | "flex-end"
  | "flex-start"
  | "space-between"
  | "space-around";

export const StyledFlex = styled.div<{
  $alignItems?: AlignItemsType;
  $justifyContent?: JustifyContentType;
  $mdFlexFlow?: "row" | "column";
  $gap?: number;
  $fullHeight?: boolean;
  $flexDirection?: "column" | "row";
  $overflow?: "hidden" | "auto" | "scroll";
  $flexGrow?: number;
  $flex?: string;
}>`
  display: flex;
  max-width: 100%;
  flex-direction: ${props =>
    props.$flexDirection ? props.$flexDirection : ""};
  align-items: ${props => (props.$alignItems ? props.$alignItems : "")};
  justify-content: ${props =>
    props.$justifyContent ? props.$justifyContent : ""};
  gap: ${props => (props.$gap ? props.theme.spacing(props.$gap) : "")};
  overflow: ${props => (props.$overflow ? props.$overflow : "")};

  ${props => props.theme.breakpoints.down("lg")} {
    flex-flow: ${props => (props.$mdFlexFlow ? props.$mdFlexFlow : "")};
  }

  ${props => props.$flexGrow && `flex-grow: ${props.$flexGrow};`}
  ${props => props.$flex && `flex: ${props.$flex};`}
  ${props =>
    props.$fullHeight &&
    `
      height: 100%;
    `}
`;

export const StyledInputLabel = styled.label<{ hasError?: boolean }>`
  font-weight: 300;
  display: flex;
  align-items: center;
  color: ${props =>
    props.hasError ? props.theme.colors.red : props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(1)};
  z-index: 1;

  .MuiTypography-body1 {
    font-weight: 300;
  }
`;

export const StyledFilterWrapper = styled.form`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(2, 3)};
  align-items: flex-end;
  margin-bottom: ${props => props.theme.spacing(5)};
  margin-top: ${props => props.theme.spacing(6)};

  & > div {
    margin: 0;
  }

  .MuiFormControl-root {
    .MuiFormHelperText-root {
      display: none;
    }
  }
`;

export const StyledFilterSubmit = styled(PrimaryButton)`
  color: ${props => props.theme.palette.primary.main};
  background-color: transparent;
  box-shadow: none;
  border: 1px solid ${props => props.theme.palette.primary.main};

  ${props => props.theme.breakpoints.up("md")} {
    &:hover {
      background-color: transparent;
    }
  }
`;

export const StyledPageWrapper = styled.div`
  display: grid;
  max-width: 478px;
  height: 100%;

  .left-content {
    padding-right: ${props => props.theme.spacing(10)};
    order: 0;
  }

  .right-content {
    order: 1;
    position: relative;
    padding-left: ${props => props.theme.spacing(12)};
    padding-right: ${props => props.theme.spacing(2)};

    & > div {
      max-width: 552px;
    }

    &:after {
      content: "";
      background-color: ${props => props.theme.colors.gray};
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      transform: translateY(-60px);
      height: calc(100% + 60px + 33px);
      z-index: -1;
      pointer-events: none;
    }
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .left-content {
      padding-right: ${props => props.theme.spacing(6)};
    }

    .right-content {
      padding-left: ${props => props.theme.spacing(6)};
    }
  }

  ${props => props.theme.breakpoints.down("md")} {
    max-width: unset;

    .left-content {
      padding: 0;
    }

    .right-content {
      padding: 0;
      &::after {
        display: none;
      }
    }

    .right-content + .left-content {
      display: none;
    }
  }
`;

export const StyledButtonBox = styled(Box)`
  display: flex;
  gap: ${props => props.theme.spacing(2)};
  margin-top: ${props => props.theme.spacing(4)};
  flex-direction: column;
`;

export const FullWidthDiv = styled.div`
  width: 100%;
`;

export const StyledBackIcon = styled(BackIcon)<{ $disabled?: boolean }>`
  height: 35px;
  width: 35px;
  padding: ${props => props.theme.spacing(1)};
  cursor: pointer;
  position: relative;
  right: ${props => props.theme.spacing(2)};
  color: ${props =>
    props.$disabled
      ? props.theme.colors.gray
      : props.theme.palette.primary.main};
`;

export const StyledSlider = styled(Slider)`
  .MuiSlider-rail {
    background-color: ${props => props.theme.palette.secondary.main};
  }

  .MuiSlider-thumb {
    height: 25px;
    width: 25px;
  }
`;

export const StyledDarkCard = styled(Card)<{
  $withPadding?: boolean;
  $isActive?: boolean;
  $defaultCursor?: boolean;
}>`
  position: relative;
  overflow: hidden;
  box-shadow: none;
  border: 1px solid;
  border-color: ${({ theme, $isActive }) =>
    $isActive ? theme.palette.primary.main : theme.colors.border}80;
  border-radius: 16px;
  color: ${({ theme }) => theme.palette.text.secondary};
  background-color: ${({ theme }) => theme.colors.gray};

  .MuiCardContent-root {
    padding: ${props => props.theme.spacing(3)};
  }

  cursor: ${({ $defaultCursor }) => ($defaultCursor ? "default" : "pointer")};
`;

export const StyledDipCard = styled(StyledDarkCard)`
  border-color: ${({ theme }) => theme.palette.dip.main};
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    // Media query pro aplikaci min-width pouze na obrazovkách menších než 'md'
    @media (max-width: ${props =>
        props.theme.breakpoints.values[DESKTOP_BREAKPOINT]}px) {
      min-width: 90% !important;
    }

    @media (min-width: ${props =>
        props.theme.breakpoints.values[DESKTOP_BREAKPOINT]}px) {
      max-width: 620px;
    }

    border-radius: 16px;
    border: 1px solid ${props => props.theme.colors.border}60;
  }

  .MuiDialog-paper {
    box-shadow: none;
    background-color: ${props => props.theme.colors.darkGray};
  }
`;

export const MultilineTypography = styled(Typography)`
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: pre-line;
  margin-top: ${props => props.theme.spacing(8)};
  padding-right: ${props => props.theme.spacing(2)};
`;

export const StyledSwitch = styled(Switch)(
  ({ theme }, $thumbColor?: string) => ({
    width: 56,
    height: 32,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      "&.Mui-checked": {
        transform: "translateX(24px)",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 32,
      height: 32,
    },
    "& :not(.Mui-checked) .MuiSwitch-thumb": {
      backgroundColor: $thumbColor ? $thumbColor : theme.palette.divider,
    },
    "& .MuiSwitch-track": {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 16,
      backgroundColor: theme.palette.background.default,
      height: 32,
      boxSizing: "border-box",
    },
  }),
);

export const StyledParagraph = styled.div<{
  $hexColor?: string | null | undefined;
}>`
  border-left: 3px solid
    ${props =>
      isNoU(props.$hexColor)
        ? props.theme.palette.primary.main
        : props.$hexColor};
  padding-left: ${props => props.theme.spacing(2)};
  margin: ${props => props.theme.spacing(3, 0)};
`;
