import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { FunctionComponent } from "react";

export const PaymentLoadingSkeleton: FunctionComponent = _ => (
  <div>
    <PrimarySkeleton width={350} height={25} />
    <br />
    <PrimarySkeleton width={150} height={50} />
    <br />
    <PrimarySkeleton width={300} height={50} />
    <br />
    <PrimarySkeleton width={300} height={50} />
    <br />
    <PrimarySkeleton width={300} height={50} />
    <br />
    <PrimarySkeleton width={300} height={300} />
  </div>
);
