import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { all, debounce, takeEvery } from "typed-redux-saga";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { GenerateQrCodeCommand, QrCodeDto, postPaymentQrCode } from "Api/Api";
import { produce } from "immer";

export type QrCodeType = "singleInvestment" | "periodicalInvestment";

export type PostGenerateQrCodeStateType = {
  [key in QrCodeType]: FetchStateType<QrCodeDto>;
};

export const postGenerateQrCodeState = (): PostGenerateQrCodeStateType => ({
  singleInvestment: getFetchStateDefaultValue(),
  periodicalInvestment: getFetchStateDefaultValue(),
});

export const clearGenerateQrCode = createAction(
  "@payments/CLEAR_GENERATE_QR_CODE",
)<QrCodeType>();

type MetaType = {
  _qrCodeType: QrCodeType;
};

export const postGenerateQrCodeAsync = createAsyncAction(
  "@payments/POST_GENERATE_QR_CODE_REQUEST",
  "@payments/POST_GENERATE_QR_CODE_SUCCESS",
  "@payments/POST_GENERATE_QR_CODE_FAILURE",
)<
  [GenerateQrCodeCommand, MetaType],
  [QrCodeDto, MetaType],
  [Error, MetaType]
>();

export const postGenerateSingleInvestmentInvoiceQrCodeRequest = createAction(
  "@payments/POST_GENERATE_SINGLE_INVESTMENT_QR_CODE_REQUEST",
)<GenerateQrCodeCommand>();

export const postGeneratePeriodicalInvestmentInvoiceQrCodeRequest =
  createAction(
    "@payments/POST_GENERATE_PERIODICAL_INVESTMENT_QR_CODE_REQUEST",
  )<GenerateQrCodeCommand>();

export type PostGenerateQrCodeActionType =
  | ActionType<typeof postGenerateQrCodeAsync>
  | ActionType<typeof clearGenerateQrCode>;

function* postGenerateQrCode(
  action: ActionType<typeof postGenerateQrCodeAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postPaymentQrCode,
      action.payload,
    );
    if (error) {
      yield put(
        postGenerateQrCodeAsync.failure(error, {
          _qrCodeType: action.meta._qrCodeType,
        }),
      );
      return;
    }

    yield put(
      postGenerateQrCodeAsync.success(response, {
        _qrCodeType: action.meta._qrCodeType,
      }),
    );
  } catch (err) {
    yield put(
      postGenerateQrCodeAsync.failure(err as Error, {
        _qrCodeType: action.meta._qrCodeType,
      }),
    );
  }
}

function* postGenerateSingleInvestmentInvoiceQrCode(
  action: ActionType<typeof postGenerateQrCodeAsync.request>,
): Generator {
  yield put(
    postGenerateQrCodeAsync.request(action.payload, {
      _qrCodeType: "singleInvestment",
    }),
  );
}

function* postGeneratePeriodicalInvestmentInvoiceQrCode(
  action: ActionType<typeof postGenerateQrCodeAsync.request>,
): Generator {
  yield put(
    postGenerateQrCodeAsync.request(action.payload, {
      _qrCodeType: "periodicalInvestment",
    }),
  );
}

export function* postGenerateQrCodeSaga() {
  yield all([
    debounce(
      500,
      getType(postGenerateSingleInvestmentInvoiceQrCodeRequest),
      postGenerateSingleInvestmentInvoiceQrCode,
    ),
    debounce(
      500,
      getType(postGeneratePeriodicalInvestmentInvoiceQrCodeRequest),
      postGeneratePeriodicalInvestmentInvoiceQrCode,
    ),
    takeEvery(getType(postGenerateQrCodeAsync.request), postGenerateQrCode),
  ]);
}

export const postGenerateQrCodeReducer = createReducer<
  PostGenerateQrCodeStateType,
  PostGenerateQrCodeActionType
>(postGenerateQrCodeState())
  .handleAction(postGenerateQrCodeAsync.request, (state, action) =>
    produce(state, draft => {
      draft[action.meta._qrCodeType] = handleActionRequest(
        state[action.meta._qrCodeType],
      );
    }),
  )
  .handleAction(postGenerateQrCodeAsync.failure, (state, action) =>
    produce(state, draft => {
      draft[action.meta._qrCodeType] = handleActionFailure(
        state[action.meta._qrCodeType],
        action,
      );
    }),
  )
  .handleAction(postGenerateQrCodeAsync.success, (state, action) =>
    produce(state, draft => {
      draft[action.meta._qrCodeType] = handleActionSuccess(
        state[action.meta._qrCodeType],
        action,
      );
    }),
  )
  .handleAction(clearGenerateQrCode, postGenerateQrCodeState);
