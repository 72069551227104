import { DealerDto, ProductItemDto } from "Api/Api";
import { ContractCategories } from "Models/Contracts";

export enum ContractModelingStep {
  Information,
  Fund,
  FundConfiguration,
}

export type ProductDetailType = Pick<
  ProductItemDto,
  "isin" | "minPerformance" | "maxPerformance" | "productID"
> & {
  properties: {
    icon: string;
    name: string;
  }[];
  header: string;
  label: string;
  paragraphs: {
    header: string;
    description: string;
  }[];
};

export type InformationFormModel = {
  name?: string;
  category?: ContractCategories;
};

export type FundData = ProductItemDto & {
  code?: string;
};

export type FundConfigurationFormModel = {
  type: "Unlimited" | "Target";
  targetAmount?: number | null;
  singleInvestment?: number | null;
  periodicalInvestment?: number | null;
  investmentLengthYears?: number | null;
  totalAmount?: number | null;
  dealerCode?: DealerDto["dealerCode"] | null;
  dealerID?: DealerDto["dealerID"] | null;
};

export type FormModels =
  | InformationFormModel
  | FundData
  | FundConfigurationFormModel;

export type FormModelType = {
  [key in ContractModelingStep]?: {
    [ContractModelingStep.Information]: InformationFormModel;
    [ContractModelingStep.Fund]: FundData;
    [ContractModelingStep.FundConfiguration]: FundConfigurationFormModel;
  }[key];
};
