import { Typography } from "@mui/material";
import { UpIcon } from "Components/Shared/Icons";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { track } from "Utils/TrackingUtils";

type Props = {
  channelID: string;
  body?: string | null | undefined;
  deepLink?: string | null | undefined;
  onOpen: (channelID: string, deepLink: string | null | undefined) => void;
};

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.background.default};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const StyledUpIcon = styled(UpIcon)`
  width: 12px;
  height: 12px;
  color: ${({ theme }) => theme.palette.background.default};
  transform: rotate(90deg);
`;

const StyledIconBox = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default}20;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  align-self: end;
  bottom: ${({ theme }) => theme.spacing(-2)};
  right: ${({ theme }) => theme.spacing(0)};
  margin-left: ${({ theme }) => theme.spacing(4)};
`;

const StyledContainer = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
`;

export const CampaignCardContent: FunctionComponent<Props> = ({
  channelID,
  body,
  deepLink,
  onOpen,
}) => {
  const open = () => {
    track({
      category: "Banner",
      event: "Click",
      action: "Open",
      url: deepLink,
      channelID: channelID,
    });

    onOpen(channelID, deepLink);
  };

  return (
    <StyledContainer>
      <StyledTypography
        variant="body1"
        color="background.default"
        component="div"
        overflow="hidden"
        marginRight={4}
      >
        {!!body && (
          <span dangerouslySetInnerHTML={{ __html: body }} key={channelID} />
        )}
      </StyledTypography>
      <StyledIconBox role="button" onClick={open}>
        <StyledUpIcon />
      </StyledIconBox>
    </StyledContainer>
  );
};
