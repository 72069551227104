import { useAppSelector } from "Hooks/useAppSelector";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, RouteProps, useLocation } from "react-router";
import { resetUser } from "State/Auth/AuthReducer";
import { AppRouting, getPath, getPathnameAndSearch } from "Utils/UrlUtils";

type Props = RouteProps & {
  isUserAuthenticated: boolean;
  forbidUnderage?: boolean;
};

export const AuthenticatedRoute: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { children, isUserAuthenticated, forbidUnderage } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useAppSelector(s => s.auth.user);

  const { pathname, search } = location;

  useEffect(() => {
    if (
      isUserAuthenticated === false &&
      pathname !== getPath(AppRouting.SignIn)
    ) {
      dispatch(
        resetUser({
          authRedirectUrl: getPathnameAndSearch({ pathname, search }),
          wasSignedOutManually: false,
        }),
      );
    }
  }, [isUserAuthenticated, pathname, search, dispatch]);

  if (forbidUnderage && user?.isUnderage) {
    return <Navigate to={getPath(AppRouting.Dashboard)} replace={true} />;
  }

  if (isUserAuthenticated === false) {
    return (
      <Navigate
        to={getPath(AppRouting.SignIn)}
        state={{ from: location }}
        replace={true}
      />
    ) as any;
  }

  if (isUserAuthenticated && pathname === "/") {
    return (
      <Navigate
        to={getPath(AppRouting.Dashboard)}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  return children ?? (null as any);
};
