import {
  Box,
  DialogContent,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { StyledDialog } from "Components/Shared/StyledComponents";
import { type FunctionComponent } from "react";
import {
  confirmable,
  createConfirmation,
  type ReactConfirmProps,
} from "react-confirm";
import {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { PrimaryButton } from "../Buttons/PrimaryButton";

export type ConfirmDialogProps = {
  theme: DefaultTheme;
  confirmText?: string;
  cancelText?: string;
} & ReactConfirmProps;

export enum ConfirmValues {
  Proceed = "proceed",
  Cancel = "cancel",
}

export const ConfirmDialogComponent: FunctionComponent<ConfirmDialogProps> = ({
  show,
  cancel,
  proceed,
  dismiss,
  confirmation,
  cancelText,
  theme,
  confirmText,
}) => {
  return (
    <StyledEngineProvider injectFirst>
      <StyledThemeProvider key="confirmDialog" theme={theme}>
        <ThemeProvider theme={theme}>
          <StyledDialog open={show} onClose={dismiss} fullWidth maxWidth="md">
            <DialogContent>
              <Typography marginBottom={5}>{confirmation}</Typography>
              <Box display="flex" justifyContent="space-around" gap={3}>
                <PrimaryButton
                  small
                  color="primary"
                  onClick={() => proceed(ConfirmValues.Proceed)}
                  fullWidth
                >
                  {confirmText}
                </PrimaryButton>
                <PrimaryButton
                  small
                  color="secondary"
                  onClick={() => cancel(ConfirmValues.Cancel)}
                  fullWidth
                >
                  {cancelText}
                </PrimaryButton>
              </Box>
            </DialogContent>
          </StyledDialog>
        </ThemeProvider>
      </StyledThemeProvider>
    </StyledEngineProvider>
  );
};

const ConfirmableDialog = confirmable(ConfirmDialogComponent);

export const confirmDialog = createConfirmation(ConfirmableDialog);
