import * as React from "react";
export const UpIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 3.60938L11.7969 4.32812L6.84375 9.26562C6.45117 9.6582 6.45117 10.2949 6.84375 10.6875C7.23633 11.0801 7.87305 11.0801 8.26562 10.6875L11.5 7.4375V20C11.4941 20.3613 11.6836 20.6953 11.9961 20.877C12.3066 21.0605 12.6934 21.0605 13.0039 20.877C13.3164 20.6953 13.5059 20.3613 13.5 20V7.4375L16.7344 10.6875C17.127 11.0801 17.7637 11.0801 18.1562 10.6875C18.5488 10.2949 18.5488 9.6582 18.1562 9.26562L13.2031 4.32812L12.5 3.60938Z"
        fill="currentColor"
      />
    </svg>
  );
};
