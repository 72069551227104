import * as React from "react";
export const StructuralIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M12.4639 0.500981C12.253 0.508655 12.05 0.58283 11.8838 0.712895L0.383804 9.7129C0.275871 9.79217 0.184959 9.89232 0.116461 10.0074C0.0479631 10.1225 0.00327454 10.2501 -0.0149537 10.3828C-0.033182 10.5155 -0.0245787 10.6505 0.0103458 10.7797C0.0452703 10.909 0.105805 11.03 0.188358 11.1354C0.270911 11.2409 0.373802 11.3287 0.490926 11.3936C0.608051 11.4585 0.737023 11.4993 0.870193 11.5134C1.00336 11.5276 1.13802 11.5148 1.26617 11.476C1.39432 11.4371 1.51336 11.3729 1.61623 11.2871L2.00001 10.9863V12.5391H3.00001V10.6465H2.43459L12.5 2.76954L22.5654 10.6465H22V12.5381H23V10.9863L23.3838 11.2871C23.4867 11.3729 23.6057 11.4371 23.7339 11.476C23.862 11.5148 23.9967 11.5276 24.1298 11.5134C24.263 11.4993 24.392 11.4585 24.5091 11.3936C24.6262 11.3287 24.7291 11.2409 24.8117 11.1354C24.8942 11.03 24.9548 10.909 24.9897 10.7797C25.0246 10.6505 25.0332 10.5155 25.015 10.3828C24.9968 10.2501 24.9521 10.1225 24.8836 10.0074C24.8151 9.89232 24.7242 9.79217 24.6162 9.7129L13.1162 0.712895C12.9306 0.567568 12.6995 0.492513 12.4639 0.500981ZM2.00001 13.4844V15.377H3.00001V13.4844H2.00001ZM22 13.4844V15.377H23V13.4844H22ZM2.00001 16.3232V18.2158H3.00001V16.3232H2.00001ZM22 16.3232V18.2148H23V16.3232H22ZM22 19.1611V21.0537H23V19.1611H22ZM2.00001 19.1621V21.0537H3.00001V19.1621H2.00001ZM2.00001 22V23C2.00003 23.1326 2.05271 23.2598 2.14648 23.3535C2.24024 23.4473 2.36741 23.5 2.50001 23.5H3.50001V22.5H3.00001V22H2.00001ZM22 22V22.5H21.5V23.5H22.5C22.6326 23.5 22.7598 23.4473 22.8536 23.3535C22.9473 23.2598 23 23.1326 23 23V22H22ZM4.44728 22.5V23.5H6.34181V22.5H4.44728ZM7.28908 22.5V23.5H9.18458V22.5H7.28908ZM10.1319 22.5V23.5H12.0264V22.5H10.1319ZM12.9736 22.5V23.5H14.8682V22.5H12.9736ZM15.8154 22.5V23.5H17.71V22.5H15.8154ZM18.6582 22.5V23.5H20.5527V22.5H18.6582Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
