import { createTheme, ThemeOptions } from "@mui/material/styles";
import { Colors } from "Components/Layout/Themes/Colors";
import { DefaultTheme } from "styled-components";

export type ColorsType = {
  PrimaryMain: string;
  PrimaryDark: string;
  PrimaryText: string;
  SecondaryMain: string;
  SecondaryDark: string;
  SecondaryText: string;
  Body: string;
  Text: string;
  TextLight: string;
  TextDisabled: string;
  Black: string;
  White: string;
  Green: string;
  Red: string;
  RedDark: string;
  Warning: string;
  Gray: string;
  DarkGray: string;
  Border: string;
  Blue: string;
  MenuBar: string;
  Divider: string;
  Dip: string;
};

const THEME_SPACING = 5;

const theme = (color: ColorsType): DefaultTheme => {
  const theme: ThemeOptions = {
    palette: {
      primary: {
        //light: color.PrimaryMain,
        main: "#FFFFFF",
        //dark: color.PrimaryMain,
        contrastText: color.PrimaryText,
      },
      secondary: {
        //light: color.SecondaryMain,
        main: color.SecondaryMain,
        //dark: color.SecondaryMain,
        contrastText: color.SecondaryText,
      },
      text: {
        primary: color.PrimaryText,
        secondary: color.SecondaryText,
        disabled: color.TextDisabled,
      },
      error: {
        main: color.Red,
        dark: color.Red,
        contrastText: color.White,
      },
      success: {
        main: color.Green,
      },
      info: {
        main: color.Blue,
        light: color.Blue,
        dark: color.Blue,
        contrastText: color.Blue,
      },
      background: {
        default: color.Body,
      },
      common: {
        black: color.Black,
        white: color.White,
      },
      warning: {
        main: color.Warning,
      },
      divider: color.Divider,
      action: {
        disabled: color.PrimaryMain,
      },
      dip: {
        main: color.Dip,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1050,
        lg: 1200,
        xl: 1536,
      },
    },
    spacing: THEME_SPACING,
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontSize: 12,
      fontFamily: "Inter, sans-serif",
      allVariants: {
        letterSpacing: "0.14px",
      },
      body1: {
        fontSize: 14,
      },
      body2: {
        color: color.Text,
        fontWeight: 400,
        fontSize: 16,
      },
      h1: {
        color: color.Text,
        fontWeight: 900,
        fontSize: 32,
      },
      h2: {
        color: color.Text,
        fontWeight: 500,
        fontSize: 22,
        marginBottom: THEME_SPACING,
      },
      h3: {
        color: color.Text,
        fontWeight: 500,
        fontSize: 24,
      },
      h4: {
        color: color.Text,
        fontWeight: 600,
        fontSize: 16,
      },
      subtitle1: {
        fontWeight: 400,
        color: color.TextLight,
        fontSize: 14,
      },
      button: {
        fontSize: 16,
        fontWeight: 500,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&:-webkit-autofill": {
              WebkitTextFillColor: color.PrimaryText,
            },
            "&::placeholder": {
              fontWeight: 400,
            },
            fontWeight: 500,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontWeight: 300,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: "translate(12px, 4px)",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "unset",
            fontWeight: 500,
          },
          input: {
            "&:-webkit-autofill": {
              borderRadius: 0,
            },
            "&::placeholder": {
              fontWeight: 400,
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          // The props to apply
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            color: color.Text,
            fontWeight: 400,
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: color.TextLight,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar": {
              width: "9px",
              height: "7px",
              background: color.Gray,
              borderRadius: "4px",
            },
            "& *::-webkit-scrollbar-thumb": {
              backgroundColor: color.TextLight,
              borderRadius: "4px",
            },
            "& *::-webkit-scrollbar": {
              width: "7px",
              height: "7px",
              background: color.Gray,
              borderRadius: "4px",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0 5px 5px 0 rgba(215, 215, 215, 0.5)",
            "& .MuiPickersBasePicker-container .MuiPickerDTTabs-tabs .MuiButtonBase-root":
              {
                color: color.White,
              },
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            height: "80px",
            backgroundColor: color.MenuBar,
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            justifyContent: "start",
            WebkitJustifyContent: "start",
            marginTop: `${THEME_SPACING * 2}px`,

            "& .MuiBottomNavigationAction-label": {
              marginTop: `${THEME_SPACING}px`,
            },
            "&.Mui-selected": {
              color: Colors.PrimaryText,
            },
            "&:hover": {
              color: Colors.PrimaryText,
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: Colors.Body,
            //boxShadow: "none",
            border: `1px solid ${color.Border}80`,
          },
          root: {
            marginTop: `${THEME_SPACING}px`,
          },
          list: {
            padding: 0,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: color.Body,
            "&:hover": {
              backgroundColor: `${color.Body}`,
            },

            borderBottom: `1px solid ${color.Border}80`,
            "&:nth-last-child(1)": {
              borderBottom: "none",
            },

            padding: `${THEME_SPACING * 3}px ${THEME_SPACING * 2}px`,
            margin: `0 ${THEME_SPACING * 3}px`,

            "&.Mui-selected": {
              backgroundColor: color.Body,
              color: color.SecondaryText,

              "&:hover": {
                backgroundColor: `${color.Body}`,
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            "&.Mui-disabled": {
              color: color.SecondaryText,
              opacity: 0.65,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
          underlineAlways: {
            textDecoration: "underline",
            textUnderlineOffset: 2,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: `${THEME_SPACING * 7}px ${THEME_SPACING * 5}px`,
          },
        },
      },
    },
  };

  var customVariables = {
    colors: {
      red: color.Red,
      redDark: color.RedDark,
      border: color.Border,
      gray: color.Gray,
      darkGray: color.DarkGray,
    },
    shadow: {
      primary: "0 5px 5px 0px rgba(0, 0, 0, 0.15)",
      primaryHover: "0 5px 5px 0 rgba(215, 215, 215, 0.5)",
    },
    borderRadius: "4px",
    vh: (val: number, isShownBottomMenu: boolean) => {
      return `calc(var(--vh, 1vh) * ${val})`;
    },
  };

  return createTheme(Object.assign(theme, customVariables)) as DefaultTheme;
};

export const getTheme = () => {
  return theme(Colors);
};
