/**
 * As hover doesn't properly work on touch devices,
 * this function is used to disable hover styles for touch devices.
 * @param style Style to be applied on hover
 * @param fallbackStyle Style to be applied if hover is disabled
 * @returns Hover style disabled for touch devices
 */
export const getSafeHover = (style: string, fallbackStyle?: string) => {
  return `
    &:hover {
      @media (hover: hover) {
       ${style}
      }  
      ${
        fallbackStyle ??
        `
        @media (hover: none) {
          ${fallbackStyle}
        }
      `
      }
    }`;
};

export const getSafeHoverBackground = (backgroundColor: string) =>
  getSafeHover(
    `background-color: ${backgroundColor}99;`,
    `background-color: ${backgroundColor};`,
  );
