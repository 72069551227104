import { Stack } from "@mui/material";
import { DetailDashboard } from "Components/Dashboard/DetailDashboard/DetailDashboard";
import { WelcomeDashboard } from "Components/Dashboard/WelcomeDashboard";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useIsLead } from "Hooks/User/useIsLead";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getUserCampaignsAsync } from "State/Campaigns/Api/GetUserCampaignsState";
import { getContractsAsync } from "State/Dashboard/Contracts/GetContractsState";
import { isNoU } from "Utils/ObjectUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";

import React, { useEffect } from "react";
import { useNavigate } from "react-router";

enum Steps {
  Welcome,
  Detail,
}

export const DashboardPage: React.FunctionComponent = _ => {
  const [step, setStep] = React.useState<Steps | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading: loading, data } = useAppSelector(
    s => s.dashboard.contracts,
  );
  const isLoading = loading && !data && isNoU(step);
  const isLead = useIsLead();

  useEffect(() => {
    if (isLead === false) {
      dispatch(getContractsAsync.request());
      dispatch(getUserCampaignsAsync.request());
    }
  }, [dispatch, isLead]);

  useEffect(() => {
    if (isLead === true) {
      setStep(Steps.Welcome);
    } else {
      setStep(Steps.Detail);
    }
  }, [isLead]);

  return (
    <AuthenticatedLayout
      title="Přehled"
      isLogoShown
      isBottomMenuShown
      isControlsShown
    >
      <LoadingWrapper isLoading={isLoading} skeleton={<LoadingSkeleton />}>
        {step === Steps.Welcome && (
          <WelcomeDashboard
            onStartClick={() => navigate(getPath(AppRouting.ContractModeling))}
          />
        )}
        {step === Steps.Detail && <DetailDashboard />}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};

const LoadingSkeleton = () => (
  <Stack gap={3} marginTop={6}>
    <PrimarySkeleton height={110} fullHeight={false} />
    <PrimarySkeleton height={150} fullHeight={false} />
    <PrimarySkeleton height={150} fullHeight={false} />
  </Stack>
);

export default DashboardPage;
