import { BankAccountItemDto, getContractBankAccountsContractID } from "Api/Api";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  handleCleanupAction,
  safeApiCall,
} from "State/Utils";
import {
  CleanupInvestmentStateActionType,
  cleanupInvestmentState,
} from "State/InvestmentsDetail/Shared/Actions";

export type GetBankAccountsStateType = FetchStateType<BankAccountItemDto[]>;

export const getBankAccountsState = (): GetBankAccountsStateType =>
  getFetchStateDefaultValue();

export type GetBankAccountsActionType =
  | ActionType<typeof getBankAccountsAsync>
  | CleanupInvestmentStateActionType;

export const getBankAccountsAsync = createAsyncAction(
  "@investments-detail/GET_BANK_ACCOUNTS_REQUEST",
  "@investments-detail/GET_BANK_ACCOUNTS_SUCCESS",
  "@investments-detail/GET_BANK_ACCOUNTS_FAILURE",
)<
  Parameters<typeof getContractBankAccountsContractID>[0],
  BankAccountItemDto[],
  Error
>();

function* getBankAccounts(
  action: ReturnType<typeof getBankAccountsAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getContractBankAccountsContractID,
      action.payload,
    );

    if (error) {
      yield put(getBankAccountsAsync.failure(error));
      return;
    }

    yield put(getBankAccountsAsync.success(response));
  } catch (err) {
    yield put(getBankAccountsAsync.failure(err as Error));
  }
}

export function* getBankAccountsSaga() {
  yield takeLatest(getType(getBankAccountsAsync.request), getBankAccounts);
}

export const getBankAccountsReducer = createReducer<
  GetBankAccountsStateType,
  GetBankAccountsActionType
>(getBankAccountsState())
  .handleAction(getBankAccountsAsync.request, handleActionRequest)
  .handleAction(getBankAccountsAsync.failure, handleActionFailure)
  .handleAction(getBankAccountsAsync.success, handleActionSuccess)
  .handleAction(
    cleanupInvestmentState,
    handleCleanupAction(getBankAccountsState),
  );
