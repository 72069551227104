import { CardContent } from "@mui/material";
import { FundDescription } from "Components/ContractModeling/FundSelector/Components/FundDescription";
import { TextButton } from "Components/Shared/Buttons/TextButton";
import { StyledDarkCard, StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import styled from "styled-components";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { TrackedButton } from "Components/Shared/Buttons/TrackedButton";
import { ProductListItemDto } from "Hooks/Investment/NewInvestment/useProductList";
import { getLocalizedFundDescription } from "Utils/Products";
import { isNoU, notNoU } from "Utils/ObjectUtils";
import { useProductColor } from "Hooks/useProductColor";
import { getSafeHoverBackground } from "Utils/CssUtils";

type Props = {
  onSelect: (product: ProductListItemDto) => void;
  onDetail: (product: ProductListItemDto) => void;
  selected?: boolean;
} & ProductListItemDto;

const PageResources = Resources.Investments.FundSelection;

const StyledButton = styled(TrackedButton)<{
  $hexColor?: string | null | undefined;
  $hexBackgroundColor?: string | null | undefined;
}>`
  min-width: unset;
  padding: ${({ theme }) => theme.spacing(1, 5)};
  border-radius: 100px;
  font-weight: 600;
  text-transform: none;

  color: ${({ theme, $hexColor }) =>
    isNoU($hexColor) ? theme.palette.background.default : $hexColor};

  ${props =>
    props.$hexBackgroundColor &&
    `
        background-color: ${props.$hexBackgroundColor};
        
        ${getSafeHoverBackground(props.$hexBackgroundColor)}
      `}
`;

export const ProductCard: FunctionComponent<Props> = ({
  onSelect,
  onDetail,
  selected,
  ...props
}) => {
  const { t } = useResource();
  const { productIsins, maxPerformance, minPerformance } = props;

  const handleSelect = () => onSelect(props);
  const handleDetail = () => onDetail(props);
  const isin = productIsins[0];
  const { color, contrastColor } = useProductColor(isin);
  const description = getLocalizedFundDescription(props.code, t);
  const descriptions = notNoU(description) ? [description] : undefined;

  return (
    <StyledDarkCard $isActive={selected}>
      <CardContent>
        <ProductLogo isin={isin} imageHeight={35} />

        <FundDescription
          maxPerformance={maxPerformance}
          minPerformance={minPerformance}
          descriptions={descriptions}
        />

        <StyledFlex $justifyContent="flex-end" $gap={4}>
          <TextButton
            color="secondary"
            onClick={handleDetail}
            trackingTag={t(PageResources.FundDescription)}
          >
            {t(PageResources.FundDescription)}
          </TextButton>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleSelect}
            trackingTag={t(PageResources.Invest)}
            trackingValue={props.code}
            $hexColor={contrastColor}
            $hexBackgroundColor={color}
          >
            {t(PageResources.Invest)}
          </StyledButton>
        </StyledFlex>
      </CardContent>
    </StyledDarkCard>
  );
};
