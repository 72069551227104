import { CardContent, Grid, Stack, Typography } from "@mui/material";
import { StyledDarkCard } from "Components/Shared/StyledComponents";
import { MouseEvent, type FunctionComponent } from "react";
import { ContractDto } from "Api/Api";
import { useInvestmentName } from "Hooks/Investment/useInvestmentName";
import { useNavigate } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { ContractCategoriesIcons } from "Hooks/codeLists/useContractCategories";
import { ContractCategories } from "Models/Contracts";
import styled from "styled-components";
import { formatCurrency } from "Utils/CurrencyUtils";
import { Colors } from "Components/Layout/Themes/Colors";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import { CoinsIcon, PurchaseIcon } from "Components/Shared/Icons";
import { Resources, useResource } from "Translations/Resources";
import { IconButton } from "Components/ContractDetail/Shared/IconButton";
import { CardCircle } from "Components/Dashboard/DetailDashboard/DashboardCard/CardCircle";
import { performanceToPercent } from "Utils/PerformanceCalculators";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { formatNumber, roundNumberToDecimalPlaces } from "Utils/NumberUtils";

type Props = {
  disabledPurchases?: boolean;
} & ContractDto;

const DarkCircleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const DashboardCard: FunctionComponent<Props> = ({
  contractID,
  name,
  purposeAmount = 0,
  actualAmount = 0,
  signDate,
  purposeCategory,
  currency,
  performanceFromAccountCreation,
  realizedProfit,
  disabledPurchases = false,
  isDip,
}) => {
  const investmentName = useInvestmentName({
    name,
    signDate,
  });
  const { t } = useResource();
  const navigate = useNavigate();

  const decimalPlaces = 2;
  const realizedProfileValue = roundNumberToDecimalPlaces(
    realizedProfit,
    decimalPlaces,
  );

  const isProfit = realizedProfileValue >= 0;

  const formattedRealizedProfit = `${
    realizedProfileValue <= 0 ? "" : "+"
  }${formatCurrency(realizedProfileValue, currency, { decimalPlaces })}`;

  const formattedPerformFromAccCreation = `(${formatNumber(
    roundNumberToDecimalPlaces(
      performanceToPercent(performanceFromAccountCreation),
      decimalPlaces,
    ),
  )}%)`;

  const profitColor =
    realizedProfileValue === 0
      ? Colors.White
      : isProfit
      ? Colors.Green
      : Colors.Red;

  const formattedActualAmount = formatCurrency(actualAmount, currency);
  const parts = formattedActualAmount.split("\u00A0");

  const currencyPart = parts[parts.length - 1];
  const numbersPart = formattedActualAmount.replace(currencyPart, "").trim();
  const progressPercent =
    (purposeAmount ?? 0) > 0
      ? Math.round(((actualAmount ?? 0) / purposeAmount!) * 100)
      : 0;

  const purposeCategoryValue: ContractCategories =
    purposeCategory ?? ContractCategories.Other;

  const redirectToDetail = () => {
    if (contractID) {
      navigate(getPath(AppRouting.ContractDetail, contractID));
    }
  };

  const redirectToPurchase = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (contractID) {
      navigate(getPath(AppRouting.Purchase, contractID));
    }
  };

  return (
    <DipTagWrapper showPlain={!isDip}>
      <StyledDarkCard onClick={redirectToDetail}>
        <DarkCircleContainer>
          <CardCircle />
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid
                item
                xs={2}
                lg={1}
                alignItems="center"
                display="flex"
                onClick={redirectToDetail}
              >
                <IconCircleWrapper
                  size={40}
                  color="primary"
                  highlighted={false}
                  backgroundColor="#393939"
                  progressPercent={progressPercent}
                >
                  {!!purposeCategory ? (
                    ContractCategoriesIcons[purposeCategoryValue]
                  ) : (
                    <CoinsIcon />
                  )}
                </IconCircleWrapper>
              </Grid>

              <Grid
                item
                xs={7}
                lg={9}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                onClick={redirectToDetail}
              >
                <Typography
                  fontSize={14}
                  variant="body2"
                  noWrap
                  paddingRight={2}
                >
                  {investmentName}
                </Typography>

                <Stack direction="row" gap={1} alignItems="baseline">
                  <Typography fontWeight={700} fontSize={18} variant="body2">
                    {numbersPart}
                  </Typography>
                  <Typography fontWeight={400} fontSize={12} variant="body2">
                    {currencyPart}
                  </Typography>
                </Stack>

                <Stack direction="row" gap={1}>
                  <Typography fontSize={12} fontWeight={400} variant="body2">
                    {t(Resources.Dashboard.Profit)}
                  </Typography>
                  <Stack direction="row" flexWrap="wrap">
                    <Typography
                      fontSize={12}
                      fontWeight={700}
                      color={profitColor}
                    >
                      {formattedRealizedProfit}
                    </Typography>
                    &nbsp;
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color={profitColor}
                    >
                      {formattedPerformFromAccCreation}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>

              <Grid
                item
                xs={2}
                lg
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                onClick={redirectToPurchase}
              >
                <IconButton
                  icon={<PurchaseIcon />}
                  label={t(Resources.Dashboard.PurchaseButton)}
                  textColor="secondary"
                  fontSize={12}
                  //onClick={redirectToPurchase}
                  disabled={disabledPurchases}
                />
              </Grid>
            </Grid>
          </CardContent>
        </DarkCircleContainer>
      </StyledDarkCard>
    </DipTagWrapper>
  );
};
