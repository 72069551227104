import { Box, Typography } from "@mui/material";
import { Step } from "Components/ContractCreate/Shared/Step";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { BankiDIcon } from "Components/Shared/Icons";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { PrivacyPolicyLink } from "Components/Shared/PrivacyPolicyLink";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { useFormData } from "Hooks/Contract/useFormData";
import {
  NewContractStep,
  type PathSelectionFormModel,
  VerificationMode,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { PageTitle } from "./Shared/PageTitle";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useYupFormSchema } from "Hooks/Contract/PathSelection/useYupFormSchema";
import { yupResolver } from "@hookform/resolvers/yup";

const PageResources = Resources.Contract.NewContract.PathSelection;

const StyledUnderline = styled.span`
  text-decoration: underline;
`;

const StyledPrivacyPolicy = styled.div`
  opacity: 0.5;
`;

const StyledBankIdNegativeIcon = styled(BankiDIcon)`
  display: block;
  height: 16px;
  margin-top: 3px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const PathSelection: FunctionComponent = () => {
  const { t } = useResource();
  const { nextStep } = useCreateContractStep();
  const { color, contrastColor } = useModelingColor();
  const { defaultValues } = useFormData<NewContractStep.PathSelection>({
    step: NewContractStep.PathSelection,
  });

  const schema = useYupFormSchema();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PathSelectionFormModel>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (formData: PathSelectionFormModel) => {
    nextStep({ formData });
  };

  return (
    <>
      <div>
        <Step actualStep={1} />

        <PageTitle>{t(PageResources.PersonalData)}</PageTitle>
        <Typography>{t(PageResources.InfoText1)}</Typography>
        <Typography marginTop={2} marginBottom={8}>
          {t(PageResources.InfoText2)}
        </Typography>

        <RadioButtons<PathSelectionFormModel, VerificationMode>
          codeList={[
            {
              code: VerificationMode.BankID,
              name: (
                <div>
                  <StyledBankIdNegativeIcon height={50} width={undefined} />
                  <Typography component="span" variant="h4">
                    {t(PageResources.CheckboxBankIdentityName1)}{" "}
                    <StyledUnderline>
                      {t(PageResources.CheckboxBankIdentityName2)}
                    </StyledUnderline>
                  </Typography>
                  <BlInfoPopper color="primary" icon="info">
                    {t(PageResources.BankIDTooltip)}
                  </BlInfoPopper>
                </div>
              ),
            },
            {
              code: VerificationMode.Manual,
              name: (
                <Typography component="span" variant="h4">
                  {t(PageResources.CheckboxManualName)}
                </Typography>
              ),
              description: t(PageResources.CheckboxManualLabel),
            },
          ]}
          control={control}
          name="verificationMode"
          errors={errors}
          spacing={4}
        />
      </div>
      <Box display="grid" gap={3}>
        <StyledPrivacyPolicy>
          {t(PageResources.MoreInformation)} &nbsp;
          <PrivacyPolicyLink />
        </StyledPrivacyPolicy>
        <PrimaryButton
          color="primary"
          onClick={handleSubmit(onSubmit)}
          trackingTag={t(Resources.Common.Continue)}
          hexColor={contrastColor}
          hexBackgroundColor={color}
        >
          {t(Resources.Common.Continue)}
        </PrimaryButton>
      </Box>
    </>
  );
};
