import { TransferDipStep } from "State/Contracts/TransferDip/TransferDipModels";
import {
  transferDipPreviewAsync,
  transferDipPreviewSaga,
} from "State/Contracts/TransferDip/TransferDipPreviewState";
import {
  transferDipAsync,
  transferDipSaga,
} from "State/Contracts/TransferDip/TransferDipState";

import { produce } from "immer";
import { all } from "typed-redux-saga";
import { ActionType, createAction, createReducer } from "typesafe-actions";
import {
  transferDipInitializeSignatureAsync,
  transferDipInitializeSignatureSaga,
} from "./TransferDipInitializeSignatureState";

export const setTransferDipStep = createAction(
  "@contract/SET_NEXT_TRANSFER_STEP",
)<TransferDipStep>();

export const setTransferDipContractID = createAction(
  "@contract/SET_TRANSFER_DIP_CONTRACT_ID",
)<number | null>();

export const setTransferDipIsConsentGranted = createAction(
  "@contract/SET_TRANSFER_DIP_IS_CONSENT_GRANTED",
)<boolean>();

export const resetTransferDipError = createAction(
  "@contract/RESET_TRANSFER_DIP_ERROR",
)();

export const resetTransferDipState = createAction(
  "@contract/RESET_TRANSFER_DIP_STATE",
)();

type TransferDipState = {
  contractID: number | null;
  contractPreview: string | null;
  isLoading: boolean;
  isConsentGranted: boolean;
  actualStep: TransferDipStep;
  requestHash: string | null;
  phone: string | null;
  signatureHash: string | null;
  signatureType: "SMS" | "BIOMETRICS";
  lastRequest: { signatureHash: string | null; isBiometry: boolean } | null;
  error: Error | null;
};

const initialTransferDipState: TransferDipState = {
  contractID: null,
  contractPreview: null,
  isLoading: false,
  isConsentGranted: false,
  actualStep: TransferDipStep.TransferInformation,
  requestHash: null,
  phone: null,
  signatureType: "SMS",
  signatureHash: null,
  lastRequest: null,
  error: null,
};

export type TransferDipAction =
  | ActionType<typeof transferDipAsync>
  | ActionType<typeof transferDipPreviewAsync>
  | ActionType<typeof transferDipInitializeSignatureAsync>
  | ActionType<typeof resetTransferDipState>
  | ActionType<typeof setTransferDipStep>
  | ActionType<typeof setTransferDipContractID>
  | ActionType<typeof setTransferDipIsConsentGranted>
  | ActionType<typeof resetTransferDipError>;

export function* watchTransferDipStateSagas() {
  yield all([
    transferDipSaga(),
    transferDipPreviewSaga(),
    transferDipInitializeSignatureSaga(),
  ]);
}

export const transferDipReducer = createReducer<
  TransferDipState,
  TransferDipAction
>(initialTransferDipState)
  .handleAction(transferDipAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.requestHash = draft.signatureHash = null;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(transferDipAsync.success, (state, action) => {
    return produce(state, draft => {
      const { requestHash, signatureHash } = action.payload;

      draft.isLoading = false;
      draft.requestHash = requestHash;
      draft.signatureHash = signatureHash;
      return draft;
    });
  })
  .handleAction(transferDipAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  })
  .handleAction(transferDipPreviewAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.contractPreview = null;
      return draft;
    });
  })
  .handleAction(transferDipPreviewAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  })
  .handleAction(transferDipPreviewAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.contractPreview = action.payload?.base64Data || null;
      return draft;
    });
  })
  .handleAction(setTransferDipStep, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.actualStep = action.payload;
      return draft;
    });
  })
  .handleAction(setTransferDipContractID, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.contractID = action.payload;
      return draft;
    });
  })
  .handleAction(setTransferDipIsConsentGranted, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.isConsentGranted = action.payload;
      return draft;
    });
  })
  .handleAction(resetTransferDipError, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(
    transferDipInitializeSignatureAsync.request,
    (state, action) => {
      return produce(state, draft => {
        draft.isLoading = true;
        draft.lastRequest = action.payload;
        draft.error = null;
        return draft;
      });
    },
  )
  .handleAction(
    transferDipInitializeSignatureAsync.success,
    (state, action) => {
      return produce(state, draft => {
        const { signatureType, phone } = action.payload;

        draft.isLoading = false;
        draft.signatureType = signatureType;
        draft.phone = phone;
        draft.actualStep = TransferDipStep.TransferSignature;
        return draft;
      });
    },
  )
  .handleAction(
    transferDipInitializeSignatureAsync.failure,
    (state, action) => {
      return produce(state, draft => {
        draft.isLoading = false;
        draft.error = action.payload;
        return draft;
      });
    },
  )
  .handleAction(resetTransferDipState, (state, _) => {
    return produce(state, _ => {
      return initialTransferDipState;
    });
  });
