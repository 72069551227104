import { ContractOverviewDto } from "Api/Api";
import { useFormatLocale } from "Hooks/DateFns/useFormatLocale";
import { Resources, useResource } from "Translations/Resources";
import { isValid } from "date-fns";

export const useInvestmentName = (
  {
    name,
    signDate,
  }: Partial<Pick<ContractOverviewDto, "name" | "signDate">> = {
      name: "",
    },
): string => {
  const { t } = useResource();
  const format = useFormatLocale();

  if (!name) {
    if (signDate) {
      const date = new Date(signDate);
      if (isValid(date)) {
        return t(Resources.Investments.InvestmentAlternativeName, {
          date: format(date),
        });
      }
    }

    return "";
  }

  return name;
};
