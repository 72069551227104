import {
  deleteAuthTokenRevoke,
  RevokeTokenCommandResult,
  RevokeTokenCommandResultStatus,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { put, takeLatest } from "typed-redux-saga";
import { safeApiCall } from "State/Utils";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";

export const revokeTokenAsync = createAsyncAction(
  "@auth/REVOKE_TOKEN_REQUEST",
  "@auth/REVOKE_TOKEN_SUCCESS",
  "@auth/REVOKE_TOKEN_FAILURE",
)<void, RevokeTokenCommandResult, Error>();

function* revokeToken(): Generator {
  try {
    const { status, response, error } = yield* safeApiCall(
      deleteAuthTokenRevoke,
    );

    if (error) {
      yield put(revokeTokenAsync.failure(error));
      return;
    }

    if (response.status === RevokeTokenCommandResultStatus.Success) {
      yield put(revokeTokenAsync.success(response));
    } else {
      yield put(
        revokeTokenAsync.failure(
          new ApplicationError(
            `Revoke token request ended with HTTP status code ${status}, request result status ${response?.status}`,
            ErrorLevel.Error,
            {
              status,
              response,
              error,
            },
          ),
        ),
      );
    }
  } catch (err) {
    yield put(revokeTokenAsync.failure(err as Error));
  }
}

export function* revokeTokenSaga() {
  yield takeLatest(getType(revokeTokenAsync.request), revokeToken);
}
