import { FormModel } from "Components/Auth/Passwords/PasswordSetForm";
import { usePasswordValidation } from "Hooks/YupValidations/usePasswordValidation";
import { Resources, useResource } from "Translations/Resources";
import { ObjectSchema, object, string } from "yup";

export const useSetPasswordSchema = (): ObjectSchema<FormModel> => {
  const { t } = useResource();
  const passwordValidation = usePasswordValidation();

  return object().shape({
    code: string().required(),
    newPassword: passwordValidation,
    confirmPassword: string()
      .required()
      .test(
        "confirmPassword",
        t(Resources.Profile.Settings.PasswordNotMatch),
        function (value) {
          return value === this.parent.newPassword;
        },
      ),
  });
};
