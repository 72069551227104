import * as React from "react";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";
import styled from "styled-components";

type Props = SkeletonProps & {
  fullWidth?: boolean;
  fullHeight?: boolean;
  marginBottom?: number;
};

const StyledSkeleton = styled(Skeleton)<{
  $fullWidth?: boolean;
  $fullHeight?: boolean;
  $marginBottom?: number;
}>`
  background-color: ${props => props.theme.palette.secondary.main}33;
  border-radius: ${props => props.theme.borderRadius};
  ${props => props.$fullWidth && "min-width: 100%;"}
  ${props => props.$fullHeight && "min-height: 100%;"}
  ${props => props.$marginBottom && `margin-bottom: ${props.$marginBottom}px ;`}
`;

export const PrimarySkeleton: React.FunctionComponent<Props> = ({
  variant = "rectangular",
  fullWidth,
  fullHeight,
  marginBottom,
  ...props
}) => {
  return (
    <StyledSkeleton
      variant={variant}
      {...props}
      $fullWidth={fullWidth}
      $fullHeight={fullHeight === undefined ? true : fullHeight}
      $marginBottom={marginBottom}
    />
  );
};
