import { SexType } from "Models/Inputs";
import { isValid, parse } from "date-fns";
import { isEmpty, isNil, isNull } from "lodash-es";

const isNullOrEmpty = (value: unknown) => isNull(value) || isEmpty(value);

export class PersonInfo {
  Gender?: SexType;
  BirthDate?: Date;
  IsValid = false;
  PersonalIdentificationNumber?: string;
}

function formatNumber(personalIdentificationNumber: string): string {
  if (
    !isNullOrEmpty(personalIdentificationNumber) &&
    personalIdentificationNumber.search("/") === -1
  ) {
    const datePart = personalIdentificationNumber.slice(0, 6);
    const endPart = personalIdentificationNumber.slice(6);
    return `${datePart}/${endPart}`;
  }

  return personalIdentificationNumber;
}

export const PersonalIdentification = {
  getInfo: (personalIdentificationNumber: string): PersonInfo => {
    const personInfo = new PersonInfo();
    if (
      isEmpty(personalIdentificationNumber) ||
      isNil(personalIdentificationNumber)
    ) {
      return personInfo;
    } // tslint:disable-next-line: no-parameter-reassignment

    personalIdentificationNumber = personalIdentificationNumber.replace(
      "/",
      "",
    );
    const IDlength = personalIdentificationNumber.length;

    if (IDlength < 6) {
      return personInfo;
    }

    const IDyear = personalIdentificationNumber.substring(0, 2);
    const IDmonth = personalIdentificationNumber.substring(2, 4);
    const IDday = personalIdentificationNumber.substring(4, 6);

    let year = +IDyear;
    let month = +IDmonth;
    let day = +IDday;
    let gender: SexType = "M";

    if (isNaN(year) || (IDlength === 9 && year >= 54)) {
      return personInfo;
    }

    if (IDlength === 9 || (IDlength === 10 && year >= 54)) {
      year += 1900;
    } else if (IDlength === 10 && year < 54) {
      year += 2000;
    }

    if (month > 50) {
      gender = "F";
    }

    if ((month >= 1 && month <= 12) || (month >= 21 && month <= 32)) {
      if (month >= 21) {
        month -= 20;
      }
    } else if ((month >= 51 && month <= 62) || (month >= 71 && month <= 82)) {
      if (month <= 62) {
        month -= 50;
      } else if (month >= 71) {
        month -= 70;
      }
    }

    if (day > 50) {
      day -= 50; // Foreigners have 50 added to their birth day
    }

    const parsedDate = parse(`${year}-${month}-${day}`, "yyyy-M-d", new Date()); // Check date
    if (!isValid(parsedDate)) {
      return personInfo;
    }

    if (personalIdentificationNumber.length === 10) {
      const ctrlBase: number = parseInt(
        personalIdentificationNumber.substring(0, 9),
        10,
      );
      const ctrlNum: number = parseInt(
        personalIdentificationNumber.substring(9, 10),
        10,
      );
      const modulo: number = ctrlBase % 11;
      personInfo.IsValid = modulo === ctrlNum || modulo - 10 === ctrlNum;
    } else if (personalIdentificationNumber.length === 9) {
      personInfo.IsValid = true;
    }

    personInfo.BirthDate = new Date(year, month - 1, day);
    personInfo.Gender = gender;
    personInfo.PersonalIdentificationNumber = formatNumber(
      personalIdentificationNumber,
    );
    return personInfo;
  },
  format: (personalIdentificationNumber: string): string => {
    return formatNumber(personalIdentificationNumber);
  },
  getAge: (date: string | Date): number => {
    const today = new Date();
    const birthDate = typeof date === "string" ? new Date(date) : date;
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },
};
