import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { DocumentListSectionDto, getDocuments } from "Api/Api";

export type GetListStateType = FetchStateType<DocumentListSectionDto[]>;

export const getListState = (): GetListStateType => getFetchStateDefaultValue();

export type GetListActionType = ActionType<typeof getListAsync>;

export const getListAsync = createAsyncAction(
  "@documents/GET_LIST_REQUEST",
  "@documents/GET_LIST_SUCCESS",
  "@documents/GET_LIST_FAILURE",
)<void, DocumentListSectionDto[], Error>();

function* getList(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getDocuments);

    if (error) {
      yield put(getListAsync.failure(error));
      return;
    }

    yield put(getListAsync.success(response));
  } catch (err) {
    yield put(getListAsync.failure(err as Error));
  }
}

export function* getListSaga() {
  yield takeLatest(getType(getListAsync.request), getList);
}

export const getListReducer = createReducer<
  GetListStateType,
  GetListActionType
>(getListState())
  .handleAction(getListAsync.request, handleActionRequest)
  .handleAction(getListAsync.failure, handleActionFailure)
  .handleAction(getListAsync.success, handleActionSuccess);
