import { useAppDispatch } from "Hooks/useAppDispatch";
import { initWebViewListener } from "Utils/WebViewUtils";
import { type FunctionComponent, PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";

export const WebViewWrapper: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    initWebViewListener(dispatch, navigate);
  });

  return <>{children}</>;
};
