import * as React from "react";
import { Typography } from "@mui/material";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { useResource, Resources } from "Translations/Resources";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { resetUser } from "State/Auth/AuthReducer";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledIcon = styled(NotInterestedIcon)`
  margin: 20px;
  font-size: 120px;
`;

export const Error403Page: React.FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useDispatch();
  return (
    <UnauthenticatedLayout title={"403"}>
      <StyledPage>
        <Typography variant="h1">Error403</Typography>
        <Typography variant="h2">
          {t(Resources.Errors.Error403.Subtitle)}
        </Typography>
        <StyledIcon />
        <PrimaryButton
          onClick={() =>
            dispatch(
              resetUser({
                wasSignedOutManually: true,
              }),
            )
          }
        >
          Odhlásit se
        </PrimaryButton>
      </StyledPage>
    </UnauthenticatedLayout>
  );
};

export default Error403Page;
