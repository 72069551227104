import {
  ChangePasswordCommandResult,
  putAuthPassword,
  ChangePasswordRequestDto,
  ChangePasswordCommandStatus,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { safeApiCall } from "State/Utils";

export const changePasswordAsync = createAsyncAction(
  "@auth/CHANGE_PASSWORD_REQUEST",
  "@auth/CHANGE_PASSWORD_SUCCESS",
  "@auth/CHANGE_PASSWORD_FAILURE",
)<
  {
    model: ChangePasswordRequestDto;
    onSuccess: () => void;
  },
  ChangePasswordCommandResult,
  Error
>();

function* changePassword(
  action: ReturnType<typeof changePasswordAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      putAuthPassword,
      action.payload.model,
    );

    if (error) {
      yield put(changePasswordAsync.failure(error));
      return;
    }

    if (response.status === ChangePasswordCommandStatus.Success) {
      yield put(changePasswordAsync.success(response));
      action.payload.onSuccess();
    } else {
      yield put(
        changePasswordAsync.failure(new ApplicationError(response.status)),
      );
    }
  } catch (err) {
    yield put(changePasswordAsync.failure(err as Error));
  }
}
export function* changePasswordSaga() {
  yield takeLatest(getType(changePasswordAsync.request), changePassword);
}
