import { ContractTypeCode } from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  FeeRateType,
  clearFeeRate,
  singleInvestmentFeeRateRequest,
  periodicalInvestmentFeeRateRequest,
} from "State/Products/FeeRate/GetFeeRateState";
import { useEffect } from "react";

export type FeeRateProps = {
  isin?: string | undefined | null;
  contractTypeCode?: ContractTypeCode | null;
  amount?: number | null;
  investmentLengthYears?: number | undefined | null;
  contractID?: number;
};

export const useFeeRate = (
  {
    amount,
    contractTypeCode,
    investmentLengthYears,
    isin,
    contractID,
  }: FeeRateProps,
  feeRate: FeeRateType,
) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(s => s.products.feeRate[feeRate]);

  useEffect(() => {
    if (
      !amount ||
      Number.isNaN(amount) ||
      !Number.isFinite(amount) ||
      !contractTypeCode ||
      !isin
    ) {
      return;
    }

    const payload = {
      amount,
      contractID,
      contractTypeCode,
      horizont: investmentLengthYears,
      isin,
    };

    if (feeRate === "periodical") {
      dispatch(periodicalInvestmentFeeRateRequest(payload));
    } else {
      dispatch(singleInvestmentFeeRateRequest(payload));
    }
  }, [
    dispatch,
    amount,
    contractTypeCode,
    investmentLengthYears,
    isin,
    contractID,
    feeRate,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearFeeRate(feeRate));
    };
  }, [dispatch, feeRate]);

  return state;
};
