import { FunctionComponent } from "react";
import { Document } from "Components/Shared/Document";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  base64String: string | null | undefined;
  isLoading: boolean;
  fileName: string;
};

const ComponentResource = Resources.Contract.ContractDraft;

export const ContractPreviewDownloadLink: FunctionComponent<Props> = ({
  base64String,
  isLoading,
  fileName,
}) => {
  const { t } = useResource();

  const onDownload = () => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${base64String}`;
    link.download = `${fileName}.pdf`;
    link.click();
  };

  return (
    <Document
      name={t(ComponentResource.Title)}
      href="nahled-smlouvy"
      onClick={onDownload}
      hrefDownloadText={t(
        isLoading
          ? ComponentResource.Loading
          : ComponentResource.DownloadButton,
      )}
      disabled={isLoading}
    />
  );
};
