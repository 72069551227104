import { Link, Typography } from "@mui/material";
import { PageTitle } from "Components/Shared/PageTitle";
import { Resources, useResource } from "Translations/Resources";
import { MAINTAINCE_SERVICE_EMAIL } from "Utils/ExternalLinkUtils";
import { FunctionComponent } from "react";

type Props = {
  pageTitle: string;
};

const PageResources = Resources.Profile.Settings.DeleteAccount;

export const DeleteAccount: FunctionComponent<Props> = ({ pageTitle }) => {
  const { t } = useResource();

  return (
    <div>
      <PageTitle showOnMobile={false} marginBottom={6}>
        {pageTitle}
      </PageTitle>
      <Typography>
        {t(PageResources.Description)}&nbsp;
        <Link
          target="_blank"
          href={`mailto:${MAINTAINCE_SERVICE_EMAIL}`}
          color="secondary"
        >
          {MAINTAINCE_SERVICE_EMAIL}
        </Link>
        &#46;
      </Typography>
    </div>
  );
};
