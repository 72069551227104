import { Typography, type CheckboxProps, Checkbox } from "@mui/material";
import { CheckIcon } from "Components/Shared/Icons";
import { FormInfo } from "Components/Shared/Inputs/FormInfo";
import { track } from "Utils/TrackingUtils";
import { FunctionComponent } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div<{ $error?: boolean }>`
  margin-top: ${props => props.theme.spacing(1)};

  display: flex;
  user-select: none;
  align-items: flex-start;

  & > label {
    display: flex;
    cursor: pointer;
    padding-left: ${props => props.theme.spacing(2)};
    padding-top: ${props => props.theme.spacing(0.5)};
    width: 100%;
    ${props => props.$error && `color: ${props.theme.colors.red};`}
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: ${props => props.theme.spacing(0.4, 0, 0, 0)};

  &.MuiButtonBase-root {
    &:hover {
      background-color: unset;
    }
  }

  &.Mui-checked + label {
    .MuiTypography-root {
      font-weight: 600;
    }
  }

  &.Mui-disabled + label {
    opacity: 0.5;
  }
`;

const StyledCheckIcon = styled(CheckIcon)<{
  $disabled?: boolean;
}>`
  border: 1px solid ${props => props.theme.colors.border}80;
  border-radius: 4px;
  margin: ${props => props.theme.spacing(0.5, 0)};
  ${props => props.$disabled && `color: ${props.theme.palette.text.secondary};`}
`;

const StyledUncheckIcon = styled.div<{
  $disabled?: boolean;
  $error?: boolean;
}>`
  width: 18px;
  height: 18px;
  border: 1px solid
    ${props =>
      props.$error ? props.theme.colors.red : props.theme.colors.border}80;
  border-radius: 4px;
  margin: ${props => props.theme.spacing(0.5, 0)};
  ${props => props.$disabled && `color: ${props.theme.palette.text.disabled};`}
`;

export type CheckboxInputProps = {
  isLabelFirst?: boolean;
  label?: string | JSX.Element;
  formInfo?: { [key: string]: string };
  hasError?: boolean;
} & CheckboxProps;

export const CheckboxInput: FunctionComponent<CheckboxInputProps> = ({
  isLabelFirst,
  label,
  formInfo,
  hasError,
  onChange,
  name,
  ...props
}) => {
  const LabelElement = (
    <label htmlFor={name}>
      {typeof label === "string" ? <Typography>{label}</Typography> : label}
      <FormInfo formInfo={formInfo} name={name ?? ""}></FormInfo>
    </label>
  );

  return (
    <StyledWrapper className="checkbox" $error={hasError}>
      {isLabelFirst && label && LabelElement}
      <StyledCheckbox
        checkedIcon={
          <StyledCheckIcon width={18} height={18} $disabled={props.disabled} />
        }
        icon={
          <StyledUncheckIcon $disabled={props.disabled} $error={hasError} />
        }
        name={name}
        onChange={(e, checked) => {
          track({
            category: "Checkbox",
            event: "Change",
            action: "Click",
            tag: name,
            value: checked ? "Checked" : "Unchecked",
          });
          onChange?.(e, checked);
        }}
        {...props}
      />
      {!isLabelFirst && label && LabelElement}
    </StyledWrapper>
  );
};
