import { Box, DialogContent, Typography } from "@mui/material";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { useEffect, type FunctionComponent, useState, useMemo } from "react";
import { TrackingWrapper } from "Components/Tracking/TrackingWrapper";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { Resources, useResource } from "Translations/Resources";
import { StyledDialog } from "Components/Shared/StyledComponents";
import { ProductItemDto } from "Api/Api";
import { useProductTilesItems } from "Hooks/Products/useProductTilesItems";
import { Product } from "Components/ContractModeling/FundSelector/Components/Product";
import { track } from "Utils/TrackingUtils";
import { useIsDipEnabled } from "Hooks/Contract/useIsDipEnabled";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { getFundColor } from "Utils/Products";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const ComponentResources = Resources.Investments.FundSelection;

export type OnSelectProduct = (product: ProductItemDto) => void;

type Props = {
  productCode?: ProductItemDto["productCode"];
  onClose: () => void;
  onSelectProduct: OnSelectProduct;
  defaultIsinValue?: string | null;
};

const PageResources = Resources.Investments.FundSelection;

export const ClassSelector: FunctionComponent<Props> = ({
  productCode,
  onClose,
  onSelectProduct,
  defaultIsinValue,
}) => {
  const { t } = useResource();
  const [checked, setChecked] = useState<ProductItemDto>();

  const isOpen = typeof productCode !== "undefined";
  const { color, contrastColor } = getFundColor(productCode);

  const { items: productItems, isLoading } = useProductTilesItems(productCode);
  const { isDipEnabled } = useIsDipEnabled();

  const filteredProductItems = useMemo(() => {
    if (!isDipEnabled) {
      return productItems;
    }

    return productItems?.filter(item => item.isDipAllowed);
  }, [productItems, isDipEnabled]);

  useEffect(() => {
    if (!productCode) {
      setChecked(undefined);
    }
  }, [productCode]);

  useEffect(() => {
    if (defaultIsinValue && filteredProductItems?.length) {
      const item = filteredProductItems.find(
        item => item.isin === defaultIsinValue,
      );
      if (item) {
        setChecked(item);
      }
    }
  }, [defaultIsinValue, filteredProductItems]);

  useEffect(() => {
    if (checked || filteredProductItems?.length !== 1) {
      return;
    }

    const selectedProduct = filteredProductItems[0];
    onSelectProduct(selectedProduct);
  }, [checked, filteredProductItems, onSelectProduct, isDipEnabled]);

  return (
    <StyledDialog open={isOpen} fullWidth maxWidth="md" onClose={onClose}>
      <DialogContent>
        <TrackingWrapper
          payload={{
            category: "ModalView",
            event: "FundClassSelectorDialog",
            value: productCode,
            action: isOpen ? "Open" : "Close",
          }}
        >
          <Box marginBottom={3}>
            {productCode && (
              <ProductLogo productCode={productCode} imageHeight={35} />
            )}
            <Typography>{t(ComponentResources.ClassSelection)}</Typography>
            <Box display="grid" gap={2} marginTop={2} marginBottom={3}>
              {" "}
              <LoadingWrapper
                isLoading={isLoading}
                skeleton={
                  <>
                    <PrimarySkeleton fullWidth height={95} />
                    <PrimarySkeleton fullWidth height={95} />
                  </>
                }
              >
                {filteredProductItems?.map(product => (
                  <DipTagWrapper showPlain={!isDipEnabled} key={product.isin}>
                    <Product
                      product={product}
                      isChecked={checked?.isin === product.isin}
                      onCheck={() => {
                        track({
                          category: "RadioButtons",
                          event: "Change",
                          action: "Click",
                          tag: "selectedProduct",
                          value: product.isin,
                        });
                        setChecked(product);
                      }}
                    />
                  </DipTagWrapper>
                ))}
              </LoadingWrapper>
            </Box>
            <PrimaryButton
              fullWidth
              disabled={!checked}
              trackingTag={t(PageResources.Invest)}
              trackingValue={checked?.isin}
              onClick={() => {
                if (checked) {
                  onSelectProduct(checked);
                }
              }}
              hexColor={contrastColor}
              hexBackgroundColor={color}
            >
              {t(PageResources.Invest)}
            </PrimaryButton>
          </Box>
        </TrackingWrapper>
      </DialogContent>
    </StyledDialog>
  );
};
