import { PreContractualInformationCzech } from "Components/ContractCreate/PreContractualInformation/PreContractualInformationCzech";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useDesktop } from "Hooks/useDesktop";
import { useModelingIsin } from "Hooks/useModelingIsin";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";

const PageResources = Resources.Contract.NewContract.PreContractualInformation;

export const PreContractualInformationPage: FunctionComponent = _ => {
  const { t } = useResource();
  const navigate = useNavigate();
  const isDipSelected = useIsDipSelected();
  const { isDesktop } = useDesktop();
  const isin = useModelingIsin();

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(getPath(AppRouting.ContractModeling))}
      headerChildren={
        isDipSelected && !isDesktop ? <DipTagWrapper inverse /> : undefined
      }
      productIsin={isin ?? ""}
    >
      <LoadingWrapper>
        <PreContractualInformationCzech />
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};
