import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PageTitle } from "Components/Shared/PageTitle";
import { PaymentInformations } from "Components/Shared/PaymentInformations/PaymentInformations";
import { PaymentLoadingSkeleton } from "Components/Shared/PaymentInformations/PaymentLoadingSkeleton";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { usePurchaseTitle } from "Hooks/Contract/usePurchaseTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import {
  clearPaymentInstructions,
  getPaymentInstructionsAsync,
} from "State/Contracts/Contract/PaymentInstructions/GetPaymentInstructionsState";
import { Resources, useResource } from "Translations/Resources";
import { getProductColor } from "Utils/Products";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const PageResources = Resources.Contract.Detail.Purchase;

type Props = {
  contractID: number;
};

export const SuccessStep: React.FunctionComponent<Props> = props => {
  const { contractID } = props;

  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { configuration } = useAppSelector(s => s.contracts.purchase);
  const { data: overview } = useAppSelector(s => s.investmentsDetail.overview);

  const isin = configuration?.isin;
  const singleInvestment = configuration?.singleInvestment;
  const periodicalInvestment = configuration?.periodicalInvestment;

  const { color, contrastColor } = getProductColor(isin);

  useEffect(() => {
    if (contractID && isin) {
      dispatch(
        getPaymentInstructionsAsync.request({
          contractID: contractID,
          isins: [isin],
        }),
      );
    }

    return () => {
      dispatch(clearPaymentInstructions());
    };
  }, [contractID, isin, dispatch]);

  const { data, isLoading, error } = useAppSelector(
    s => s.contracts.contract.paymentInstruction,
  );

  const paymentInstructions = data?.[0]?.paymentInstructions;
  const productIsin = paymentInstructions?.[0]?.isin || undefined;
  const pageTitle = usePurchaseTitle();

  return (
    <>
      <div>
        <LoadingWrapper
          error={error}
          isLoading={isLoading}
          skeleton={<PaymentLoadingSkeleton />}
        >
          <PageTitle showOnMobile={false}>{pageTitle}</PageTitle>
          {productIsin && (
            <ProductLogo
              marginTop={isDesktop ? 6 : 0}
              marginBottom={2}
              isin={productIsin}
              imageHeight={35}
            />
          )}

          <PaymentInformations
            contractID={contractID}
            bankAccountRegion={overview?.bankAccountRegion}
            isinPaymentsInfo={
              productIsin
                ? {
                    [productIsin]: {
                      singleInvestment:
                        singleInvestment === 0 ? undefined : singleInvestment,
                      periodicalInvestment:
                        periodicalInvestment === 0
                          ? undefined
                          : periodicalInvestment,
                    },
                  }
                : {}
            }
            isNoticeToFirstPaymentVisible={
              data?.[0]?.isNoticeToFirstPaymentVisible
            }
            contractPaymentMessage={
              data?.[0]?.contractPaymentMessage || undefined
            }
            defaultBankAccount={data?.[0]?.defaultBankAccount || undefined}
            paymentInstructions={paymentInstructions}
            contractTypeCode={overview?.type}
            showSingleInvestment={!!singleInvestment}
            showSingleInvestmentProductLogo={false}
            showPeriodicalInvestment={!!periodicalInvestment}
            showPeriodicalInvestmentProductLogo={false}
            showDivider={false}
          />
        </LoadingWrapper>
      </div>
      <PrimaryButton
        color="primary"
        onClick={() => navigate(getPath(AppRouting.ContractDetail, contractID))}
        trackingTag={t(PageResources.DetailButton)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(PageResources.DetailButton)}
      </PrimaryButton>
    </>
  );
};
