import { Typography } from "@mui/material";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";

type Props = {
  actualStep: number;
};

export const Step: FunctionComponent<Props> = ({ actualStep }) => {
  const { t } = useResource();

  return (
    <Typography variant="subtitle1">
      {t(Resources.Common.Step, { actual: actualStep, count: 5 })}
    </Typography>
  );
};
