import { Typography } from "@mui/material";
import styled from "styled-components";

const StyledFormRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${props => props.theme.spacing(5, 4)};
  padding-left: ${props => props.theme.spacing(20)};
  margin-top: ${props => props.theme.spacing(1)};

  .checkbox {
    margin-top: ${props => props.theme.spacing(7)};
  }

  label {
    white-space: nowrap;
  }
`;

const StyledFormTitle = styled.div`
  margin: ${props => props.theme.spacing(5, 0)};
  text-transform: uppercase;
  opacity: 0.5;
`;

const StyledFormControl = styled.div<{
  $marginBottom?: number;
  $fullWidth?: boolean;
}>`
  &.long {
    flex: 0 0 360px;
    max-width: 360px;
  }

  &.medium {
    flex: 0 0 170px;
    max-width: 170px;
  }

  &.tiny {
    flex: 0 0 85px;
    max-width: 85px;
  }

  ${({ $fullWidth }) => ($fullWidth ? "width: 100%;" : "")}

  ${({ $marginBottom, theme }) =>
    $marginBottom !== undefined
      ? `margin-bottom: ${theme.spacing($marginBottom)};`
      : ""}
`;

const StyledFormLabelWrapper = styled.div<{
  $isError?: boolean;
  $disabled?: boolean;
}>`
  margin-bottom: ${props => props.theme.spacing(2)};
  display: flex;
  align-items: center;
  z-index: 1;

  label {
    display: flex;
    align-items: center;
    font-weight: 300;
    margin: 0;

    ${props =>
      props.$disabled &&
      `
      opacity: 0.5;
    `}

    color: ${props =>
      props.$isError
        ? props.theme.colors.red
        : props.theme.palette.text.primary};
  }
`;
const StyledFormInput = styled.div`
  & > div {
    display: flex;
  }
`;

const StyledValidationText = styled.div`
  color: ${props => props.theme.palette.error.main};
  min-height: 20px;
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const StyledError = styled(StyledValidationText)<{
  $disableBottomMargin?: boolean;
}>`
  margin-top: ${props => props.theme.spacing(1)};
  ${props =>
    !props.$disableBottomMargin
      ? `margin-bottom: ${props.theme.spacing(2)}`
      : `margin-bottom: ${props.theme.spacing(1)}`};
`;

const ErrorTypography = styled(Typography)`
  color: ${props => props.theme.colors.red};
`;

export {
  StyledFormControl,
  StyledFormInput,
  StyledFormLabelWrapper,
  StyledFormRow,
  StyledFormTitle,
  StyledValidationText,
  StyledError,
  ErrorTypography,
};
