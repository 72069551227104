import * as React from "react";
export const CopyIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M9.5 0V3L10.5 4V1H18V7H24V20H16.5V21H25V6.29688L18.7031 0H9.5ZM19 1.70312L23.2969 6H19V1.70312ZM0 4V25H15.5V10.2969L15.3594 10.1406L9.35938 4.14062L9.20312 4H0ZM1 5H8.5V11H14.5V24H1V5ZM9.5 5.71875L13.7812 10H9.5V5.71875Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
