export enum WebViewMessageTypes {
  INIT_REQUEST = "INIT_REQUEST",
  INIT_RESPONSE = "INIT_RESPONSE",
  BIOMETRIC_SIGN_UP_REQUEST = "BIOMETRIC_SIGN_UP_REQUEST",
  BIOMETRIC_SIGN_UP_RESPONSE = "BIOMETRIC_SIGN_UP_RESPONSE",
  BIOMETRIC_SIGN_UP_RESULT_REQUEST = "BIOMETRIC_SIGN_UP_RESULT_REQUEST",
  BIOMETRIC_SIGN_UP_RESULT_RESPONSE = "BIOMETRIC_SIGN_UP_RESULT_RESPONSE",
  BIOMETRIC_SIGN_IN_REQUEST = "BIOMETRIC_SIGN_IN_REQUEST",
  BIOMETRIC_SIGN_IN_RESPONSE = "BIOMETRIC_SIGN_IN_RESPONSE",
  BIOMETRIC_SIGNATURE_REQUEST = "BIOMETRIC_SIGNATURE_REQUEST",
  BIOMETRIC_SIGNATURE_RESPONSE = "BIOMETRIC_SIGNATURE_RESPONSE",
  DISABLE_BIOMETRICS_REQUEST = "DISABLE_BIOMETRICS_REQUEST",
  DISABLE_BIOMETRICS_RESPONSE = "DISABLE_BIOMETRICS_RESPONSE",
  SET_USER_REQUEST = "SET_USER_REQUEST",
  SET_USER_RESPONSE = "SET_USER_RESPONSE",
  NOTIFICATION_TOKEN_REQUEST = "NOTIFICATION_TOKEN_REQUEST",
  NOTIFICATION_TOKEN_RESPONSE = "NOTIFICATION_TOKEN_RESPONSE",
  ENABLE_NOTIFICATIONS_REQUEST = "ENABLE_NOTIFICATIONS_REQUEST",
  ENABLE_NOTIFICATIONS_RESPONSE = "ENABLE_NOTIFICATIONS_RESPONSE",
  UPDATE_REQUEST = "UPDATE_REQUEST",
}

export type WebViewMessage =
  | {
      type: WebViewMessageTypes.INIT_REQUEST;
      payload: {
        message: string;
      };
    }
  | {
      type: WebViewMessageTypes.INIT_RESPONSE;
      payload:
        | {
            os: "android" | "ios";
            nativeVersion: string;
            isNotificationEnabled: boolean;
            isBiometricsAvailable: boolean;
            isBiometricsEnabled: false;
          }
        | {
            os: "android" | "ios";
            nativeVersion: string;
            isNotificationEnabled: boolean;
            isBiometricsAvailable: boolean;
            isBiometricsEnabled: true;
            login: string;
          };
    }
  | {
      type: WebViewMessageTypes.BIOMETRIC_SIGN_UP_REQUEST;
      payload: {
        challenge: string;
        login: string;
      };
    }
  | {
      type: WebViewMessageTypes.BIOMETRIC_SIGN_UP_RESPONSE;
      payload:
        | {
            isSuccess: true;
            challenge: string;
            signedChallenge: string;
            publicKey: string;
            id: string;
          }
        | {
            isSuccess: false;
            errorMessage: string;
          };
    }
  | {
      type: WebViewMessageTypes.BIOMETRIC_SIGN_UP_RESULT_REQUEST;
      payload: {
        id: string;
        isSuccess: boolean;
      };
    }
  | {
      type: WebViewMessageTypes.BIOMETRIC_SIGN_UP_RESULT_RESPONSE;
      payload: {
        isBiometricsEnabled: boolean;
      };
    }
  | {
      type: WebViewMessageTypes.BIOMETRIC_SIGN_IN_REQUEST;
      payload: {
        challenge: string;
      };
    }
  | {
      type: WebViewMessageTypes.BIOMETRIC_SIGN_IN_RESPONSE;
      payload:
        | {
            isSuccess: true;
            challenge: string;
            signedChallenge: string;
            login: string;
            publicKey: string;
          }
        | {
            isSuccess: false;
            errorMessage: string;
          };
    }
  | {
      type: WebViewMessageTypes.DISABLE_BIOMETRICS_REQUEST;
      payload: {};
    }
  | {
      type: WebViewMessageTypes.DISABLE_BIOMETRICS_RESPONSE;
      payload: {
        isBiometricsEnabled: boolean;
        id: string;
      };
    }
  | {
      type: WebViewMessageTypes.BIOMETRIC_SIGNATURE_REQUEST;
      payload: {
        challenge: string;
      };
    }
  | {
      type: WebViewMessageTypes.BIOMETRIC_SIGNATURE_RESPONSE;
      payload:
        | {
            isSuccess: true;
            challenge: string;
            signedChallenge: string;
            login: string;
            publicKey: string;
          }
        | {
            isSuccess: false;
            errorMessage: string;
          };
    }
  | {
      type: WebViewMessageTypes.SET_USER_REQUEST;
      payload: {
        login: string;
      };
    }
  | {
      type: WebViewMessageTypes.SET_USER_RESPONSE;
      payload: {
        isBiometricsEnabled: boolean;
      };
    }
  | {
      type: WebViewMessageTypes.ENABLE_NOTIFICATIONS_REQUEST;
      payload: {};
    }
  | {
      type: WebViewMessageTypes.ENABLE_NOTIFICATIONS_RESPONSE;
      payload: {
        isSuccess: boolean;
      };
    }
  | {
      type: WebViewMessageTypes.NOTIFICATION_TOKEN_REQUEST;
      payload: {};
    }
  | {
      type: WebViewMessageTypes.NOTIFICATION_TOKEN_RESPONSE;
      payload: {
        isSuccess: boolean;
        token: string;
      };
    }
  | {
      type: WebViewMessageTypes.UPDATE_REQUEST;
      payload: {};
    };
