import { Biometrics } from "Components/Auth/SignUp/Biometrics";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useUser } from "Hooks/useUser";
import { FunctionComponent } from "react";
import { Navigate, useNavigate } from "react-router";
import { getBiometricSignUpChallengeAsync } from "State/Auth/Biometrics/BiometricSignUpChallengeState";
import { AppRouting, getPath } from "Utils/UrlUtils";

export const BiometricPage: FunctionComponent = _ => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { canUseBiometrics } = useBiometricsCanBeUsed();

  const user = useUser();

  if (!!user && !canUseBiometrics) {
    return <Navigate to={getPath(AppRouting.Dashboard)} />;
  }

  return (
    <UnauthenticatedLayout title={"Biometrie"}>
      {!!user && canUseBiometrics && (
        <Biometrics
          onEnableBiometrics={() =>
            dispatch(
              getBiometricSignUpChallengeAsync.request({
                login: user.login,
                redirectUrl: getPath(AppRouting.Dashboard),
              }),
            )
          }
          onIgnoreBiometrics={() => navigate(getPath(AppRouting.Dashboard))}
        />
      )}
    </UnauthenticatedLayout>
  );
};

export default BiometricPage;
