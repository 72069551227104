import { type ReactNode, type FunctionComponent, useRef } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { type TextFieldProps } from "@mui/material/TextField";
import { Input } from "Components/Shared/Inputs/FileInput/Input";
import { CloseIcon, PhotoIcon } from "Components/Shared/Icons";
import { Colors } from "Components/Layout/Themes/Colors";
import styled from "styled-components";
import { TextInput } from "Components/Shared/Inputs/TextInput";
import { useFieldError } from "Hooks/useFieldError";
import { type FieldError } from "react-hook-form";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { Resources, useResource } from "Translations/Resources";
import { ErrorTypography, StyledError } from "Components/Shared/FormStyles";
import { notNoU } from "Utils/ObjectUtils";

type NonUndefined<T> = T extends undefined ? never : T;

type Props<T extends boolean = false> = {
  value?: T extends true ? File[] : File | null;
  hideSizeText?: boolean;
  multiple?: T;
  getInputText?: (files: T extends true ? File[] : File | null) => string;
  getSizeText?: (files: T extends true ? File[] : File | null) => string;
  onChange?: (value: T extends true ? File[] : File | null) => void;
  label?: string;
  helperText?: ReactNode;
  fieldError?: FieldError;
  accept?: string[];
  isLoading?: boolean;
  maxSizeMB?: number;
  setFormError?: (error: string) => void;
} & Omit<TextFieldProps, "label" | "onChange">;

const matchIsFile = (value: unknown): value is File => value instanceof File;

const fileListToArray = (filelist: FileList): File[] => Array.from(filelist);

function matchIsNonEmptyArray<T>(array: T[]): array is [T, ...T[]] {
  return array.length > 0;
}

const StyledIconWrapper = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.secondary.main}20;
  padding: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(1)};
  border-radius: 50%;
  min-width: 35px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextField = styled(TextInput)`
  .MuiInputBase-root {
    border: 1px dashed ${props => props.theme.colors.border}80;
    width: 100%;

    ${props =>
      props.error &&
      `
      span {
        color: red;
      }
    `}
  }

  .MuiInputLabel-root {
    width: 100%;
    top: 30%;
    left: ${props => props.theme.spacing(9)};
    color: ${props => props.theme.palette.text.primary};
    opacity: 0.9;
  }

  .MuiInputAdornment-root {
    &.MuiInputAdornment-positionEnd {
      padding-top: ${props => props.theme.spacing(1)};
      padding-right: ${props => props.theme.spacing(2)};
    }
  }
`;

export const FileInput: FunctionComponent<Props> = <T extends boolean = false>(
  props: Props<T>,
) => {
  const { t } = useResource();
  const { palette } = useTheme();
  const { hasError, errorMessage, originalProps } = useFieldError(props);

  const {
    value,
    onChange,
    label,
    disabled,
    getInputText,
    getSizeText,
    hideSizeText,
    inputProps,
    InputProps,
    multiple,
    className,
    helperText,
    accept,
    isLoading,
    maxSizeMB,
    setFormError,
    ...restTextFieldProps
  } = originalProps;
  const inputRef = useRef<HTMLInputElement>(null);
  const isMultiple =
    multiple ||
    (inputProps?.multiple as boolean) ||
    (InputProps?.inputProps?.multiple as boolean) ||
    false;

  const clearInputValue = () => {
    const inputEl = inputRef.current;
    if (inputEl) {
      inputEl.value = "";
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const files = fileList ? fileListToArray(fileList) : [];

    clearInputValue();

    if (notNoU(maxSizeMB)) {
      const maxSize = maxSizeMB * 1024 * 1024;
      const isTooBig = files.some(file => file.size > maxSize);
      if (isTooBig) {
        setFormError?.(t(Resources.Common.FileTooBig, { size: maxSizeMB }));

        return;
      }
    }

    if (isMultiple) {
      onChange?.(files as NonNullable<typeof value>);
    } else {
      if (Array.isArray(accept) && !accept.includes(files[0].type)) {
        setFormError?.(t(Resources.Common.UnsupportedFileType));

        return;
      }

      onChange?.(files[0] as unknown as NonNullable<typeof value>);
    }
  };

  const handleClearAll = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (disabled) {
      return;
    }

    if (multiple) {
      onChange?.([] as unknown as NonNullable<typeof value>);
    } else {
      onChange?.(null as NonUndefined<typeof value>);
    }
  };

  const hasAtLeastOneFile = Array.isArray(value)
    ? matchIsNonEmptyArray(value)
    : matchIsFile(value);

  const getTheInputText = (): string => {
    if (value === null || (Array.isArray(value) && value.length === 0)) {
      return label || "";
    }

    if (typeof getInputText === "function" && value !== undefined) {
      return getInputText(value);
    }

    if (value && hasAtLeastOneFile) {
      if (Array.isArray(value) && value.length > 1) {
        return `${value.length} files`;
      }
      return matchIsFile(value) ? value.name : value[0]?.name || "";
    }

    return label || "";
  };

  return (
    <div>
      <StyledTextField
        type="file"
        disabled={disabled}
        onInputChange={handleChange}
        error={hasError}
        InputProps={{
          startAdornment: (
            <StyledIconWrapper onClick={() => inputRef.current?.click()}>
              {props.isLoading ? (
                <CircularProgress size={21} color="secondary" />
              ) : (
                <PhotoIcon
                  color={Colors.SecondaryText}
                  height="21px"
                  width="21px"
                />
              )}
            </StyledIconWrapper>
          ),
          fullWidth: true,
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ visibility: hasAtLeastOneFile ? "visible" : "hidden" }}
            >
              <Box onClick={handleClearAll} role="button">
                <CloseIcon
                  color={palette.text.primary}
                  aria-label="Clear"
                  cursor="pointer"
                  opacity={0.8}
                />
              </Box>
            </InputAdornment>
          ),
          ...InputProps,
          inputProps: {
            accept: Array.isArray(accept) ? accept.join(",") : undefined,
            label: getTheInputText(),
            multiple: isMultiple,
            ref: inputRef,
            placeholder: label,
            ...inputProps,
            ...InputProps?.inputProps,
          },
          inputComponent: Input,
        }}
        {...restTextFieldProps}
      />
      {hasError && errorMessage && (
        <StyledError $disableBottomMargin={!!helperText}>
          <ErrorTypography>{errorMessage}</ErrorTypography>
        </StyledError>
      )}
      {helperText && <Typography>{helperText}</Typography>}
    </div>
  );
};
