import {
  CreateContractState,
  createContractReducer,
} from "State/Contracts/Create/CreateState";

import {
  ModelingContractState,
  modelingContractReducer,
  watchModelingContractSaga,
} from "State/Contracts/Modeling/ModelingState";

import { combineReducers } from "redux";
import { all } from "typed-redux-saga";
import {
  contractReducer,
  watchContractSagas,
} from "State/Contracts/Contract/ContractReducer";
import { purchaseReducer } from "State/Contracts/Purchase/PurchaseState";
import { redemptionReducer } from "State/Contracts/Redemption/RedemptionState";
import { uninvestedDepositsReducer } from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";
import {
  draftReducer,
  watchDraftSagas,
} from "State/Contracts/Draft/DraftState";
import { rehydrateContractReducer } from "State/Contracts/Rehydrate/RehydrateState";
import { transferDipReducer } from "State/Contracts/TransferDip/TransferDipReducer";

export type ContractsState = {
  create: CreateContractState;
  modeling: ModelingContractState;
};

export function* watchContractsSaga() {
  yield all([
    watchModelingContractSaga(),
    watchContractSagas(),
    watchDraftSagas(),
  ]);
}

export const contractsReducer = combineReducers({
  create: createContractReducer,
  modeling: modelingContractReducer,
  contract: contractReducer,
  purchase: purchaseReducer,
  redemption: redemptionReducer,
  uninvestedDeposits: uninvestedDepositsReducer,
  draft: draftReducer,
  rehydrate: rehydrateContractReducer,
  transferDip: transferDipReducer,
});
