import { useAppSelector } from "Hooks/useAppSelector";
import { useMemo } from "react";
import { isWebView } from "Utils/WebViewUtils";

export const useNotification = () => {
  const { isLoading, isEnabled, isPermissionGranted, fcmToken } = useAppSelector(
    s => s.notifications,
  );

  const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
  const isWebviewInitialized = useMemo(() => isWebView(), []);

  return {
    isLoading,
    isEnabled,
    isPermissionGranted,
    fcmToken,
    canBeUsed: isAuthenticated && isWebviewInitialized,
  };
};
