import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { NewContractStep } from "State/Contracts/Create/Models";
import { terminateContractAsync } from "State/Contracts/Create/TerminateContract/TerminateContractState";
import { useCallback } from "react";

export const useTerminateContract = () => {
  const dispatch = useAppDispatch();
  const ContractSignature = useAppSelector(
    s => s.contracts.create.formData[NewContractStep.ContractSignature],
  );

  const terminateContract = useCallback(() => {
    if (ContractSignature?.contractID) {
      dispatch(terminateContractAsync.request(ContractSignature.contractID));
    }
  }, [dispatch, ContractSignature?.contractID]);

  return { terminateContract };
};
