import { produce } from "immer";
import { WebViewMessage, WebViewMessageTypes } from "Models/WebViewModels";
import {
  fcmTokenStatusAsync,
  fcmTokenStatusSaga,
} from "State/Notifications/Api/FcmTokenStatusState";
import {
  registerFcmTokenAsync,
  registerFcmTokenSaga,
} from "State/Notifications/Api/RegisterFcmTokenState";
import { all } from "typed-redux-saga";
import { ActionType, createAction, createReducer } from "typesafe-actions";

export type NotificationsState = {
  isLoading: boolean;
  isEnabled: boolean | null;
  isPermissionGranted: boolean;
  fcmToken: string | null;
  error: Error | null;
};

const initialState: NotificationsState = {
  isLoading: false,
  isEnabled: null,
  isPermissionGranted: false,
  fcmToken: null,
  error: null,
};

export const setIsNotificationsEnabled = createAction(
  "@notifications/SET_IS_NOTIFICATIONS_ENABLED",
)<boolean>();

export const handleNotificationTokenResponse = createAction(
  "@notifications/HANDLE_NOTIFICATION_TOKEN_RESPONSE",
)<
  Extract<
    WebViewMessage,
    { type: WebViewMessageTypes.NOTIFICATION_TOKEN_RESPONSE }
  >["payload"]
>();

export const handleEnableNotificationsResponse = createAction(
  "@notifications/HANDLE_ENABLE_NOTIFICATIONS_RESPONSE",
)<
  Extract<
    WebViewMessage,
    { type: WebViewMessageTypes.ENABLE_NOTIFICATIONS_RESPONSE }
  >["payload"]
>();

export const setIsNotificationsPermissionGranted = createAction(
  "@notifications/SET_IS_NOTIFICATIONS_PERMISSION_GRANTED",
)<boolean>();

export const resetNotificationsState = createAction(
  "@notifications/RESET_NOTIFICATIONS_STATE",
)<void>();

export type NotificationsAction =
  | ActionType<typeof setIsNotificationsEnabled>
  | ActionType<typeof setIsNotificationsPermissionGranted>
  | ActionType<typeof handleNotificationTokenResponse>
  | ActionType<typeof handleEnableNotificationsResponse>
  | ActionType<typeof resetNotificationsState>
  | ActionType<typeof fcmTokenStatusAsync>
  | ActionType<typeof registerFcmTokenAsync>;

export function* watchNotificationsStateSagas() {
  yield all([fcmTokenStatusSaga(), registerFcmTokenSaga()]);
}

export const notificationsReducer = createReducer<
  NotificationsState,
  NotificationsAction
>(initialState)
  .handleAction(setIsNotificationsEnabled, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.isEnabled = action.payload;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(setIsNotificationsPermissionGranted, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.isPermissionGranted = action.payload;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(handleNotificationTokenResponse, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.isPermissionGranted = action.payload.isSuccess;
      draft.fcmToken = action.payload.token;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(handleEnableNotificationsResponse, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.isPermissionGranted = action.payload.isSuccess;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(fcmTokenStatusAsync.request, (state, _) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(fcmTokenStatusAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.isEnabled =
        action.payload.isNotificationPermissionPending ||
        action.payload.isNotificationEnabled;
      return draft;
    });
  })
  .handleAction(fcmTokenStatusAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  })
  .handleAction(registerFcmTokenAsync.request, (state, _) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(registerFcmTokenAsync.success, (state, _) => {
    return produce(state, draft => {
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(registerFcmTokenAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  })
  .handleAction(resetNotificationsState, (state, _) => {
    return produce(state, _ => {
      return initialState;
    });
  });
