import { put, takeLatest } from "redux-saga/effects";
import {
  ProcessBankIDVerificationCommandResultStatus,
  getBankIdVerify,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { NavigateFunction } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { getTrackingSessionID } from "Utils/TrackingUtils";
import { setBankIDProfile } from "State/Contracts/Create/BankIDProfile/SetBankIDProfile";
import { safeApiCall } from "State/Utils";

export const getBankIDProfileAsync = createAsyncAction(
  "@bankID/PROFILE_REQUEST",
  "@bankID/PROFILE_SUCCESS",
  "@bankID/PROFILE_FAILURE",
)<
  {
    token: string;
    navigate: NavigateFunction;
    t: (resourcePath: string, options?: any) => string;
  },
  void,
  Error
>();

function* getBankIDProfile(
  action: ReturnType<typeof getBankIDProfileAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getBankIdVerify,
      action.payload.token,
      getTrackingSessionID(),
    );

    if (error) {
      yield put(getBankIDProfileAsync.failure(error));
      return;
    }

    if (
      response.status ===
        ProcessBankIDVerificationCommandResultStatus.Success &&
      !!response.profile
    ) {
      yield put(
        setBankIDProfile.request({
          profile: response.profile,
          t: action.payload.t,
        }),
      );
      yield put(getBankIDProfileAsync.success());
      action.payload.navigate(getPath(AppRouting.ContractCreate));
    } else {
      yield put(getBankIDProfileAsync.failure(new Error(response.status)));
    }
  } catch (err) {
    yield put(getBankIDProfileAsync.failure(err as Error));
  }
}
export function* getBankIDProfileSaga() {
  yield takeLatest(getType(getBankIDProfileAsync.request), getBankIDProfile);
}
