import * as React from "react";
import { Resources, useResource } from "Translations/Resources";
import styled from "styled-components";
import { Box, Container } from "@mui/material";
import { StyledBackIcon } from "Components/Shared/StyledComponents";
import { useDesktop } from "Hooks/useDesktop";
import { TestVersionTag } from "Components/Layout/components/TestVersionTag";

type Props = {
  title: string;
  onBackClick?(): void;
};

const StyledBackButton = styled(StyledBackIcon)`
  margin-bottom: ${props => props.theme.spacing(6)};
`;

const StyledBox = styled(Box)`
  padding: ${props => props.theme.spacing(7, 5, 7, 5)};
`;

export const UnauthenticatedLayout: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, title, onBackClick }) => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();

  React.useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  return (
    <>
      <TestVersionTag />
      <Container maxWidth="sm">
        <StyledBox
          display="flex"
          flexDirection="column"
          flexGrow={1}
          height="100%"
        >
          {!!onBackClick && (
            <StyledBackButton role="button" onClick={onBackClick} />
          )}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={isDesktop ? "start" : "space-between"}
            flexGrow={1}
            gap={2}
          >
            {children}
          </Box>
        </StyledBox>
      </Container>
    </>
  );
};
