import { Stack } from "@mui/material";
import { type FunctionComponent } from "react";

import { Investment } from "Components/ContractDetail/Overview/components/InvestmentComposition/Investment";
import { useAppSelector } from "Hooks/useAppSelector";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useUser } from "Hooks/useUser";
import {
  ContractUnsettledTradeItemDto,
  ContractUnsettledTradeType,
} from "Api/Api";
import { InvestmentCompositionWrapper } from "Components/ContractDetail/Overview/components/InvestmentComposition/InvestmentCompositionWrapper";
import { InvestmentCompositionPieChart } from "Components/ContractDetail/Overview/components/InvestmentComposition/InvestmentCompositionPieChart";

type Props = {
  contractId: number;
};

export const InvestmentComposition: FunctionComponent<Props> = ({
  contractId,
}) => {
  const user = useUser();

  const { data: overview, isLoading: overviewLoading } = useAppSelector(
    s => s.investmentsDetail.overview,
  );

  const { isLoading: unsettledTradesLoading, data: unsettledTrades } =
    useAppSelector(state => state.investmentsDetail.unsettledTrades);

  const isLoading =
    (overviewLoading && !overview) ||
    (unsettledTradesLoading && !unsettledTrades);

  const getAwaitingInvestmentValue = (
    productID: number | null | undefined,
    unsettledTrades: ContractUnsettledTradeItemDto[] | null | undefined,
  ): number => {
    if (!unsettledTrades?.length) {
      return 0;
    }

    return unsettledTrades
      .filter(item => productID === item.productID)
      .filter(item => item.type === ContractUnsettledTradeType.BUY)
      .reduce((accumulator, item) => accumulator + (item.amount ?? 0), 0);
  };

  return (
    <InvestmentCompositionWrapper
      pieChart={
        <InvestmentCompositionPieChart
          portfolioItems={overview?.portfolioItems || []}
        />
      }
      investments={
        <Stack gap={2}>
          <LoadingWrapper
            isLoading={isLoading}
            skeleton={
              <>
                <PrimarySkeleton height={150} />
                <PrimarySkeleton height={150} />
              </>
            }
          >
            {overview?.portfolioItems?.map(
              ({ amount, isin, name, productID, currency }) => (
                <Investment
                  key={isin}
                  estate={amount}
                  currency={currency}
                  isin={isin}
                  uninvestedDeposits={getAwaitingInvestmentValue(
                    productID,
                    unsettledTrades,
                  )}
                  name={name}
                  contractID={contractId}
                  isUnderage={!!user?.isUnderage}
                  contractType={overview.type}
                  isDip={overview?.isDip ?? false}
                />
              ),
            )}
          </LoadingWrapper>
        </Stack>
      }
    />
  );
};
