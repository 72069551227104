import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  alpha,
  styled,
} from "@mui/material";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useMenuItems } from "Hooks/useMenuItems";
import { Link as RLink, useLocation } from "react-router-dom";

const StyledListItem = styled(ListItem, {
  shouldForwardProp: prop => prop !== "$active",
})<{ $active?: boolean }>`
  border-radius: 8px;
  padding: 0;

  background-color: ${props =>
    props.$active
      ? alpha(props.theme.palette.primary.main, 0.15)
      : alpha("#fff", 0.05)};

  color: ${props =>
    props.$active
      ? props.theme.palette.text.primary
      : props.theme.palette.text.secondary};

  ${props =>
    props.$active &&
    `
      font-weight: 700;
    `}
`;

const StyledList = styled(List)`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)};
`;

const StyledItemText = styled(ListItemText)`
  margin: 0;
`;

export const SideMenu = () => {
  const { items } = useMenuItems();
  const { pathname } = useLocation();

  return (
    <nav>
      <StyledList>
        {items.map(({ icon, id, label, value, disabled, onClick }) => (
          <StyledListItem key={id} $active={value === pathname}>
            <ListItemButton
              component={RLink}
              key={id}
              to={value}
              onClick={onClick}
              disabled={disabled}
              aria-disabled={disabled}
              sx={{
                padding: 3,
              }}
            >
              <StyledItemText>
                <StyledFlex $alignItems="center" $gap={2}>
                  {icon}
                  <div>{label}</div>
                </StyledFlex>
              </StyledItemText>
            </ListItemButton>
          </StyledListItem>
        ))}
      </StyledList>
    </nav>
  );
};
