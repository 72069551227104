import { useMemo } from "react";
import uaparser from "ua-parser-js";

export const useUserAgentTracking = () => {
  const result = useMemo(() => {
    const ua = window.navigator.userAgent;
    const parser = new uaparser();
    const parsedUA = parser.setUA(ua).getResult();

    return {
      browser: parsedUA.browser.name,
      browserVersion: parsedUA.browser.version,
      device: parsedUA.device.type,
      os: parsedUA.os.name,
      osVersion: parsedUA.os.version,
    };
  }, []);

  return result;
};
