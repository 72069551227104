import { Box } from "@mui/material";
import { Chip } from "Components/Shared/Chip";
import { ContractGuideWrapper } from "Components/Shared/ContractGuideWrapper";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useModelingTitleValues } from "Hooks/Contract/useModelingTitleValues";
import { useDesktop } from "Hooks/useDesktop";
import { type FunctionComponent } from "react";

type InvestmentTitleProps = {
  allowDipWrapper?: boolean;
  productIsin?: string | null;
  allowProductBackground?: boolean;
};

export const InvestmentTitle: FunctionComponent<InvestmentTitleProps> = ({
  allowDipWrapper,
  productIsin,
  allowProductBackground,
}) => {
  const { category, modelationName } = useModelingTitleValues();
  const isDip = useIsDipSelected();
  const { isDesktop } = useDesktop();

  if (!modelationName || !isDesktop) {
    return null;
  }

  return (
    <ContractGuideWrapper
      isDipWrapped={allowDipWrapper && isDip}
      productIsin={allowDipWrapper ? productIsin : undefined}
      allowProductBackground={allowProductBackground}
    >
      <StyledFlex $gap={3} $justifyContent="space-between">
        <PageTitle>{modelationName}</PageTitle>
        <Box flexShrink={0}>
          {category && (
            <Chip icon={category.icon} label={category.Name} $active $passive />
          )}
        </Box>
      </StyledFlex>
    </ContractGuideWrapper>
  );
};
