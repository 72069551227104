import { Box, Link, Typography } from "@mui/material";
import { LogoStartScreenIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { LAYOUT_PADDING_X } from "Constants/Layout";
import { type FunctionComponent } from "react";
import { Resources, useResource } from "Translations/Resources";
import {
  MAINTAINCE_SERVICE_PHONE,
  MAINTAINCE_SERVICE_EMAIL,
} from "Utils/ExternalLinkUtils";

const PageResources = Resources.MaintenancePage;

export const MaintenancePage: FunctionComponent = _ => {
  const { t } = useResource();

  return (
    <StyledFlex $gap={1} $alignItems="center" $flexDirection="column">
      <Box paddingTop={20}>
        <LogoStartScreenIcon height={120} />
      </Box>

      <Typography
        variant="body2"
        textAlign="center"
        paddingTop={20}
        paddingX={LAYOUT_PADDING_X}
      >
        {t(PageResources.Description)}
      </Typography>

      <Link
        target="_blank"
        href={`tel:${MAINTAINCE_SERVICE_PHONE}`}
        fontWeight={600}
        color="secondary"
        marginTop={2}
      >
        {MAINTAINCE_SERVICE_PHONE}
      </Link>

      <Link
        target="_blank"
        href={`mailto:${MAINTAINCE_SERVICE_EMAIL}`}
        fontWeight={600}
        color="secondary"
      >
        {MAINTAINCE_SERVICE_EMAIL}
      </Link>
    </StyledFlex>
  );
};

export default MaintenancePage;
