import { Box, Typography } from "@mui/material";
import { ContractOverviewDto } from "Api/Api";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { ContractGuideWrapper } from "Components/Shared/ContractGuideWrapper";
import { PageTitle } from "Components/Shared/PageTitle";
import { useYupFormSchema } from "Hooks/Contract/Edit/useYupFormSchema";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";
import { Resources, useResource } from "Translations/Resources";
import {
  contractEditAsync,
  ContractRenameFormModel,
} from "../../State/Contracts/Edit/ContractEditState";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { CategorySelector } from "Components/ContractModeling/NewInvestmentForm/components/CategorySelector";
import { useNavigate } from "react-router";

type Props = {
  contractID: number;
} & Pick<ContractOverviewDto, "name" | "purposeCategory" | "isDip">;

const ComponentResources = Resources.Contract.Edit;

export const ContractEditForm: FunctionComponent<Props> = ({
  contractID,
  name,
  purposeCategory,
  isDip,
}) => {
  const { t } = useResource();
  const { isLoading } = useAppSelector(s => s.contracts.contract.edit);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const schema = useYupFormSchema({ name });
  const form = useForm<ContractRenameFormModel>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      name: name,
      purposeCategory: purposeCategory,
    },
  });

  const { control, formState, watch, setValue, handleSubmit } = form;

  const onSubmit = (formData: ContractRenameFormModel) => {
    dispatch(contractEditAsync.request({ ...formData, contractID, navigate }));
  };

  const nameValue = watch("name");
  const purposeCategoryValue = watch("purposeCategory");

  const valuesNotChanged =
    (name || null) === (nameValue || null) &&
    (purposeCategory || null) === (purposeCategoryValue || null);

  return (
    <>
      <FormProvider {...form}>
        <div>
          <ContractGuideWrapper isDipWrapped={!!isDip}>
            <PageTitle showOnMobile={false} noWrap>
              {t(ComponentResources.Title)}
            </PageTitle>
          </ContractGuideWrapper>
          <Typography>{t(ComponentResources.Description)}</Typography>

          <Box marginY={3}>
            <BlInputFormField
              control={control}
              name="name"
              label={t(ComponentResources.NameLabel)}
              placeholder={t(ComponentResources.NamePlaceholder)}
              errors={formState.errors}
              hasTracking
            />
          </Box>

          <CategorySelector
            label={t(ComponentResources.PurposeCategoryLabel)}
            watch={() => watch("purposeCategory")}
            setValue={x => setValue("purposeCategory", x)}
          />
        </div>
        <PrimaryButton
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading || !formState.isValid || valuesNotChanged}
          isLoading={isLoading}
          trackingTag={t(ComponentResources.Submit)}
        >
          {t(ComponentResources.Submit)}
        </PrimaryButton>
      </FormProvider>
    </>
  );
};
