import TextField, { TextFieldProps } from "@mui/material/TextField";
import useDebounce from "Hooks/useDebounce";
import { FieldErrorProps, useFieldError } from "Hooks/useFieldError";
import _isNil from "lodash-es/isNil";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { IMaskMixin } from "react-imask";
import styled from "styled-components";
import { isNoU } from "Utils/ObjectUtils";
import { track } from "Utils/TrackingUtils";
import { type IMask } from "react-imask";

const StyledInput = styled(TextField)<{ $isEmpty?: boolean }>`
  color-scheme: dark;
  margin: ${props => props.theme.spacing(1, 0)};

  /* Fix for ios date input */
  input::-webkit-date-and-time-value {
    text-align: left;
  }

  .MuiInputBase-input {
    padding-bottom: ${props => props.theme.spacing(1)};

    color: ${props =>
      props.$isEmpty
        ? props.theme.palette.text.disabled
        : props.theme.palette.text.primary};

    font-size: ${props => props.theme.typography.body2.fontSize}px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover &:-webkit-autofill:focus {
      box-shadow: 0 0 0px 1000px
        ${props => props.theme.palette.background.default} inset;
      color: ${props => props.theme.palette.text.primary};
    }
    &::placeholder {
      color: ${props => props.theme.palette.secondary.main};
      opacity: 0.3;
    }
  }

  .MuiInputBase-root {
    border: 1px solid ${props => props.theme.colors.border}80;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    padding: ${props => props.theme.spacing(1, 3)};

    &.Mui-error {
      border-color: ${props => props.theme.palette.error.main};
    }

    &::before {
      border-bottom: none;
    }
    &::after {
      border-bottom: none;
    }

    &:hover {
      &::before {
        border-bottom: none;
      }
    }

    .MuiInputLabel-root {
      font-size: 18px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.colors.border};
    }

    .MuiOutlinedInput-multiline {
      padding: 0;
    }

    .MuiFormHelperText-root {
      margin: 0;
      min-height: 20px;
    }

    &.Mui-disabled {
      border-color: ${props => props.theme.colors.border}40;
      &.MuiInputBase-formControl {
        background-color: unset;

        .MuiInputBase-input {
          color: ${props => props.theme.palette.text.primary};
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.theme.colors.border};
        }
      }
    }
  }

  .MuiFilledInput-root {
    padding: ${props => props.theme.spacing(1)};
    &:hover {
      &::before {
        border-bottom: none;
      }
    }
  }

  .MuiInputLabel-root {
    top: ${props => props.theme.spacing(1)};
    left: ${props => props.theme.spacing(1)};
    color: ${props =>
      props.error
        ? props.theme.palette.error.main
        : props.theme.palette.text.primary};
    opacity: 0.9;

    &.Mui-disabled {
      opacity: 0.5;
    }
  }

  .MuiInputAdornment-root {
    &.MuiInputAdornment-positionEnd {
      padding-top: ${props => props.theme.spacing(4)};
      padding-right: ${props => props.theme.spacing(2)};
    }

    &.MuiInputAdornment-positionStart {
      padding-left: ${props => props.theme.spacing(2)};

      > .MuiTypography-root {
        opacity: 0.9;
      }
    }
  }

  .MuiTypography-root {
    color: ${props => props.theme.palette.secondary.main};
    opacity: 0.3;
    font-size: ${props => props.theme.typography.body2.fontSize}px;
  }

  .MuiFormHelperText-root {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    color: ${props =>
      props.error ? "default" : props.theme.palette.text.secondary};
    margin: ${props => props.theme.spacing(1, 0, 1, 0)};
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  flex-flow: column;

  .input-wrapper {
    position: relative;
  }
`;

const StyledUnit = styled.div`
  position: absolute;
  pointer-events: none;
  top: calc(50% - 20px);
  right: 20px;
  opacity: 0.2;
`;

const MaskedInput = IMaskMixin(({ ref, ...props }: Props) => {
  return <StyledInput {...props} ref={ref} />;
}) as any;

type Props = TextFieldProps &
  FieldErrorProps & {
    label?: React.ReactNode;
    name?: string;
    mask?:
      | string
      | NumberConstructor
      | RegExp
      | Date
      | DateConstructor
      | IMask.AnyMaskedOptions;
    imaskPattern?: string;
    isMaskLazy?: boolean;
    maskPlaceholder?: string;
    unmask?: boolean | "typed";
    autoUnmask?: boolean;
    max?: number | Date;
    min?: number | Date;
    onMaskInputChange?: (val: string) => void;
    onInputChange?:
      | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    inputEndAdornment?: JSX.Element;
    inputStartAdornment?: JSX.Element;
    maxLength?: number;
    scale?: number;
    unit?: string;
    pattern?: string;
    hasTracking?: boolean;
    tagTrackingEnhancer?: (value: string | null | undefined) => string;
  };

export const TextInput: React.FunctionComponent<Props> = props => {
  const maskedInputRef = React.useRef<HTMLInputElement>();
  const inputRef = React.useRef<HTMLInputElement>();
  const { hasError, errorMessage, originalProps } = useFieldError(props);
  const [defaultValue, setDefaultValue] = useState(props.value ?? "");
  const {
    label,
    name,
    mask,
    isMaskLazy,
    maskPlaceholder,
    unmask,
    autoUnmask = false,
    max,
    min,
    onMaskInputChange,
    onInputChange,
    value,
    inputEndAdornment,
    inputStartAdornment,
    maxLength,
    scale,
    unit,
    onChange,
    pattern,
    imaskPattern,
    hasTracking,
    autoFocus,
    tagTrackingEnhancer,
    ...rest
  } = originalProps;

  const handleMaskInputChange = (nextValue: string) => {
    onMaskInputChange && onMaskInputChange(nextValue);
  };

  const [trackingValue, setTrackingValue] = useState<{
    value: string | undefined;
    source: "user" | "script";
  }>();
  const debouncedTrackingValue = useDebounce(trackingValue, 1500);

  const handleTagTrackingEnhancer = useCallback(
    (value: string | null | undefined) => {
      if (!!tagTrackingEnhancer) {
        return tagTrackingEnhancer(value);
      } else {
        return value;
      }
    },
    [tagTrackingEnhancer],
  );

  useEffect(() => {
    if (hasTracking && debouncedTrackingValue !== undefined) {
      track({
        category: "Input",
        event:
          debouncedTrackingValue.source === "user" ? "Change" : "ChangeByApp",
        tag: handleTagTrackingEnhancer(name),
        value: debouncedTrackingValue.value,
      });
    }
  }, [debouncedTrackingValue, name, hasTracking, handleTagTrackingEnhancer]);

  useEffect(() => {
    // we need to hack autofocus for masked input, otherwise it will not work
    // as native react autoFocus make focus to the end of the input (after the mask)
    if (autoFocus) {
      maskedInputRef.current?.focus();
      // Handle autofocus for masked input, with a workaround for Safari browser
      maskedInputRef.current?.dispatchEvent(new Event("focus"));
    }
  }, [autoFocus]);

  return (
    <StyledInputWrapper>
      <div className={"input-wrapper"}>
        {isNoU(mask) ? (
          <StyledInput
            name={name}
            id={name}
            variant="filled"
            label={label}
            InputLabelProps={{
              shrink: true,
              variant: "filled",
            }}
            fullWidth
            error={hasError}
            value={value}
            InputProps={{
              endAdornment: inputEndAdornment,
              startAdornment: inputStartAdornment,
            }}
            inputProps={{ maxLength: maxLength, pattern }}
            {...rest}
            helperText={errorMessage ?? rest.helperText}
            onChange={e => {
              onInputChange?.(e);
              //TODO: check if event was triggered by user or script
              setTrackingValue({ value: e.target.value, source: "user" });
            }}
            onFocus={e => {
              if (rest.type !== "date") {
                return;
              }

              setDefaultValue(e.target.value);
            }}
            onInput={e => {
              if (rest.type !== "date") {
                return;
              }

              // iOS fix for reset date input
              // without this, the date input will not reset to the default value but date picker will
              const target = e.nativeEvent?.target;
              const iosClearDefault = () => {
                // @ts-ignore
                if (target?.defaultValue) {
                  // @ts-ignore
                  target.defaultValue = defaultValue;
                }
              };
              window.setTimeout(iosClearDefault, 0);
            }}
            autoFocus={autoFocus}
            inputRef={inputRef}
          ></StyledInput>
        ) : (
          <MaskedInput
            mask={mask}
            unmask={unmask ?? true}
            autoUnmask={autoUnmask}
            radix=","
            mapToRadix={["."]}
            thousandsSeparator={" "}
            max={max ?? Number.MAX_SAFE_INTEGER}
            min={min ?? Number.MIN_SAFE_INTEGER}
            value={String(value ?? "")}
            name={name}
            id={name}
            variant="filled"
            InputLabelProps={{
              shrink: true,
              variant: "filled",
            }}
            fullWidth
            error={hasError}
            onAccept={(e: string, mask: any, event: any) => {
              handleMaskInputChange?.(e);
              setTrackingValue({
                value: e,
                source: !!event ? "user" : "script",
              });
            }}
            scale={scale}
            label={label}
            InputProps={{
              endAdornment: inputEndAdornment,
              startAdornment: inputStartAdornment,
            }}
            inputProps={{
              pattern,
              ref: maskedInputRef,
            }}
            placeholderChar={maskPlaceholder ?? "_"}
            lazy={isMaskLazy}
            $isEmpty={_isNil(value) || value === ""}
            pattern={imaskPattern}
            {...rest}
            helperText={errorMessage ?? rest.helperText}
            autoU
          ></MaskedInput>
        )}
        {unit && <StyledUnit>{unit}</StyledUnit>}
      </div>
    </StyledInputWrapper>
  );
};
