import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getProductTilesAsync } from "State/Products/ProductTiles/GetProductTilesState";
import { useEffect, useMemo } from "react";

export const useProductTilesItems = (
  productCode?: string | null | undefined,
) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(s => s.products.productTiles);

  useEffect(() => {
    if (!data && !isLoading) {
      dispatch(getProductTilesAsync.request());
    }
  }, [data, dispatch, isLoading]);

  const items = useMemo(
    () =>
      productCode
        ? data?.productTiles?.find(({ code }) => code === productCode)?.items ??
          []
        : [],
    [productCode, data],
  );

  return { items, isLoading };
};
