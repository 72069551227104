import { Resources, useResource } from "Translations/Resources";
import { getContactInformationSchema } from "Utils/Contracts/NewContract/ValidationSchemas/ContactInformation";

export const useYupFormSchema = () => {
  const { t } = useResource();

  return getContactInformationSchema({
    translates: {
      USACountryPermit: t(
        Resources.Contract.NewContract.Shared.USACountryPermit,
      ),
      InvalidFormat: t(Resources.Validation.InvalidFormat),
    },
  });
};
