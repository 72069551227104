import {
  BankIDProfileResponse,
  BankItemDto,
  CurrencyCode,
  Idcard,
  PersonalDocumentType,
} from "Api/Api";
import type {
  ContactInformationAddressType,
  ContactInformationFormModel,
  DocumentModel,
} from "Models/Forms";
import { SexType } from "Models/Inputs";
import {
  AMLQuestionsFormModel,
  BankConnectionFormModel,
  PersonalDataFormModel,
} from "State/Contracts/Create/Models";
import {
  convertCzechIbanToBankNumber,
  getBankUniqueCode,
} from "Utils/BankUtils";
import { getContactInformationSchema } from "Utils/Contracts/NewContract/ValidationSchemas/ContactInformation";
import { getPersonalDataSchema } from "Utils/Contracts/NewContract/ValidationSchemas/PersonalData";
import { PersonalIdentification } from "Utils/PersonalIdentification";
import { addYears, isAfter } from "date-fns";
import { boolean, mixed, object, string } from "yup";
import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
} from "Constants/Countries";
import { isNoU, notNoU } from "Utils/ObjectUtils";
import { Resources } from "Translations/Resources";
import {
  AllowedBankIdDocumentTypes,
  BankIdClaims,
  BankIdDocumentType,
  BankIdScopes,
} from "Models/BankID";
import {
  formatDocumentsDateToDate,
  parseDateToDocumentsDate,
} from "Utils/Contracts/NewContract/DateUtils";

const convertCurrencyFromBankId = (currency: string): CurrencyCode => {
  switch (currency) {
    case "CZK":
    case "CZ":
      return CurrencyCode.CZK;
    case "EUR":
    case "EU":
      return CurrencyCode.EUR;
  }
  return CurrencyCode.CZK;
};

const validatableNationalities = new Set([
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
]);

function mapNationalities(
  primaryNationality: string | null | undefined,
  otherNationalities: string[] | null | undefined,
): string[] {
  if (primaryNationality && otherNationalities?.length) {
    return [...new Set([primaryNationality, ...otherNationalities])];
  }

  if (primaryNationality) {
    return [primaryNationality];
  }

  if (otherNationalities?.length) {
    return otherNationalities;
  }

  return [];
}

const getComputedSex = (
  nationality?: string | null,
  birthNumber?: string,
): SexType | undefined => {
  if (
    !nationality ||
    !birthNumber ||
    !validatableNationalities.has(nationality)
  ) {
    return;
  }

  return PersonalIdentification.getInfo(birthNumber)?.Gender;
};

const isKnownBankIdDocumentType = (
  type?: string | null | undefined,
): type is BankIdDocumentType => {
  return !!type;
};

export function getPersonalDocumentType(
  type: string | null | undefined,
): PersonalDocumentType | null {
  if (!isKnownBankIdDocumentType(type)) {
    return null;
  }

  switch (type) {
    case "ID":
    case "OP":
      return PersonalDocumentType.IdentityCard;
    case "P":
    case "CA":
      return PersonalDocumentType.Passport;
    case "DL":
      return PersonalDocumentType.DrivingLicense;
    case "IR":
    case "PS":
    case "IX":
    case "IE":
      return PersonalDocumentType.TemporaryResidencePermit;
    default:
      return null;
  }
}

type MappedBankIdType = [
  PersonalDataFormModel,
  ContactInformationFormModel,
  BankConnectionFormModel,
  DocumentModel | undefined,
  Pick<AMLQuestionsFormModel, "politicallyExposed">,
];

export function mapBankIDProfileResponse(
  profile: BankIDProfileResponse,
  banks: BankItemDto[] = [],
  t: (resourcePath: string, options?: any) => string,
): MappedBankIdType {
  const gender: SexType = profile.gender === "female" ? "F" : "M";

  const personalData: PersonalDataFormModel = {
    email: profile.email,
    firstName: isNoU(profile.middle_name)
      ? profile.given_name
      : `${profile.given_name} ${profile.middle_name}`,
    lastName: profile.family_name,
    nationalities: mapNationalities(
      profile.primary_nationality,
      profile.nationalities,
    )
      .filter(Boolean)
      .map(value => ({
        value,
        bankId: true,
      })),
    personalIdentificationNumber: profile.birthnumber || "",
    phone:
      profile.phone_number?.length === 9
        ? `+420${profile.phone_number}`
        : profile.phone_number,
    birthDate: profile.birthdate,
    sex:
      profile.gender !== "other"
        ? gender
        : getComputedSex(profile.primary_nationality, profile.birthnumber),
    birthCity: profile.birthplace,
    birthCountry: profile.birthcountry,
  };

  const filteredIdCards = profile.idcards
    .sort((a, b) => {
      if (isNoU(a.issue_date) && isNoU(b.issue_date)) {
        return 0;
      }

      if (isNoU(a.issue_date)) {
        return -1;
      }

      if (isNoU(b.issue_date)) {
        return 1;
      }

      return (
        new Date(b.issue_date).getTime() - new Date(a.issue_date).getTime()
      );
    })
    .filter(
      ({ valid_to, type }) =>
        isAfter(new Date(valid_to), new Date()) &&
        AllowedBankIdDocumentTypes.some(allowedType => allowedType === type) &&
        notNoU(getPersonalDocumentType(type)),
    );

  const idCard =
    filteredIdCards.find(({ type }) => type === "ID") ?? filteredIdCards?.[0];

  const IdentityCardDocument: DocumentModel | undefined = idCard
    ? {
        countryIssue: idCard.country,
        dateExpiry: idCard.valid_to,
        dateValidFrom: isNoU(idCard.issue_date)
          ? parseDateToDocumentsDate(
              addYears(formatDocumentsDateToDate(idCard.valid_to), -10),
            )
          : idCard.issue_date,
        issuingAuthority:
          idCard.issuer ??
          t(
            Resources.Contract.NewContract.Documents
              .BankIDIssuingAuthorityPlaceholder,
          ),
        number: idCard.number,
        type: getPersonalDocumentType(idCard.type)!,
      }
    : undefined;

  const addresses: ContactInformationFormModel = {
    mailingAddressAsPermanent: profile.addresses?.length === 1,
    addresses: {
      permanentAddress: getPermanentAddress(profile),
      mailingAddress: getMailingAddress(profile),
    },
  };

  // we use a variable for the index to make sure we have the accounts and account details in sync
  const accountIndex = 0;
  const iban = profile.paymentAccounts?.[accountIndex];
  const bankCurrencyString =
    profile.paymentAccountsDetails?.[accountIndex]?.currency;
  const bankCurrency = notNoU(bankCurrencyString)
    ? convertCurrencyFromBankId(bankCurrencyString)
    : CurrencyCode.CZK;
  let bankConnection: BankConnectionFormModel;

  if (iban?.startsWith(CZECHIA_COUNTRY_CODE)) {
    const { bankCode, bankNumber } = convertCzechIbanToBankNumber(iban);
    const bank = bankCode
      ? banks.find(
          ({ country, numericCode }) =>
            country === CZECHIA_COUNTRY_CODE && numericCode === bankCode,
        )
      : undefined;
    bankConnection = {
      bankConnectionType: "bankNumber",
      bankNumber: bankNumber,
      bankCode: bankNumber?.split("/")[1],
      selectedBank: bank
        ? getBankUniqueCode({
            ...bank,
            country: bank.country ?? CZECHIA_COUNTRY_CODE,
            numericCode: bank.numericCode ?? bankNumber,
          })
        : undefined,
      swiftCode: bank?.swiftCode ?? "",
      country: bank?.country ?? CZECHIA_COUNTRY_CODE,
      bankCurrency,
    };
  } else {
    bankConnection = {
      bankConnectionType: "iban",
      bankCurrency,
      iban,
    };
  }

  const aml: Pick<AMLQuestionsFormModel, "politicallyExposed"> = {
    politicallyExposed: profile.pep ?? undefined,
  };

  return [personalData, addresses, bankConnection, IdentityCardDocument, aml];
}

function getPermanentAddress(
  profile: BankIDProfileResponse,
): ContactInformationAddressType {
  return getAddress(profile, "PERMANENT_RESIDENCE");
}

function getMailingAddress(
  profile: BankIDProfileResponse,
): ContactInformationAddressType {
  return getAddress(profile, "SECONDARY_RESIDENCE");
}

function getAddress(
  profile: BankIDProfileResponse,
  type: "PERMANENT_RESIDENCE" | "SECONDARY_RESIDENCE",
): ContactInformationAddressType {
  const address = profile.addresses?.find(address => address.type === type);

  return {
    city: address?.city ?? "",
    country: address?.country ?? "",
    postalCode: address?.zipcode ?? "",
    street: address?.street ?? address?.cityarea ?? address?.city ?? "",
    streetConscriptionNumber:
      address?.buildingapartment ?? address?.evidencenumber ?? "",
    streetNumber: address?.streetnumber ?? "",
  };
}

export const validateAllRequiredBankIdFields = async ([
  personalData,
  contactInformation,
  ,
  document,
  aml,
]: MappedBankIdType) => {
  await Promise.all([
    getPersonalDataSchema({
      requireEmail: false,
      requirePhone: false,
    }).validate(personalData, { abortEarly: false }),
    getContactInformationSchema().validate(contactInformation, {
      abortEarly: false,
    }),
    object()
      .shape({
        type: mixed()
          .oneOf(Object.values(PersonalDocumentType))
          .required()
          .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Type}`),
        countryIssue: string()
          .required()
          .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Country}`),
        dateExpiry: string()
          .required()
          .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.ValidTo}`),
        dateValidFrom: string()
          .optional()
          .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.IssueDate}`),
        issuingAuthority: string()
          .optional()
          .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Issuer}`),
        number: string()
          .required()
          .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Number}`),
      })
      .required()
      .validate(document, { abortEarly: false }),
    object()
      .shape({
        politicallyExposed: boolean()
          .required()
          .label(`${BankIdScopes.ProfileLegalStatus}:${BankIdClaims.Pep}`),
      })
      .required()
      .validate(aml, { abortEarly: false }),
  ]);
};

export const profileIdCardsScopeClaimsErrorFilter = (
  idCard: Idcard | null | undefined,
  error: string,
) => {
  switch (error) {
    case `${BankIdScopes.ProfileIdCards}:${BankIdClaims.Type}`:
      return idCard && (isNoU(idCard.type) || idCard.type === "");
    case `${BankIdScopes.ProfileIdCards}:${BankIdClaims.Country}`:
      return idCard && (isNoU(idCard.country) || idCard.country === "");
    case `${BankIdScopes.ProfileIdCards}:${BankIdClaims.ValidTo}`:
      return idCard && (isNoU(idCard.valid_to) || idCard.valid_to === "");
    case `${BankIdScopes.ProfileIdCards}:${BankIdClaims.IssueDate}`:
      return idCard && (isNoU(idCard.issue_date) || idCard.issue_date === "");
    case `${BankIdScopes.ProfileIdCards}:${BankIdClaims.Issuer}`:
      return idCard && (isNoU(idCard.issuer) || idCard.issuer === "");
    case `${BankIdScopes.ProfileIdCards}:${BankIdClaims.Number}`:
      return idCard && (isNoU(idCard.number) || idCard.number === "");
    default:
      return true;
  }
};

export const nationalitiesScopeClaimsMapper = (
  primaryNationality: string | null | undefined,
  nationalities: string[] | null | undefined,
  error: string,
) => {
  if (
    error !==
    `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.Nationalities}`
  ) {
    return error;
  }

  if (isNoU(primaryNationality) && isNoU(nationalities)) {
    return `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.PrimaryNationality}, ${error}`;
  }

  if (isNoU(primaryNationality)) {
    return `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.PrimaryNationality}`;
  }

  return error;
};
