import { LastInformationFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { object, boolean, ObjectSchema } from "yup";

export const useYupFormSchema = (): ObjectSchema<LastInformationFormModel> => {
  const { t } = useResource();

  return object<LastInformationFormModel>().shape({
    acceptPersonalDataMarketing: boolean().optional(),
    clientAgreeTakeoverData: boolean().optional(),
    voluntarilyDecision: boolean()
      .oneOf(
        [true],
        t(Resources.Contract.NewContract.LastInformation.DecisionRequired),
      )
      .required(
        t(Resources.Contract.NewContract.LastInformation.DecisionRequired),
      ),
    declareContractingDataValidity: boolean()
      .oneOf(
        [true],
        t(Resources.Contract.NewContract.LastInformation.DecisionRequired),
      )
      .required(
        t(Resources.Contract.NewContract.LastInformation.DecisionRequired),
      ),
  });
};
