import {
  PathSelectionFormModel,
  VerificationMode,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { ObjectSchema, object, mixed } from "yup";

export const useYupFormSchema = (): ObjectSchema<PathSelectionFormModel> => {
  const { t } = useResource();

  return object<PathSelectionFormModel>().shape({
    verificationMode: mixed<VerificationMode>()
      .oneOf(
        [VerificationMode.BankID, VerificationMode.Manual],
        t(Resources.Contract.NewContract.PathSelection.Validation.Required),
      )
      .required(
        t(Resources.Contract.NewContract.PathSelection.Validation.Required),
      ),
  });
};
