import { ContractOverviewDto } from "Api/Api";

export const getPaymentModellingInfo = (
  overview: ContractOverviewDto | undefined | null = undefined,
  isin: string,
) => {
  const modellingItem = overview?.modelling?.modellingItems?.find(
    m => m.isin === isin,
  );
  return modellingItem;
};
