import { Box, InputAdornment, Link, Typography } from "@mui/material";
import { Chart } from "Components/Charts/Chart";
import { InvestmentForm } from "Components/ContractModeling/FundConfiguration/InvestmentForm";
import { InvestmentTitle } from "Components/ContractModeling/shared/InvestmentTitle";
import { SelectButtons } from "Components/Shared/Inputs/SelectButtons";
import { CurrencyRenderer } from "Components/Shared/CurrencyRenderer";
import {
  StyledButtonBox,
  StyledFlex,
  MultilineTypography,
} from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FunctionComponent, useCallback, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYupFormSchema } from "Hooks/Investment/NewInvestment/FundConfiguration/useYupFormSchema";
import { useChartData } from "Hooks/Investment/NewInvestment/FundConfiguration/useChartData";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useFormData } from "Hooks/Investment/NewInvestment/useFormData";
import {
  ContractModelingStep,
  FundConfigurationFormModel,
} from "State/Contracts/Modeling/Models";
import { setNextStep } from "State/Contracts/Modeling/ModelingState/ModelingStateState";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { FUND_DETAILS_URL } from "Utils/ExternalLinkUtils";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useAppSelector } from "Hooks/useAppSelector";
import { usePeriodicalInvestmentFeeRate } from "Hooks/ProductFeeRate/usePeriodicalInvestmentFeeRate";
import { useSingleInvestmentFeeRate } from "Hooks/ProductFeeRate/useSingleInvestmentFeeRate";
import { isNoU, notNoU } from "Utils/ObjectUtils";

import { debounce } from "lodash-es";
import {
  calculatePeriodicalInvestment,
  calculateSingleInvestment,
  calculateInvestmentTotalAmount,
} from "Utils/ModelationCalculators";
import { useSaveContractDraft } from "Hooks/Contract/useSaveContractDraft";
import { formatCurrency } from "Utils/CurrencyUtils";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CollapsibleWrapper } from "Components/Shared/CollapsibleWrapper";

export const MINIMUM_DEPOSIT = 0;
export const MAXIMUM_SINGLE_DEPOSIT = 100_000_000;
export const MAXIMUM_PERIODICAL_DEPOSIT = 10_000_000;
export const DEFAULT_INVESTMENT_LENGTH = 10;

const PageResources = Resources.Investments.FundConfiguration;

export const FundConfiguration: FunctionComponent = () => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const { postDraft } = useSaveContractDraft();

  const { defaultValues } = useFormData<ContractModelingStep.FundConfiguration>(
    {
      step: ContractModelingStep.FundConfiguration,
    },
  );
  const { defaultValues: fundSelectorValues } =
    useFormData<ContractModelingStep.Fund>({
      step: ContractModelingStep.Fund,
    });

  const { color, contrastColor } = useModelingColor();

  const schema = useYupFormSchema(fundSelectorValues);
  const form = useForm<FundConfigurationFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      investmentLengthYears: DEFAULT_INVESTMENT_LENGTH,
      targetAmount: 1_000_000,
      ...defaultValues,
      singleInvestment:
        defaultValues?.singleInvestment ??
        fundSelectorValues?.singleDefaultInvestment ??
        0,
      periodicalInvestment:
        defaultValues?.periodicalInvestment ??
        fundSelectorValues?.periodicalDefaultInvestment ??
        0,
    },
  });
  const {
    watch,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue,
    getValues,
  } = form;
  const navigate = useNavigate();

  const type = watch("type");

  useEffect(() => {
    clearErrors();
  }, [type, clearErrors]);

  const isTargetAmount = type === "Target";

  const onSubmit = (formData: FundConfigurationFormModel) => {
    dispatch(
      setNextStep({ formData, onlySave: true, modelingCompleted: true }),
    );
    postDraft({ isContractCreated: false, isContractSigned: false });
    navigate(getPath(AppRouting.PreContractualInformation));
  };

  const singleInvestment = watch("singleInvestment");
  const periodicalInvestment = watch("periodicalInvestment");
  const targetAmount = watch("targetAmount");
  const investmentLengthYears = watch("investmentLengthYears");

  const contractTypeCode = fundSelectorValues?.contractTypeCode;
  const isin = fundSelectorValues?.isin;

  const {
    periodicalInvestmentFeeRateLabel,
    data: periodicalInvestmentFeeRateData,
    isLoading: isLoadingPeriodicalInvestmentFeeRate,
  } = usePeriodicalInvestmentFeeRate({
    amount: periodicalInvestment,
    contractTypeCode,
    investmentLengthYears,
    isin,
  });

  const periodicalInvestmentFeeRate =
    periodicalInvestmentFeeRateData?.feeRate ?? 0;

  const {
    singleInvestmentFeeRateLabel,
    data: singleInvestmentFeeRateData,
    isLoading: isLoadingSingleInvestmentFeeRate,
  } = useSingleInvestmentFeeRate({
    amount: singleInvestment,
    contractTypeCode,
    investmentLengthYears,
    isin,
  });

  const singleInvestmentFeeRate = singleInvestmentFeeRateData?.feeRate ?? 0;

  const isLoadingInvestmentFeeRates =
    (isLoadingSingleInvestmentFeeRate && !singleInvestmentFeeRate) ||
    (isLoadingPeriodicalInvestmentFeeRate && !periodicalInvestmentFeeRate);

  const { [ContractModelingStep.Fund]: selectedFund } = useAppSelector(
    e => e.contracts.modeling.modelingState.formData,
  );

  const appreciationPercentsMax = selectedFund?.maxPerformance;
  const appreciationPercentsMin = selectedFund?.minPerformance;

  const chartData = useChartData({
    singleInvestment: singleInvestment,
    singleInvestmentFeeRate: singleInvestmentFeeRate,
    periodicalInvestment: periodicalInvestment,
    periodicalInvestmentFeeRate: periodicalInvestmentFeeRate,
    investmentLengthYears: investmentLengthYears,
    appreciationPercentsMax: appreciationPercentsMax,
    appreciationPercentsMin: appreciationPercentsMin,
    isLoading: isLoadingInvestmentFeeRates,
  });

  const targetAmountValue =
    !isLoadingInvestmentFeeRates && chartData.length
      ? chartData[chartData.length - 1].expectedYieldMax
      : null;

  const investmentTotalAmount =
    !isLoadingInvestmentFeeRates &&
    notNoU(singleInvestment) &&
    notNoU(periodicalInvestment) &&
    notNoU(investmentLengthYears)
      ? calculateInvestmentTotalAmount(
          singleInvestment,
          periodicalInvestment,
          investmentLengthYears,
        )
      : 0;

  useEffect(() => {
    if (targetAmountValue && targetAmountValue > 0 && !isTargetAmount) {
      setValue("targetAmount", Math.round(targetAmountValue));
    }
  }, [targetAmountValue, setValue, isTargetAmount]);

  const debouncedRecalculatePeriodicalInvestment = debounce(() => {
    if (isTargetAmount) {
      const periodicalInvestment =
        getValuesAndPerformCalculatePeriodicalInvestment(
          getValues,
          singleInvestmentFeeRate,
          periodicalInvestmentFeeRate,
          appreciationPercentsMax,
        );

      setValue("periodicalInvestment", periodicalInvestment);
    }
  }, 200);

  const debouncedRecalculateSingleInvestment = debounce(() => {
    if (isTargetAmount) {
      const singleInvestment = getValuesAndPerformCalculateSingleInvestment(
        getValues,
        singleInvestmentFeeRate,
        periodicalInvestmentFeeRate,
        appreciationPercentsMax,
      );

      setValue("singleInvestment", singleInvestment);

      if (singleInvestment === 0) {
        debouncedRecalculatePeriodicalInvestment();
      }
    }
  }, 200);

  const tagTrackingEnhancer = useCallback(
    (value: string | null | undefined) => `${value}${isin}`,
    [isin],
  );

  return (
    <FormProvider {...form}>
      <div>
        <LoadingWrapper isLoading={isLoadingInvestmentFeeRates} wrapChildren>
          <InvestmentTitle
            allowDipWrapper
            allowProductBackground
            productIsin={isin}
          />

          <Typography component="span" marginBottom={2}>
            {t(PageResources.Label)}
          </Typography>
          <CollapsibleWrapper
            activator={
              <>
                &nbsp;
                <Link component="span" sx={{ cursor: "pointer" }}>
                  {t(PageResources.FutureInvestmentActivator)}
                </Link>
              </>
            }
            children={
              <Typography marginTop={2}>
                {t(PageResources.FutureInvestment)}
              </Typography>
            }
          />

          <Box marginTop={8}>
            <Controller
              control={control}
              name="type"
              defaultValue="Unlimited"
              render={({ field: { value, onChange } }) => (
                <SelectButtons
                  buttons={[
                    {
                      label: t(PageResources.SelectButtonFirstOption),
                      trackingTag: "type",
                      value: "Unlimited",
                      trackingValue: "Unlimited",
                    },
                    {
                      label: t(PageResources.SelectButtonSecondOption),
                      trackingTag: "type",
                      value: "Target",
                      trackingValue: "Target",
                    },
                  ]}
                  active={value}
                  label={t(PageResources.SelectButtonsLabel)}
                  onChange={value => onChange(value)}
                  color={contrastColor}
                  backgroundColor={color}
                />
              )}
            />
          </Box>

          <InvestmentForm
            showTargetAmount={isTargetAmount}
            currency={fundSelectorValues?.currency ?? null}
            singleInvestmentFeeRateLabel={singleInvestmentFeeRateLabel}
            periodicalInvestmentFeeRateLabel={periodicalInvestmentFeeRateLabel}
            maxPerformance={appreciationPercentsMax ?? null}
            totalAmount={investmentTotalAmount ?? null}
            isin={isin}
            onTargetAmountChange={value => {
              if (value !== targetAmount) {
                debouncedRecalculatePeriodicalInvestment();
              }
            }}
            onSingleInvestmentChange={value => {
              if (value !== singleInvestment) {
                debouncedRecalculatePeriodicalInvestment();
              }
            }}
            onPeriodicalInvestmentChange={value => {
              if (value !== periodicalInvestment) {
                debouncedRecalculateSingleInvestment();
              }
            }}
          />

          <BlInputFormField
            label={t(PageResources.InvestmentLength)}
            control={control}
            name="investmentLengthYears"
            inputProps={{
              min: 1,
              max: 100,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            errors={errors}
            mask={Number}
            min={1}
            max={100}
            isNumber
            hasTracking={true}
            tagTrackingEnhancer={tagTrackingEnhancer}
            onChange={value => {
              if (value !== investmentLengthYears) {
                debouncedRecalculatePeriodicalInvestment();
              }
            }}
            inputEndAdornment={
              <InputAdornment position="end">
                {t(Resources.Common.YearsLabel, {
                  count: investmentLengthYears,
                })}
              </InputAdornment>
            }
          />

          {!isTargetAmount && (
            <>
              <Typography
                variant="subtitle1"
                marginTop={4}
                textTransform="uppercase"
              >
                {t(PageResources.EndEstimatedValue)}
              </Typography>

              <StyledFlex $alignItems="center">
                <CurrencyRenderer
                  value={targetAmountValue}
                  currency={fundSelectorValues?.currency ?? undefined}
                  fixedDecimalPlaces={true}
                  decimalPlaces={0}
                />
                <BlInfoPopper size={18} icon="info">
                  {t(PageResources.TargetAmountInfo)}
                </BlInfoPopper>
              </StyledFlex>

              <Typography variant="subtitle1" marginTop={1}>
                {`${t(PageResources.TotalAmount)} ${formatCurrency(
                  investmentTotalAmount,
                  fundSelectorValues?.currency,
                )}`}
              </Typography>
              <Typography variant="subtitle1">
                {t(PageResources.ExpectedAppreciation, {
                  value: appreciationPercentsMax,
                })}
              </Typography>
            </>
          )}

          <Typography
            variant="subtitle1"
            marginTop={7}
            textTransform="uppercase"
          >
            {t(PageResources.ProgressDisplay)}
          </Typography>

          <Chart
            data={chartData}
            barLabel={t(PageResources.ChartBarLabel)}
            lineLabel={t(PageResources.ChartLineLabel)}
            isYieldLineDashed
            isExpectedYield
            color={color}
            isAlignedLeft
          />

          <MultilineTypography component="div" marginTop={7}>
            <Typography variant="subtitle1">
              {t(PageResources.InvestmentWarning)}&nbsp;
              <Link href={FUND_DETAILS_URL} target="_blank" color="primary">
                {t(PageResources.InvestmentWarningLink)}
              </Link>
            </Typography>
          </MultilineTypography>
        </LoadingWrapper>
      </div>

      <StyledButtonBox>
        <PrimaryButton
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoadingInvestmentFeeRates}
          trackingTag={t(PageResources.MakeContract)}
          hexColor={contrastColor}
          hexBackgroundColor={color}
        >
          {t(PageResources.MakeContract)}
        </PrimaryButton>
      </StyledButtonBox>
    </FormProvider>
  );
};

function getValuesAndPerformCalculatePeriodicalInvestment(
  getValues: () => FundConfigurationFormModel,
  singleInvestmentFeeRate: number | null | undefined,
  periodicalInvestmentFeeRate: number | null | undefined,
  maxPerformance: number | null | undefined,
) {
  const { targetAmount, singleInvestment, investmentLengthYears } = getValues();

  if (
    !isNoU(targetAmount) &&
    !isNoU(singleInvestment) &&
    !isNoU(singleInvestmentFeeRate) &&
    !isNoU(periodicalInvestmentFeeRate) &&
    !isNoU(maxPerformance) &&
    !isNoU(investmentLengthYears)
  ) {
    const periodicalInvestment = calculatePeriodicalInvestment(
      Number(targetAmount),
      Number(singleInvestment),
      Number(singleInvestmentFeeRate),
      Number(periodicalInvestmentFeeRate),
      Number(maxPerformance),
      Number(investmentLengthYears),
    );

    return Math.round(periodicalInvestment);
  }
}

function getValuesAndPerformCalculateSingleInvestment(
  getValues: () => FundConfigurationFormModel,
  singleInvestmentFeeRate: number | null | undefined,
  periodicalInvestmentFeeRate: number | null | undefined,
  maxPerformance: number | null | undefined,
) {
  const { targetAmount, periodicalInvestment, investmentLengthYears } =
    getValues();

  if (
    !isNoU(targetAmount) &&
    !isNoU(periodicalInvestment) &&
    !isNoU(singleInvestmentFeeRate) &&
    !isNoU(periodicalInvestmentFeeRate) &&
    !isNoU(maxPerformance) &&
    !isNoU(investmentLengthYears)
  ) {
    const singleInvestment = calculateSingleInvestment(
      Number(targetAmount),
      Number(periodicalInvestment),
      Number(singleInvestmentFeeRate),
      Number(periodicalInvestmentFeeRate),
      Number(maxPerformance),
      Number(investmentLengthYears),
    );

    return Math.round(singleInvestment);
  }
}
