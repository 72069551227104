import { Typography } from "@mui/material";
import { SignatureFormTitle } from "Components/ContractBankConnections/Shared/SignatureFormTitle";
import { Loader } from "Components/Layout/Loader";
import { FunctionComponent, ReactNode } from "react";

type Props = {
  title: string;
  titleComponent?: ReactNode;
  working: string;
};

export const SignatureFormLoader: FunctionComponent<Props> = ({
  title,
  titleComponent,
  working,
}) => {
  return (
    <div>
      <SignatureFormTitle title={title} titleComponent={titleComponent} />
      <Typography marginBottom={15}>{working}</Typography>
      <Loader />
    </div>
  );
};
