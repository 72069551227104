import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import {
  ClientVerifyContactConfirmationCommand,
  ClientVerifyContactConfirmationCommandResult,
  StatusCommandResult,
  putClientContactConfirmation,
} from "Api/Api";
import { userContactVerified } from "State/Shared/UserContactVerified";

export type PutContactConfirmationStateType =
  FetchStateType<StatusCommandResult>;

export const putContactConfirmationState =
  (): PutContactConfirmationStateType => getFetchStateDefaultValue();

export type PutContactConfirmationActionType = ActionType<
  typeof putContactConfirmationAsync
>;

export const putContactConfirmationAsync = createAsyncAction(
  "@client/PUT_CONTACT_CONFIRMATION_REQUEST",
  "@client/PUT_CONTACT_CONFIRMATION_SUCCESS",
  "@client/PUT_CONTACT_CONFIRMATION_FAILURE",
)<
  ClientVerifyContactConfirmationCommand,
  ClientVerifyContactConfirmationCommandResult,
  Error
>();

function* putContactConfirmation(
  action: ActionType<typeof putContactConfirmationAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      putClientContactConfirmation,
      action.payload,
    );

    if (error) {
      yield put(putContactConfirmationAsync.failure(error));
      return;
    }

    yield put(putContactConfirmationAsync.success(response));
    yield put(userContactVerified());
  } catch (err) {
    yield put(putContactConfirmationAsync.failure(err as Error));
  }
}

export function* putContactConfirmationSaga() {
  yield takeLatest(
    getType(putContactConfirmationAsync.request),
    putContactConfirmation,
  );
}

export const putContactConfirmationReducer = createReducer<
  PutContactConfirmationStateType,
  PutContactConfirmationActionType
>(putContactConfirmationState())
  .handleAction(putContactConfirmationAsync.request, handleActionRequest)
  .handleAction(putContactConfirmationAsync.failure, handleActionFailure)
  .handleAction(putContactConfirmationAsync.success, handleActionSuccess);
