import { Typography } from "@mui/material";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import { ReactNode, type FunctionComponent } from "react";
import styled from "styled-components";

type Props = {
  icon: ReactNode;
  label: string;
  onClick?(): void;
  disabled?: boolean;
  size?: number;
  textColor?: "primary" | "secondary";
  highlighted?: boolean;
  fontSize?: number;
};

const StyledBox = styled.div<{ $disabled?: boolean; $background?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing(1)};
  max-width: max-content;
  position: relative;

  ${props =>
    props.$background &&
    `
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 117px;
      height: 107px;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="117" height="107" viewBox="0 0 117 107" fill="none"><circle opacity="0.15" cx="71.5" cy="59.5" r="71.5" fill="#252525"/></svg>');
      z-index: -1;
    }
  `}

  ${props =>
    props.$disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const IconButton: FunctionComponent<Props> = ({
  icon,
  label,
  onClick,
  disabled,
  size = 40,
  textColor,
  highlighted,
  fontSize,
}) => {
  return (
    <StyledBox onClick={onClick} role="button" $disabled={disabled}>
      <IconCircleWrapper size={size} color="primary" highlighted={highlighted}>
        {icon}
      </IconCircleWrapper>
      <Typography
        fontWeight={600}
        textAlign="center"
        color={textColor}
        fontSize={fontSize}
      >
        {label}
      </Typography>
    </StyledBox>
  );
};
