import { Stack } from "@mui/material";
import { AppDocumentType } from "Api/Api";
import { ComponentTitleWrapper } from "Components/Shared/ComponentTitleWrapper";
import { Document } from "Components/Shared/Document";
import { DocumentsIcon } from "Components/Shared/Icons";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PageTitle } from "Components/Shared/PageTitle";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { LookupItem } from "Models/LookupItem";
import { getListAsync } from "State/Documents/List/GetListState";
import { Resources, useResource } from "Translations/Resources";
import { useEffect, type FunctionComponent, useMemo } from "react";
import { useForm } from "react-hook-form";

const PageResources = Resources.More.Documents;

type Props = {
  pageTitle: string;
  defaultDocumentType: string | undefined;
};

export const Documents: FunctionComponent<Props> = ({
  pageTitle,
  defaultDocumentType,
}) => {
  const { t } = useResource();

  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(s => s.documents.list);

  if (!defaultDocumentType) {
    defaultDocumentType = "";
  }

  const { control, watch } = useForm<{ documentType?: string }>({
    defaultValues: { documentType: defaultDocumentType },
  });
  const documentType = watch("documentType");

  const documentTypes = useMemo<LookupItem[]>(
    () => [
      { Name: t(Resources.Common.All), Value: "" },
      ...Object.values(AppDocumentType ?? {}).map<LookupItem>(type => ({
        Name: t(PageResources.Categories[type]),
        Value: type,
      })),
    ],
    [t],
  );

  useEffect(() => {
    dispatch(getListAsync.request());
  }, [dispatch]);

  return (
    <div>
      <PageTitle showOnMobile={false}>{pageTitle}</PageTitle>
      <LoadingWrapper
        isLoading={isLoading}
        skeleton={
          <Stack gap={3} marginTop={6}>
            <PrimarySkeleton variant="text" />
            <PrimarySkeleton variant="rectangular" height={50} />
            <PrimarySkeleton variant="rectangular" height={50} />
          </Stack>
        }
      >
        <DropdownInput
          errors={{}}
          control={control}
          name="documentType"
          options={documentTypes}
          label={t(PageResources.Category)}
        />
        {data
          ?.filter(d => !documentType || d.documentType === documentType)
          .map(({ documentType, documents }) => (
            <ComponentTitleWrapper
              title={t(PageResources.Categories[documentType])}
              key={documentType}
            >
              {documents.map(({ documentName, appDocumentID, url }) => (
                <Document
                  key={appDocumentID}
                  name={documentName}
                  icon={<DocumentsIcon />}
                  href={url}
                />
              ))}
            </ComponentTitleWrapper>
          ))}
      </LoadingWrapper>
    </div>
  );
};
