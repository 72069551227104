import {
  HideUserCampaignCommandResult,
  HideUserCampaignCommandResultStatus,
  HideUserCampaignRequest,
  putCampaignsChannelID,
} from "Api/Api";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { takeLeading, put } from "redux-saga/effects";
import { safeApiCall } from "State/Utils";
import { createAsyncAction, getType } from "typesafe-actions";

export const hideUserCampaignAsync = createAsyncAction(
  "@campaigns/HIDE_USER_CAMPAIGN_REQUEST",
  "@campaigns/HIDE_USER_CAMPAIGN_SUCCESS",
  "@campaigns/HIDE_USER_CAMPAIGN_FAILURE",
)<
  { channelID: string } & HideUserCampaignRequest,
  { channelID: string } & HideUserCampaignCommandResult,
  Error
>();

function* hideUserCampaign(
  action: ReturnType<typeof hideUserCampaignAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      putCampaignsChannelID,
      {
        action: action.payload.action,
      },
      action.payload.channelID,
    );

    if (error) {
      yield put(hideUserCampaignAsync.failure(error));
      return;
    }

    if (response.status === HideUserCampaignCommandResultStatus.Fail) {
      yield put(
        hideUserCampaignAsync.failure(
          new ApplicationError(
            "Failed to hide user campaign.",
            ErrorLevel.Error,
            {
              status: response.status,
              error: response.error,
            },
          ),
        ),
      );
      return;
    }

    yield put(
      hideUserCampaignAsync.success({
        ...response,
        channelID: action.payload.channelID,
      }),
    );
  } catch (error) {
    yield put(hideUserCampaignAsync.failure(error as Error));
  }
}

export function* hideUserCampaignSaga() {
  yield takeLeading(getType(hideUserCampaignAsync.request), hideUserCampaign);
}
