import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
} from "Constants/Countries";

export const ValidatedPersonalNumberNationalities = [
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
];

export const USA_NATIONALITY_FORBID_TYPE = "usa-forbidden";
