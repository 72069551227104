const MONTHS_PER_YEAR = 12;

export const calculateTargetAmounts = (
  singleInvestment: number,
  singleInvestmentFeeRate: number,
  periodicalInvestment: number,
  periodicalInvestmentFeeRate: number,
  investmentLengthYears: number,
  appreciationPercentsMax: number,
  appreciationPercentsMin: number = 0,
) => {
  return Array.from({ length: investmentLengthYears }, (_, year) => {
    const { deposit, expectedYield: expectedYieldMax } = calculateTargetAmount(
      singleInvestment,
      singleInvestmentFeeRate,
      periodicalInvestment,
      periodicalInvestmentFeeRate,
      appreciationPercentsMax,
      year,
    );

    const { expectedYield: expectedYieldMin } = calculateTargetAmount(
      singleInvestment,
      singleInvestmentFeeRate,
      periodicalInvestment,
      periodicalInvestmentFeeRate,
      appreciationPercentsMin,
      year,
    );

    return {
      period: year + 1,
      deposit,
      expectedYieldMax,
      expectedYieldMin,
    };
  });
};

export const calculateTargetAmount = (
  singleInvestment: number,
  singleInvestmentFeeRate: number,
  periodicalInvestment: number,
  periodicalInvestmentFeeRate: number,
  appreciationPercents: number,
  year: number,
) => {
  /**
   * V je počáteční jednorázový vklad
   */
  const V = singleInvestment;

  /**
   * P je pravidelný vklad na začátku každého období
   */
  const P = periodicalInvestment;

  /**
   * f1 procento jednorázového vkladu mínus poplatek jednorázového vkladu, př. při poplatku 1% je f1 = 99 % => 0,99
   */
  const f1 = 1 - (singleInvestmentFeeRate ?? 0) / 100;

  /**
   * f2 procento pravidelného vkladu mínus poplatek pravidelného vkladu
   */
  const f2 = 1 - (periodicalInvestmentFeeRate ?? 0) / 100;

  /**
   * r měsíční úročitel, např. při předpokládaném ročním zhodnocení 4% je r = (1,04 ^(1/12))-1
   */
  const r = calculateR(appreciationPercents);

  const n = (year + 1) * 12;

  // prettier-ignore
  const fraction = ((((1 + r) ** n) - 1) / r);

  /**
   * C je cílová částka
   */
  // prettier-ignore
  const C = V * f1 * (1 + r) ** n + P * f2 * fraction * (1 + r);

  return {
    deposit: singleInvestment + periodicalInvestment * 12 * year,
    expectedYield: C,
  };
};

export const calculatePeriodicalInvestment = (
  targetAmount: number,
  singleInvestment: number,
  singleInvestmentFeeRate: number,
  periodicalInvestmentFeeRate: number,
  appreciationPercents: number,
  investmentLengthYears: number,
) => {
  // Constants from the previous function
  const V = singleInvestment;
  const f1 = 1 - singleInvestmentFeeRate / 100;
  const f2 = 1 - periodicalInvestmentFeeRate / 100;
  const r = calculateR(appreciationPercents);
  const n = investmentLengthYears * 12;
  const C = targetAmount;

  // prettier-ignore
  const P = (r * (C - f1*V*((r+1)**n)))/(f2*(r+1)*(((r+1)**n)-1));

  return Math.max(0, P);
};

export const calculateSingleInvestment = (
  targetAmount: number,
  periodicalInvestment: number,
  singleInvestmentFeeRate: number,
  periodicalInvestmentFeeRate: number,
  appreciationPercents: number,
  investmentLengthYears: number,
) => {
  // Constants from the previous function
  const P = periodicalInvestment;
  const f1 = 1 - singleInvestmentFeeRate / 100;
  const f2 = 1 - periodicalInvestmentFeeRate / 100;
  const r = calculateR(appreciationPercents);
  const n = investmentLengthYears * 12;
  const C = targetAmount;

  // prettier-ignore
  const V = -((r + 1) ** (-n) * (-C * r + f2 * P * (r + 1) ** n + f2 * P * r * (r + 1) ** n - f2 * P * r - f2 * P)) / (f1 * r);

  return Math.max(0, V);
};

/**
 * r měsíční úročitel, např. při předpokládaném ročním zhodnocení 4% je r = (1,04 ^(1/12))-1
 */
function calculateR(appreciationPercents: number) {
  const appreciation = appreciationPercents / 100;
  return Math.pow(1 + appreciation, 1 / 12) - 1;
}

export function calculateInvestmentTotalAmount(singleInvestment: number, periodicalInvestment: number, investmentLengthYears: number) {
  return Math.max(0, singleInvestment + periodicalInvestment * MONTHS_PER_YEAR * investmentLengthYears);
}