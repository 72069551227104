import { Stack, Typography } from "@mui/material";
import { InvestmentTitle } from "Components/ContractModeling/shared/InvestmentTitle";
import { useResource } from "Translations/Resources";
import { Resources } from "Translations/Resources";
import { type FunctionComponent, useState, useEffect } from "react";
import { ProductCard } from "Components/ContractModeling/FundSelector/ProductCard";

import { useAppDispatch } from "Hooks/useAppDispatch";
import { ProductDetailDialog } from "Components/Shared/ProductDetailDialog";
import {
  setIsDip,
  setNextStep,
} from "State/Contracts/Modeling/ModelingState/ModelingStateState";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import {
  ClassSelector,
  OnSelectProduct,
} from "Components/ContractModeling/FundSelector/Components/ClassSelector";
import {
  ProductListItemDto,
  useProductList,
} from "Hooks/Investment/NewInvestment/useProductList";
import { ContractModelingStep } from "State/Contracts/Modeling/Models";
import { useFormData } from "Hooks/Investment/NewInvestment/useFormData";
import { useSaveContractDraft } from "Hooks/Contract/useSaveContractDraft";
import { DipCard } from "Components/ContractModeling/FundSelector/DipCard";
import { DipDescriptionDialog } from "./Components/DipDescriptionDialog";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { ContractCategories } from "Models/Contracts";
import { useSettings } from "Hooks/App/Settings/useSettings";
import { clearAppSettings } from "State/App/Settings/GetSettingsState";
import { useIsDipEnabled } from "Hooks/Contract/useIsDipEnabled";

const PageResources = Resources.Investments.FundSelection;

export const FundSelector: FunctionComponent = () => {
  const { t } = useResource();
  const { isDipEnabled } = useIsDipEnabled();

  const [productDetail, setProductDetail] = useState<{
    code?: string;
    isin?: string | null | undefined;
  }>();

  const [classSelector, setClassSelector] = useState<ProductListItemDto>();

  const dispatch = useAppDispatch();
  const isDipSelected = useIsDipSelected();

  const { defaultValues } = useFormData<ContractModelingStep.Fund>({
    step: ContractModelingStep.Fund,
  });

  const { defaultValues: informationFormData } =
    useFormData<ContractModelingStep.Information>({
      step: ContractModelingStep.Information,
    });

  const { isLoading: isLoadingProductList, productList } =
    useProductList(isDipSelected);

  const { postDraft } = useSaveContractDraft();
  const { settings, isLoading: isLoadingSettings } = useSettings();

  const isLoading = isLoadingProductList || isLoadingSettings;

  const onSelect: OnSelectProduct = product => {
    dispatch(
      setNextStep({
        formData: {
          code: classSelector?.code,
          ...product,
        },
      }),
    );
    postDraft({ isContractCreated: false, isContractSigned: false });
  };

  const onDetailProductList = ({ code }: ProductListItemDto) => {
    setProductDetail({ code });
  };

  useEffect(() => {
    dispatch(clearAppSettings());
  }, [dispatch]);

  useEffect(() => {
    if (!settings) {
      return;
    }

    if (
      settings.isDIPEnabled &&
      informationFormData?.category === ContractCategories.Retirement
    ) {
      dispatch(setIsDip({ isDip: true }));
      return;
    }

    dispatch(setIsDip({ isDip: false }));
  }, [dispatch, informationFormData?.category, settings]);

  const [isDipDescriptionDialogVisible, setIsDipDescriptionDialogVisible] =
    useState(false);

  return (
    <div>
      <InvestmentTitle />
      <Typography marginBottom={4}>{t(PageResources.Label)}</Typography>

      <Stack gap={4}>
        <LoadingWrapper
          isLoading={isLoading}
          skeleton={
            <>
              <PrimarySkeleton height={155} />
              <PrimarySkeleton height={155} />
              <PrimarySkeleton height={155} />
              <PrimarySkeleton height={155} />
            </>
          }
        >
          {settings?.isDIPEnabled && (
            <DipCard
              onDetail={() => setIsDipDescriptionDialogVisible(true)}
            ></DipCard>
          )}

          {productList
            ?.filter(
              ({ isDipAllowed }) =>
                !isDipSelected ||
                (settings?.isDIPEnabled && isDipSelected && isDipAllowed),
            )
            .map(product => (
              <DipTagWrapper showPlain={!isDipSelected} key={product.code}>
                <ProductCard
                  {...product}
                  onSelect={setClassSelector}
                  onDetail={onDetailProductList}
                  selected={defaultValues?.code === product.code}
                />
              </DipTagWrapper>
            ))}
        </LoadingWrapper>
      </Stack>

      <DipDescriptionDialog
        onClose={() => setIsDipDescriptionDialogVisible(false)}
        isOpen={isDipDescriptionDialogVisible}
      />

      <ProductDetailDialog
        onClose={() => setProductDetail(undefined)}
        isin={productDetail?.isin}
        productCode={productDetail?.code}
        isDip={isDipEnabled}
      />

      <ClassSelector
        productCode={classSelector?.code}
        onClose={() => setClassSelector(undefined)}
        onSelectProduct={onSelect}
        defaultIsinValue={defaultValues?.isin}
      />
    </div>
  );
};
