import * as React from "react";
export const BackIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.4805 1.49023C17.2207 1.49804 16.9746 1.60742 16.793 1.79296L6.79297 11.793C6.40234 12.1836 6.40234 12.8164 6.79297 13.207L16.793 23.207C17.043 23.4688 17.416 23.5742 17.7676 23.4824C18.1172 23.3906 18.3906 23.1172 18.4824 22.7676C18.5742 22.416 18.4688 22.043 18.207 21.793L8.91406 12.5L18.207 3.20702C18.502 2.91991 18.5918 2.48046 18.4316 2.0996C18.2695 1.7207 17.8926 1.47851 17.4805 1.49023Z"
        fill="currentColor"
      />
    </svg>
  );
};
