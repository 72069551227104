import { putClientAnalyticsStatus } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";

export const updateClientAnalyticsStatusAsync = createAsyncAction(
  "@settings/UPDATE_CLIENT_ANALYTICS_STATUS_REQUEST",
  "@settings/UPDATE_CLIENT_ANALYTICS_STATUS_SUCCESS",
  "@settings/UPDATE_CLIENT_ANALYTICS_STATUS_FAILURE",
)<
  {
    previousValue: boolean;
    currentValue: boolean;
  },
  boolean,
  Error
>();

function* updateClientAnalyticsStatus(
  action: ReturnType<typeof updateClientAnalyticsStatusAsync.request>,
): Generator {
  const setPreviousValue = put(
    updateClientAnalyticsStatusAsync.success(action.payload.previousValue),
  );
  try {
    //optimistic update
    yield put(
      updateClientAnalyticsStatusAsync.success(action.payload.currentValue),
    );
    const { error } = yield* safeApiCall(putClientAnalyticsStatus, {
      isAnalyticsEnabled: action.payload.currentValue,
    });

    if (error) {
      yield setPreviousValue;
      yield put(updateClientAnalyticsStatusAsync.failure(error));
      return;
    }
  } catch (err) {
    yield setPreviousValue;
    yield put(updateClientAnalyticsStatusAsync.failure(err as Error));
  }
}
export function* updateClientAnalyticsStatusSaga() {
  yield takeLatest(
    getType(updateClientAnalyticsStatusAsync.request),
    updateClientAnalyticsStatus,
  );
}
