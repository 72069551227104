import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { Documents } from "Components/More/Documents/Documents";
import { useDesktop } from "Hooks/useDesktop";
import { Resources, useResource } from "Translations/Resources";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { type FunctionComponent } from "react";
import { useNavigate } from "react-router";

export const DocumentsPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const documentType = urlParams.get("category") as string | undefined;

  return (
    <AuthenticatedLayout
      title={t(Resources.More.Menu.Documents.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(getPath(AppRouting.More))}
    >
      <Documents
        pageTitle={t(Resources.More.Menu.Documents.Title)}
        defaultDocumentType={documentType}
      />
    </AuthenticatedLayout>
  );
};
