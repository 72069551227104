import { logError } from "ErrorService";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { WebViewMessage, WebViewMessageTypes } from "Models/WebViewModels";
import { handleOnWebViewMessage } from "State/WebView/WebViewListenerSaga";
import { appWindow } from "Utils/WindowUtils";
import { NavigateFunction } from "react-router";
import { toast } from "react-toastify";

export function initWebViewListener(
  dispatch: ReturnType<typeof useAppDispatch>,
  navigate: NavigateFunction,
) {
  appWindow.postMessageToWebView = function (msg: string) {
    console.log("Message from iOS shell", msg);
    const message = JSON.parse(msg) as WebViewMessage;
    dispatch(
      handleOnWebViewMessage({
        message,
        navigate,
      }),
    );
  };

  window.addEventListener(
    "postMessageToWebView",
    appWindow.postMessageToWebView,
  );

  if (!isWebView()) {
    return;
  }

  postMessageFromWebView({
    type: WebViewMessageTypes.INIT_REQUEST,
    payload: {
      message: "Hello from the React App! Listening at 'postMessageToWebView'",
    },
  });
}

export function postMessageFromWebView(message: WebViewMessage) {
  if (isWebView()) {
    console.log("Post message to iOS shell", message);

    const postMessageListener = getPostMessageListener();
    if (!postMessageListener) {
      logError(
        new ApplicationError(
          "iOS shell postMessageListener not found",
          ErrorLevel.Critical,
        ),
      );
      console.error("iOS shell postMessageListener not found");
      return;
    }
    postMessageListener.postMessage(JSON.stringify(message));
    console.log("Post message to iOS shell", message);
  } else {
    toast.warning("iOS webview not detected");
    console.log("iOS shell not detected");
  }
}
function getPostMessageListener() {
  return (
    appWindow.webkit?.messageHandlers?.postMessageListener ?? appWindow.Android
  );
}

export function isWebView() {
  return !!getPostMessageListener();
}
