import { ProductsIsinDetail } from "Constants/Products";
import { useProductList } from "Hooks/Investment/NewInvestment/useProductList";
import { useProductTilesItems } from "Hooks/Products/useProductTilesItems";
import { Resources, useResource } from "Translations/Resources";
import { isKnownIsin } from "Utils/Products";
import { useMemo } from "react";

export const useFormattedProductClass = (isin: string | null | undefined) => {
  const { t } = useResource();
  const { productList: products } = useProductList();

  const product = products?.find(p => p.productIsins.some(pi => pi === isin));

  const { items: productTiles } = useProductTilesItems(product?.code);

  const productClass = useMemo(() => {
    if (!isKnownIsin(isin)) {
      return "";
    }

    const productTile = productTiles?.find(pt => pt.isin === isin);
    const classCode = productTile
      ? productTile.classCode
      : ProductsIsinDetail[isin].classCode;

    if (classCode) {
      return t(Resources.Investments.FundSelection.ClassLabel, {
        class: classCode,
      });
    }

    return "";
  }, [isin, productTiles, t]);

  return productClass;
};
