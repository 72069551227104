import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getAppSettingsAsync } from "State/App/Settings/GetSettingsState";
import { useEffect } from "react";

export const useSettings = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading: isLoadingSettings } = useAppSelector(
    s => s.settings.settings,
  );

  const settings = data?.settings;
  const isLoading = isLoadingSettings && !settings;

  useEffect(() => {
    if (settings) {
      return;
    }

    dispatch(getAppSettingsAsync.request());
  }, [dispatch, settings]);

  return { settings, isLoading };
};
