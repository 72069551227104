import { all } from "typed-redux-saga";
import {
  GetAppSettingsStateType,
  getAppSettingsReducer,
  getAppSettingsSaga,
} from "./GetSettingsState";
import { combineReducers } from "redux";

export type AppSettingsState = {
  settings: GetAppSettingsStateType;
};

export function* watchAppSettingsSaga() {
  yield all([getAppSettingsSaga()]);
}

export const appSettingsReducer = combineReducers<AppSettingsState>({
  settings: getAppSettingsReducer,
});
