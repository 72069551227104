import { all } from "typed-redux-saga";
import { CampaignDto } from "Api/Api";
import {
  getUserCampaignsAsync,
  getUserCampaignsSaga,
} from "State/Campaigns/Api/GetUserCampaignsState";
import { ActionType, createReducer } from "typesafe-actions";
import { produce } from "immer";
import {
  hideUserCampaignAsync,
  hideUserCampaignSaga,
} from "State/Campaigns/Api/HideUserCampaignState";

type CampaignsState = {
  isLoading: boolean;
  data: CampaignDto[] | null;
  error: Error | null;
};

export type CampaignsAction =
  | ActionType<typeof getUserCampaignsAsync>
  | ActionType<typeof hideUserCampaignAsync>;

export function* watchCampaignsStateSagas() {
  yield all([getUserCampaignsSaga(), hideUserCampaignSaga()]);
}

export const campaignsReducer = createReducer<CampaignsState, CampaignsAction>({
  isLoading: false,
  data: null,
  error: null,
})
  .handleAction(getUserCampaignsAsync.request, (state, _) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(getUserCampaignsAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.data = action.payload.campaigns ?? null;
      return draft;
    });
  })
  .handleAction(getUserCampaignsAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  })
  .handleAction(hideUserCampaignAsync.request, (state, _) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(hideUserCampaignAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.data =
        state.data?.filter(x => x.channelID !== action.payload.channelID) ??
        null;
      return draft;
    });
  })
  .handleAction(hideUserCampaignAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  });
