import { CurrencyCode } from "Models/CurrencyCodes";
import { CurrencyCode as ApiCurrencyCode } from "Api/Api";
import { Resources } from "Translations/Resources";
import { roundNumberToDecimalPlaces } from "Utils/NumberUtils";

type FormatCurrencyOptions = {
  decimalPlaces?: number;
  fixedDecimalPlaces?: boolean;
  isCurrencySymbolShown?: boolean;
  displaySign?: boolean | undefined;
};

export const formatCurrency = (
  value: number | null | undefined,
  currencyCode: CurrencyCode | string | null | undefined,
  {
    decimalPlaces = 2,
    fixedDecimalPlaces = false,
    isCurrencySymbolShown = true,
    displaySign = false,
  }: FormatCurrencyOptions = {},
) => {
  const locales = () => {
    switch (currencyCode) {
      case CurrencyCode.EUR:
        return "sk-SK";
      case CurrencyCode.CHF:
        return "fr-CH";
      case CurrencyCode.GBP:
        return "en-GB";
      case CurrencyCode.USD:
        return "en-US";
      case CurrencyCode.PLN:
        return "pl-PL";

      default:
        return "cs-CZ";
    }
  };

  const formatter = new Intl.NumberFormat(locales(), {
    style: isCurrencySymbolShown ? "currency" : "decimal",
    currency: currencyCode ?? CurrencyCode.CZK,
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: fixedDecimalPlaces ? decimalPlaces : 0,
    signDisplay: displaySign ? "exceptZero" : undefined,
  });

  return formatter.format(roundNumberToDecimalPlaces(value, decimalPlaces));
};

/**
 *
 * @returns Api currency code if currency is in ApiCurrencyCode enum, otherwise undefined
 */
export const convertApiCurrencyCodeFromString = (currency?: string | null) => {
  if (currency && currency in ApiCurrencyCode) {
    return currency as ApiCurrencyCode;
  }
};

export const getCurrencySymbol = (currency?: CurrencyCode) => {
  if (currency) {
    return formatCurrency(0, currency).replace(/\d/g, "").trim();
  }

  return currency;
};

export const getLocalizedCurrency = (
  currencyCode: CurrencyCode,
  t: (resourcePath: string, options?: any) => string,
): string => {
  switch (currencyCode) {
    case CurrencyCode.EUR:
      return t(Resources.Currencies.EUR);
    case CurrencyCode.CZK:
      return t(Resources.Currencies.CZK);
    default:
      return "unknown";
  }
};

export const getLocalizedCurrencyCode = (
  currencyCode: CurrencyCode | string | null | undefined,
): string => {
  return formatCurrency(null, currencyCode).replace(/\d/g, "").trim();
};
