import { useContractCategories } from "Hooks/codeLists/useContractCategories";
import { useAppSelector } from "Hooks/useAppSelector";
import { ContractModelingStep } from "State/Contracts/Modeling/Models";
import { useMemo } from "react";

export const useModelingTitleValues = () => {
  const { [ContractModelingStep.Information]: information } = useAppSelector(
    e => e.contracts.modeling.modelingState.formData,
  );

  const categories = useContractCategories();

  const category = useMemo(() => {
    if (!information?.category) {
      return;
    }

    return categories.find(({ Value }) => Value === information.category);
  }, [information?.category, categories]);

  return {
    modelationName: information?.name,
    category,
  };
};
