import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import {
  ModelingStateState,
  modelingStateReducer,
} from "State/Contracts/Modeling/ModelingState/ModelingStateState";

export type ModelingContractState = {
  modelingState: ModelingStateState;
};

export function* watchModelingContractSaga() {
  yield all([]);
}

export const modelingContractReducer = combineReducers<ModelingContractState>({
  modelingState: modelingStateReducer,
});
