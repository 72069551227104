import { createReducer, ActionType, createAction } from "typesafe-actions";
import {
  SignInResult,
  SignInStatus,
  EmailVerificationCommandResultStatus,
} from "Api/Api";
import { fromUnixTime } from "date-fns";
import { produce } from "immer";
import { signInAsync } from "State/Auth/SignIn/SignInState";
import { signUpAsync } from "State/Auth/SignUp/SignUpState";
import {
  AppUser,
  ResetPasswordStep,
  SignInStep,
} from "State/Auth/Models/AuthStateModels";
import { verifyEmailAsync } from "State/Auth/Verifications/EmailVerificationState";
import {
  resetPasswordAsync,
  setPasswordResetStep,
} from "State/Auth/Passwords/ResetPasswordState";
import { resendVerificationEmailAsync } from "State/Auth/Verifications/ResendVerificationEmailState";
import { acceptTermsAndConditionsAsync } from "State/Auth/TermsAndConditions/AcceptTermsAndConditionsState";
import { updateClientNotificationStatusAsync } from "State/Auth/AppData/UpdateClientNotificationStatusState";
import { updateClientAnalyticsStatusAsync } from "State/Auth/AppData/UpdateClientAnalyticsStatusState";
import { changePasswordAsync } from "State/Auth/Passwords/ChangePasswordState";
import { setPasswordAsync } from "State/Auth/Passwords/SetPasswordState";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { checkAndProlongTokenAsync } from "State/Auth/Tokens/CheckAndProlongTokenState";
import { signWithSmsSmsAsync } from "State/Contracts/Create/SignatureSms/SignWithSmsState";
import { signWithBiometryAsync } from "State/Contracts/Biometrics/SignWithBiometryState";
import { signInBiometricsAsync } from "State/Auth/Biometrics/BiometricSignInState";
import { revokeTokenAsync } from "State/Auth/Tokens/RevokeTokenState";

/**
 * Type of global state type, that handles "user"
 */
export type AuthState = {
  user: AppUser | null;
  error: Error | null;
  isLoading: boolean;
  signOutDate: string | null;
  signInStep: SignInStep;
  resetPasswordStep: ResetPasswordStep;
  phoneVerificationToken: string | null;
  unauthenticatedUrl: string | null;
  emailVerification: {
    email: string | null;
    tokenID: string | null;
  };
  forgottenPassword: {
    email: string | null;
    tokenID: string | null;
    error: Error | null;
  };
  twoFactor: {
    tokenID: string | null;
  };
  tokenExpiration: string | null;
  isAuthenticated: boolean;
};

export const resetUser = createAction("@auth/RESET")<{
  authRedirectUrl?: string;
  wasSignedOutManually: boolean;
}>();

export const resetError = createAction("@auth/RESET_ERROR")<void>();

export const setSignInStep = createAction(
  "@auth/SET_SIGN_IN_STEP",
)<SignInStep>();

export const closeWelcomePage = createAction(
  "@auth/CLOSE_WELCOME_PAGE",
)<void>();

export const setUnauthenticatedUrl = createAction(
  "@auth/SET_UNAUTHENTICATED_URL",
)<string | null>();

export const setVerificationEmail = createAction(
  "@auth/SET_VERIFICATION_EMAIL",
)<string | null>();

export const setIsAuthenticated = createAction(
  "@auth/SET_IS_AUTHENTICATED",
)<boolean>();

export const setError = createAction("@auth/SET_ERROR")<Error | null>();

/**
 * Collection of actions, that can change state
 */
export type AuthAction =
  | ActionType<typeof signInAsync>
  | ActionType<typeof signInBiometricsAsync>
  | ActionType<typeof signUpAsync>
  | ActionType<typeof resetPasswordAsync>
  | ActionType<typeof setPasswordResetStep>
  | ActionType<typeof changePasswordAsync>
  | ActionType<typeof verifyEmailAsync>
  | ActionType<typeof setUnauthenticatedUrl>
  | ActionType<typeof resetUser>
  | ActionType<typeof resetError>
  | ActionType<typeof revokeTokenAsync>
  | ActionType<typeof setError>
  | ActionType<typeof setSignInStep>
  | ActionType<typeof closeWelcomePage>
  | ActionType<typeof setVerificationEmail>
  | ActionType<typeof resendVerificationEmailAsync>
  | ActionType<typeof acceptTermsAndConditionsAsync>
  | ActionType<typeof updateClientNotificationStatusAsync>
  | ActionType<typeof updateClientAnalyticsStatusAsync>
  | ActionType<typeof setPasswordAsync>
  | ActionType<typeof checkAndProlongTokenAsync>
  | ActionType<typeof signWithSmsSmsAsync>
  | ActionType<typeof signWithBiometryAsync>
  | ActionType<typeof setIsAuthenticated>;
/**
 * State is updated here.
 *
 * The most important rule of using Redux architecture is:
 *
 * NEVER MUTATE STATE !!!!!!!!!!!!!
 *
 * ALWAYS CREATE NEW OBJECT !!!
 */
export const authReducer = createReducer<AuthState, AuthAction>({
  user: null,
  error: null,
  isLoading: false,
  signOutDate: null,
  signInStep: SignInStep.Credentials,
  resetPasswordStep: ResetPasswordStep.Email,
  phoneVerificationToken: null,
  unauthenticatedUrl: null,
  emailVerification: {
    email: null,
    tokenID: null,
  },
  tokenExpiration: null,
  forgottenPassword: {
    email: null,
    tokenID: null,
    error: null,
  },
  twoFactor: {
    tokenID: null,
  },
  isAuthenticated: false,
})
  .handleAction(setIsAuthenticated, (state, action) => {
    return produce(state, draft => {
      draft.isAuthenticated = action.payload;
      return draft;
    });
  })
  .handleAction(setVerificationEmail, (state, action) => {
    return { ...state, IDToken: action.payload };
  })
  .handleAction(resetUser, state => {
    return { ...state, user: null, isAuthenticated: false };
  })
  .handleAction(setUnauthenticatedUrl, (state, action) => {
    return {
      ...state,
      unauthenticatedUrl: action.payload,
    };
  })
  .handleAction(setSignInStep, (state, action) => {
    return {
      ...state,
      signInStep: action.payload,
      isLoading: false,
    };
  })
  .handleAction(closeWelcomePage, state =>
    produce(state, draft => {
      if (!!draft.user) {
        draft.user.hasAgreedToTermsAndConditionsOfMobileApp = false;
      }
      return draft;
    }),
  )
  .handleAction(signWithSmsSmsAsync.success, (state, action) => {
    return produce(state, draft => {
      if (!!action.payload.signInResult) {
        draft.user = mapSignInResultToAppUser(action.payload.signInResult);
        draft.tokenExpiration = parseExpiration(
          action.payload.signInResult.token!,
        );
        draft.isAuthenticated = true;
      }
      return draft;
    });
  })
  .handleAction(signWithBiometryAsync.success, (state, action) => {
    return produce(state, draft => {
      if (!!action.payload.signInResult) {
        draft.user = mapSignInResultToAppUser(action.payload.signInResult);
        draft.tokenExpiration = parseExpiration(
          action.payload.signInResult.token!,
        );
        draft.isAuthenticated = true;
      }
      return draft;
    });
  })
  .handleAction(signInAsync.success, (state, action) => {
    return produce(state, draft => {
      const { status, token, twoFactorTokenID } = action.payload;

      if (
        status === SignInStatus.PasswordResetRequired ||
        status === SignInStatus.AccountWaitingForEmailConfirmation
      ) {
        draft.error = null;
        draft.isLoading = false;
        return draft;
      }

      if (status === SignInStatus.TwoFactorVerificationTokenStep) {
        draft.error = null;
        draft.isLoading = false;
        draft.signInStep = SignInStep.TwoFactorVerificationToken;
        draft.twoFactor.tokenID = twoFactorTokenID!;
        return draft;
      }

      if (status === SignInStatus.Success && !!token) {
        draft.user = mapSignInResultToAppUser(action.payload);
        draft.tokenExpiration = parseExpiration(token);
        draft.error = null;
        draft.isLoading = false;
        draft.signInStep = SignInStep.Credentials;
        draft.twoFactor.tokenID = null;
        draft.isAuthenticated = true;
        return draft;
      }

      draft.isLoading = false;
      draft.error = new ApplicationError(
        action.payload.error,
        ErrorLevel.Error,
        action.payload.errorMessage,
      );
      return draft;
    });
  })
  .handleAction(signInAsync.request, state => {
    return { ...state, isLoading: true };
  })
  .handleAction(signInAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.error = action.payload;
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(signInBiometricsAsync.success, (state, action) => {
    return produce(state, draft => {
      if (action.payload.status === SignInStatus.Success) {
        draft.user = mapSignInResultToAppUser(action.payload);
        draft.tokenExpiration = parseExpiration(action.payload.token!);
        draft.error = null;
        draft.isLoading = false;
        draft.signInStep = SignInStep.Credentials;
        draft.twoFactor.tokenID = null;
        draft.isAuthenticated = true;
        return draft;
      }

      draft.isLoading = false;
      draft.error = new ApplicationError(
        action.payload.error,
        ErrorLevel.Error,
        action.payload.errorMessage,
      );
      return draft;
    });
  })
  .handleAction(signInBiometricsAsync.request, state => {
    return { ...state, isLoading: true };
  })
  .handleAction(signInBiometricsAsync.failure, (state, action) => {
    return {
      ...state,
      errorCode: action.payload.message,
      isLoading: false,
    };
  })
  .handleAction(signUpAsync.success, (state, action) => {
    return {
      ...state,
      isLoading: false,
      emailVerification: {
        email: action.payload.email,
        tokenID: action.payload.tokenID,
      },
    };
  })
  .handleAction(signUpAsync.request, state => {
    return { ...state, isLoading: true };
  })
  .handleAction(signUpAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.error = action.payload;
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(verifyEmailAsync.request, (state, _) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(verifyEmailAsync.success, (state, action) => {
    if (
      action.payload.status === EmailVerificationCommandResultStatus.Success &&
      !!action.payload.signInResult &&
      !!action.payload.signInResult.token
    ) {
      return {
        ...state,
        user: mapSignInResultToAppUser(action.payload.signInResult),
        tokenExpiration: parseExpiration(action.payload.signInResult.token),
        errorCode: null,
        isLoading: false,
        isAuthenticated: true,
      };
    }

    return {
      ...state,
      errorCode: "Error",
    };
  })
  .handleAction(verifyEmailAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.error = action.payload;

      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(resetPasswordAsync.request, state => {
    return produce(state, draft => {
      draft.forgottenPassword.error = null;
      draft.isLoading = true;
      return draft;
    });
  })
  .handleAction(resetPasswordAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.forgottenPassword.error = action.payload;
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(resetPasswordAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.resetPasswordStep = ResetPasswordStep.SetPassword;
      draft.forgottenPassword.email = action.payload.email;
      draft.forgottenPassword.tokenID = action.payload.result.tokenID!;
      return draft;
    });
  })
  .handleAction(setPasswordResetStep, (state, action) => {
    return {
      ...state,
      resetPasswordStep: action.payload,
    };
  })
  .handleAction(resetError, state =>
    produce(state, draft => {
      draft.error = null;
      draft.forgottenPassword.error = null;
      return draft;
    }),
  )
  .handleAction(changePasswordAsync.request, state =>
    produce(state, draft => {
      draft.error = null;
      draft.isLoading = true;
      return draft;
    }),
  )
  .handleAction(changePasswordAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.error = null;
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(changePasswordAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.error = action.payload;
      draft.isLoading = false;
    });
  })
  .handleAction(resendVerificationEmailAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.emailVerification.tokenID = action.payload.tokenID!;
      return draft;
    });
  })
  .handleAction(acceptTermsAndConditionsAsync.success, (state, action) => {
    return produce(state, draft => {
      if (!!draft.user) {
        draft.user.hasAgreedToTermsAndConditionsOfMobileApp = true;
      }
      return draft;
    });
  })
  .handleAction(
    updateClientNotificationStatusAsync.success,
    (state, action) => {
      return produce(state, draft => {
        if (!!draft.user) {
          draft.user.appData.isNotificationEnabled = action.payload;
        }
        return draft;
      });
    },
  )
  .handleAction(updateClientAnalyticsStatusAsync.success, (state, action) => {
    return produce(state, draft => {
      if (!!draft.user) {
        draft.user.appData.isAnalyticsEnabled = action.payload;
      }
      return draft;
    });
  })
  .handleAction(setPasswordAsync.request, state => {
    return produce(state, draft => {
      draft.isLoading = true;
      return draft;
    });
  })
  .handleAction(setPasswordAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.resetPasswordStep = ResetPasswordStep.Email;
      draft.forgottenPassword.email = null;
      draft.forgottenPassword.tokenID = null;
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(setPasswordAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.forgottenPassword.error = action.payload;
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(checkAndProlongTokenAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.tokenExpiration = parseExpiration(action.payload.token as string);
      return draft;
    });
  })
  .handleAction(setError, (state, action) => {
    return produce(state, draft => {
      draft.error = action.payload;
      return draft;
    });
  })
  .handleAction(revokeTokenAsync.request, (state, _) => {
    return produce(state, draft => draft);
  })
  .handleAction(revokeTokenAsync.success, (state, _) => {
    return produce(state, draft => draft);
  })
  .handleAction(revokeTokenAsync.failure, (state, _) => {
    return produce(state, draft => draft);
  });

const mapSignInResultToAppUser = (result: SignInResult) => {
  const user: AppUser = {
    login: result.login as string,
    accessRightCodes: result.accessRightCodes || [],
    hasAgreedToTermsAndConditionsOfMobileApp:
      result.hasAgreedToTermsAndConditionsOfMobileApp,
    isUnderage: result.isUnderage,
    profilePicture: result.profilePicture ?? null,
    appData: result.appData!,
    roles: result.roles,
  };
  return user;
};

function parseExpiration(token: string) {
  return fromUnixTime(
    JSON.parse(window.atob(token.split(".")[1])).exp,
  ).toISOString();
}
