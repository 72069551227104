import { ContractOverviewDto } from "Api/Api";
import { Chart, ChartDataProps } from "Components/Charts/Chart";
import { useAppSelector } from "Hooks/useAppSelector";
import { Resources, useResource } from "Translations/Resources";
import { useMemo, type FunctionComponent } from "react";

const PageResources = Resources.Contract.Detail.Overview;

type Props = {
  data?: ContractOverviewDto["graphData"];
  currency: string;
  isAlignedLeft?: boolean;
};

export const ProgressChart: FunctionComponent<Props> = ({
  data,
  currency,
  isAlignedLeft = false,
}) => {
  const { t } = useResource();
  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map(
      (x, i) =>
        ({
          deposit: x.deposit,
          expectedYieldMax: x.value,
          expectedYieldMin: x.value,
          period: i + 1,
        }) as ChartDataProps,
    );
  }, [data]);

  const { data: overview } = useAppSelector(
    state => state.investmentsDetail.overview,
  );

  return (
    <Chart
      data={chartData}
      barLabel={t(PageResources.ChartBarLabel)}
      lineLabel={t(PageResources.ChartLineLabel)}
      isYieldLineDashed={false}
      currency={currency}
      startDate={!!overview?.signDate ? new Date(overview.signDate) : undefined}
      endDate={new Date()}
      isAlignedLeft={isAlignedLeft}
    />
  );
};
