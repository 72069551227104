import { Grid, Typography } from "@mui/material";
import { AuthRedirectProgress } from "Components/Auth/Shared/AuthRedirectProgress";
import { StyledContainer } from "Components/Auth/Styles";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useModelingColor } from "Hooks/useModelingColor";
import { getBankIDProfileAsync } from "State/BankID/GetBankIDProfileState";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export const BankIDCallbackPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { color, contrastColor } = useModelingColor();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const bankIDError = searchParams.get("error");

  const queryString = location.hash.replace("#", "");
  const params = new URLSearchParams(queryString);
  const accessToken = params.get("access_token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Přesměrování";
  }, []);

  useEffect(() => {
    if (!!accessToken) {
      dispatch(
        getBankIDProfileAsync.request({
          token: accessToken,
          navigate,
          t,
        }),
      );
    }
  }, [dispatch, navigate, accessToken, t]);

  useEffect(() => {
    if (bankIDError === "access_denied") {
      navigate(getPath(AppRouting.ContractCreate));
    }
  }, [bankIDError, navigate]);

  const isError = !!bankIDError;

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <StyledContainer>
          {!isError && <AuthRedirectProgress />}
          {isError && (
            <div>
              <Typography variant="h3">Chyba</Typography>
              <Typography>{bankIDError}</Typography>
              <br />
            </div>
          )}
        </StyledContainer>
      </Grid>

      <Grid item marginBottom={20}>
        <PrimaryButton
          color="primary"
          onClick={() => navigate(getPath(AppRouting.Dashboard))}
          hexColor={contrastColor}
          hexBackgroundColor={color}
        >
          {t(Resources.Navigation.Dashboard)}
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};
