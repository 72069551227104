import { type ChipProps, Chip } from "@mui/material";
import { FunctionComponent } from "react";
import styled from "styled-components";

type Props = {
  label: string;
  active: boolean;
} & Pick<ChipProps, "onClick">;

const StyledChip = styled(Chip)<{ $active?: boolean }>`
  height: 27px;
  background-color: ${props => props.theme.palette.secondary.main}27;

  ${props =>
    props.$active
      ? `
        cursor: default;
        background-color: ${props.theme.palette.primary.main}27;
        font-weight: 700;
        color: ${props.theme.palette.primary.main};

        &:hover {
          background-color: ${props.theme.palette.primary.main}27;
        }
      `
      : `
        cursor: pointer;
        opacity: 0.5;
        &:hover {
          background-color: ${props.theme.palette.secondary.main}55;
        }
      `}

  .MuiChip-label {
    text-transform: uppercase;
    font-size: 0.75rem;
  }
`;

export const MenuChip: FunctionComponent<Props> = ({ active, ...props }) => {
  return <StyledChip role="menuitem" {...props} $active={active} />;
};
