import { logError } from "ErrorService";
import { ApiCallError } from "Models/Errors/ApiCallError";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { toast } from "react-toastify";
import { AnyAction } from "redux";
import { put, takeEvery } from "typed-redux-saga";
import { isNoU } from "Utils/ObjectUtils";
import { ValidationError } from "yup";

const isUserDialogEnabled =
  import.meta.env.VITE_SENTRY_IS_FEEDBACK_ENABLED === "true";

const BLACKLISTED_STATUSES = [
  200, 201, 202, 204, 205, 206, 401, 403, 404, 422, 499, 503,
];

function* handleFailure(action: AnyAction): Generator {
  if (action.type.endsWith("_FAILURE")) {
    const payload = action.payload;

    if (
      isError(payload) &&
      !isApiCallError(payload) &&
      !isValidationError(payload)
    ) {
      if (
        !isApplicationError(payload) &&
        !BLACKLISTED_STATUSES.includes(payload.cause)
      ) {
        toast.error(`V aplikaci nastala chyba.`);
        console.error(payload);
        logError(payload, null, isUserDialogEnabled);
      } else if (isCriticalApplicationError(payload)) {
        console.error(payload);

        if (isNoU(payload.hideToast) || !payload.hideToast) {
          toast.error(`V aplikaci nastala chyba.`);
        }

        logError(
          payload,
          {
            data: payload.data,
          },
          isUserDialogEnabled,
        );
      }
    }

    yield put({ type: "EMPTY" });
  }
}

const isError = (o: any) => o instanceof Error;

const isApplicationError = (o: any): o is ApplicationError =>
  o instanceof ApplicationError;

const isCriticalApplicationError = (o: any): o is ApplicationError => {
  const isApplicationError = o instanceof ApplicationError;

  if (!isApplicationError) {
    return false;
  }

  return o.level === ErrorLevel.Critical;
};

const isApiCallError = (o: any): o is ApiCallError<unknown> =>
  o instanceof ApiCallError;
const isValidationError = (o: any) => o instanceof ValidationError;

export function* errorSaga() {
  yield takeEvery("*", handleFailure);
}
