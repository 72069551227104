import * as React from "react";
export const PhotoIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.54688 2.5C9.04883 2.5 8.59375 2.76562 8.28125 3.23438L7.46875 4.5H3C1.62109 4.5 0.5 5.62109 0.5 7V19C0.5 20.3789 1.62109 21.5 3 21.5H22C23.3789 21.5 24.5 20.3789 24.5 19V7C24.5 5.62109 23.3789 4.5 22 4.5H17.4688L16.6719 3.21875C16.3652 2.75977 15.9043 2.5 15.4062 2.5H9.54688ZM5 7C5.55078 7 6 7.44922 6 8C6 8.55078 5.55078 9 5 9C4.44922 9 4 8.55078 4 8C4 7.44922 4.44922 7 5 7ZM12.5 7.5C15.5332 7.5 18 9.9668 18 13C18 16.0332 15.5332 18.5 12.5 18.5C9.4668 18.5 7 16.0332 7 13C7 9.9668 9.4668 7.5 12.5 7.5ZM12.5 8.5C10.0195 8.5 8 10.5195 8 13C8 15.4824 10.0195 17.5 12.5 17.5C14.9824 17.5 17 15.4824 17 13C17 10.5195 14.9824 8.5 12.5 8.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
