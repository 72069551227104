import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

export enum InvestmentDetailTabs {
  Overview = "overview",
  Transactions = "transactions",
  CashMovement = "cash-movement",
  Dealer = "dealer",
  UnsettledTrades = "unsettled-trades",
  Contract = "contract",
  Accounts = "accounts",
  PaymentInformation = "payment-information",
}

type MenuItem = {
  value: InvestmentDetailTabs;
  label: string;
};

const TabsResource = Resources.Contract.Detail.Tabs;

export const useMenuTabs = () => {
  const { t } = useResource();

  return useMemo<MenuItem[]>(
    () => [
      { value: InvestmentDetailTabs.Overview, label: t(TabsResource.Overview) },
      { value: InvestmentDetailTabs.Contract, label: t(TabsResource.Contract) },
      {
        value: InvestmentDetailTabs.PaymentInformation,
        label: t(TabsResource.PaymentInformation),
      },
      {
        value: InvestmentDetailTabs.Transactions,
        label: t(TabsResource.Transactions),
      },
      {
        value: InvestmentDetailTabs.CashMovement,
        label: t(TabsResource.CashMovement),
      },
      {
        value: InvestmentDetailTabs.UnsettledTrades,
        label: t(TabsResource.UnsettledMovements),
      },
      { value: InvestmentDetailTabs.Dealer, label: t(TabsResource.Dealer) },
      { value: InvestmentDetailTabs.Accounts, label: t(TabsResource.Accounts) },
    ],
    [t],
  );
};
