import { getBankIDProfileAsync } from "State/BankID/GetBankIDProfileState";
import { createReducer, ActionType } from "typesafe-actions";

export type BankIDState = {
  isLoading: boolean;
  // profile?: BankIDProfileResponse;
};

export type BankIDAction = ActionType<typeof getBankIDProfileAsync>;

export const bankIDReducer = createReducer<BankIDState, BankIDAction>({
  isLoading: false,
})
  .handleAction(getBankIDProfileAsync.request, state => {
    return { ...state, isLoading: true };
  })
  .handleAction(getBankIDProfileAsync.success, (state, action) => {
    return { ...state, isLoading: false };
  })
  .handleAction(getBankIDProfileAsync.failure, (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  });
