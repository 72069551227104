import {
  SetPasswordCommand,
  SetPasswordCommandResult,
  SetPasswordCommandResultStatus,
  postAuthPasswordSet,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { NavigateFunction } from "react-router";
import { safeApiCall } from "State/Utils";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { getPath, AppRouting } from "Utils/UrlUtils";

export const setPasswordAsync = createAsyncAction(
  "@auth/SET_PASSWORD_REQUEST",
  "@auth/SET_PASSWORD_SUCCESS",
  "@auth/SET_PASSWORD_FAILURE",
)<
  {
    model: SetPasswordCommand;
    navigate: NavigateFunction;
  },
  SetPasswordCommandResult,
  Error
>();

function* setPassword(
  action: ReturnType<typeof setPasswordAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postAuthPasswordSet,
      action.payload.model,
    );

    if (error) {
      yield put(setPasswordAsync.failure(error));
      return;
    }

    if (response.status === SetPasswordCommandResultStatus.Success) {
      yield put(setPasswordAsync.success(response));
      yield* call(() => action.payload.navigate(getPath(AppRouting.SignIn)));
    } else {
      yield put(
        setPasswordAsync.failure(new ApplicationError(response.status)),
      );
    }
  } catch (err) {
    yield put(setPasswordAsync.failure(err as Error));
  }
}
export function* setPasswordSaga() {
  yield takeLatest(getType(setPasswordAsync.request), setPassword);
}
