import { SignatureForm } from "Components/ContractBankConnections/Shared/SignatureForm";
import { useInvestUninvestedDepositsTitle } from "Hooks/Contract/useInvestUninvestedDepositsTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import {
  setInvestUninvestedDepositsStep,
  InvestUninvestedDepositsStep,
} from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";

import { Resources, useResource } from "Translations/Resources";
import { getProductColor } from "Utils/Products";

type Props = { contractID: number };

const ComponentResources = Resources.Contract.Detail.Purchase;

export const SignatureStep: React.FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();

  const { t } = useResource();

  const {
    isLoading: isLoadingUninvestedDeposits,
    result,
    lastRequest,
  } = useAppSelector(s => s.contracts.uninvestedDeposits);

  const { color, contrastColor } = getProductColor(lastRequest?.isin);

  const signatureHash = result?.signatureHash;
  const pageTitle = useInvestUninvestedDepositsTitle();

  return (
    <SignatureForm
      isLoading={isLoadingUninvestedDeposits}
      contractID={null}
      signatureHash={signatureHash}
      productColor={color}
      productContrastColor={contrastColor}
      sms={{
        phone: (result?.type === "SMS" ? result.phone : null) ?? "",
      }}
      biometrics={{
        signatureType: BiometricsSignatureType.UninvestedDeposits,
      }}
      translations={{
        isLoading: t(ComponentResources.Target.Confirmation.WorkingText),
        sms: {
          title: pageTitle,
          codeSent: t(Resources.Signature.SmsSent),
          codeDetermination: t(
            ComponentResources.Target.Confirmation.CodeDetermination,
          ),
          verificationError: t(
            ComponentResources.Target.Confirmation.VerificationError,
          ),
          button: t(ComponentResources.Target.Confirmation.Submit),
        },
        biometrics: {
          title: t(ComponentResources.Target.Confirmation.Biometrics),
          signatureError: t(
            ComponentResources.Target.Confirmation.BiometricsError,
          ),
          signatureInProcessError: t(
            ComponentResources.Target.Confirmation.BiometricsInProcessError,
          ),
        },
        signatureHashNotFoundError: t(
          ComponentResources.Target.Confirmation.SignatureHashNotFoundError,
        ),
      }}
      onSuccess={() => {
        dispatch(
          setInvestUninvestedDepositsStep(InvestUninvestedDepositsStep.Success),
        );
      }}
    />
  );
};
