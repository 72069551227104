import { Typography } from "@mui/material";
import { FunctionComponent } from "react";
import styled from "styled-components";

type Props = {
  label: string;
  value: string;
};

const StyledLabel = styled(Typography)`
  opacity: 0.7;
`;

export const LabelValue: FunctionComponent<Props> = ({ label, value }) => {
  return (
    <div>
      <StyledLabel>{label}</StyledLabel>
      <Typography variant="body1" fontWeight={700}>
        {value}
      </Typography>
    </div>
  );
};
