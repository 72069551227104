import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { DeleteAccount } from "Components/Profile/DeleteAccount/DeleteAccount";
import { useDesktop } from "Hooks/useDesktop";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useNavigate } from "react-router";

const PageResources = Resources.Profile.Settings.DeleteAccount;

export const DeleteAccountPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const navigate = useNavigate();

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(-1)}
    >
      <DeleteAccount pageTitle={t(PageResources.Title)} />
    </AuthenticatedLayout>
  );
};
