import { format, parse } from "date-fns";

export const DATE_FORMAT = "yyyy-MM-dd";

export const formatDocumentsDateToDate = (
  date: string
) => {
  return parse(date, DATE_FORMAT, new Date());
};

export const parseDateToDocumentsDate = (date: Date) => {
  return format(date, DATE_FORMAT);
};
