import * as React from "react";
export const EditIcon = (
  props: {
    size?: number;
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.204 10.7957L19 8.9997C19.5453 8.45445 19.8179 8.18182 19.9636 7.88773C20.2409 7.32818 20.2409 6.67122 19.9636 6.11167C19.8179 5.81757 19.5453 5.54495 19 4.9997C18.4548 4.45445 18.1821 4.18182 17.888 4.03609C17.3285 3.7588 16.6715 3.7588 16.112 4.03609C15.8179 4.18182 15.5453 4.45445 15 4.9997L13.1814 6.81835C14.1452 8.46895 15.5314 9.84451 17.204 10.7957ZM11.7269 8.27281L4.8564 15.1433C4.43134 15.5684 4.21881 15.7809 4.07907 16.042C3.93934 16.3031 3.88039 16.5978 3.7625 17.1873L3.1471 20.2643C3.08058 20.5969 3.04732 20.7632 3.14193 20.8578C3.23654 20.9524 3.40284 20.9191 3.73545 20.8526L6.81243 20.2372C7.40189 20.1193 7.69661 20.0604 7.95771 19.9206C8.21881 19.7809 8.43134 19.5684 8.8564 19.1433L15.7458 12.2539C14.1241 11.2383 12.7524 9.87597 11.7269 8.27281Z"
        fill={props.color}
      />
    </svg>
  );
};
