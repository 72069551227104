import {
  FormModel,
  RedemptionConfigurationStepForm,
} from "Components/ContractRedemption/RedemptionConfigurationStepForm";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { redeemAsync } from "State/Contracts/Redemption/Redeem/RedeemState";
import { getBankAccountsAsync } from "State/InvestmentsDetail/BankAccounts/BankAccountsState";
import { useEffect, type FunctionComponent } from "react";

type Props = { contractID: number; isin: string };

export const RedemptionConfigurationStep: FunctionComponent<Props> = props => {
  const { contractID } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBankAccountsAsync.request(contractID));
  }, [contractID, dispatch]);

  const { data: overview } = useAppSelector(s => s.investmentsDetail.overview);

  const portfolioItem = overview?.portfolioItems.find(
    s => s.isin === props.isin,
  );

  const { data: bankAccounts } = useAppSelector(
    s => s.investmentsDetail.bankAccounts,
  );

  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();

  const submit = (data: FormModel) => {
    dispatch(
      redeemAsync.request({
        amount: data.amount,
        contractID: contractID,
        isin: props.isin,
        bankAccountID: data.bankAccountID!,
        isBiometry: canUseBiometricsForSigning,
        dipCancelAgree: data.dipCancelAgree,
        method: data.method,
        type: data.type,
      }),
    );
  };

  const isLoading = !portfolioItem || !bankAccounts;

  const { isLoading: isLoadingRedemption } = useAppSelector(
    x => x.contracts.redemption,
  );

  return (
    <>
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <RedemptionConfigurationStepForm
          portfolioItem={portfolioItem}
          bankAccounts={bankAccounts}
          onSubmit={submit}
          isLoading={isLoadingRedemption}
          isDip={overview?.isDip ?? false}
        />
      )}
    </>
  );
};

const LoadingSkeleton = () => (
  <div>
    <PrimarySkeleton height={150} fullHeight={false} marginBottom={40} />
    <PrimarySkeleton height={100} fullHeight={false} marginBottom={20} />
    <PrimarySkeleton height={100} fullHeight={false} marginBottom={20} />
    <PrimarySkeleton height={100} fullHeight={false} marginBottom={20} />
    <PrimarySkeleton height={100} fullHeight={false} marginBottom={20} />
  </div>
);
