import {
  GetExchangeRateStateType,
  getExchangeRateReducer,
  getExchangeRateSaga,
} from "State/ExchangeRate/Api/ExchangeRateState";
import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type ExchangeRatesState = {
  exchangeRate: GetExchangeRateStateType;
};

export function* watchExchangeRatesSaga() {
  yield all([getExchangeRateSaga()]);
}

export const exchangeRatesReducer = combineReducers<ExchangeRatesState>({
  exchangeRate: getExchangeRateReducer,
});
