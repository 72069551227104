import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { Control, FieldErrors, FieldPath, FieldValues } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { type Props as InputProps } from "Components/Shared/Inputs/Form/BlInputFormField";
import { IMask } from "react-imask";

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  label?: string;
  control: Control<FormType, object>;
  errors: FieldErrors<FormType>;
  readonly?: boolean | undefined;
} & Omit<
  InputProps<FormType>,
  "mask" | "allowEmptyNumber" | "isMaskLazy" | "inputStartAdornment" | "label"
>;

const FormResource = Resources.Forms.PersonalData;

export const PhoneInput = <T extends object>({
  control,
  errors,
  name,
  readonly,
  ...props
}: Props<T>) => {
  const { t } = useResource();

  return (
    // TODO: We need to extend IMask to support our custom startsWith
    // @ts-ignore
    <BlInputFormField
      control={control}
      name={name}
      errors={errors}
      label={t(FormResource.Phone)}
      defaultValue="420"
      // TODO: We need to extend IMask to support our custom startsWith
      // @ts-ignore
      mask={[
        {
          mask: "+000 000 000 000",
          startsWith: "420",
          lazy: false,
        },
        {
          mask: "+000 000 000 000",
          startsWith: "421",
          lazy: false,
        },
        {
          mask: readonly ? "num" : "+num",
          lazy: false,
          startsWith: "",
          blocks: {
            num: {
              mask: Number,
              thousandsSeparator: readonly ? " " : "",
            },
          },
        },
      ]}
      allowEmptyNumber
      isMaskLazy={false}
      dispatch={(appended, dynamicMasked) => {
        const number = (dynamicMasked.value + appended).replace(/\D/g, "");

        return dynamicMasked.compiledMasks.find(
          // TODO: We need to extend IMask to support our custom startsWith
          // @ts-ignore
          m => number.indexOf(m?.startsWith) === 0,
        ) as IMask.AnyMasked;
      }}
      valueTransformer={value => {
        if (readonly || !value) {
          return value;
        }

        return `+${value}`;
      }}
      inputProps={{
        inputMode: "tel",
      }}
      {...props}
    />
  );
};
