import * as React from "react";
export const PlusSlimIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 0.5V8H0.5V9H8V16.5H9V9H16.5V8H9V0.5H8Z" fill="currentColor" />
    </svg>
  );
};
