import {
  BusinessSectorListQueryResult,
  getCodeListBusinessSector,
} from "Api/Api";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";

export type GetBusinessSectorStateType =
  FetchStateType<BusinessSectorListQueryResult>;

export const getBusinessSectorState = (): GetBusinessSectorStateType =>
  getFetchStateDefaultValue();

export type GetBusinessSectorActionType = ActionType<
  typeof getBusinessSectorAsync
>;

export const getBusinessSectorAsync = createAsyncAction(
  "@codeList/GET_BUSINESS_SECTOR_REQUEST",
  "@codeList/GET_BUSINESS_SECTOR_SUCCESS",
  "@codeList/GET_BUSINESS_SECTOR_FAILURE",
)<void, BusinessSectorListQueryResult, Error>();

function* getBusinessSector(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getCodeListBusinessSector);

    if (error) {
      yield put(getBusinessSectorAsync.failure(error));
      return;
    }

    yield put(getBusinessSectorAsync.success(response));
  } catch (err) {
    yield put(getBusinessSectorAsync.failure(err as Error));
  }
}

export function* getBusinessSectorSaga() {
  yield takeLatest(getType(getBusinessSectorAsync.request), getBusinessSector);
}

export const getBusinessSectorReducer = createReducer<
  GetBusinessSectorStateType,
  GetBusinessSectorActionType
>(getBusinessSectorState())
  .handleAction(getBusinessSectorAsync.request, handleActionRequest)
  .handleAction(getBusinessSectorAsync.failure, handleActionFailure)
  .handleAction(getBusinessSectorAsync.success, handleActionSuccess);
