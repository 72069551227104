import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { ProductsIsinDetail } from "Constants/Products";
import { ProductTradingFrequencyCode } from "Models/Products";
import { isKnownIsin } from "Utils/Products";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";

const ComponentResources =
  Resources.Contract.Detail.Overview.UninvestedDepositsTooltip;

type Props = {
  isin: string;
};

export const UninvestedDepositTooltip: FunctionComponent<Props> = ({
  isin,
}) => {
  const { t } = useResource();

  function getLocalizedTooltip(isin: string): string {
    if (isKnownIsin(isin)) {
      const productDetail = ProductsIsinDetail[isin];

      if (productDetail) {
        switch (productDetail.tradingFrequency) {
          case ProductTradingFrequencyCode.DAILY:
            return t(ComponentResources.DailyTradeFrequency);
          case ProductTradingFrequencyCode.MONTHLY:
            return t(ComponentResources.MonthlyTradeFrequency);
          default:
            return t(ComponentResources.UnknownIsin);
        }
      }
    }

    return t(ComponentResources.UnknownIsin);
  }

  return (
    <BlInfoPopper color="secondary" icon="info">
      {getLocalizedTooltip(isin)}
    </BlInfoPopper>
  );
};
