import { ContactInformation } from "Components/More/Contacts/shared/ContactInformation";
import { PageTitle } from "Components/Shared/PageTitle";
import { Resources, useResource } from "Translations/Resources";
import {
  CONTACT_EMAIL,
  MAINTAINCE_SERVICE_PHONE,
} from "Utils/ExternalLinkUtils";
import { FunctionComponent } from "react";

const ComponentResources = Resources.More.Contacts;

type Props = {
  pageTitle: string;
};

export const Contacts: FunctionComponent<Props> = ({ pageTitle }) => {
  const { t } = useResource();

  return (
    <div>
      <PageTitle showOnMobile={false}>{pageTitle}</PageTitle>
      <ContactInformation
        title={t(ComponentResources.Title)}
        phone={MAINTAINCE_SERVICE_PHONE}
        email={CONTACT_EMAIL}
      />
    </div>
  );
};
