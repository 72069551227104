import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { parseSentryUserFromJWT } from "Utils/TrackingUtils";
import { VITE_APPLICATION_VERSION, VITE_BUILD_NUMBER } from "Utils/BuildUtils";

export const SENTRY_CONFIG = {
  IS_ENABLED: import.meta.env.VITE_SENTRY_IS_ENABLED === "true",
  IS_FEEDBACK_ENABLED:
    import.meta.env.VITE_SENTRY_IS_FEEDBACK_ENABLED === "true",
  DSN: import.meta.env.VITE_SENTRY_DSN as string,
  ORG: import.meta.env.VITE_SENTRY_ORG as string,
  PROJECT: import.meta.env.VITE_SENTRY_PROJECT as string,
  MASK_ALL_TEXT: import.meta.env.VITE_SENTRY_MASK_ALL_TEXT === "true",
  MASK_ALL_INPUTS: import.meta.env.VITE_SENTRY_MASK_ALL_INPUTS === "true",
};

export function initSentry() {
  if (SENTRY_CONFIG.IS_ENABLED) {
    Sentry.init({
      dsn: SENTRY_CONFIG.DSN,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        new Sentry.Replay({
          maskAllText: SENTRY_CONFIG.MASK_ALL_TEXT,
          maskAllInputs: SENTRY_CONFIG.MASK_ALL_INPUTS,
          networkDetailAllowUrls: [/investika/, "localhost"],
          networkDetailDenyUrls: [
            /api\/tracking/,
            /api\/auth\/token\/prolong/,
            /api\/health\/status/,
          ],
        }),
        new Sentry.HttpContext(),
        new Sentry.Dedupe(),
      ],
      environment: import.meta.env.VITE_VERSION as string,
      tracePropagationTargets: [/investika/, "localhost"],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1,
      tracesSampleRate: 0.01,
      normalizeDepth: 8,
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (
          SENTRY_CONFIG.IS_FEEDBACK_ENABLED &&
          event.exception &&
          event.extra?.doNotShowDialog !== true
        ) {
          Sentry.showReportDialog({ eventId: event.event_id, lang: "cs" });
        }
        return event;
      },
      initialScope: scope => {
        const user = parseSentryUserFromJWT();
        if (user) {
          scope.setUser(user);
          scope.setTag("user.login", user.username);
        }

        scope.setTag("app.version", VITE_APPLICATION_VERSION);
        scope.setTag("app.build", VITE_BUILD_NUMBER);

        return scope;
      },
      autoSessionTracking: false,
    });
  }
}
