import { GetMobAppSettingsQueryResult, getSettings } from "Api/Api";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { takeLeading } from "typed-redux-saga";

export type GetAppSettingsStateType =
  FetchStateType<GetMobAppSettingsQueryResult>;

export const getAppSettingsState = (): GetAppSettingsStateType =>
  getFetchStateDefaultValue();

export const getAppSettingsAsync = createAsyncAction(
  "@settings/GET_APP_SETTINGS_REQUEST",
  "@settings/GET_APP_SETTINGS_SUCCESS",
  "@settings/GET_APP_SETTINGS_FAILURE",
)<void, GetMobAppSettingsQueryResult, Error>();

export const clearAppSettings = createAction(
  "@settings/CLEAR_GET_APP_SETTINGS",
)();

export type getAppSettingsActionType =
  | ActionType<typeof getAppSettingsAsync>
  | ActionType<typeof clearAppSettings>;

function* getAppSettings(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getSettings);

    if (error) {
      yield put(getAppSettingsAsync.failure(error));
      return;
    }

    yield put(getAppSettingsAsync.success(response));
  } catch (err) {
    yield put(getAppSettingsAsync.failure(err as Error));
  }
}

export function* getAppSettingsSaga() {
  yield takeLeading(getType(getAppSettingsAsync.request), getAppSettings);
}

export const getAppSettingsReducer = createReducer<
  GetAppSettingsStateType,
  getAppSettingsActionType
>(getAppSettingsState())
  .handleAction(getAppSettingsAsync.request, handleActionRequest)
  .handleAction(getAppSettingsAsync.failure, handleActionFailure)
  .handleAction(getAppSettingsAsync.success, handleActionSuccess)
  .handleAction(clearAppSettings, getAppSettingsState);
