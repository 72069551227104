import { ProfileChangePasswordForm } from "Components/Profile/ChangePassword/ProfileChangePasswordForm";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { resetError } from "State/Auth/AuthReducer";
import { changePasswordAsync } from "State/Auth/Passwords/ChangePasswordState";
import { useEffect, type FunctionComponent, useState } from "react";
import { useNavigate } from "react-router";

export type FormModel = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const ProfileChangePassword: FunctionComponent = _ => {
  const navigate = useNavigate();

  const { error, isLoading } = useAppSelector(e => e.auth);

  const dispatch = useAppDispatch();

  const onSubmit = (data: FormModel) => {
    dispatch(resetError());
    dispatch(
      changePasswordAsync.request({
        model: {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
        onSuccess: () => setIsModalVisible(true),
      }),
    );
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, [dispatch]);

  return (
    <>
      <ProfileChangePasswordForm
        onSubmit={onSubmit}
        serverError={error}
        isLoading={isLoading}
        isModalVisible={isModalVisible}
        onModalClose={() => {
          setIsModalVisible(false);
          navigate(-1);
        }}
      />
    </>
  );
};
