import * as React from "react";
export const DocumentsIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={19}
      height={23}
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 0V23H18.5V6.29688L18.3594 6.14062L12.3594 0.140625L12.2031 0H0.5ZM1.5 1H11.5V7H17.5V22H1.5V1ZM12.5 1.71875L16.7812 6H12.5V1.71875ZM4.5 10V11H14.5V10H4.5ZM4.5 13V14H12.5V13H4.5ZM4.5 16V17H14.5V16H4.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
