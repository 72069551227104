import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { postMessageFromWebView } from "Utils/WebViewUtils";
import {
  SignInBiometricsChallengeCommandResult,
  postAuthSignInBiometricsChallenge,
} from "Api/Api";
import { safeApiCall } from "State/Utils";
import { WebViewMessageTypes } from "Models/WebViewModels";
import { logError } from "ErrorService";

export const getBiometricSignInChallengeAsync = createAsyncAction(
  "@auth/GET_BIOMETRICS_SIGN_IN_CHALLENGE_REQUEST",
  "@auth/GET_BIOMETRICS_SIGN_IN_CHALLENGE_SUCCESS",
  "@auth/GET_BIOMETRICS_SIGN_IN_CHALLENGE_FAILURE",
)<
  {
    login: string;
  },
  SignInBiometricsChallengeCommandResult,
  Error
>();

function* getBiometricSignInChallenge(
  action: ReturnType<typeof getBiometricSignInChallengeAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postAuthSignInBiometricsChallenge,
      action.payload,
    );

    if (error) {
      yield put(getBiometricSignInChallengeAsync.failure(error));
      console.error("biometricSignInGetAuthenticationState error", error);
      logError(error, { payload: action.payload }, false);
      return;
    }

    if (!!response.challenge) {
      postMessageFromWebView({
        type: WebViewMessageTypes.BIOMETRIC_SIGN_IN_REQUEST,
        payload: {
          challenge: response.challenge,
        },
      });
    }

    yield put(getBiometricSignInChallengeAsync.success(response));
  } catch (err) {
    yield put(getBiometricSignInChallengeAsync.failure(err as Error));
  }
}
export function* getBiometricSignInChallengeSaga() {
  yield takeLatest(
    getType(getBiometricSignInChallengeAsync.request),
    getBiometricSignInChallenge,
  );
}
