import { type FunctionComponent, useEffect } from "react";

import { useAppSelector } from "Hooks/useAppSelector";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { getProductsAsync } from "State/Products/Products/GetProductsState";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import {
  FormModel,
  PurchaseForm,
} from "Components/ContractPurchase/Purchase/PurchaseForm";
import { Box } from "@mui/material";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { InvestmentTypes } from "Pages/Contracts/ModelingPage";
import { CurrencyCode } from "Api/Api";
import { useIsSelfNegotiatedContract } from "Hooks/Contract/Detail/useIsSelfNegotiatedContract";
import { useProductCardItems } from "Hooks/Products/useProductCardItems";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  pageTitle: string;
  contractID: number;
  investmentType: InvestmentTypes;
  defaultIsin: string | null;
  decimalPlaces?: number;
  onSubmit: (data: { currency: CurrencyCode } & FormModel) => void;
  onSelectedProductChange?: (isin: string | null) => void;
};

export const PurchaseFormDataWrapper: FunctionComponent<Props> = ({
  pageTitle,
  contractID,
  investmentType,
  defaultIsin,
  decimalPlaces = 0,
  onSubmit,
  onSelectedProductChange,
}) => {
  const { t } = useResource();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOverviewAsync.request(contractID));
  }, [contractID, dispatch]);

  const { data: overview } = useAppSelector(s => s.investmentsDetail.overview);

  const contractTypeCode = overview?.type;
  const isSelfNegotiatedContract =
    useIsSelfNegotiatedContract(contractTypeCode) ?? false;

  useEffect(() => {
    if (isSelfNegotiatedContract && contractTypeCode) {
      dispatch(
        getProductsAsync.request({
          contractTypeCode,
          isDip: overview?.isDip,
        }),
      );
    }
  }, [dispatch, contractTypeCode, isSelfNegotiatedContract, overview?.isDip]);

  const submit = (data: FormModel) => {
    if (overview) {
      onSubmit({ ...data, currency: overview.currency });
    }
  };

  const currency = overview?.currency;
  const isLoadingTarget = investmentType === "Target" && !overview;

  const { products, isLoading: isLoadingProducts } =
    useProductCardItems(contractID);

  const isLoading =
    isLoadingProducts ||
    !currency ||
    !overview ||
    !products ||
    isLoadingTarget ||
    !contractTypeCode;

  const isPeriodicalInvestmentVisible =
    investmentType === "Unlimited" && isSelfNegotiatedContract;

  const showProductsNotFoundMessage =
    !isSelfNegotiatedContract && !products?.length;

  return (
    <>
      {!isLoading && currency && overview && products && contractTypeCode && (
        <PurchaseForm
          key={isPeriodicalInvestmentVisible.toString()}
          pageTitle={pageTitle}
          currency={currency}
          products={products}
          decimalPlaces={decimalPlaces}
          onSubmit={submit}
          maxPeriodicalInvestment={
            investmentType === "Target"
              ? overview?.uninvestedDeposits ?? undefined
              : undefined
          }
          isPeriodicalInvestmentVisible={isPeriodicalInvestmentVisible}
          investmentType={investmentType}
          contractTypeCode={contractTypeCode}
          contractID={contractID}
          defaultISIN={defaultIsin}
          productsNotFoundMessage={t(
            Resources.Contract.Detail.Purchase.ProductsNotFound,
          )}
          showProductsNotFoundMessage={showProductsNotFoundMessage}
          isDip={overview?.isDip ?? false}
          onSelectedProductChange={onSelectedProductChange}
        />
      )}

      {isLoading && <LoadingSkeleton />}
    </>
  );
};

const LoadingSkeleton = () => (
  <Box>
    <PrimarySkeleton variant="rectangular" height={25} fullHeight={false} />
    <br />
    <br />
    <PrimarySkeleton variant="rectangular" height={25} fullHeight={false} />
    <br />
    <PrimarySkeleton variant="rectangular" height={70} fullHeight={false} />
    <br />
    <PrimarySkeleton variant="rectangular" height={70} fullHeight={false} />

    <br />
    <br />
    <br />
    <PrimarySkeleton variant="rectangular" height={25} fullHeight={false} />
    <br />
    <PrimarySkeleton variant="rectangular" height={120} fullHeight={false} />
    <br />
    <PrimarySkeleton variant="rectangular" height={120} fullHeight={false} />
    <br />
    <PrimarySkeleton variant="rectangular" height={75} fullHeight={false} />
  </Box>
);
