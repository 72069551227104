import MuiChip, { type ChipProps } from "@mui/material/Chip";
import { type FunctionComponent } from "react";
import styled from "styled-components";

type Props = {
  $active?: boolean;
  $passive?: boolean;
  $iconSize?: number;
  $activeBackgroundOpacity?: number;
  $activeIconSize?: number;
  $paddingX?: number;
} & ChipProps;

const StyledChip = styled(MuiChip)<Props>`
  font-size: ${({ theme }) => theme.typography.body1.fontSize}px;
  border-radius: 100px;
  background-color: ${({ theme, $active, $activeBackgroundOpacity }) =>
    $active && $activeBackgroundOpacity
      ? `${theme.palette.secondary.main}${$activeBackgroundOpacity}`
      : `${theme.palette.secondary.main}19`};

  ${({ $active, theme }) =>
    $active &&
    `
      color: ${theme.palette.primary.main};
      font-weight: 600;
    `}

  ${({ $passive }) =>
    $passive &&
    `
      cursor: default;
    `}

  ${({ $paddingX, theme }) =>
    $paddingX &&
    `
      padding: ${theme.spacing(0, $paddingX)};
    `}

  &:hover {
    background-color: ${({ theme, $passive }) =>
      !$passive && theme.palette.secondary.main}40;
  }

  & .MuiChip-icon {
    margin-left: 10px;
  }

  ${({ $active, $iconSize, $activeIconSize }) =>
    $iconSize &&
    `
      & .MuiChip-icon {
        width: ${$active && $activeIconSize ? $activeIconSize : $iconSize}px;
        height: ${$active && $activeIconSize ? $activeIconSize : $iconSize}px;
      }
    `}
`;

export const Chip: FunctionComponent<Props> = props => {
  return <StyledChip {...props} />;
};
