import { Typography } from "@mui/material";
import { TrackedButton } from "Components/Shared/Buttons/TrackedButton";
import { PlusBoldIcon } from "Components/Shared/Icons";
import { LAYOUT_PADDING_X } from "Constants/Layout";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

const StyledButton = styled(TrackedButton)<{
  $hexColor?: string | null | undefined;
}>`
  min-width: unset;
  padding: ${({ theme }) => theme.spacing(1, 4, 1, 2.5)};
  margin: ${props => props.theme.spacing(2, LAYOUT_PADDING_X)};
  border-radius: 100px;
  font-weight: 600;
  text-transform: none;

  background-color: ${({ theme }) => theme.palette.background.default};
`;

type Props = {
  contractID: number;
};

const ComponentResources = Resources.Contract.Detail.BankAccounts;

export const CreateBankConnectionButton: FunctionComponent<Props> = ({
  contractID,
}) => {
  const { t } = useResource();
  const navigate = useNavigate();

  return (
    <StyledButton
      variant="outlined"
      color="primary"
      onClick={() =>
        navigate(getPath(AppRouting.ContractCreateBankConnection, contractID))
      }
      trackingTag="ContractCreateBankConnection"
    >
      <PlusBoldIcon />
      <Typography component="span">
        {t(ComponentResources.CreateBankConnectionButton)}
      </Typography>
    </StyledButton>
  );
};
