import { LookupItem } from "Models/LookupItem";
import { useMemo } from "react";

import {
  BeachIcon,
  CarIcon,
  GraduationCapIcon,
  GrandfatherIcon,
  OtherIcon,
  StructuralIcon,
} from "Components/Shared/Icons";
import { Resources, useResource } from "Translations/Resources";
import { ContractCategories } from "Models/Contracts";
import { ResourceDictionary } from "Translations/ResourceDictionary";

type ListItem = LookupItem<ContractCategories> & { icon: JSX.Element };

export const ContractCategoriesIcons: Record<ContractCategories, JSX.Element> =
  {
    [ContractCategories.Study]: <GraduationCapIcon color="inherit" />,
    [ContractCategories.Car]: <CarIcon color="inherit" />,
    [ContractCategories.Vacation]: <BeachIcon color="inherit" />,
    [ContractCategories.Housing]: <StructuralIcon color="inherit" />,
    [ContractCategories.Retirement]: <GrandfatherIcon color="inherit" />,
    [ContractCategories.Other]: <OtherIcon color="inherit" />,
  };

const Categories: {
  label: keyof ResourceDictionary["CodeLists"]["ContractCategories"];
  value: ListItem["Value"];
}[] = [
  {
    label: "Studium",
    value: ContractCategories.Study,
  },
  {
    label: "Car",
    value: ContractCategories.Car,
  },
  {
    label: "Vacation",
    value: ContractCategories.Vacation,
  },
  {
    label: "Housing",
    value: ContractCategories.Housing,
  },
  {
    label: "Retirement",
    value: ContractCategories.Retirement,
  },
  {
    label: "Other",
    value: ContractCategories.Other,
  },
];

export const useContractCategories = () => {
  const { t } = useResource();

  return useMemo<ListItem[]>(
    () =>
      Categories?.map<ListItem>(({ label, value }) => ({
        Name: t(Resources.CodeLists.ContractCategories[label]),
        Value: value,
        icon: ContractCategoriesIcons[value],
      })),
    [t],
  );
};
