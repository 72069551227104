import { DATE_INPUT_FORMAT } from "Constants/Inputs";
import { format, isValid } from "date-fns";

export const useInputDateFormatter = (
  date: Date | string | null | undefined,
) => {
  if (!date) {
    return "";
  }

  const dateObject = new Date(date);

  if (isValid(dateObject)) {
    return format(dateObject, DATE_INPUT_FORMAT);
  }
};
