export enum ErrorLevel {
  Error = "Error",
  Critical = "Critical",
}

/**
 * ApplicationError
 * Use this error to indicate that something went wrong in the application logic.
 * Do not use it for errors caused by code
 *
 */
export class ApplicationError extends Error {
  constructor(
    message: string,
    level: ErrorLevel = ErrorLevel.Error,
    data: any = null,
    validationErrors?: string | null | undefined,
    hideToast?: boolean | null | undefined,
  ) {
    super(message);
    this.name = "ApplicationError";
    this.validationErrors = validationErrors;
    this.level = level;
    this.data = data;
    this.hideToast = hideToast;
  }

  hideToast?: boolean | null | undefined;
  validationErrors?: string | null | undefined;
  level: ErrorLevel = ErrorLevel.Error;
  data?: any;
}
