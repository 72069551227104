import { Typography } from "@mui/material";
import { PageTitle } from "Components/ContractCreate/Shared/PageTitle";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useModelingColor } from "Hooks/useModelingColor";
import { setIdentityNumberVerified } from "State/Contracts/Create/CreateState";
import { NewContractStep } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useForm } from "react-hook-form";

const ComponentResources = Resources.Contract.NewContract.PersonalInformation;

type FormModel = {
  personalIdentificationNumber: string;
};

type Props = {
  validateExistence(): void;
};

export const PersonalIdentificatonNumberVerification: FunctionComponent<
  Props
> = ({ validateExistence }) => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const { color, contrastColor } = useModelingColor();
  const personalData = useAppSelector(
    s => s.contracts.create.formData[NewContractStep.PersonalData],
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<FormModel>();

  const onSubmit = (data: FormModel) => {
    if (
      personalData?.personalIdentificationNumber &&
      personalData?.personalIdentificationNumber ===
        data.personalIdentificationNumber
    ) {
      dispatch(setIdentityNumberVerified(true));
      validateExistence();
    } else {
      setError("personalIdentificationNumber", {
        message: t(ComponentResources.PersonalIdentificationNumberMismatch),
      });
    }
  };

  return (
    <>
      <div>
        <PageTitle>
          {t(ComponentResources.PersonalIdentificationNumberVerification.Title)}
        </PageTitle>
        <Typography marginBottom={3}>
          {t(ComponentResources.PersonalIdentificationNumberVerification.Label)}
        </Typography>
        <BlInputFormField
          control={control}
          errors={errors}
          name="personalIdentificationNumber"
          isMaskLazy={false}
          label={t(ComponentResources.PersonalIdentificationNumber)}
          mask={/^.+$/}
        />
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
