import * as React from "react";
export const DashboardIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4922 0.99317C12.4637 0.993672 12.4352 0.996612 12.4072 1.00196C6.10898 1.05224 1 6.18962 1 12.5C1 18.8415 6.159 24 12.5 24C18.841 24 24 18.8415 24 12.5C24 9.41051 22.7732 6.60464 20.7842 4.53614C20.7837 4.53564 20.7837 4.53469 20.7832 4.53419L20.7822 4.53321C20.2556 3.98596 19.6748 3.49086 19.0498 3.05567C18.9932 2.99294 18.9217 2.94556 18.8418 2.91798C17.0469 1.7249 14.9016 1.02062 12.5938 1.00196C12.5931 1.00195 12.5925 1.00196 12.5918 1.00196C12.559 0.995709 12.5256 0.992765 12.4922 0.99317ZM12 2.0127V8.0293C9.75379 8.27922 8 10.1878 8 12.5C8 14.9815 10.019 17 12.5 17C13.561 17 14.5357 16.6292 15.3057 16.0127L19.5508 20.2578C17.6853 21.9548 15.215 23 12.5 23C6.71 23 2 18.2895 2 12.5C2 6.87832 6.44158 2.2749 12 2.0127ZM13 2.0127C13.1093 2.01789 13.2178 2.02662 13.3262 2.03516L13 2.36133V2.0127ZM14.5674 2.20801C14.9931 2.29398 15.4061 2.41367 15.8115 2.54981L13 5.36133V3.77344C13.0122 3.76295 13.024 3.75187 13.0352 3.74024L14.5674 2.20801ZM16.8252 2.9502C17.1827 3.11366 17.5294 3.29601 17.8643 3.49708L13.291 8.07032C13.1954 8.05326 13.0976 8.04213 13 8.03126V6.7754L16.8252 2.9502ZM18.7119 4.06348C19.0567 4.31866 19.3911 4.58666 19.7021 4.88087L15.374 9.042C15.0697 8.78839 14.7313 8.57531 14.3672 8.40821L18.7119 4.06348ZM12.4424 9.00294C12.482 9.00768 12.522 9.00768 12.5615 9.00294C14.463 9.03622 16 10.5913 16 12.5C16 14.4295 14.43 16 12.5 16C10.57 16 9 14.4295 9 12.5C9 10.5899 10.5391 9.03414 12.4424 9.00294Z"
        fill="currentColor"
      />
    </svg>
  );
};
