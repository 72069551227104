import React, { ReactNode } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

import { useLocation } from "react-router";
import { track } from "Utils/TrackingUtils";
import { BottomMenu } from "./components/BottomMenu";
import { AppHeader, AppHeaderProps } from "./components/AppHeader";
import { StyledBackIcon } from "Components/Shared/StyledComponents";
import { usePrefetcher } from "Hooks/codeLists/usePrefetcher";
import { useDesktop } from "Hooks/useDesktop";
import { ContentWrapper } from "./components/ContentWrapper";
import { Box, Container, Grid } from "@mui/material";
import { SideMenu } from "./components/SideMenu";
import { LAYOUT_PADDING_X } from "Constants/Layout";
import { TestVersionTag } from "Components/Layout/components/TestVersionTag";

export const StyledLayoutWrapper = styled(Container)`
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
`;

const StyledWrapper = styled.div<{ $isBottomMenuShown?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${props =>
    props.$isBottomMenuShown &&
    ` padding: ${props.theme.spacing(0, 0, 14, 0)};
  `}// ${props => props.theme.breakpoints.down("md")} {
  //   padding: ${props => props.theme.spacing(2, 0)};
  //   padding-bottom: 0;
  // }

  // ${props => props.theme.breakpoints.down("lg")} {
  //   padding: ${props => props.theme.spacing(2)};
  // }
`;

type Props = {
  title: string;
  titleComponent?: ReactNode;
  isLogoShown?: boolean;
  onBackClick?(): void;
  isBottomMenuShown?: boolean;
  isTitleShown?: boolean;
  disableContentPadding?: boolean;
  backButtonDisabled?: boolean;
  headerChildren?: ReactNode;
} & Pick<
  AppHeaderProps,
  "productIsin" | "allowProductBackground" | "isControlsShown"
>;

export const AuthenticatedLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const {
    title,
    titleComponent,
    isLogoShown,
    onBackClick,
    isBottomMenuShown,
    isTitleShown = true,
    disableContentPadding,
    backButtonDisabled,
    headerChildren,
    productIsin,
    allowProductBackground,
    isControlsShown,
  } = props;

  const { t } = useResource();
  const { pathname } = useLocation();

  usePrefetcher();

  const { isDesktop } = useDesktop();

  React.useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const showBottomMenu = isBottomMenuShown && !isDesktop;

  const header = (
    <AppHeader
      onBackClick={onBackClick}
      title={titleComponent || title}
      isLogoShown={isLogoShown}
      isTitleShown={isTitleShown}
      children={headerChildren}
      productIsin={productIsin}
      allowProductBackground={allowProductBackground}
      isControlsShown={isControlsShown}
    />
  );

  const content = (
    <ContentWrapper disableContentPadding={disableContentPadding}>
      {props.children}
    </ContentWrapper>
  );

  if (isDesktop) {
    return (
      <>
        <TestVersionTag />
        <Box display="flex" flexDirection="column">
          {header}
          <StyledLayoutWrapper maxWidth="md">
            <Grid
              container
              spacing={20}
              flexGrow={1}
              overflow="hidden"
              display="flex"
            >
              <Grid item xs="auto" marginLeft={LAYOUT_PADDING_X}>
                <SideMenu />
              </Grid>
              <Grid
                item
                flex={1}
                display="flex"
                minWidth={0}
                maxWidth="750px"
                overflow="auto"
              >
                {onBackClick && (
                  <Box minWidth="fit-content">
                    <StyledBackIcon
                      onClick={() => {
                        track({
                          action: "Click",
                          event: "GoBack",
                          category: "AppState",
                        });
                        onBackClick();
                      }}
                      $disabled={backButtonDisabled}
                    />
                  </Box>
                )}
                <Box display="grid" flex={1} paddingTop={1} overflow="hidden">
                  {content}
                </Box>
              </Grid>
            </Grid>
          </StyledLayoutWrapper>
        </Box>
      </>
    );
  }

  return (
    <>
      <TestVersionTag />
      <StyledLayoutWrapper maxWidth="md">
        {header}
        <StyledWrapper $isBottomMenuShown={showBottomMenu}>
          <Box
            display="flex"
            flexDirection="column"
            overflow="hidden"
            height="100%"
          >
            {content}
          </Box>
        </StyledWrapper>
      </StyledLayoutWrapper>

      {showBottomMenu && <BottomMenu />}
    </>
  );
};
