import {
  FcmTokenStatusQueryResult,
  FcmTokenStatusQueryResultStatus,
  getFcmTokensTokenStatus,
} from "Api/Api";
import { put } from "redux-saga/effects";
import { safeApiCall } from "State/Utils";
import { debounce } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const fcmTokenStatusAsync = createAsyncAction(
  "@notifications/FCM_TOKEN_STATUS_REQUEST",
  "@notifications/FCM_TOKEN_STATUS_SUCCESS",
  "@notifications/FCM_TOKEN_STATUS_FAILURE",
)<{ token: string }, FcmTokenStatusQueryResult, Error>();

function* fcmTokenStatus(
  action: ReturnType<typeof fcmTokenStatusAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getFcmTokensTokenStatus,
      action.payload.token,
    );

    if (
      error?.response.status === FcmTokenStatusQueryResultStatus.TokenNotFound
    ) {
      yield put(
        fcmTokenStatusAsync.success({
          ...error.response,
          isNotificationEnabled: true,
          isNotificationPermissionPending: true,
        }),
      );
      return;
    }

    if (error) {
      yield put(fcmTokenStatusAsync.failure(error));
      return;
    }

    yield put(fcmTokenStatusAsync.success(response));
  } catch (error) {
    yield put(fcmTokenStatusAsync.failure(error as Error));
  }
}

export function* fcmTokenStatusSaga() {
  yield debounce(500, getType(fcmTokenStatusAsync.request), fcmTokenStatus);
}
