import { type FunctionComponent } from "react";
import styled from "styled-components";
import { CurrencyCode, GenerateQrCodeCommand } from "Api/Api";
import { CzechInvoiceQRCode } from "Components/Shared/InvoiceQrCode/CzechInvoiceQRCode";
import { SlovakiaInvoiceQrCode } from "Components/Shared/InvoiceQrCode/SlovakiaInvoiceQrCode";
import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
} from "Constants/Countries";
import { isNoU } from "Utils/ObjectUtils";

export type InvoiceQrCodeProps = {
  size?: number;
  showSaveButton?: boolean;
  isPeriodicalPayment?: boolean;
  expiryDate?: Date;
  currency: CurrencyCode;
  isBySquareQRCodeEnabled: boolean;
  bankCountryCode: string;
  bankAccountRegion?: string | null | undefined;
  isSingleInvestment: boolean;
} & GenerateQrCodeCommand;

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${props => props.theme.spacing(3, 0)};
`;

export const InvoiceQRCode: FunctionComponent<
  React.PropsWithChildren<InvoiceQrCodeProps>
> = ({
  bankCountryCode,
  bankAccountRegion,
  size = 250,
  currency,
  isBySquareQRCodeEnabled,
  isSingleInvestment,
  ...props
}) => {
  if (
    bankCountryCode === CZECHIA_COUNTRY_CODE &&
    (isNoU(bankAccountRegion) || bankAccountRegion === CZECHIA_COUNTRY_CODE)
  ) {
    return <CzechInvoiceQRCode {...props} size={size} />;
  }

  if (isBySquareQRCodeEnabled && bankCountryCode === SLOVAKIA_COUNTRY_CODE) {
    return (
      <SlovakiaInvoiceQrCode
        {...props}
        size={size}
        isSingleInvestment={isSingleInvestment}
      />
    );
  }

  return null;
};
