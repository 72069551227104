import { Stack, Typography } from "@mui/material";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { BooleanRadioButtons } from "Components/Shared/Inputs/CommonInputs/BooleanRadioButtons";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources = Resources.Contract.NewContract.AMLQuestions;

export const PoliticallyExposed: FunctionComponent = _ => {
  const { t } = useResource();
  const isBankIDVerified = useIsBankIDVerified();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<AMLQuestionsFormModel>();

  const exposed = watch("politicallyExposed");

  return (
    <div>
      <BooleanRadioButtons
        label={
          <>
            {t(ComponentResources.PoliticallyExposed)}
            <BlInfoPopper>
              {t(ComponentResources.PoliticallyExposedPopper)}
            </BlInfoPopper>
          </>
        }
        control={control}
        errors={errors}
        name="politicallyExposed"
        disabled={isBankIDVerified}
      />

      {!!exposed && (
        <Stack gap={2}>
          <Typography>
            {t(ComponentResources.PoliticallyExposedDescription)}
          </Typography>
          <BlInputFormField
            control={control}
            errors={errors}
            name="politicallyExposedDescription"
            variant="standard"
            multiline
            minRows={3}
          />
        </Stack>
      )}
    </div>
  );
};
