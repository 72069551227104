import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { call, put, takeLeading } from "typed-redux-saga";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import {
  ContractEditCommandResult,
  ContractEditRequest,
  postContractContractIDEdit,
} from "Api/Api";
import { produce } from "immer";
import { NavigateFunction } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";

export type ContractRenameFormModel = Pick<
  ContractEditRequest,
  "name" | "purposeCategory"
>;

export type ContractEditState = {
  isLoading: boolean;
  error?: Error;
};

export const initialState: ContractEditState = {
  isLoading: false,
  error: undefined,
};

export const resetContractEditState = createAction(
  "@contract/RESET_CONTRACT_EDIT_STATE",
)<void>();

export type ContractRenameStateAction =
  | ActionType<typeof resetContractEditState>
  | ActionType<typeof contractEditAsync>;

export const contractEditAsync = createAsyncAction(
  "@contract/POST_CONTRACT_EDIT_REQUEST",
  "@contract/POST_CONTRACT_EDIT_SUCCESS",
  "@contract/POST_CONTRACT_EDIT_FAILURE",
)<
  ContractEditRequest & {
    contractID: number;
    navigate: NavigateFunction;
  },
  ContractEditCommandResult,
  Error
>();

function* contractEdit(
  action: ReturnType<typeof contractEditAsync.request>,
): Generator {
  try {
    const { response, error } =
      yield *
      safeApiCall(
        postContractContractIDEdit,
        action.payload,
        action.payload.contractID,
      );

    if (!!error) {
      yield put(contractEditAsync.failure(error));
      return;
    }

    yield* call(() =>
      action.payload.navigate(
        getPath(AppRouting.ContractDetail, action.payload.contractID),
      ),
    );

    yield put(contractEditAsync.success(response));
  } catch (err) {
    yield put(contractEditAsync.failure(err as Error));
  }
}

export function* watchContractEditSaga() {
  yield takeLeading(getType(contractEditAsync.request), contractEdit);
}

export const contractEditReducer = createReducer<
  ContractEditState,
  ContractRenameStateAction
>(initialState)
  .handleAction(contractEditAsync.request, (state, _) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.error = undefined;
      return draft;
    });
  })
  .handleAction(contractEditAsync.success, (state, _) => {
    return produce(state, draft => {
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(contractEditAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  })
  .handleAction(resetContractEditState, (state, _) => {
    return produce(state, _ => {
      return initialState;
    });
  });
