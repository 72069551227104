import { Box, BoxProps, Typography } from "@mui/material";
import { ContractDto } from "Api/Api";
import { CoinsIcon } from "Components/Shared/Icons";
import { ContractCategoriesIcons } from "Hooks/codeLists/useContractCategories";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

type Props = {
  percentage: number;
} & Pick<ContractDto, "purposeCategory"> &
  Pick<BoxProps, "marginY">;

const StyledWrapper = styled.div<Pick<BoxProps, "marginY">>`
  position: relative;
  margin: ${props => props.theme.spacing(Number(props.marginY) ?? 4, 0)};
  width: 100%;
`;

const StyledBar = styled.div<{ opacity: number }>`
  border-radius: 10px;
  height: 10px;
  width: 100%;
  opacity: ${props => props.opacity};
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.11) 0%,
    ${props => props.theme.palette.primary.main} 100%
  );
`;

const StyledBox = styled.div<Pick<Props, "percentage">>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: ${props => props.percentage}%;
  border-right: 1px solid ${props => props.theme.palette.primary.main};
`;

const StyledBoxContent = styled(Box)`
  color: ${props => props.theme.palette.primary.main};
  position: relative;
  right: ${props => props.theme.spacing(1)};
`;

const MIN_BOX_SIZE = 45;

export const PercentageChart: FunctionComponent<Props> = ({
  percentage,
  purposeCategory: contractCategory,
  marginY,
}) => {
  const styledBoxContentRef = useRef<HTMLDivElement | null>(null);

  const [inverted, setInverted] = useState(false);

  const handlePosition = useCallback(() => {
    if (!styledBoxContentRef.current) {
      return;
    }

    if (styledBoxContentRef.current?.offsetWidth < MIN_BOX_SIZE) {
      setInverted(true);
    } else {
      setInverted(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handlePosition);

    return () => {
      window.removeEventListener("resize", handlePosition);
    };
  }, [handlePosition]);

  useEffect(() => {
    handlePosition();
  }, [handlePosition, percentage]);

  const categoryIcon =
    contractCategory && contractCategory in ContractCategoriesIcons ? (
      ContractCategoriesIcons[
        contractCategory as keyof typeof ContractCategoriesIcons
      ]
    ) : (
      <CoinsIcon />
    );

  return (
    <StyledWrapper marginY={marginY}>
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <StyledBar opacity={0.5} />
      </Box>
      <Box display="flex">
        <StyledBox percentage={percentage} ref={styledBoxContentRef}>
          <Box position="absolute" width="100%">
            <StyledBar opacity={1} />
          </Box>
          {!inverted && (
            <>
              <StyledBoxContent paddingBottom={2}>
                <Box height={30}>{categoryIcon}</Box>
              </StyledBoxContent>
              <StyledBoxContent>
                <Typography color="secondary" fontWeight={600}>
                  {percentage}%
                </Typography>
              </StyledBoxContent>
            </>
          )}
        </StyledBox>

        {inverted && (
          <Box marginLeft={2}>
            <StyledBoxContent paddingBottom={2}>
              {categoryIcon}
            </StyledBoxContent>
            <StyledBoxContent>
              <Typography color="secondary" fontWeight={600}>
                {percentage}%
              </Typography>
            </StyledBoxContent>
          </Box>
        )}
      </Box>
    </StyledWrapper>
  );
};
