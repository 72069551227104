import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { string, object, ObjectSchema } from "yup";
import { Link, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import styled from "styled-components";
import { SignInStep } from "State/Auth/Models/AuthStateModels";
import { nameof } from "Utils/ObjectUtils";
import { FunctionComponent } from "react";
import { StyledButtonsWrapper } from "Components/Auth/Styles";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { TextButton } from "Components/Shared/Buttons/TextButton";
import { useNavigate } from "react-router";
import { PASSWORD_PLACEHOLDER } from "Constants/Inputs";
import { getPath, AppRouting } from "Utils/UrlUtils";
import useHandleServerError from "Hooks/useHandleServerError";
import { MAINTAINCE_SERVICE_PHONE } from "Utils/ExternalLinkUtils";
import { BiometricSignInButton } from "Components/Auth/SignIn/BiometricsSignInButton";

type Props = {
  isLoading: boolean;
  serverError: Error | null;
  step: SignInStep;
  onSubmit: (FormModel: FormModel) => void;
  onReset: () => void;
};

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(3)};
`;

const StyledHiddenButton = styled.button`
  display: none;
`;

export type FormModel = {
  login: string;
  password: string;
  token?: string;
};

const PageResources = Resources.SignIn;

export const SignInForm: FunctionComponent<Props> = props => {
  const { t } = useResource();
  const navigate = useNavigate();

  const { isLoading, serverError, step } = props;
  const validationSchema: ObjectSchema<FormModel> = object({
    login: string().required(),
    password: string().required(),
    token: string().when(nameof<FormModel>("login"), ([_], schema) =>
      step === SignInStep.TwoFactorVerificationToken
        ? schema.required(t(Resources.Validation.Required))
        : schema.notRequired(),
    ),
  }).defined();

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: { login: "", password: "" },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = form;

  useHandleServerError({
    form,
    serverError,
    resource: PageResources.Error,
    key: "login",
    keyMap: {
      login: "username",
      password: "password",
      token: "token",
    },
  });

  useHandleServerError({
    form,
    serverError,
    resource: PageResources.Error,
    key: "token",
    keyMap: {
      login: "username",
      password: "password",
      token: "token",
    },
  });

  const onSubmit = (data: FormModel) => {
    props.onSubmit(data);
  };

  return (
    <>
      <div>
        <Typography
          component="h2"
          variant="h2"
          paddingTop={theme => theme.spacing(3)}
        >
          {t(PageResources.WelcomeBack)}
        </Typography>
        <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
          <div>
            {step === SignInStep.Credentials && (
              <>
                <BlInputFormField
                  type="email"
                  autoFocus
                  control={control}
                  name="login"
                  errors={errors}
                  label={t(PageResources.Username)}
                  placeholder="např: antonin.vomacka@email.cz"
                  autoComplete="username"
                  autoCorrect="off"
                  autoCapitalize="none"
                ></BlInputFormField>
                <BlInputFormField
                  control={control}
                  name="password"
                  errors={errors}
                  label={t(Resources.Common.Password)}
                  type="password"
                  autoComplete="current-password"
                  placeholder={PASSWORD_PLACEHOLDER}
                ></BlInputFormField>

                <Typography marginTop={2} component="div">
                  {t(PageResources.ContactSupport)}
                  <Link
                    target="_blank"
                    href={`tel:${MAINTAINCE_SERVICE_PHONE}`}
                  >
                    {MAINTAINCE_SERVICE_PHONE}.
                  </Link>
                </Typography>

                {/* hidden send button to keep keyboard submit functionality */}
                <StyledHiddenButton type="submit" />
              </>
            )}

            {step === SignInStep.TwoFactorVerificationToken && (
              <>
                <BlInputFormField
                  autoFocus
                  control={control}
                  name="token"
                  errors={errors}
                  label={t(PageResources.VerificationToken)}
                  mask="***-***"
                  maskPlaceholder="_"
                  isMaskLazy={false}
                ></BlInputFormField>
              </>
            )}
          </div>
        </StyledForm>
      </div>
      <StyledButtonsWrapper spacing={4}>
        <PrimaryButton
          color="primary"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        >
          {t(PageResources.SignIn)}
        </PrimaryButton>

        <BiometricSignInButton />

        <TextButton
          color="primary"
          onClick={() => navigate(getPath(AppRouting.ForgottenPassword))}
        >
          {t(PageResources.ForgottenPassword)}
        </TextButton>
      </StyledButtonsWrapper>
    </>
  );
};
