import { CardContent, Grid, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { StyledDarkCard } from "Components/Shared/StyledComponents";
import { PAYMENT_SEARCH_PRICE_TYPE } from "Constants/Contracts/Detail";
import { useActualTab } from "Hooks/ContractDetail/useActualTab";
import { InvestmentDetailTabs } from "Hooks/ContractDetail/useMenuTabs";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";

const ComponentResources =
  Resources.Contract.Detail.Overview.PaymentInformation;

export const PaymentInformationCard: FunctionComponent = () => {
  const { t } = useResource();

  const [, setTab] = useActualTab();

  const onShowPayments = () => {
    setTab(InvestmentDetailTabs.PaymentInformation, {
      type: PAYMENT_SEARCH_PRICE_TYPE,
    });
  };

  return (
    <StyledDarkCard $defaultCursor>
      <CardContent>
        <Grid container justifyContent="space-between" gap={2}>
          <Grid item xs={12} display="flex" flexDirection="column">
            <Typography
              fontSize={16}
              fontWeight={700}
              color="secondary"
              marginBottom={1}
            >
              {t(ComponentResources.Title)}
            </Typography>
            <Typography marginBottom={2} color="secondary">
              {t(ComponentResources.Description)}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <PrimaryButton
              color="primary"
              small
              paddingLeftRight={4}
              onClick={onShowPayments}
            >
              {t(ComponentResources.ButtonText)}
            </PrimaryButton>
          </Grid>
        </Grid>
      </CardContent>
    </StyledDarkCard>
  );
};
