import {
  CampaignListQueryResultStatus,
  getCampaigns,
  UserCampaignListQueryResult,
} from "Api/Api";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { takeLeading, put } from "redux-saga/effects";
import { safeApiCall } from "State/Utils";
import { createAsyncAction, getType } from "typesafe-actions";

export const getUserCampaignsAsync = createAsyncAction(
  "@campaigns/GET_USER_CAMPAIGNS_REQUEST",
  "@campaigns/GET_USER_CAMPAIGNS_SUCCESS",
  "@campaigns/GET_USER_CAMPAIGNS_FAILURE",
)<void, UserCampaignListQueryResult, Error>();

function* getUserCampaigns(
  action: ReturnType<typeof getUserCampaignsAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(getCampaigns);

    if (error?.response.status === CampaignListQueryResultStatus.NotFound) {
      yield put(getUserCampaignsAsync.success(error.response));
      return;
    }

    if (error) {
      yield put(getUserCampaignsAsync.failure(error));
      return;
    }

    if (response.status === CampaignListQueryResultStatus.Fail) {
      yield put(
        getUserCampaignsAsync.failure(
          new ApplicationError(
            "Failed to retrieve user campaigns.",
            ErrorLevel.Error,
            {
              status: response.status,
              error: response.error,
            },
          ),
        ),
      );
      return;
    }

    yield put(getUserCampaignsAsync.success(response));
  } catch (error) {
    yield put(getUserCampaignsAsync.failure(error as Error));
  }
}

export function* getUserCampaignsSaga() {
  yield takeLeading(getType(getUserCampaignsAsync.request), getUserCampaigns);
}
