import { useAppSelector } from "Hooks/useAppSelector";
import { RedemptionStep } from "State/Contracts/Redemption/RedemptionState";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

const PageResources = Resources.Contract.Detail.Redemption;

export const useRedemptionTitle = () => {
  const { t } = useResource();
  const actualStep = useAppSelector(s => s.contracts.redemption.step);

  const pageTitle = useMemo(() => {
    switch (actualStep) {
      case RedemptionStep.Configuration:
        return t(PageResources.Configuration.Title);
      case RedemptionStep.Signature:
        return t(PageResources.Confirmation.Title);
      case RedemptionStep.Success:
        return t(PageResources.Success.Title);
      default:
        return null;
    }
  }, [actualStep, t]);

  return pageTitle;
};
