import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  handleCleanupAction,
  safeApiCall,
} from "State/Utils";
import {
  ContractUnsettledTradeItemDto,
  getContractUnsettledTradesContractID,
} from "Api/Api";
import {
  CleanupInvestmentStateActionType,
  cleanupInvestmentState,
} from "State/InvestmentsDetail/Shared/Actions";

export type GetUnsettledTradesStateType = FetchStateType<
  ContractUnsettledTradeItemDto[]
>;

export const getUnsettledTradesState = (): GetUnsettledTradesStateType =>
  getFetchStateDefaultValue();

export type GetUnsettledTradesActionType =
  | ActionType<typeof getUnsettledTradesAsync>
  | CleanupInvestmentStateActionType;

export const getUnsettledTradesAsync = createAsyncAction(
  "@investments-detail/GET_UNSETTLED_TRADES_REQUEST",
  "@investments-detail/GET_UNSETTLED_TRADES_SUCCESS",
  "@investments-detail/GET_UNSETTLED_TRADES_FAILURE",
)<
  Parameters<typeof getContractUnsettledTradesContractID>[0],
  ContractUnsettledTradeItemDto[],
  Error
>();

function* getUnsettledTrades(
  action: ReturnType<typeof getUnsettledTradesAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getContractUnsettledTradesContractID,
      action.payload,
    );

    if (error) {
      yield put(getUnsettledTradesAsync.failure(error));
      return;
    }

    yield put(getUnsettledTradesAsync.success(response.contractUnsettledItems));
  } catch (err) {
    yield put(getUnsettledTradesAsync.failure(err as Error));
  }
}

export function* getUnsettledTradesSaga() {
  yield takeLatest(
    getType(getUnsettledTradesAsync.request),
    getUnsettledTrades,
  );
}

export const getUnsettledTradesReducer = createReducer<
  GetUnsettledTradesStateType,
  GetUnsettledTradesActionType
>(getUnsettledTradesState())
  .handleAction(getUnsettledTradesAsync.request, handleActionRequest)
  .handleAction(getUnsettledTradesAsync.failure, handleActionFailure)
  .handleAction(getUnsettledTradesAsync.success, handleActionSuccess)
  .handleAction(
    cleanupInvestmentState,
    handleCleanupAction(getUnsettledTradesState),
  );
