const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
const PRODUCTION_ENVIRONMENT = "production";
const STAGING_ENVIRONMENT = "staging";
const DEVELOPMENT_ENVIRONMENT = "development";

export function useEnvironment() {
  return {
    isProduction: ENVIRONMENT === PRODUCTION_ENVIRONMENT,
    isStaging: ENVIRONMENT === STAGING_ENVIRONMENT,
    isDevelopment: ENVIRONMENT === DEVELOPMENT_ENVIRONMENT,
  };
}
