import {
  TrackingPayload,
  getDefaultTrackingUrl,
  track,
} from "Utils/TrackingUtils";
import { AppRouting } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const BLACK_LISTED_URLS = [
  AppRouting.ContractModeling.path(),
  AppRouting.ContractCreate.path(),
  AppRouting.ContractDealer.path(),
  AppRouting.ContractDistribution.path(),
];

export const useViewTracking = (
  payload: TrackingPayload,
  ignoreBlackListedUrls: boolean = true,
) => {
  const location = useLocation();

  const { url, category, event, action, channelID, tag, value } = payload;

  useEffect(() => {
    const trackingUrl = url ?? getDefaultTrackingUrl();

    if (
      !ignoreBlackListedUrls &&
      BLACK_LISTED_URLS.some(u => trackingUrl.startsWith(u))
    ) {
      return;
    }

    track({
      url: trackingUrl,
      category,
      event,
      action,
      channelID,
      tag,
      value,
    });
  }, [
    location,
    url,
    category,
    event,
    action,
    channelID,
    tag,
    value,
    ignoreBlackListedUrls,
  ]);
};
