import { CurrencyCode } from "Api/Api";
import { CZECHIA_COUNTRY_CODE } from "Constants/Countries";
import { ContractCreateBankConnectionFormModel } from "State/Contracts/Contract/BankConnections/ContractCreateBankConnectionState";
import { Resources, useResource } from "Translations/Resources";
import { isValidCzechBankNumber } from "Utils/BankUtils";
import { nameof } from "Utils/ObjectUtils";
import { validateIBAN } from "ibantools";
import { ObjectSchema, ValidationError, object, string } from "yup";

export const useYupFormSchema =
  (): ObjectSchema<ContractCreateBankConnectionFormModel> => {
    const { t } = useResource();

    return object<ContractCreateBankConnectionFormModel>().shape({
      type: string().oneOf(["iban", "bankNumber"]).required(),
      currency: string().oneOf(Object.values(CurrencyCode)).required(),
      bank: string().when(
        nameof<ContractCreateBankConnectionFormModel>("type"),
        {
          is: (type: "iban" | "bankNumber") => type === "iban",
          then: schema => schema.notRequired(),
          otherwise: schema => schema.required(),
        },
      ),
      bankCode: string().when(
        nameof<ContractCreateBankConnectionFormModel>("type"),
        {
          is: (type: "iban" | "bankNumber") => type === "iban",
          then: schema => schema.notRequired(),
          otherwise: schema => schema.required(),
        },
      ),
      bankNumber: string().when(
        nameof<ContractCreateBankConnectionFormModel>("type"),
        {
          is: (type: "iban" | "bankNumber") => type === "iban",
          then: schema => schema.notRequired(),
          otherwise: schema =>
            schema.required().test(function (bankNumber, _) {
              if (!isValidCzechBankNumber(bankNumber)) {
                throw new ValidationError(
                  t(Resources.Validation.InvalidBankAccount),
                  undefined,
                  "bankNumber",
                );
              }

              return true;
            }),
        },
      ),
      iban: string().when(
        nameof<ContractCreateBankConnectionFormModel>("type"),
        {
          is: (type: "iban" | "bankNumber") => type === "iban",
          then: schema =>
            schema
              .required()
              .max(34)
              .test(function (iban, _) {
                if (iban.startsWith(CZECHIA_COUNTRY_CODE)) {
                  throw new ValidationError(
                    t(
                      Resources.Contract.BankConnections.Create.Modelling
                        .Validations.CzechIbanNotAllowed,
                    ),
                    undefined,
                    "iban",
                  );
                }

                if (!validateIBAN(iban.replace(/\s/g, "")).valid) {
                  throw new ValidationError(
                    t(Resources.Validation.InvalidFormat),
                    undefined,
                    "iban",
                  );
                }

                return true;
              }),
          otherwise: schema => schema.notRequired(),
        },
      ),
    });
  };
