import { CheckClientExistsQueryResult, getClientExistence } from "Api/Api";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { takeLeading } from "typed-redux-saga";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { setClientExistenceValidationResult } from "State/Shared/ClientExistenceValidationResult";
import { ApplicationError } from "Models/Errors/ApplicationError";

export type GetExistenceStateType =
  FetchStateType<CheckClientExistsQueryResult>;

export const getExistenceState = (): GetExistenceStateType =>
  getFetchStateDefaultValue();

export type GetExistenceActionType =
  | ActionType<typeof getExistenceAsync>
  | ActionType<typeof clearExistence>;

export const getExistenceAsync = createAsyncAction(
  "@client/GET_EXISTENCE_REQUEST",
  "@client/GET_EXISTENCE_SUCCESS",
  "@client/GET_EXISTENCE_FAILURE",
)<
  {
    personalIdentificationNumber: string;
    citizenship: string;
  },
  CheckClientExistsQueryResult,
  Error
>();

export const clearExistence = createAction("@client/CLEAR_EXISTENCE")();

function* getExistence(
  action: ActionType<typeof getExistenceAsync.request>,
): Generator {
  try {
    const { response, error, status } = yield* safeApiCall(
      getClientExistence,
      action.payload.personalIdentificationNumber,
      action.payload.citizenship,
    );

    if (error) {
      yield put(getExistenceAsync.failure(error));
      return;
    }

    yield put(setClientExistenceValidationResult(status === 200));

    if (status === 200) {
      yield put(getExistenceAsync.success(response));
    } else {
      yield put(
        getExistenceAsync.failure(new ApplicationError(response.status)),
      );
    }
  } catch (err) {
    yield put(getExistenceAsync.failure(err as Error));
  }
}

export function* getExistenceSaga() {
  yield takeLeading(getType(getExistenceAsync.request), getExistence);
}

export const getExistenceReducer = createReducer<
  GetExistenceStateType,
  GetExistenceActionType
>(getExistenceState())
  .handleAction(getExistenceAsync.request, handleActionRequest)
  .handleAction(getExistenceAsync.failure, handleActionFailure)
  .handleAction(getExistenceAsync.success, handleActionSuccess)
  .handleAction(clearExistence, getExistenceState);
