import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import useHandleServerError from "Hooks/useHandleServerError";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { object, ObjectSchema, string } from "yup";

export enum PasswordResetNoticeTypes {
  None = "0",
  OldPortalUser = "1",
}

type Props = {
  serverError: Error | null;
  isLoading: boolean;
  onSubmit(login: string): void;
};

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(4)};
`;

type FormModel = {
  login: string;
};

const PageResources = Resources.ForgottenPassword;

export const PasswordResetForm: FunctionComponent<Props> = ({
  serverError,
  isLoading,
  onSubmit,
}) => {
  const { t } = useResource();
  const urlParams = new URLSearchParams(window.location.search);
  const noticeType =
    (urlParams.get("noticeType") as PasswordResetNoticeTypes | undefined) ??
    PasswordResetNoticeTypes.None;

  const validationSchema: ObjectSchema<FormModel> = object({
    login: string().required(t(Resources.Validation.Required)),
  }).defined();

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  useHandleServerError({
    form,
    serverError,
    resource: PageResources.Error,
    key: "login",
  });

  return (
    <>
      <div>
        <Typography variant="h2" component="h2">
          {t(Resources.SignIn.ResetPassword)}
        </Typography>

        {noticeType === PasswordResetNoticeTypes.OldPortalUser && (
          <Typography>{t(PageResources.OldPortalUserNotice)}</Typography>
        )}

        <StyledForm autoComplete="off">
          <BlInputFormField
            autoFocus
            control={control}
            name="login"
            errors={errors}
            label={t(Resources.SignIn.Username)}
            placeholder={t(Resources.Common.ExampleLogin)}
            autoComplete="username"
          ></BlInputFormField>
        </StyledForm>
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(data => onSubmit(data.login))}
        isLoading={isLoading}
        trackingTag={t(Resources.Common.Continue)}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
