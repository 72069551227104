import { Box, Link, Typography, styled } from "@mui/material";
import { PageTitle } from "Components/Shared/PageTitle";
import { AppRouting, getPath } from "Utils/UrlUtils";
import {
  FunctionComponent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { track } from "Utils/TrackingUtils";
import { useWindowSize } from "react-use";
import { Resources, useResource } from "Translations/Resources";
import { ContractGuideWrapper } from "Components/Shared/ContractGuideWrapper";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useModelingIsin } from "Hooks/useModelingIsin";
import { useDesktop } from "Hooks/useDesktop";
import { DocumentLink } from "Components/Shared/DocumentLink";
import { TERMS_OF_COMPLAINT_URL } from "Utils/ExternalLinkUtils";

const FADE_EFFECT_PERCENT_START = 1;
const FADE_EFFECT_HEIGHT = 180;

export const ScrollableBox = styled(Box)`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
`;

export const PreContractualInformationCzech: FunctionComponent = _ => {
  const navigate = useNavigate();
  const nextStepButtonRef = useRef<HTMLDivElement>(null);
  const scrollableBoxRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLDivElement>(null);
  const { height: winHeight } = useWindowSize();
  const isDip = useIsDipSelected();
  const { color, contrastColor } = useModelingColor();
  const isin = useModelingIsin();
  const { isDesktop } = useDesktop();

  const onSubmit = () => {
    track({
      category: "Button",
      event: "PreContractualInformationAgreement",
      action: "Click",
      url: getPath(AppRouting.PreContractualInformation),
    });

    navigate(getPath(AppRouting.ContractCreate));
  };

  const [scrollableBoxFadeRatio, setScrollableBoxFadeRatio] = useState(
    FADE_EFFECT_PERCENT_START,
  );

  const [height, setHeight] = useState(0);

  const calculateFadeBox = useCallback(() => {
    if (!scrollableBoxRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollableBoxRef.current;

    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const fadeRatio = 1 - scrollPosition;

    setScrollableBoxFadeRatio(fadeRatio);
  }, []);

  useLayoutEffect(() => {
    const nextStepButtonOffset = nextStepButtonRef.current?.offsetTop ?? 0;
    const contentHeight = headingRef.current?.clientHeight ?? 0;
    setHeight(nextStepButtonOffset - contentHeight - 210);
    calculateFadeBox();
  }, [winHeight, calculateFadeBox]);

  const calculateScrollableBoxOffset = () => {
    return `linear-gradient(to bottom, black calc(100% - (${FADE_EFFECT_HEIGHT}px * ${scrollableBoxFadeRatio})), transparent 100%)`;
  };

  const onScroll = () => {
    calculateFadeBox();
  };

  const { t } = useResource();

  return (
    <>
      <Box
        margin={0}
        padding={0}
        display="flex"
        flexDirection="column"
        flex={1}
      >
        <Box ref={headingRef}>
          {isDesktop && (
            <ContractGuideWrapper isDipWrapped={isDip} productIsin={isin ?? ""}>
              <PageTitle>Předsmluvní informace</PageTitle>
            </ContractGuideWrapper>
          )}
          <Typography marginBottom={5}>
            Níže vám předkládáme předsmluvní informace.
          </Typography>
        </Box>
        {height > 0 && (
          <>
            <ScrollableBox
              ref={scrollableBoxRef}
              overflow="auto"
              height={`${height}px`}
              maxHeight={`${height}px`}
              paddingRight={3}
              onScroll={onScroll}
              sx={{
                maskImage: calculateScrollableBoxOffset(),
              }}
            >
              <Typography variant="h3" marginBottom={1} fontSize={16}>
                INFORMACE PRO ÚČELY UZAVÍRÁNÍ SMLUV DISTANČNÍM ZPŮSOBEM
              </Typography>
              <Typography variant="subtitle1" marginBottom={3}>
                (ISZDZ-2301) (účinné ode dne{" "}
                <Typography
                  component="span"
                  color="primary"
                  variant="subtitle1"
                  fontWeight={700}
                >
                  1. prosince 2023
                </Typography>
                )
              </Typography>
              <Typography variant="h4" marginBottom={2} fontSize={14}>
                I. ZÁKLADNÍ UJEDNÁNÍ
              </Typography>
              <Typography marginBottom={1}>
                (1) Tyto informace jsou určeny pro účely uzavírání rámcových
                smluv distančním způsobem (tedy na dálku) zejména ve smyslu
                ustanovení § 1841 a násl. zákona č.89/2012 Sb., občanský
                zákoník, ve znění pozdějších předpisů.
              </Typography>
              <Typography marginBottom={1}>
                (2) Práva a povinnosti Klienta při vydávání a odkupování Cenných
                papírů se řídí Smlouvou, Obchodními podmínkami, statutem
                příslušného Investičního fondu a obecně závaznými právními
                předpisy. Právo Klienta na ochranu spotřebitele ve smyslu
                příslušných právních předpisů státu, ve kterém jsou Cenné papíry
                nabízeny, tímto ustanovením není dotčeno. Smluvní dokumentace je
                taktéž k dispozici na internetových stránkách Společnosti{" "}
                <Link href="https://moje.investika.cz/" target="_blank">
                  zde
                </Link>
                , případně na portále{" "}
                <Link href="https://portal.investika.cz/login" target="_blank">
                  Moje INVESTIKA
                </Link>
                .
              </Typography>
              <Typography marginBottom={1}>
                (3) Předpokladem pro nabytí Cenných papírů Klientem je uzavření
                Smlouvy mezi Klientem a Společností.
              </Typography>
              <Typography marginBottom={1}>
                (4) Pojmy uvedené a nedefinované v těchto informacích mají
                stejný význam, jako je jim přiřazen v rámci Obchodních podmínek.
              </Typography>
              <Typography marginBottom={3}>
                (5) Informace zde uvedené včetně údaje o ceně zůstávají v
                platnosti až do případné změny těchto informací, nebo jde-li o
                změnu ceny, až do změny Sazebníku podle Smlouvy způsobem ve
                Smlouvě sjednaným.
              </Typography>
              <Typography variant="h4" marginBottom={2} fontSize={14}>
                II. INFORMACE O SPOLEČNOSTI
              </Typography>
              <Typography marginBottom={1}>
                (1){" "}
                <Typography fontWeight={700} component="span">
                  Obecné informace:
                </Typography>{" "}
                &nbsp; Společnost INVESTIKA, investiční společnost, a.s., IČO:
                041 58 911, se sídlem U Zvonařky 291/3, 120 00 Praha 2, Česká
                republika, zapsaná v obchodním rejstříku vedeném Městským soudem
                v Praze pod sp. zn. B 20668 (dále jen „
                <Typography fontWeight={700} component="span">
                  Společnost
                </Typography>
                “). Klientská linka: (+420) 233 334 990, e-mail:
                info@investika.cz, internetové stránky Společnosti dostupné{" "}
                <Link href="https://moje.investika.cz/" target="_blank">
                  zde
                </Link>
                .
              </Typography>
              <Typography marginBottom={1}>
                (2){" "}
                <Typography fontWeight={700} component="span">
                  Hlavní předmět podnikání:
                </Typography>{" "}
                Činnost Společnosti podle Zákona, spočívající v obhospodařování
                investičních fondů, které jsou: a) standardními fondy, b)
                speciálními fondy, c) fondy kvalifikovaných investorů, s
                výjimkou kvalifikovaných fondů rizikového kapitálu podle článku
                3 písm. b) přímo použitelného předpisu Evropské unie
                upravujícího evropské fondy rizikového kapitálu a
                kvalifikovaných fondů sociálního podnikání podle článku 3 písm.
                b) přímo použitelného předpisu Evropské unie upravujícího
                evropské fondy sociálního podnikání.
              </Typography>
              <Typography marginBottom={1}>
                (3){" "}
                <Typography fontWeight={700} component="span">
                  Orgán dohledu:
                </Typography>{" "}
                Orgánem dohledu nad výkonem činnosti Společnosti je Česká
                národní banka (ČNB), se sídlem Na Příkopě 864/28, 115 03 Praha
                1, IČO:48136450. S přihlédnutím k vašim oprávněným zájmům i
                oprávněným zájmům ze strany Společnosti, budou sporné
                záležitosti primárně směřovány k řešení smírnou cestou.
              </Typography>
              <Typography marginBottom={3}>
                (4){" "}
                <Typography fontWeight={700} component="span">
                  Garanční fond:
                </Typography>{" "}
                Společnost není ve vztahu k obhospodařování a administraci
                Investičních fondů účastníkem záručního systému zabezpečovaného
                Garančním fondem obchodníků s cennými papíry, ani jiného
                garančního fondu.
              </Typography>
              <Typography variant="h4" marginBottom={2} fontSize={14}>
                III. POSKYTOVANÉ SLUŽBY
              </Typography>
              <Typography marginBottom={1}>
                (1){" "}
                <Typography fontWeight={700} component="span">
                  Poskytované služby:
                </Typography>{" "}
                Kolektivní investování prostřednictvím konkrétních Investičních
                fondů, jejichž cílem je dosáhnout zhodnocení vložených
                prostředků Investorů v rámci investiční strategie uvedené ve
                statutu příslušného Investičního fondu.
              </Typography>
              <Typography marginBottom={1}>
                (2){" "}
                <Typography fontWeight={700} component="span">
                  Způsob výpočtu ceny služby:
                </Typography>{" "}
                Cena je určena dle Sazebníku příslušného Investičního fondu,
                který je také dostupný na internetových stránkách společnosti{" "}
                <Link href="https://moje.investika.cz/" target="_blank">
                  zde
                </Link>
                .
              </Typography>
              <Typography marginBottom={1}>
                (3){" "}
                <Typography fontWeight={700} component="span">
                  Náklady spojené s uzavřením Smlouvy:
                </Typography>{" "}
                Uzavření Smlouvy není spojeno se zvláštními náklady, kromě
                případných nákladů na ověření podpisu a totožnosti Klienta a
                poštovného v souvislosti s doručením podepsané Smlouvy
                Společnosti, není-li v Sazebníku uvedeno jinak. Při využití
                elektronické pošty hradí Klient poskytovateli poplatky za
                připojení k veřejné telekomunikační síti (internetu) a příp.
                poplatky za hosting elektronické pošty ve výši, kterou si Klient
                s poskytovatelem sjednal.
              </Typography>
              <Typography marginBottom={1}>
                (4){" "}
                <Typography fontWeight={700} component="span">
                  Doba závaznosti Smlouvy:
                </Typography>{" "}
                Smlouva zavazuje smluvní strany nejméně po dobu, po kterou je
                Klient Investorem Investičního fondu, tedy po dobu, po kterou
                vlastní alespoň jeden Cenný papír.
              </Typography>
              <Typography marginBottom={1}>
                (5){" "}
                <Typography fontWeight={700} component="span">
                  Rizika spojená s investováním do Investičních fondů:
                </Typography>{" "}
                Společnost nemůže zaručit dosažení stanovených investičních
                cílů. Kurzy, ceny, výnosy, zhodnocení, výkonnost či jiné
                parametry dosažené v minulosti jednotlivými aktivy, do nichž
                Investiční fondy investují, nemohou v žádném případě sloužit
                jako indikátor nebo záruka budoucích kurzů, cen, výnosů,
                zhodnocení, výkonnosti či jiných parametrů takovýchto nebo
                obdobných aktiv, a mohou se v čase měnit, tzn. růst i klesat.
                Předpokládané či možné výnosy nejsou zaručené, minulé výnosy
                nejsou zárukou budoucích výnosů, hodnota investice může v čase
                kolísat a návratnost původně investovaných prostředků tak není
                zaručena. Zaručena není pro budoucí období ani výkonnost
                dosažená konkrétním Investičním fondem v předchozích obdobích.
                Detailní informace o rizicích spojených s investováním do
                Cenných papírů jsou upraveny ve statutu každého příslušného
                Investičního fondu.
              </Typography>
              <Typography marginBottom={3}>
                (6){" "}
                <Typography fontWeight={700} component="span">
                  Způsob platby:
                </Typography>{" "}
                Způsob platby je vždy bezhotovostní. Platební instrukce včetně
                bližší specifikace dané platby (variabilní symbol, specifický
                symbol, bankovní spojení, částka atp.) jsou součástí každé
                uzavírané Smlouvy, jakožto její příloha, nebo budou sděleny před
                uzavřením příslušné Smlouvy.
              </Typography>
              <Typography variant="h4" marginBottom={2} fontSize={14}>
                IV. POUČENÍ
              </Typography>
              <Typography marginBottom={1}>
                (1){" "}
                <Typography fontWeight={700} component="span">
                  Nemožnost odstoupení od Smlouvy:
                </Typography>{" "}
                Vzhledem k tomu, že cena poskytovaných služeb závisí na
                výchylkách finančního trhu nezávislých na vůli Společnosti,
                resp. že cena finančních služeb závisí na pohybech cen na
                finančních trzích, které Společnost nemůže ovlivnit, jako jsou
                služby k devizovým hodnotám a investičním nástrojům, Klient není
                oprávněn odstoupit od Smlouvy, jejíž součástí je Žádost o vydání
                Cenných papírů, bez uvedení důvodu ve lhůtě čtrnácti dnů od
                uzavření Smlouvy. Podmínky, za nichž je Klient oprávněn ukončit
                Smlouvu, vymezuje Smlouva. Smlouva také vymezuje podmínky, za
                nichž je Společnost oprávněna Smluvní dokumentaci změnit.
              </Typography>
              <Typography marginBottom={1}>
                (2){" "}
                <Typography fontWeight={700} component="span">
                  Ukončení Smlouvy:
                </Typography>{" "}
                Kterákoliv ze smluvních stran je oprávněna ukončit smluvní vztah
                písemnou výpovědí, a to v souladu a za podmínek uvedených ve
                Smlouvě. K jednostrannému ukončení závazkového vztahu mezi
                Klientem a Společností ze strany Klienta – vlastníka Cenných
                papírů – dojde rovněž v případě odkupu všech Klientem
                vlastněných Cenných papírů a při současném splnění podmínky kdy
                po dobu 2 (dvou) let ode dne převodu posledního odkoupeného či
                převáděného Cenného papíru z majetkového účtu Klienta
                (Investora), nedojde k připsání peněžních prostředků určených
                Klientem (Investorem) na úpis Cenných papírů na účet některého
                Investičního fondu obhospodařovaného a/nebo administrovaného
                Společností, nebo i v případě, že Klient nebude souhlasit se
                změnou Obchodních podmínek navrženou ze strany Společnosti.
                Bližší informace pro výpověď Smlouvy stanoví Smlouva, Obchodní
                podmínky a statut konkrétního Investičního fondu, které jsou
                dostupné na internetových stránkách společnosti{" "}
                <Link href="https://moje.investika.cz/" target="_blank">
                  zde
                </Link>
                .
              </Typography>
              <Typography marginBottom={1}>
                (3){" "}
                <Typography fontWeight={700} component="span">
                  Obecné informace o daních:
                </Typography>{" "}
                V souvislosti s uzavřenou Smlouvou mohou vzniknout Klientovi
                daňově či účetní povinnosti, případně povinnosti k úhradě
                poplatků. Společnost svým Klientům neposkytuje daňové ani účetní
                poradenství, a proto upozorňuje Klienty, že každou jednotlivou
                situaci je třeba konzultovat s daňovým poradcem. Společnost
                proto doporučuje, aby se Klienti vždy poradili se svými právními
                a daňovými poradci o daňových důsledcích koupě, prodeje a držení
                Cenných papírů a přijímání kapitálových výnosů podle daňových
                předpisů platných v České republice a v zemích, jejichž jsou
                rezidenty, jakož i v zemích, v nichž výnosy z držení a prodeje
                Cenných papírů mohou být zdaněny. Další údaje o zdanění obsahují
                také statuty jednotlivých Investičních fondů.
              </Typography>
              <Typography marginBottom={3}>
                (4){" "}
                <Typography fontWeight={700} component="span">
                  Uplatnění reklamace:
                </Typography>{" "}
                Klient je oprávněn svá práva z vadného plnění Společnosti
                uplatnit dle Reklamačního řádu Společnosti, který stanoví
                podmínky pro uplatňování těchto práv.
              </Typography>
              <Typography variant="h4" marginBottom={2} fontSize={14}>
                V. PRÁVNÍ PŘEDPISY
              </Typography>
              <Typography marginBottom={1}>
                (1){" "}
                <Typography fontWeight={700} component="span">
                  Rozhodné právo a příslušnost soudu:
                </Typography>{" "}
                Společnost, její činnost i veškerá Smluvní dokumentace podléhá
                právním předpisům České republiky a přímo použitelným právním
                předpisům Evropské unie. Právem rozhodným pro řešení případných
                sporů mezi Klientem a Společností je české právo. V případě
                přeshraniční distribuce Investičních fondů obhospodařovaných
                nebo administrovaných Společností se závazkové vztahy se
                spotřebiteli řídí právním řádem příslušného členského státu, ve
                kterém dochází k přeshraniční distribuci, ledaže právo České
                republiky poskytuje spotřebiteli lepší ochranu jeho práv. Soudní
                příslušnost pro řešení sporů se řídí právními předpisy
                upravujícími soudní příslušnost dle rozhodného práva.
              </Typography>
              <Typography marginBottom={3}>
                (2){" "}
                <strong>
                  Subjekt mimosoudního řešení sporů mezi Klientem a Společností:
                </strong>{" "}
                V souladu se zákonem č. 634/1992 Sb., o ochraně spotřebitele, ve
                znění pozdějších předpisů (dále jen „
                <Typography fontWeight={700} component="span">
                  Zákon o ochraně spotřebitele
                </Typography>
                “), má Klient, který je spotřebitelem, právo na mimosoudní
                řešení případných sporů mezi Klientem a Společností, které jsou
                spotřebitelským sporem ve smyslu Zákona o ochraně spotřebitele.
                Subjektem mimosoudního řešení spotřebitelských sporů mezi
                Klientem a Společností je finanční arbitr ve smyslu zákona č.
                229/2002 Sb., o finančním arbitrovi, ve znění pozdějších
                předpisů. Bližší informace o finančním arbitrovi a řešení
                spotřebitelských sporů prostřednictvím finančního arbitra je
                možné získat na adrese sídla finančního arbitra, Legerova
                1581/69, Praha 1, 110 00, Česká republika, tel.: 257042070,
                e-mail: arbitr@finarbitr.cz. Internetové stránky finančního
                arbitra jsou www.finarbitr.cz. V případě Klienta –
                kvalifikovaného investora se nejedná o spotřebitele, a proto
                ustanovení Zákona o ochraně spotřebitele o mimosoudním řešení
                spotřebitelských sporů nejsou na fondy kvalifikovaných investorů
                aplikována. V případě přeshraničního sporu pomáhá spotřebitelům
                v přístupu k příslušnému subjektu mimosoudního řešení
                spotřebitelských sporů Evropské spotřebitelské centrum Česká
                republika, které je kontaktním místem dle Nařízení Evropského
                parlamentu a Rady (EU) č. 524/2013 ze dne 21. května 2013.
              </Typography>
              <Typography variant="h4" marginBottom={2} fontSize={14}>
                VI. PROSTŘEDKY KOMUNIKACE
              </Typography>
              <Typography marginBottom={1}>
                (1){" "}
                <Typography fontWeight={700} component="span">
                  Údaj o jednacím jazyku:
                </Typography>{" "}
                Společnost s Klienty jedná, poskytuje veškerou Smluvní
                dokumentaci a plní informační povinnosti vůči Klientům v českém
                či slovenském jazyce, a v případě přeshraničního nabízení
                Cenných papírů Společnost s Klienty jedná, resp. poskytuje
                dokumentaci v úředním jazyku státu, ve kterém jsou Cenné papíry
                nabízeny.
              </Typography>
              <Typography marginBottom={1}>
                (2){" "}
                <Typography fontWeight={700} component="span">
                  Způsob komunikace:
                </Typography>{" "}
                Vzájemná komunikace mezi Společností a Klientem probíhá zejm.
                formou e-mailové komunikace, příp. telefonicky či skrze Portál
                nebo Mobilní aplikaci; to neplatí pro případ uzavírání, změny či
                ukončování Smlouvy, ani komunikaci související s vydáváním a
                odkupováním Cenných papírů. Veškeré povinnosti týkající se
                informování Klientů ve smyslu Zákona a jeho prováděcích předpisů
                poskytuje Společnost Klientovi zpravidla elektronicky (na
                jakémkoli nosiči informací), včetně internetových stránek
                Společnosti a e-mailu či skrze Portál nebo Mobilní aplikaci.
              </Typography>
              <Typography marginBottom={1}>
                (3){" "}
                <Typography fontWeight={700} component="span">
                  Poskytování informací o službách:
                </Typography>{" "}
                Informace dle Zákona jsou poskytovány v rozsahu a lhůtách
                stanovených ve statutu příslušného Investičního fondu.
              </Typography>
              <Typography marginBottom={3}>
                (4){" "}
                <Typography fontWeight={700} component="span">
                  Formuláře Společnosti:
                </Typography>{" "}
                Klient se zavazuje informovat Společnost o veškerých podstatných
                změnách, které se ho týkají jako Klienta Společnosti, a to do 14
                (čtrnácti) dnů ode dne, kdy se o nich sám dozví. Těmito změnami
                se rozumí zejména změny identifikačních údajů, uváděných v
                Průkazu totožnosti, změny Bankovního účtu klienta, změna
                elektronické korespondenční adresy (e-mail) apod. Klient je
                povinen činit veškerá právní jednání vůči Společnosti formou
                zvláštních standardizovaných formulářů, jejichž vzory jsou k
                dispozici v sídle Společnosti, na internetových stránkách
                společnosti{" "}
                <Link href="https://moje.investika.cz/" target="_blank">
                  zde
                </Link>
                , případně na Portále nebo v Mobilní aplikaci. To neplatí v
                případě, kdy Společnost takový formulář nenabízí. Některé
                formuláře mohou obsahovat požadavky na úřední ověření podpisu
                nebo jiný způsob ověření identity Klienta.
              </Typography>
              <Typography variant="h4" marginBottom={2} fontSize={14}>
                VII. ZÁVĚREČNÁ UJEDNÁNÍ
              </Typography>
              <Typography marginBottom={1}>
                (1){" "}
                <Typography fontWeight={700} component="span">
                  Cenné papíry:
                </Typography>{" "}
                Nabídka, prodej nebo koupě Cenných papírů Investičních fondů
                obhospodařovaných a/nebo administrovaných Společností jsou v
                některých zemích omezeny zákonem. Cenné papíry Investičních
                fondů obhospodařovaných a/nebo administrovaných Společností
                takto zejména nejsou registrovány v souladu se zákonem o cenných
                papírech Spojených států amerických z roku 1933 a nesmějí být
                nabízeny, prodávány nebo předávány na území Spojených států
                amerických nebo osobám, které jsou rezidenty či daňovými
                rezidenty Spojených států amerických.
              </Typography>
              <Typography marginBottom={1}>
                (2){" "}
                <Typography fontWeight={700} component="span">
                  Obecné informace:
                </Typography>{" "}
                V případě rozporu mezi ujednáními těchto informací a ujednáními
                statutů Investičních fondů obhospodařovaných a/nebo
                administrovaných Společností nebo Smlouvou, a Obchodních
                podmínek mají přednost ujednání statutů příslušných Investičních
                fondů obhospodařovaných a/nebo administrovaných Společností nebo
                Smlouvy nebo Obchodní podmínky.
              </Typography>
              <Typography marginBottom={3}>
                (3){" "}
                <Typography fontWeight={700} component="span">
                  Poskytnutí informací:
                </Typography>{" "}
                Společnost je povinna s dostatečným předstihem před uskutečněním
                investice Klientovi bezúplatně poskytnout Sdělení klíčových
                informací a úplný statut kteréhokoli Investičního fondu
                obhospodařovaného a/nebo administrovaného Společností, v
                aktuálním znění a na požádání je Klient oprávněn bezúplatně
                získat také poslední uveřejněnou výroční a případně i pololetní
                zprávu Investičního fondu obhospodařovaného a/nebo
                administrovaného Společností. Všechny tyto dokumenty jsou k
                dispozici v sídle Společnosti a též na internetových stránkách
                společnosti{" "}
                <Link href="https://moje.investika.cz/" target="_blank">
                  zde
                </Link>
                .
              </Typography>
              <Typography variant="h4" marginBottom={2} fontSize={14}>
                VIII. VYMEZENÍ NĚKTERÝCH POJMŮ
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Cenným papírem“
                </Typography>{" "}
                se rozumí cenný papír nebo zaknihovaný cenný papír, který
                představuje účast Investora na Společností obhospodařovaném
                a/nebo administrovaném Investičním fondu, resp. podfondu a se
                kterým jsou spojena další práva plynoucí ze Zákona nebo statutu
                příslušného Investičního fondu, přičemž Investiční fond může
                vydávat různé třídy cenných papírů.
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Investičním fondem“
                </Typography>{" "}
                se rozumí investiční fond, resp. podfond ve smyslu Zákona, který
                je obhospodařován a/nebo administrován Společností.
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Investorem“
                </Typography>{" "}
                se rozumí osoba, která je vlastníkem Cenných papírů Investičního
                fondu.
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Klientem“
                </Typography>{" "}
                se rozumí jakákoli fyzická či právnická osoba, která se
                Společností uzavírá Smlouvu.
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Obchodními podmínkami“
                </Typography>{" "}
                se rozumí obchodní podmínky Společnosti, které jsou dostupné na
                internetových stránkách společnosti{" "}
                <Link href="https://moje.investika.cz/" target="_blank">
                  zde
                </Link>
                .
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Produktem“
                </Typography>{" "}
                se rozumí Klientem zvolený konkrétní pokyn k investici do
                jednotlivě určeného druhu Cenného papíru či složený pokyn k
                investici do více druhů Cenných papírů v dopředu určených
                poměrech a za předem dohodnutých podmínek.
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Reklamačním řádem“
                </Typography>{" "}
                se rozumí Pravidla pro vyřizování stížností a reklamací vydané
                Společností, v platném znění, a který je k dispozici
                internetových stránkách Společnosti{" "}
                <DocumentLink href={TERMS_OF_COMPLAINT_URL} target="_blank">
                  zde
                </DocumentLink>
                .
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Sazebníkem“
                </Typography>{" "}
                se rozumí dokument označený „Sazebník poplatků a další
                parametry“, který je součástí Smlouvy, případně je dostupný na
                internetových stránkách Společnosti{" "}
                <Link
                  href="https://moje.investika.cz/moje-investika"
                  target="_blank"
                >
                  zde
                </Link>
                .
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Sdělením klíčových informací“
                </Typography>{" "}
                se rozumí souhrn základních informací o Investičním fondu, který
                obsahuje stručné základní charakteristiky Investičního fondu
                nezbytné pro pochopení povahy a rizik spojených s investováním
                do tohoto Investičního fondu, zpracované formou srozumitelnou
                běžnému investorovi, a který byl vypracován v souladu se Zákonem
                a dalšími obecně závaznými právními předpisy.
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Smlouvou“
                </Typography>{" "}
                se rozumí rámcová smlouva o vydávání a odkupování Cenných papírů
                uzavřená mezi Společností a Klientem.
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Smluvní dokumentací“
                </Typography>{" "}
                se rozumí Smlouva a Obchodní podmínky, popřípadě i další
                dokumenty (např. statut příslušného fondu, Sdělení klíčových
                informací, Sazebník) nutné k uzavření Smlouvy.
              </Typography>
              <Typography marginBottom={1}>
                <Typography fontWeight={700} component="span">
                  „Zákonem“
                </Typography>{" "}
                se rozumím zákon č. 240/2013 Sb., o investičních společnostech a
                investičních fondech, ve znění pozdějších předpisů.
              </Typography>
            </ScrollableBox>
          </>
        )}
      </Box>

      <Box ref={nextStepButtonRef} margin={0} padding={0}>
        <PrimaryButton
          fullWidth={true}
          color="primary"
          onClick={onSubmit}
          trackingTag={t(Resources.Common.Continue)}
          hexColor={contrastColor}
          hexBackgroundColor={color}
        >
          {t(Resources.Common.Continue)}
        </PrimaryButton>
      </Box>
    </>
  );
};
