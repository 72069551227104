export function isPwa() {
  return ["fullscreen", "standalone", "minimal-ui"].some(
    displayMode =>
      window.matchMedia("(display-mode: " + displayMode + ")").matches,
  );
}

export function getUserAgentTrackingValue(
  deviceType: string | undefined,
  os: string | undefined,
  osVersion: string | undefined,
): string {
  return `${getDeviceType(deviceType)};${getOsFullName(os, osVersion)}`;
}

function getDeviceType(deviceType: string | undefined): string {
  return deviceType ?? "desktop";
}

function getOsFullName(
  os: string | undefined,
  version: string | undefined,
): string {
  if (!os) {
    return "unknown";
  }

  if (version) {
    return `${os} ${version}`;
  }

  return os;
}
