import { InputAdornment } from "@mui/material";
import { CurrencyCode } from "Api/Api";
import {
  MAXIMUM_PERIODICAL_DEPOSIT,
  MAXIMUM_SINGLE_DEPOSIT,
  MINIMUM_DEPOSIT,
} from "Components/ContractModeling/FundConfiguration/FundConfiguration";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { useCurrencySymbol } from "Hooks/useCurrencySymbol";
import { FundConfigurationFormModel } from "State/Contracts/Modeling/Models";
import { Resources, useResource } from "Translations/Resources";
import { convertApiCurrencyCodeFromString } from "Utils/CurrencyUtils";
import { useCallback, type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { formatCurrency } from "Utils/CurrencyUtils";

type Props = {
  showTargetAmount: boolean;
  currency: CurrencyCode | string | null;
  singleInvestmentFeeRateLabel: string;
  periodicalInvestmentFeeRateLabel: string;
  maxPerformance: number | null;
  totalAmount: number | null;
  isin: string | null | undefined;
  onTargetAmountChange: (value: number) => void;
  onSingleInvestmentChange: (value: number) => void;
  onPeriodicalInvestmentChange: (value: number) => void;
};

const PageResources = Resources.Investments.FundConfiguration;

export const InvestmentForm: FunctionComponent<Props> = props => {
  const {
    showTargetAmount = true,
    currency,
    singleInvestmentFeeRateLabel,
    periodicalInvestmentFeeRateLabel,
    maxPerformance,
    totalAmount,
    isin,
    onTargetAmountChange,
    onSingleInvestmentChange,
    onPeriodicalInvestmentChange,
  } = props;

  const { t } = useResource();
  const { control, formState } = useFormContext<FundConfigurationFormModel>();
  const currSymbol = useCurrencySymbol(
    convertApiCurrencyCodeFromString(currency),
  );

  const tagTrackingEnhancer = useCallback(
    (value: string | null | undefined) => `${value};${isin}`,
    [isin],
  );

  return (
    <>
      {showTargetAmount && (
        <BlInputFormField
          label={
            <>
              {t(PageResources.TargetAmount)}{" "}
              <BlInfoPopper icon="info" marginLeft={0}>
                {t(PageResources.TargetAmountInputInfo)}
              </BlInfoPopper>
            </>
          }
          helperText={
            <>
              {t(PageResources.TotalAmount)}&nbsp;
              {formatCurrency(totalAmount, currency)}
              <br />
              {t(PageResources.ExpectedAppreciation, {
                value: maxPerformance,
              })}
            </>
          }
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          control={control}
          name="targetAmount"
          errors={formState?.errors}
          mask={Number}
          min={MINIMUM_DEPOSIT}
          allowEmptyNumber={false}
          hasTracking={true}
          tagTrackingEnhancer={tagTrackingEnhancer}
          isNumber
          onChange={value => onTargetAmountChange(value)}
          inputEndAdornment={
            <InputAdornment position="end">{currSymbol}</InputAdornment>
          }
        />
      )}

      <BlInputFormField
        label={t(PageResources.SingleInvestment)}
        helperText={singleInvestmentFeeRateLabel}
        control={control}
        name="singleInvestment"
        errors={formState?.errors}
        mask={Number}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        min={MINIMUM_DEPOSIT}
        max={MAXIMUM_SINGLE_DEPOSIT}
        allowEmptyNumber={false}
        hasTracking={true}
        tagTrackingEnhancer={tagTrackingEnhancer}
        isNumber
        onChange={value => onSingleInvestmentChange(value)}
        inputEndAdornment={
          <InputAdornment position="end">{currSymbol}</InputAdornment>
        }
      />

      <BlInputFormField
        label={t(PageResources.PeriodicalInvestment)}
        helperText={periodicalInvestmentFeeRateLabel}
        control={control}
        name="periodicalInvestment"
        errors={formState?.errors}
        min={MINIMUM_DEPOSIT}
        max={MAXIMUM_PERIODICAL_DEPOSIT}
        mask={Number}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        isNumber
        allowEmptyNumber={false}
        hasTracking={true}
        tagTrackingEnhancer={tagTrackingEnhancer}
        onChange={value => onPeriodicalInvestmentChange(value)}
        inputEndAdornment={
          <InputAdornment position="end">{currSymbol}</InputAdornment>
        }
      />
    </>
  );
};
