import { ResourceDictionary } from "Translations/ResourceDictionary";

export const CzechResourcesDictionary: ResourceDictionary = {
  Common: {
    AppName: "INVESTIKA pod palcem",
    Years: "{{count}} let",
    Years_one: "{{count}} rok",
    Years_few: "{{count}} roky",
    Years_other: "{{count}} let",
    YearsLabel: "let",
    YearsLabel_one: "rok",
    YearsLabel_few: "roky",
    YearsLabel_other: "let",
    Continue: "Pokračovat",
    Step: "KROK {{actual}} Z {{count}}",
    TextCopied: "Text byl zkopírován do schránky.",
    Yes: "ano",
    No: "ne",
    Choose: "Vyberte",
    Download: "Stáhnout",
    Downloading: "Stahování",
    UnsupportedFileType: "Nepodporovaný typ souboru",
    Email: "E-mail",
    Password: "Heslo",
    ExampleLogin: "např. antonin.vomacka@email.cz",
    PiecesShort: "{{count, number}} ks",
    PiecesSuffix: "ks",
    Active: "Aktivní",
    Inactive: "Neaktivní",
    Blocked: "Blokováno",
    Canceled: "Zrušeno",
    Ended: "Ukončeno",
    All: "Vše",
    PrivacyPolicyLink: "Zásadách zpracování osobních údajů.",
    Error:
      "Něco se pokazilo. Omlouváme se za komplikace. Zkuste to prosím znovu za chvíli.",
    FileTooBig: "Soubor je příliš velký. Maximální velikost je {{size}} MB.",
    KidTariffDocumentLink:
      "Klíčové informace pro investory a sazebníky k fondům rámcové smlouvy.",
    Leave: "Opustit",
    Stay: "Zůstat",
    DipName: "Dlouhodobý investiční produkt",
    PaymentInstructions: {
      RS_INVCZKSELF_DIP: "Platební instrukce pro zaměstnavatele RS_INVCZKSELF",
      RS_INVEURSELF_DIP: "Platební instrukce pro zaměstnavatele RS_INVEURSELF",
    },
    Close: "Zavřít",
    QuantityUnit: "ks",
    TestVersionTag: "TEST",
    Amount: "Částka",
  },
  Errors: {
    ApplicationError: {
      Title: "Nastala chyba",
      Home: "Domů",
    },
    Error422: {
      ContractDraft:
        "Je nám líto, ale vámi poskytnuté údaje obsahují nepodporované znaky",
    },
    Error404: {
      Subtitle: "Stránka nebyla nalezena",
      Home: "Domů",
    },
    Error403: {
      Subtitle: "K zobrazení této stránky nemáte přístupová práva",
    },
    NetworkErrorFallback: {
      Title: "Nepřipojeno",
      Description:
        "Ověřte prosím vaše připojení k internetu, a zkuste to znovu. Aplikace vyžaduje připojení k internetu pro správné fungování.",
      RefreshButton: "Obnovit",
    },
  },
  Currencies: {
    CZK: "korun",
    EUR: "eur",
  },
  Forms: {
    PersonalData: {
      PersonalData: "Osobní údaje",
      FirstName: "Jméno",
      LastName: "Příjmení",
      Contact: "Kontakt",
      Email: "E-mail",
      Phone: "Telefon",
      PermanentAddress: "Adresa trvalého pobytu",
      StreetAddress: "Ulice a číslo popisné",
      Street: "Ulice",
      StreetConscriptionNumber: "Číslo popisné",
      StreetNumber: "Číslo orientační",
      City: "Město",
      Country: "Stát",
      PostalCode: "PSČ",
      MailingAddressAsPermanentAddress:
        "Korespondenční adresa je shodná s adresou trvalého pobytu.",
      BirthDate: "Datum narození",
      Sex: {
        Female: "Žena",
        Male: "Muž",
        Other: "Jiné",
      },
      TitleBeforeName: "Titul před jménem",
      TitleAfterName: "Titul za jménem",
      BirthCity: "Místo narození (obec)",
      BirthCountry: "Stát narození",
      Gender: "Pohlaví",
      MailingAddress: "Korespondenční adresa",
      PrimaryNationality: "Státní příslušnost",
      Nationalities: "Další státní příslušnost",
      PersonalIdentificationNumber: "Rodné číslo / Národní identifikátor",
    },
    Documents: {
      IdentityCard: "Občanský průkaz",
      DrivingLicense: "Řidičský průkaz",
      ResidencePermit: "Povolení k pobytu",
      Passport: "Pas",
      GunLicense: "Zbrojní průkaz",
      BirthCertificate: "Rodný list",
      ExtractFromCommercialRegister: "Výpis z obchodního rejstříku",
      TradeLicense: "Živnostenský list",
      Other: "Jiný",
    },
    Investments: {
      SingleInvestmentRequired:
        "Pravidelný vklad nemůže být 0, pokud je jednorázový vklad 0.",
      PeriodicalInvestmentFeePart1: "Pravidelný vstupní poplatek:",
      PeriodicalInvestmentFeePart2: " {{value, number}} %",
      SingleInvestmentFeePart1: "Jednorázový vstupní poplatek:",
      SingleInvestmentFeePart2: " {{value, number}} %",
      SingleInvestmentMax:
        "Maximální jednorázový vklad je {{value, number}} {{currencySymbol}}.",
      SingleInvestmentMin:
        "Minimální jednorázový vklad je {{value, number}} {{currencySymbol}}.",
      PeriodicalInvestmentMax:
        "Maximální pravidelný vklad je {{value, number}} {{currencySymbol}}.",
      PeriodicalInvestmentMin:
        "Minimální pravidelný vklad je {{value, number}} {{currencySymbol}}.",
    },
  },
  MaintenancePage: {
    Description:
      "Omlouváme se za nepříjemnosti. Usilovně pracujeme na zlepšení aplikace a jejích funkcí. Pokud potřebujete vyřešit požadavky urgentně, jsme vám k dispozici na klientské lince:",
  },
  StartPage: {
    CreateAccount: "Vytvořit účet",
    SignIn: "Přihlásit se",
  },
  SignIn: {
    Error: {
      General: "Nastala chyba",
      InvalidLogin: "Špatné heslo nebo e-mail",
      InvalidCredentials: "Špatné heslo nebo e-mail",
      ApplicationVerification: "Špatné heslo nebo e-mail",
      PhoneNumberNotFound: "Telefonní číslo nebylo nalezeno",
      SmsSendingFailed: "Selhalo odeslání SMS",
      InvalidToken: "Nevalidní token",
      AccountNotFound: "Špatné heslo nebo e-mail",
      MissingAccessRole: "Do aplikace nemáte přístupové právo",
      DealerAccessRoleNotAllowed:
        "Aplikace je určena koncovým investorům. Klientské smlouvy prosím spravujte v portálu.",
      InvalidClientType:
        "Omlouváme se, ale aplikace je v tuto chvíli učena pouze pro fyzické osoby. Využijte prosím náš webový portál.",
    },
    Title: "Přihlášení",
    ResetPassword: "Obnovte si heslo",
    WelcomeBack: "Vítejte zpět!",
    Username: "Uživatelské jméno nebo e-mail",
    ForgottenPassword: "Zapomenuté heslo",
    SignIn: "Přihlásit",
    SignInBiometrics: "Biometrické přihlášení",
    VerificationToken: "Ověřovací token",
    CheckEmail: "Zkontrolujte e-mail",
    TokenSent: "Na váš e-mail byl odeslán odkaz pro nastavení nového hesla.",
    ToLogin: "Na přihlášení",
    ContactSupport: "Máte potíže s přihlášením? Kontaktujte nás na tel. čísle ",
  },
  SignUp: {
    Error: {
      General: "Nastala chyba",
      UserExists: "Účet již existuje",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
    Password: "Heslo",
    Title: "Vytvořit účet",
    Label:
      "Objevte nové příležitosti investování a zhodnoťte tak svůj majetek.",
    Analytics: {
      Text: "V INVESTICE preferujeme znalost klienta a osobní přístup k němu. INVESTIKA by ráda shromažďovala analytická data, aby vám mohla nabízet produkty dle vašich osobních preferencí. Více informací naleznete v",
      Link: "Zásadách zpracování osobních údajů.",
      ConsentCheckBox: "Souhlasím se zpracováním analytických dat.",
    },
    TermsAndConditions: {
      ConsentCheckBoxText: "Seznámil/a jsem se s",
      ConsentCheckBoxLink: "Obchodními podmínkami.",
      IsTrueMessage: "S obchodními podmínkami je nutné se seznámit.",
    },
    EmailVerification: {
      Title: "Ověřte svůj e-mail",
      VerifyEmail: "Ověřte svůj e-mail",
      EmailSent: {
        PartOne: "Na zadaný e-mail ",
        PartTwo: " jsme vám zaslali kód k ověření.",
      },
      ResendCode: {
        Text: "E-mail nedorazil.",
        Link: "Znovu požádat o odeslání kódu.",
      },
      Code: "Kód",
      Error: {
        General: "Nastala chyba",
        InvalidCode: "Neplatný kód",
      },
    },
    ContactSupport:
      "Máte potíže s vytvořením účtu? Kontaktujte nás na tel. čísle ",
  },
  Validation: {
    ServerError: {
      General: "Nastala chyba",
    },
    General: "Nastala chyba",
    Required: "Vyplňte pole",
    InvalidEmail: "E-mail je nevalidní",
    AlphabetsOnly: "Pro toto pole jsou povoleny pouze písmenka",
    DigitsOnly: "Může obsahovat pouze číslice",
    InvalidPhone: "Telefonní číslo je nevalidní",
    InvalidFormat: "Neplatný formát",
    InvalidBankAccount: "Neplatné číslo účtu",
    InvalidPersonalIdentificationNumber: "Neplatné rodné číslo",
    Number: {
      Integer: "Musí být celé číslo",
      Min: "Musí být větší nebo rovno {{count}}",
    },
    String: {
      Min: "Musí být alespoň {{count}} znaků dlouhé.",
      Max: "Nesmí být delší než {{count}} znaků.",
      StrictLength: "Musí obsahovat {{count}} znaků",
      InvalidFormat: "Neplatný formát",
    },
    Array: {
      Min: "Musí obsahovat alespoň {{count}} položku.",
      Min_one: "Musí být vybrána alespoň {{count}} položka.",
      Min_few: "Musí být vybrány alespoň {{count}} položky.",
      Min_other: "Musí být vybráno alespoň {{count}} položek.",
    },
    Enum: {
      OneOf: "Vyberte možnost",
    },
  },
  Transactions: {
    Type: {
      Buy: "Nákup",
      Sell: "Prodej",
      Withdrawal: "Výběr",
      LeadReward: "Odměna za doporučení",
    },
    Status: {
      Completed: "Provedeno",
      PendingTrade: "Zpracovává se",
      WithdrawRequested: "Probíhá výběr",
      Withdrawn: "Provedeno",
    },
  },
  ForgottenPassword: {
    Error: {
      General: "Nastala chyba.",
      UserNotFound: "Uživatel nebyl nalezen.",
    },
    Title: "Zkontrolujte email",
    Subtitle: {
      FirstPart: "Na zadaný e-mail ",
      SecondPart: " jsme vám zaslali kód k ověření.",
    },
    OldPortalUserNotice:
      "Z důvodu uplynutí dlouhé doby od vašeho posledního přihlášení, vaše heslo expirovalo. Prosím obnovte si své heslo.",
    Code: {
      Label: "Kód",
      ResendCode: {
        FirstPart: "Email nedorazil.",
        SecondPart: "Odeslat kód znovu.",
      },
    },
    NewPassword: {
      Label: "Nové heslo",
    },
    ConfirmPassword: {
      Label: "Potvrzení hesla",
    },
    Submit: "Nastavit heslo",
  },
  BankIDVerification: {
    Error: {
      General: "Autentizace přes BankID se nezdařila. Zkuste to prosím znovu.",
      BankIDUserInfoError: "Nepodařilo se získat data z BankID.",
      VerificationAlreadyExists: "Uživatel z BankID byl již ověřen.",
    },
  },
  Navigation: {
    Dashboard: "Přehled",
    NewInvestment: "Nová investice",
    More: "Více",
  },
  Investments: {
    NewInvestmentForm: {
      Title: "Nová investice",
      Label: "Řekněte nám více o své nové investici.",
      Placeholder: "(Svou investici si můžete pojmenovat)",
      Name: "Název investice",
      DefaultName: "Smlouva ze dne",
      Categories: "Kategorie (nepovinné)",
    },
    FundSelection: {
      Label: "Vyberte si produkt, kterým dosáhnete svého cíle.",
      Invest: "Investovat",
      FundDescription: "Popis fondu",
      ExpectedAppreciation: {
        Label: "Očekávané zhodnocení:",
        Format: "{{min}}-{{max}} % p.a.",
        Default: "Očekávané zhodnocení: {{min}}-{{max}} % p.a.",
        Efektika: "Očekávané zhodnocení: více než 7 % p.a.",
      },
      ClassLabel: "Třída {{class}}",
      ClassSelection: "Vyberte si, do jaké třídy fondu chcete investovat",
      AllowedInvestment: "Umožňuje investici od {{value}} {{currency}}.",
      FundSelectOptions: {
        Investika: {
          Description: "INVESTICE PODEPŘENÉ CIHLOU A BETONEM",
        },
        Monetika: {
          Description: "ALTERNATIVA KE SPOŘICÍMU ÚČTU BEZ LIMITŮ",
        },
        Efektika: {
          Description: "INVESTICE DO NEJVĚTŠÍCH FIREM SVĚTA",
        },
        Euromonetika: {
          Description: "INVESTIČNÍ ALTERNATIVA K EUROVÝM SPOŘICÍM ÚČTŮM",
        },
      },
      DIP: {
        Card: {
          Title: "Sjednat v režimu DIP",
          Description:
            "Sjednejte si novou investici jako dlouhodobý investiční produkt. ",
          Link: "Více informací",
        },
        Dialog: {
          Title: "DIP",
        },
      },
    },
    FundConfiguration: {
      Label:
        "Uveďte, jak chcete investovat,   a zjistěte, jakou částku získáte, nebo za jak dlouhou dobu svého cíle dosáhnete.",
      FutureInvestment:
        "Kdykoli v budoucnu se můžete rozhodnout, jestli výši své pravidelné investice upravíte nebo zda jednorázově zainvestujete další peníze.",
      FutureInvestmentActivator: "(více)",
      FutureInvestmentDeactivator: "(méně)",
      SelectButtonsLabel: "Jak chcete investovat",
      EndEstimatedValue: "Předpokládaná hodnota na konci",
      ProgressDisplay: "Znázornění průběhu",
      MakeContract: "Uzavřít smlouvu",
      ChartBarLabel: "Vklady",
      ChartLineLabel: "Předpokládaný výnos",
      SelectButtonFirstOption: "Vím, kolik odkládat",
      SelectButtonSecondOption: "Znám cílovou částku",
      TargetAmount: "Cílová částka",
      SingleInvestment: "Jednorázový vklad",
      PeriodicalInvestment: "Pravidelný vklad",
      InvestmentLength: "Délka investování",
      DealerSelection: "Výběr poradce",
      DealerSelectionButtonLabel: "Potřebujete poradce?",
      DealerSelectionDescription:
        "Vyberte si, zda chcete o uzavření smlouvy informovat některého ze svých stávajících poradců, nebo se obejdete bez poradce.",
      DealerSelectionConsentText:
        "V případě výběru poradce bude smlouva předána do správy vybraného poradce. Stisknutím tlačítka „Pokračovat“ berete na vědomí, že osobní údaje ze smlouvy budou v daném případě předány vybranému poradci. Bližší informace o zpracování osobních údajů jsou k dispozici v ",
      DealerSelectionConsentLink: "zásadách zpracování osobních údajů.",
      Distribution: "Distribuce",
      NoDealer: "Bez poradce",
      InvestmentWarning:
        "UPOZORNĚNÍ: Předpokládané či odhadované výnosy plynoucí z investic do cenných papírů jednotlivých investičních fondů nejsou zaručené a zaručena není ani návratnost vložených prostředků. Informace o minulé výkonnosti nebo odhadované budoucí výkonnosti fondů slouží pouze pro ilustraci a nejsou zárukou jakéhokoli budoucího výnosu. Tržní hodnota jednotlivých cenných papírů jednotlivých fondů může v čase jak růst, tak i klesat. Podrobné informace o rizicích spojených s investicí do fondů jsou uvedeny v jejich statutech a ve sdělení klíčových informací, které jsou k dispozici",
      InvestmentWarningLink: "na našich webových stránkách.",
      ExpectedAppreciation:
        "S předpokládaným očekávaným zhodnocením {{value}} % p.a.",
      TargetAmountInputInfo:
        "Od cílové částky jsou již odečteny vstupní poplatky.",
      TargetAmountInfo:
        "Předpokládaná hodnota na konci je spočítána po odečtení vstupních poplatků.",
      TotalAmount: "Celková hodnota vkladů",
    },
    InvestmentAlternativeName: "Smlouva z {{date}}",
  },
  Dashboard: {
    TotalValue: "Celková hodnota",
    YourGoals: "Vaše cíle",
    CurrentValue: "Aktuální hodnota:",
    InvestingRemains: "Doporučená minimální doba investování:",
    GoalDetails: "Detail cíle",
    InvestWaitingLabel1: "Vaše investice čeká na váš první vklad.",
    InvestWaitingLabel2:
      "Jakmile dorazí váš vklad na náš účet, provedeme jejich zainvestování.",
    ShowPaymentInformation: "Zobrazit platební údaje",
    PaymentInformation: "Platební údaje",
    CardWaitingTransaction: "Čeká na první platbu.",
    PurchaseButton: "Přikoupit",
    Profit: "Výnos:",
    ContractsUnavailabilityLabel: "Nevidíte svou smlouvu?",
    ContractsUnavailabilityTooltip:
      "Tato aplikace zobrazuje pouze smlouvy o investicích do fondů, které obhospodařuje investiční společnost INVESTIKA. Fondy, které společnost INVESTIKA pouze administruje, je nutné obsluhovat prostřednictvím jejich obhospodařovatele. Zároveň jsou zobrazeny pouze smlouvy, kde jste vlastníkem, nikoli zastupující osobou.",
  },
  WelcomeDashboard: {
    Title: "Už jen krůček k první investici",
    Description:
      "Prohlédněte si nabídku našich produktů a začněte hned investovat!",
    BeginInvestingButton: "Začít",
  },
  Contract: {
    NewContract: {
      Title: "Nová investice",
      TerminateModalTitle: "Opravdu chcete opustit podpis?",
      Shared: {
        USACountryPermit:
          "Omlouváme se, avšak v tuto chvíli nelze sjednat rámcovou smlouvu o vydávání a odkupování podílových listů s osobami, které mají americké státní občanství (popřípadě se narodili ve Spojených státech amerických).",
        VATIdInvalid: "DIČ musí být délky 8-20 znaků.",
        PhoneVerification: {
          Label1: "Na vámi zadané telefonní číslo",
          Label2:
            "jsme odeslali kód {{codeDetermination}}. Opište prosím tento kód do políčka níže.",
          Code: "Kód",
          CodeDescription: "SMS nedorazila.",
          ResendCode: "Znovu požádat o odeslání kódu.",
        },
        BankAccountWarning: {
          WithAccount:
            "Upozorňujeme, že první platba musí být odeslána z účtu {{value}}, který jste uvedli jako své bankovní spojení!",
          WithoutAccount:
            "Upozorňujeme, že první platba musí být odeslána z účtu, který jste uvedli jako své bankovní spojení!",
          RequireMessage:
            "Platba musí obsahovat zprávu pro příjemce uvedenou výše.",
        },
      },
      PreContractualInformation: {
        Title: "Předsmluvní informace",
        Subtitle: "Níže vám předkládáme předsmluvní informace.",
      },
      PathSelection: {
        PersonalData: "Osobní údaje",
        InfoText1:
          "Rádi bychom teď o vás zjistili pár informací, abychom vám mohli poskytnout naše služby.",
        InfoText2:
          "Udělat to lze buď ručně, nebo pomocí  bankovní identity, která potvrdí vaši totožnost a vlastnictví bankovního účtu.",
        CheckboxBankIdentityName2: "bankovní identitou",
        CheckboxBankIdentityName1: "Ověřím se",
        CheckboxManualName: "Nemám bankovní identitu",
        CheckboxManualLabel: "Zadám údaje ručně a nahraji fotky dvou dokladů.",
        MoreInformation: "Více informací naleznete v",
        BankIDTooltip:
          "Svou totožnost ověříte prostřednictvím své banky. Bank ID za vás rovnou předvyplní vaše osobní údaje.",
        Validation: {
          Required: "Vyberte možnost",
        },
      },
      PersonalInformation: {
        Title: "Osobní údaje",
        Label: "Prosím, vyplňte nebo zkontrolujte své osobní údaje.",
        Nationality: "Státní příslušnost",
        AnotherNationality: "Další státní příslušnost",
        PersonalIdentificationNumberVerification: {
          Title: "Ověření Bankovní identity",
          Label:
            "Abychom mohli využít vaše osobní údaje získané z bankovní identity, jako výraz souhlasu prosím zadejte své rodné číslo bez lomítka.",
        },
        PersonalIdentificationNumber: "Rodné číslo / Národní identifikátor",
        DeleteNationality: "Smazat státní příslušnost",
        AddNationality: "Přidat další státní příslušnost",
        FirstName: "Jméno",
        LastName: "Příjmení",
        Constant: "Kontakt",
        Phone: "Telefon",
        PersonalIdentificationNumberMismatch:
          "Rodné číslo se neshoduje s rodným číslem v BankID",
        UnderAge: "Nejste plnoletý",
        BankIDError:
          "Litujeme, ale Bank ID proces selhal, zkuste registrační proces bez Bank ID.",
        BankIDMissingValuesError:
          "Je nám líto, ale vaše banka neposkytla veškeré údaje, které potřebujeme pro vaši identifikaci. Pokračujte prosím výběrem zasmluvnění bez BankID.",
        DuplicityMessage:
          "Byl nalezen jiný účet s tímto rodným číslem. Pro vytvoření investice se prosím přihlaste pod tímto účtem.",
        DuplicityConfirmation:
          "Chcete uložit stav průvodce, odhlásit se a pokračovat pod jiným účtem?",
      },
      ContactVerification: {
        Title: "Ověření kontaktu",
        CodeDetermination: "k ověření kontaktu",
        BadCode: "Zadaný kód se neshoduje s kódem zaslaným na telefon.",
        PhoneValidated:
          "Telefonní číslo bylo jíž ověřeno. Můžete pokračovat na další krok.",
      },
      BankConnection: {
        Title: "Bankovní spojení",
        Label:
          "Sdělte nám prosím číslo bankovního účtu, jehož jste majitelem a z něhož zašlete svou první investici.",
        BankNumber: "Číslo účtu",
        Iban: "IBAN",
        BankCode: "Kód banky",
        CzIbanDenied: "Pro české bankovní spojení není dovolen formát IBAN.",
        BankCurrency: "Měna účtu",
      },
      Documents: {
        Title: "Doklady",
        Label: "Vyplňte prosím údaje ze dvou dokladů totožnosti.",
        FirstDocumentTitle: "První doklad",
        FirstDocumentFront: "Přední strana prvního dokladu",
        FirstDocumentBack: "Zadní strana prvního dokladu",
        FirstDocumentDescription: "Zadní strana prvního dokladu",
        SecondDocumentTitle: "Druhý doklad",
        SecondDocumentFront: "Přední strana druhého dokladu",
        SecondDocumentDescription:
          "Druhým dokladem může být řidičský průkaz, cestovní pas, zbrojní průkaz, nebo povolení k pobytu.",
        ExpiryDateBelowValidity: "Nesmí být menší než platnost dokladu.",
        DocumentIndefiniteExpiryDateNotAllowed:
          "Tento doklad není platný s neomezenou platností.",
        DocumentExpired: "Doklad je neplatný",
        DocumentType: "Typ dokladu",
        DocumentNumber: "Číslo dokladu",
        DocumentValidStart: "Platnost od",
        DocumentExpireDate: "Platnost do",
        DocumentIsExpiryDateIndefinite: "Neomezená platnost",
        CountryIssue: "Stát vydání",
        IssuingAuthority: "Doklad vydal",
        IssuingAuthorityPlaceholder: "Zadejte vydávající úřad",
        BankIDIssuingAuthorityPlaceholder: "Neuvedeno v BankID",
        MustBeIdentityCard: "Prosím, vyberte občanský průkaz",
        CannotBeIdentityCard: "Nemůže být občanský průkaz",
      },
      ContactInformation: {
        Title: "Kontaktní údaje",
        Label: "Prosím, vyplňte nebo zkontrolujte své kontaktní údaje.",
        PermanentAddress: "Adresa trvalého pobytu",
        MailingAddress: "Korespondenční adresa",
        StreetAddress: "Ulice a číslo popisné",
        City: "Město",
        Country: "Stát",
        PostalCode: "PSČ",
        MailingAddressAsPermanentAddress:
          "Korespondenční adresa je shodná s adresou trvalého pobytu.",
      },
      AMLQuestions: {
        Title: "AML dotazník",
        Label:
          "Teď prosím ještě vyplňte povinný dotazník, který vyplývá z právních předpisů upravujících problematiku o boji proti praní špinavých peněz.",
        AnnualIncome: "Výše čistého ročního příjmu",
        IncomeSource: "Zdroj příjmů",
        FieldOfActivity: "Obor činnosti",
        JobPosition: "Pracovní pozice",
        JobPositionPopper:
          "Vedoucí pozicí je myšlena pracovní pozice s významných rozhodujícím vlivem na řízení zaměstnavatele – tj. zejm. statutární orgán/výkonný ředitel/finanční ředitel.",
        SourceOfFunds:
          "Jaký je zdroj peněžních prostředků, které budou investovány?",
        FundsVolume: "Objem prostředků",
        PoliticallyExposed: "Jste politicky exponovaná osoba?",
        PoliticallyExposedDescription:
          "Pokud jste politicky exponovanou osobou, uveďte informace o původu majetku (např. příjmy z podnikání včetně specifikace podnikatele, příjmy ze zaměstnání včetně uvedení zaměstnavatele, příjmy z prodeje majetku, z daru, dědictví, z výživného, důchodu či jiných dávek, jiné zdroje):",
        PoliticallyExposedPopper:
          "Politicky exponovanou osobou je fyzická osoba ve smyslu § 4 odst. 5 AML zákona, a to: a) fyzická osoba, která je nebo byla ve významné veřejné funkci s celostátním nebo s regionálním významem, jako je zejména hlava státu, předseda vlády, vedoucí ústředního orgánu státní správy a jeho zástupce (náměstek, státní tajemník), člen parlamentu, člen řídícího orgánu politické strany, vedoucí představitel územní samosprávy, soudce nejvyššího soudu, ústavního soudu nebo jiného nejvyššího justičního orgánu, proti jehož rozhodnutí obecně až na výjimky nelze použít opravné prostředky, člen bankovní rady centrální banky, vysoký důstojník ozbrojených sil nebo sboru, člen nebo zástupce člena, je-li jím právnická osoba, statutárního orgánu obchodní korporace ovládané státem, velvyslanec nebo vedoucí diplomatické mise, anebo fyzická osoba, která obdobnou funkci vykonává nebo vykonávala v jiném státě, v orgánu Evropské unie anebo v mezinárodní organizaci, b) fyzická osoba, která je 1. osobou blízkou k osobě uvedené v písmenu a), 2. společníkem nebo skutečným majitelem stejné právnické osoby, popřípadě svěřenského fondu nebo jiného právního uspořádání bez právní osobnosti, jako osoba uvedená v písmenu a), nebo je o ní povinné osobě známo, že je v jakémkoli jiném blízkém podnikatelském vztahu s osobou uvedenou v písmenu a), nebo 3. skutečným majitelem právnické osoby, popřípadě svěřenského fondu nebo jiného právního uspořádání bez právní osobnosti, o kterých je povinné osobě známo, že byly vytvořeny ve prospěch osoby uvedené v písmenu a). Povinnosti a omezení vztahující se k politicky exponovaným osobám se uplatní ještě nejméně po dobu 12 měsíců ode dne, kdy politicky exponovaná osoba přestala vykonávat příslušnou funkci.",
        TaxResidency: "Uveďte stát vaší daňové rezidentury",
        TaxResidencyOptions: {
          Czechia: "Česká republika",
          Slovakia: "Slovenská republika",
          USA: "USA",
          Other: "Jiný",
        },
        SourceOfFundsOptions: {
          Savings: "úspory z výše uvedených příjmů",
          SaleOfProperty: "prodej majetku",
          Heritage: "dědictví",
          Other: "jiné (např. úspory)",
        },
        JobPositionOptions: {
          ManagerialPosition: "vedoucí pozice",
          NonManagerialPosition: "nevedoucí pozice",
        },
        SourceOfIncomeOptions: {
          Salary: "mzda/plat",
          Business: "podnikání",
          Rent: "důchod/výživné/dávky (nezletilá osoba, student)",
          Dividend:
            "příjmy z kapitálu (pronájem nemovitostí, podíl na společnostech apod.)",
          Other: "jiné (uveďte)",
        },
        AnnualIncomeOptions: {
          NetIncomeTypeA: "do {{value1}} Kč/do {{value2}} EUR",
          NetIncomeTypeB:
            "od {{value1}} Kč až do {{value2}} Kč/od {{value3}} EUR až do {{value4}} EUR",
          NetIncomeTypeC:
            "od {{value1}} Kč až do {{value2}} Kč/od {{value3}} EUR až do {{value4}} EUR",
          NetIncomeTypeD: "nad {{value1}} Kč/nad {{value2}} EUR",
        },
        TaxResidencyUSAPopper:
          "V tuto chvíli bohužel neposkytujeme služby daňovým rezidentům USA. Děkujeme vám za pochopení.",
        Roughly: "Orientačně",
        OtherTaxResidencySelectLabel: "Vyberte stát",
        VAT: {
          Label: "DIČ",
          Tooltip:
            "DIČ, případně jiné identifikační číslo pro účely danění v příslušném státě.",
        },
      },
      LastInformation: {
        Title: "Závěrečná sdělení",
        AcceptPersonalDataMarketing:
          "Souhlasím se zpracováním osobních údajů pro marketingové účely. Více informací naleznete v ",
        AcceptPersonalDataMarketingLink: "Zásadách zpracování osobních údajů.",
        VoluntarilyDecision:
          "Prohlašuji, že investiční rozhodnutí činím dobrovolně a z vlastního podnětu.",
        DecisionRequired: "Prohlášení je povinné",
        ClientAgreeTakeoverData:
          "Souhlasím s předáváním osobních údajů osobám propojeným s INVESTIKA. Více informací naleznete v ",
        DeclareContractingDataValidity:
          "Prohlašuji, že údaje a dokumenty, které jsem poskytl v rámci procesu zasmluvnění, jsou pravdivé, úplné, aktuální a přesné.",
      },
      ContractPreview: {
        Title: "Náhled smlouvy",
        Label: "Před podpisem smlouvy si pečlivě přečtěte její návrh.",
        SignContract: "Potáhnutím přejdete k podpisu",
        FileName: "ramcova-smlouva-nahled",
        KidTariffDocumentLabel:
          "Klíčové informace pro investory a sazebníky k fondům rámcové smlouvy.",
        DIP: {
          LabelParts: {
            First: "Před podpisem ",
            Second: "Rámcové smlouvy ",
            Third: "a smlouvy o ",
            Fourth: "DIP ",
            Fifth: "si pečlivě přečtěte její návrh, který je uveden níže.",
          },
        },
        PdfPreviewError:
          "Nastala chyba při zobrazení náhledu smlouvy, prosím, stáhněte si soubor do vašeho zařízení.",
      },
      ContractSignature: {
        Title: "Podpis smlouvy",
        SignContract: "Podepsat smlouvu",
        CodeDetermination: "k podpisu smlouvy",
        WorkingText: "Chystáme pro vás podpis smlouvy, prosíme o strpení...",
        VerificationError: "Ověřovací kód se neshoduje.",
        PersonalIdentificationNumberMismatch:
          "Je nám líto, není možné se nově zasmluvnit jako jiný klient než již dříve zasmluvněný klient z tohoto účtu.",
        DistributionNotAllowed:
          "Je nám líto, chcete-li sjednat novou investici, kontaktujte vašeho poradce a sdělte mu svůj požadavek.",
        DipUnavailable:
          "Je nám líto, není možné v tento moment sjednat novou investici jako dlouhodobý investiční produkt. Vraťte se zpět a vyberte jiný produkt z naší nabídky.",
        SignatureHashNotFoundError:
          "Požadavek na ověření pomocí SMS vypršel. Vraťte se zpět a přejděte k podpisu smlouvy.",
        Biometrics: "Probíhá ověření pomocí biometrie.",
        BiometricsError:
          "Ověření pomocí biometrie selhalo. Vraťte se zpět a smlouvu podepište pomocí SMS kódu.",
        BiometricsInProcessError:
          "Požadavek na ověření pomocí biometrie vypršel. Vraťte se zpět a přejděte k podpisu smlouvy.",
      },
      FinalPage: {
        Title: "Gratulujeme!",
        Label: "Smlouvu jsme přijali ke zpracování. ",
        ButtonText: "Pokračovat na přehled investic",
      },
      BankID: {
        Link: "Přejít na stránky BankID",
        WaitingForBankID:
          "Probíhá ověření pomocí BankID. Pokud krok selže, můžete se vrátit zpět pomocí šipky v levém horním rohu.",
      },
    },
    Detail: {
      PageTitle: "Detail investice",
      Tabs: {
        Overview: "Přehled",
        Transactions: "Transakce",
        CashMovement: "Peněžní pohyby",
        Dealer: "Poradce",
        Contract: "Smlouva",
        Accounts: "Účty",
        PaymentInformation: "Platební údaje",
        UnsettledMovements: "Nevypořádané pohyby",
      },
      Overview: {
        CurrentValue: "Aktuální hodnota",
        InvestmentValueTooltip:
          "Hodnota aktuálního majetku je součet zainvestovaných vkladů, peněžního zůstatku a peněz na cestě",
        Detail: "Detail",
        PurchaseButton: "Investovat peníze",
        RedemptionButton: "Vybrat peníze",
        ToMoveButton: "Přesunout",
        TotalDeposits: "Celkové vklady",
        InvestedDeposits: "Zainvestované vklady",
        TotalWithdraws: "Celkové odkupy",
        RealizedProfit: "Realizovaný zisk",
        UnrealizedProfit: "Nerealizovaný zisk",
        UnrealizedProfitTooltip:
          "Nerealizovaný zisk je rozdíl mezi sumou pořizovacích hodnot cenných papírů a aktuální hodnotou cenných papírů k datu výpisu.",
        UninvestedDeposits: "Peněžní zůstatek",
        UninvestedDepositsTooltip: {
          DailyTradeFrequency:
            "Denní četnost obchodování: „Vaše prostředky budou zainvestovány při nejbližším obchodním dni.“",
          MonthlyTradeFrequency:
            "Měsíční četnost obchodování: „Realitní fond je obchodován 1x měsíčně. Vaše peníze budou zainvestovány při nejbližším obchodním dni.“",
          UnknownIsin: "Popisek není k dispozici.",
        },
        PaidEntryFees: "Zaplacené vstupní poplatky",
        LastDepositDate: "Datum posledního vkladu",
        InvestDeposit: "Zainvestovat zůstatek",
        Progression: "Vývoj",
        ActualPercent: "Cíl",
        ChartBarLabel: "Vklady",
        ChartLineLabel: "Hodnota",
        InvestmentComposition: "Složení investice",
        Estate: "Majetek",
        AwaitingInvestmentLabel: "Čeká na zainvestování",
        AwaitingInvestedTooltipText:
          "Částka bude zainvestována při nejbližším obchodním dni daného fondu.",
        Change: "Vyměnit",
        Buy: "Přikoupit",
        DepositMoney: "Vložit peníze",
        ActualValue: "Aktuální hodnota",
        SecuritiesPieces: "Počet kusů CP",
        PaymentInformation: {
          Title: "Už jen krůček!",
          Description:
            "Vaše peníze ještě nedorazily. Zobrazte si platební údaje z modelace smlouvy.",
          ButtonText: "Zobrazit platební údaje",
        },
      },
      PaymentInformation: {
        Title: "Vyberte, do kterého fondu chcete poslat peníze.",
        PortfolioItemsNotFound:
          "Na základě existující smlouvy uzavřené prostřednictvím vašeho poradce nebylo dosud do žádného z našich produktů zainvestováno. Není tak možné poskytnout vám zde konkrétní platební údaje. Obraťte se prosím za tímto účelem na svého poradce. Děkujeme za pochopení.",
      },
      Transactions: {
        Deposit: "Vklad",
        Buyout: "Odkup",
        NoneTransactionsFoundPlaceholder: "Zatím nemáte žádné záznamy.",
        Filter: {
          Dialog: {
            Title: "Filtr",
            DateRange: "Období",
            DateFrom: "Od",
            DateTo: "Do",
            Include: "Zobrazit",
            IncludePurchases: "Nákupy",
            IncludeRedemptions: "Odkupy",
            IncludeAssetsExchanges: "Výměny",
            IncludeAssetsTransfers: "Převody",
            Apply: "Použít",
            Open: "Filtrovat",
            Close: "Zrušit",
          },
        },
      },
      CashMovements: {
        NoneTransactionsFoundPlaceholder: "Zatím nemáte žádné záznamy.",
        Filter: {
          Dialog: {
            Title: "Filtr",
            DateRange: "Období",
            DateFrom: "Od",
            DateTo: "Do",
            Include: "Zobrazit",
            IncludeDeposits: "Vklady",
            IncludeWithdrawals: "Výběry",
            IncludeFees: "Poplatky",
            Apply: "Použít",
            Open: "Filtrovat",
            Close: "Zrušit",
          },
        },
      },
      UnsettledTrades: {
        NoneTransactionsFoundPlaceholder: "Zatím nemáte žádné záznamy.",
      },
      BankAccounts: {
        CreateBankConnectionButton: "Přidat bankovní účet",
      },
      Contract: {
        Details: "Podrobnosti",
        ContractStatus: "Stav smlouvy",
        ContractType: "Typ smlouvy",
        ContractNumber: "Číslo smlouvy",
        Documents: "Dokumenty",
        ManagementLabel: "Správa smlouvy",
        ManagementText:
          "Smlouva je ve správě vašeho poradce. Pokud si přejete provádět změny nebo jiné operace prosím, kontaktujte ho.",
        DipOpeningDateLabel: "Sjednání dlouhodobého investičního produktu",
      },
      Dealer: {
        Call: "Zavolat",
        SendMail: "Poslat e-mail",
      },
      Purchase: {
        SingleInvestment: "Jednorázový vklad",
        PeriodicalInvestment: "Pravidelný vklad",
        ExpectedAppreciation: "Očekávané zhodnocení:",
        ExpectedAppreciationValue: "{{min}}-{{max}} % p.a.",
        PurchaseButton: "Přikoupit",
        DetailButton: "Na detail cíle",
        Amount: "Částka",
        Product: "Produkt",
        Unlimited: {
          Title: "Přikoupit",
        },
        Target: {
          Title: "Zainvestovat",
          Confirmation: {
            Title: "Zainvestovat",
            WorkingText:
              "Chystáme pro vás podpis pokynu k nákupu, prosíme o strpení...",
            CodeDetermination: "k potvrzení zainvestování",
            Submit: "Potvrdit",
            Biometrics: "Potvrzení biometrickými údaji",
            BiometricsError:
              "Ověření pomocí biometrie selhalo. Vraťte se zpět a pokyn k nákupu podepište pomocí SMS kódu.",
            BiometricsInProcessError:
              "Požadavek na ověření pomocí biometrie vypršel. Vraťte se zpět a přejděte k podpisu pokynu k nákupu.",
            SignatureHashNotFoundError:
              "Požadavek na ověření pomocí SMS vypršel. Vraťte se zpět a přejděte k podpisu pokynu k nákupu.",
            VerificationError: "Ověřovací kód se neshoduje.",
          },
          Success: {
            Title: "Hotovo!",
            Text: "Vámi podepsaný pokyn k nákupu byl zadán ke zpracování.",
            Button: "Na detail cíle",
          },
        },
        ProductsNotFound:
          "Smlouva je ve správě vašeho poradce. Pro zainvestování pěněžitých prostředků prosím, kontaktujte ho.",
      },
      Redemption: {
        DipDialog: {
          Confirmation:
            "Společnost tímto Klienta informuje a upozorňuje, že provedení odkupu cenných papírů dle této smlouvy může mít za následek vznik povinnosti Klienta vrátit daňovou podporu poskytnutou Klientovi jako důsledek toho, že odkupované cenné papíry tvoří součást majetku v rámci dlouhodobého investičního produktu, který je Klientovi poskytován Společností na základě smlouvy o dlouhodobém investičním produktu, která byla mezi Klientem a Společností uzavřena.",
        },
        Configuration: {
          Title: "Pokyn k prodeji",
          CPCount: "Počet kusů cenných papírů",
          Parameters: "Kam zaslat peníze",
          Method: {
            Title: "Způsob prodeje",
            Pieces: "Prodat konkrétní počet cenných papírů",
            Amount: "Získat konkrétní finanční částku",
          },
          Type: {
            Title: "Typ odkupu",
            Single: "Jednorázový odkup",
          },
          Pieces: {
            Title: "Počet prodávaných cenných papírů",
            Validation: {
              Min: "Minimální počet ks CP k odkupu je 1.",
              Max: "Maximální počet ks CP k odkupu je {{max}}.",
            },
          },
          Amount: {
            Title: "Částka k odkupu",
            Validation: {
              Min: "Minimální částka k odkupu je 100 Kč.",
              Max: "Maximální částka k odkupu je {{max}} Kč.",
            },
          },
          ConfirmationButton: "Potáhnutím odkup potvrdíte",
          BankAccount: {
            Title: "Bankovní účet",
          },
          DipCancelAgree: {
            Title:
              "Produkty tvoří součást majetku v rámci dlouhodobého investičního produktu. Provedení odkupu může mít za následek vznik povinnosti vrátit daňovou podporu poskytnutou v souvislosti s dlouhodobým investičním produktem, a rovněž i ztrátu daňové podpory do budoucna a nebude se tak již jednat o dlouhodobý investiční produkt. Jste si tohoto vědomi, a přejete si i přesto pokračovat a odkup provést?",
            Label:
              "Beru na vědomí, a přeji si i přesto pokračovat a odkup provést",
            DecisionRequired: "Prohlášení je povinné",
          },
          RedeemAll: "Odkoupit vše",
        },
        Confirmation: {
          Title: "Pokyn k prodeji",
          WorkingText:
            "Chystáme pro vás podpis pokynu k prodeji, prosíme o strpení...",
          CodeDetermination: "k podpisu pokynu k prodeji",
          Submit: "Podepsat prodej",
          Biometrics: "Potvrzení biometrickými údaji",
          BiometricsError:
            "Ověření pomocí biometrie selhalo. Vraťte se zpět a pokyn k prodeji podepište pomocí SMS kódu.",
          BiometricsInProcessError:
            "Požadavek na ověření pomocí biometrie vypršel. Vraťte se zpět a přejděte k podpisu pokynu k prodeji.",
          SignatureHashNotFoundError:
            "Požadavek na ověření pomocí SMS vypršel. Vraťte se zpět a přejděte k podpisu pokynu k prodeji.",
          VerificationError: "Ověřovací kód se neshoduje.",
        },
        Success: {
          Title: "Hotovo!",
          Text: "Vámi podepsaný pokyn k prodeji byl zadán ke zpracování.",
          Button: "Do přehledu investic",
        },
        NotAllowedFund: {
          Title: "Nelze odkoupit",
          Disclaimer: {
            Part1: "Podle",
            TosLinkText: "VOP",
            Part2: "musí",
            FormLinkText: "žádost o jednorázový i pravidelný odkup",
            Part3:
              "cenných papírů splňovat podmínky, které jsou uvedeny v kapitole „Postupy a podmínky pro odkupování Podílových listů“",
            StatusLinkText: "statutu",
            Part4: "fondu.",
          },
          Close: "Zavřít",
        },
      },
      DipTransfer: {
        Title: "Převod na DIP",
      },
    },
    DipTransfer: {
      Title: "Převod na DIP",
      AlreadyTransferredMessage:
        "Je nám líto, není možné převést investici na dlouhodobý investiční produkt.",
      UnavailableMessage:
        "Je nám líto, není možné v tento moment převést investici na dlouhodobý investiční produkt. Zkuste to prosím znovu za chvíli.",
      Information: {
        Title: "Převod na DIP",
        Description:
          "Čerpejte výhody tohoto produktu převedením této smlouvy do režimu DIP.",
        MoreInformation: {
          Title: "Více informací",
          Description: {
            FirstPart:
              "V rámci DIP nemusíte začít spořit od nuly. Můžete si do něj převést již existující investice do fondů z rodiny INVESTIKA a dál si k nim odkládat podle pravidel DIP.",
            SecondPart: {
              FirstPart: "DIP je při splnění zákonem požadovaných podmínek (",
              SecondPart:
                "tj. odkup vydaných cenných papírů lze učinit zejména, nikoliv však výlučně, až po 120 měsících od uzavření smlouvy o DIP, nejdříve však v kalendářním roce, ve kterém Klient dosáhne 60 let věku",
              ThirdPart:
                ")  daňově podporovaným produktem spoření na stáří. Provedení odkupu cenných papírů tvořících součást majetku v rámci DIP může mít za následek vznik povinnosti Klienta vrátit daňovou podporu poskytnutou Klientovi v souvislosti se smlouvou o DIP, která bude uzavřena mezi Klientem a Společností, a to zejména, nikoliv však výlučně, pokud Klient provede jakýkoliv odkup cenných papírů dříve než stanoví příslušné právní předpisy (plnění daňových povinností v souvislosti s dlouhodobým investičním produktem je povinností Klienta).",
            },
          },
        },

        KeyProperties: {
          Title: "Hlavní vlastnosti DIP",
          MoreInfo: "Další informace",
          Properties: {
            TaxBenefit: "Daňové zvýhodnění",
            EmployerContribution: "Příspěvek zaměstnavatele",
            TimeSpanRequirement: "Trvání po dobu nejméně 10 let",
            WithdrawalAgeRequirement: "Výběr nejdříve v 60 letech věku",
            HigherYieldPotential: "Vyšší výnosový potenciál",
            SavingsAndWithdrawalFlexibility: "Flexibilita při spoření i výběru",
          },
        },
        Transfer: {
          Title: "Převést smlouvu",
          Description:
            "K převodu stávající smlouvy na DIP je nutné odsouhlasit níže uvedené prohlášení a poté elektronicky podepsat smlouvu o dlouhodobém investičním produktu.",
          ConfirmationText:
            "Potvrzuji, že jsem zcela srozuměn s důsledky uzavření smlouvy o dlouhodobém investičním produktu a jejími dopady na cenné papíry, které budou součástí mého majetku v rámci dlouhodobého investičního produktu.",
        },
        DecisionRequired: "Prohlášení je povinné",
      },
      Preview: {
        Title: "Převod na DIP",
        Description:
          "Před podpisem smlouvy o DIP si pečlivě přečtěte její návrh, který je uveden níže.",
        Download: "Stáhnout náhled",
        ConfirmText: "Potáhnutím přejdete k podpisu",
        FileName: "ramcova-smlouva-nahled",
        SignatureTrackingTag: "ContractTransferDipPreview",
      },
      Signature: {
        Title: "Převod na DIP",
        WorkingText: "Chystáme pro vás podpis smlouvy, prosíme o strpení...",
        CodeDetermination: "k podpisu pokynu k převodu smlouvy",
        Submit: "Podepsat převod smlouvy",
        Biometrics: "Potvrzení biometrickými údaji",
        BiometricsError:
          "Ověření pomocí biometrie selhalo. Vraťte se zpět a smlouvu podepište pomocí SMS kódu.",
        BiometricsInProcessError:
          "Požadavek na ověření pomocí biometrie vypršel. Vraťte se zpět a přejděte k podpisu pokynu k převodu smlouvy.",
        SignatureHashNotFoundError:
          "Požadavek na ověření pomocí SMS vypršel. Vraťte se zpět a přejděte k podpisu pokynu k převodu smlouvy.",
        VerificationError: "Ověřovací kód se neshoduje.",
      },
      FinalPage: {
        Title: "Převedeno!",
        Description:
          "Děkujeme za důvěru, vaše smlouva byla úspěšně převedena na DIP. ",
        Information: {
          Description:
            "Co to pro vás znamená? Abyste mohli čerpat daňové výhody v rámci DIP, je třeba, mimo jiné, držet tuto investici nejméně po dobu 10 let a současně ji vybrat nejdříve v 60 letech.",
          References: {
            Contract: {
              Before: "Podepsanou smlouvu naleznete v ",
              Link: "detailu smlouvy.",
            },
            PaymentInstructions: {
              Before:
                "Platební instrukce pro případné příspěvky vašeho zaměstnavatele naleznete v ",
              Link: "platebních instrukcích",
              After: " v detailu smlouvy.",
            },
          },
        },
        ButtonText: "Pokračovat na detail smlouvy",
      },
      PromoCard: {
        Title: "Převést do režimu DIP",
        Description:
          "Převeďte si tuto smlouvu do režimu DIP a čerpejte benefity tohoto produktu.",
        Button: "Převést na DIP",
        MoreInformation: "Více informací",
      },
    },
    Dealer: {
      Title: "Kontaktujte poradce ",
      Header: "Nová investice",
      Description:
        "Všimli jsme si, že se o vás již stará finanční poradce. Chcete-li novou investici nebo upravit některou ze stávajících, kontaktujte svého poradce a sdělte mu svůj požadavek.",
    },
    Distribution: {
      Title: "Kontaktujte centrálu",
      Description:
        "Přejete si novou investici? Kontaktujte naši centrálu, spojíme vás s poradcem.",
    },
    ContractDraft: {
      Title: "Náhled smlouvy",
      DownloadButton: "Zobrazit",
      Loading: "Probíhá příprava...",
    },
    BankConnections: {
      Create: {
        Title: "Přidat bankovní účet",
        Modelling: {
          Description:
            "Vyplňte žádost o přidání nového bankovního spojení ke smlouvě.",
          IbanLabel: "IBAN",
          IbanRadio: "IBAN",
          BankNumberLabel: "Číslo účtu",
          BankNumberRadio: "Číslo účtu",
          BankCodeLabel: "Kód banky",
          CurrencyLabel: "Měna účtu",
          SwipeButtonText: "Potáhnutím žádost potvrdíte",
          Validations: {
            CzechIbanNotAllowed:
              "Pro české bankovní spojení není dovolen formát IBAN.",
          },
        },
        Signature: {
          Biometrics: "Potvrzení biometrickými údaji",
          WorkingText:
            "Chystáme pro vás podpis žádosti o přídání nového bankovního spojení ke smlouvě, prosíme o strpení...",
          CodeDetermination: "pro dokončení žádosti",
          SubmitButton: "Podepsat žádost",
          SignatureHashNotFoundError:
            "Požadavek na ověření pomocí SMS vypršel. Vraťte se zpět a přejděte k podpisu žádosti o přídání nového bankovního spojení ke smlouvě.",
          VerificationError: "Ověřovací kód se neshoduje.",
          BiometricsError:
            "Ověření pomocí biometrie selhalo. Vraťte se zpět a žádost o přídání nového bankovního spojení ke smlouvě podepište pomocí SMS kódu.",
          BiometricsInProcessError:
            "Požadavek na ověření pomocí biometrie vypršel. Vraťte se zpět a přejděte k podpisu žádosti o přídání nového bankovního spojení ke smlouvě.",
        },
      },
      Deactivate: {
        Title: "Deaktivovat bankovní účet",
        Information: {
          Description: "Přejete si uvedené bankovní spojení deaktivovat?",
          SwipeButtonText: "Potáhnutím žádost potvrdíte",
        },
        Signature: {
          Biometrics: "Potvrzení biometrickými údaji",
          WorkingText:
            "Chystáme pro vás podpis žádosti o deaktivaci bankovního spojení ke smlouvě, prosíme o strpení...",
          CodeDetermination: "pro dokončení žádosti",
          SubmitButton: "Podepsat žádost",
          SignatureHashNotFoundError:
            "Požadavek na ověření pomocí SMS vypršel. Vraťte se zpět a přejděte k podpisu žádosti o deaktivaci bankovního spojení ke smlouvě.",
          VerificationError: "Ověřovací kód se neshoduje.",
          BiometricsError:
            "Ověření pomocí biometrie selhalo. Vraťte se zpět a žádost o deaktivaci bankovního spojení ke smlouvě podepište pomocí SMS kódu.",
          BiometricsInProcessError:
            "Požadavek na ověření pomocí biometrie vypršel. Vraťte se zpět a přejděte k podpisu žádosti o deaktivaci bankovního spojení ke smlouvě.",
        },
      },
    },
    Edit: {
      Title: "Pojmenování investice",
      Description: "Pojmenujte si svou investici.",
      NameLabel: "Název investice",
      NamePlaceholder: "(Svou investici si můžete pojmenovat)",
      PurposeCategoryLabel: "Kategorie",
      Submit: "Potvrdit",
    },
  },
  More: {
    Menu: {
      News: {
        Title: "Novinky",
        Description: "Přečtěte si o aktuálním dění ve společnosti INVESTIKA.",
      },
      InvestikaInformation: {
        Title: "Informace o Investice",
        Description:
          "Základní informace a historie společnosti INVESTIKA, investiční společnost, a.s.",
      },
      Documents: {
        Title: "Dokumenty",
        Description:
          "Důležité dokumenty týkající se spravovaných fondů a nabízených produktů.",
      },
      PersonalData: {
        Title: "Osobní údaje",
        Description:
          "Upravte své osobní informace a preference, aby aplikace odpovídala vašim potřebám.",
      },
      Contacts: {
        Title: "Kontakty",
        Description:
          "Máte nějaký dotaz, neváhejte se nám ozvat na jednom z uvedených kontaktů.",
      },
      About: {
        Title: "O aplikaci",
        Description: "Základní informace o této aplikaci.",
      },
    },
    PersonalData: {
      PersonalData: "Osobní údaje",
      Constant: "Kontakt",
      PermanentAddress: "Adresa trvalého pobytu",
      Information: {
        Title: "Změna údajů",
        References: {
          Administration:
            "Vyplněný a úředně ověřený formulář prosím zašlete na adresu společnosti INVESTIKA.",
          Analytics: {
            Before: "Více informací naleznete v dokumentu ",
            Link: "Zásady zpracování osobních údajů.",
          },
          ChangeRequest: {
            Before:
              "Pokud chcete změnit, upravit či aktualizovat své osobní údaje, vyplňte prosím formulář ",
            Link: "Žádost o změnu osobních údajů",
            After: " v sekci Dokumenty.",
          },
        },
      },
    },
    Documents: {
      Category: "Kategorie",
      Categories: {
        GeneralTermsAndConditions: "Všeobecné obchodní podmínky",
        FundStatutes: "Statuty fondů",
        KeyInvestorInformation: "Klíčové informace pro investory",
        TariffsAndFees: "Sazebníky",
        FormsAndApplications: "Formuláře a žádosti",
      },
    },
    About: {
      Description:
        "Aplikace je určena pro zletilé fyzické osoby - investory, kterým poskytuje přehled jejich investic do fondů společnosti INVESTIKA, možnost investování a online správu investic.",
      Constraints:
        "Přes aplikaci v tuto chvíli není možné sjednat smlouvu pro právnickou osobu, nebo zastupovat nezletilé osoby.",
      Version: {
        Web: "Verze aplikace: {{applicationVersion}}. ({{buildNumber}})",
        Mobile:
          "Verze aplikace: {{applicationVersion}}. ({{buildNumber}} - {{nativeVersion}})",
      },
    },
    Contacts: {
      Title: "Infolinka",
      Information: {
        Call: "Zavolat",
        SendMail: "Poslat e-mail",
      },
    },
  },
  Profile: {
    Settings: {
      Title: "Nastavení",
      LoggedUser: "Aktuálně přihlášený uživatel:",
      Biometric: {
        Title: "Biometrie",
        Description:
          "Zapněte si biometrické přihlašování a podepisování smluv.",
      },
      Notifications: {
        Title: "Notifikace",
        Description:
          "Nastavte si, zda chcete dostávat notifikace o událostech týkajících se vašich investic.",
      },
      Analytics: {
        Title: "Sběr analytických dat",
        Description:
          "Díky shromažďování analytických dat vám INVESTIKA může nabízet produkty dle vašich osobních preferencí.",
      },
      ChangePassword: "Změna hesla",
      ChangePasswordButton: "Změnit heslo",
      PasswordChanged: "Vaše heslo bylo úspěšně změněno.",
      PasswordChangedButton: "OK",
      DeleteAccount: {
        Title: "Smazat účet",
        Description:
          "Pokud chcete změnit, upravit či aktualizovat své osobní údaje, nebo smazat účet, kontaktujte nás na e-mail:",
      },
      OldPassword: "Stávající heslo",
      NewPassword: "Nové heslo",
      ConfirmPassword: "Potvrzení hesla",
      PasswordNotMatch: "Zadaná hesla se neshodují",
      Logout: "Odhlásit se",
      Error: {
        General: "Nastala chyba",
        WeakPassword:
          "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
        UserNotFound: "Uživatel nebyl nalezen",
        OldCredentialsInvalid: "Špatné stávající heslo",
      },
    },
  },
  Components: {
    PaymentInformation: {
      Instructions: "Platbu zašlete s následujícími parametry:",
      AccountNumber: "Číslo účtu",
      VariableSymbol: "Variabilní symbol",
      SpecificSymbol: "Specifický symbol",
      PaymentMessage: "Zpráva pro příjemce",
      FirstPaymentMessage:
        "Investika smlouva {{contractNo}} {{firstName}} {{lastName}}",
      FirstPaymentMessageForeignBankAccountCountry:
        "Investika smlouva {{contractNo}} {{firstName}} {{lastName}}",
      ForeignPaymentWarning: `Některé SEPA platby nepodporují variabilní a specifické symboly, prosím ujistěte se, že tato informace v zadané platbě figuruje.
        Případně prosíme o doplnění variabilního symbolu (VS) = číslo smlouvy a specifického symbolu (SS) = čtyřmístný kód produktu do <strong>poznámky/reference</strong> platby,
        a to ve formátu <strong>/VSxxxxxxxxxx/SSyyyy</strong>. Nebude-li platba dostatečně identifikována, nemohou být vaše peněžní prostředky zainvestovány.`,
      SingleInvestment: "Jednorázový vklad",
      PeriodicalInvestment: "Pravidelný vklad",
      Dip: {
        Description:
          "Jelikož je smlouva uzavřena jako DIP, může vám na ni přispívat zaměstnavatel. Vyberte, které platební údaje chcete zobrazit.",
      },
      Tabs: {
        Client: {
          ButtonText: "Klient",
        },
        Employer: {
          ButtonText: "Zaměstnavatel",
          Description:
            "Předejte zaměstnavateli platební údaje, které jsou uvedené níže v dokumentech.",
          DocumentsReference:
            "Platební instrukce později naleznete i na detailu smlouvy v záložce platebních instrukcí.",
          VariableSymbol:
            "Zaměstnavateli potřebujete předat i číslo této smlouvy, které se použije jako variabilní symbol: {{vs}}",
        },
      },
      DocumentsNotFound:
        "Platební instrukce pro zaměstnavatele se nepodařilo dohledat. Na základě uzavření této smlouvy prostřednictvím vašeho poradce se na něj prosím obraťte.",
    },
    InvoiceQRCode: {
      QrCodeFileName: "Platební údaje",
      SaveImage: "Uložit QR kód",
    },
  },
  TermsAndConditions: {
    Title: "Podmínky užívání",
    CheckboxConditions: {
      FirstPartText: "Seznámil jsem se a souhlasím s",
      SecondPartText: "obchodními podmínkami",
    },
    Disclaimer: {
      FirstPartText: "Beru na vědomí, že Investika sbírá mé osobní údaje dle",
      SecondPartText: "Zásad o zpracování osobních údajů",
    },
    IsTrueMessage: "Musíte souhlasit s obch. podmínkami",
  },
  AnalyticsDisclaimer: {
    TextPart1: "Stiskem",
    TextPart2: " Pokračovat ",
    TextPart3:
      "berete na vědomí, že INVESTIKA zpracovává vaše osobní údaje. Více informací naleznete v ",
    Link: "Zásadách zpracování osobních údajů.",
  },
  CodeLists: {
    ContractCategories: {
      Car: "Auto",
      Housing: "Bydlení",
      Other: "Jiné",
      Retirement: "Důchod",
      Studium: "Studium",
      Vacation: "Dovolená",
    },
  },
  Biometrics: {
    Title: "Používání biometrie",
    Description:
      "K přihlášení do aplikace a potvrzování transakcí můžete místo hesla využívat ověření pomocí biometrie.",
    Description2: "Chcete tuto funkci zapnout?",
    Enable: "Používat biometrii",
    Skip: "Ne děkuji",
    MoreInformation: "Více informací o biometrii naleznete v",
    TermsAndConditions: "Obchodních podmínkách.",
  },
  Dealers: {
    3556884: {
      Name: "infolinka",
      Email: "administrace@investika.cz",
      Phone: "+420 233 334 990",
    },
    3692004: {
      Name: "infolinka",
      Email: "administrace@investika.cz",
      Phone: "+420 233 334 990",
    },
  },
  Chart: {
    Tooltip: {
      Deposit: "Vklady {{value}}",
      Yield: "Majetek {{value}}",
    },
  },
  Signature: {
    Error: {
      General: "Nastala chyba, zkontrolujte kód.",
      InvalidCode: "Neplatný kód.",
    },
    SmsSent: "SMS byla odeslána.",
  },
  Products: {
    Class: "Třída {{class}}",
    DescriptionTitle: "Třída fondu: {{class}}",
    Descriptions: {
      CZ0008474830: {
        Description: "Jedná se o třídu fondu pro investice v českých korunách.",
      },
      CZ0008475902: {
        Description:
          "Jedná se o třídu fondu v eurech, která je určena zejména pro investory s příjmy nebo úsporami v této měně.",
      },
      CZ0008477650: {
        Description:
          "Jedná se o měnově nezajištěnou třídu fondu. To znamená, že výkonnost fondu je navíc ovlivňována měnovým kurzem CZK/USD.",
      },
      CZ0008477676: {
        Description:
          "Jedná se o třídu s měnovým zajištěním. To znamená, že výkonnost třídy kopíruje výkonnost indexu S&P 500 a není ovlivňována měnovým kurzem koruny CZK/USD.",
      },
    },
  },
  ApiValidation: {
    Biometrics: {
      InvalidChallenge: "Neplatná challenge",
      InvalidSignature: "Neplatný podpis",
      InvalidPublicKeyFormat: "Neplatný formát veřejného klíče",
      PublicKeyNotFound: "Veřejný klíč nebyl nalezen",
      NotEnabled: "Biometrické ověření není povoleno",
      CredentialsAlreadyExists: "Biometrické ověření již existuje",
    },
    Reset: {
      PasswordReset: "Obnova hesla",
      PasswordResetCode: "Ověřovací kód",
    },
    Validation: {
      Accepted: "Musí být akceptováno.",
      ActiveUrl: "Není platná URL.",
      After: "Musí být datum po zadaném datu.",
      AfterOrEqual: "Musí být datum po zadaném datu nebo rovné tomuto datu.",
      Alpha: "Musí obsahovat pouze písmena.",
      AlphaDash: "Musí obsahovat pouze písmena, čísla, pomlčky a podtržítka.",
      AlphaNum: "Musí obsahovat pouze písmena a čísla.",
      Array: "Musí být pole.",
      Before: "Musí být datum před zadaným datem.",
      BeforeOrEqual:
        "Musí být datum před zadaným datem nebo rovné tomuto datu.",
      Between: {
        Numeric: "Musí být mezi zadanými číselnými hodnotami.",
        File: "Musí být mezi zadanými velikostmi souborů v kilobytech.",
        String: "Musí být mezi zadaným počtem znaků.",
        Array: "Musí mít zadaný počet položek.",
      },
      Boolean: "Musí být pravda nebo nepravda.",
      Confirmed: "Potvrzení se neshoduje.",
      Date: "Není platné datum.",
      DateEquals: "Musí být datum rovné zadanému datu.",
      DateFormat: "Neodpovídá zadanému formátu data.",
      Different: "Musí být různé.",
      Digits: "Musí být zadaný počet číslic.",
      DigitsBetween: "Musí být mezi zadaným počtem číslic.",
      Dimensions: "Má neplatné rozměry obrázku.",
      Distinct: "Pole nesmí obsahovat duplikáty.",
      Email: "Musí být platná e-mailová adresa.",
      EndsWith: "Musí končit jednou z uvedených hodnot.",
      Exists: "Vybraná hodnota je neplatná.",
      File: "Musí být soubor.",
      Filled: "Pole musí mít hodnotu.",
      Image: "Musí být obrázek.",
      In: "Vybraná hodnota je neplatná.",
      InArray: "Pole neexistuje v zadané hodnotě.",
      Integer: "Musí být celé číslo.",
      Ip: "Musí být platná IP adresa.",
      Ipv4: "Musí být platná IPv4 adresa.",
      Ipv6: "Musí být platná IPv6 adresa.",
      Json: "Musí být platný JSON řetězec.",
      Max: {
        Numeric: "Nesmí být větší než zadaná hodnota.",
        File: "Nesmí být větší než zadaná velikost v kilobytech.",
        String: "Nesmí být větší než zadaný počet znaků.",
        Array: "Nesmí mít více než zadaný počet položek.",
      },
      Mimes: "Musí být soubor typu: :values.",
      Mimetypes: "Musí být soubor typu: :values.",
      Min: {
        Numeric: "Musí být alespoň zadaná hodnota.",
        File: "Musí být alespoň zadaná velikost v kilobytech.",
        String: "Musí obsahovat alespoň zadaný počet znaků.",
        Array: "Musí obsahovat alespoň zadaný počet položek.",
      },
      MultipleOf: "Musí být násobkem zadané hodnoty.",
      NotIn: "Vybraná hodnota je neplatná.",
      NotRegex: "Formát je neplatný.",
      Numeric: "Musí být číslo.",
      Present: "Musí být přítomno.",
      Regex: "Formát je neplatný.",
      Required: "Toto pole je povinné.",
      RequiredIf: "Toto pole je povinné, když je splněna daná podmínka.",
      RequiredUnless: "Toto pole je povinné, pokud není splněna daná podmínka.",
      RequiredWith: "Toto pole je povinné, když jsou přítomné dané hodnoty.",
      RequiredWithAll:
        "Toto pole je povinné, když jsou přítomny všechny dané hodnoty.",
      RequiredWithout:
        "Toto pole je povinné, když nejsou přítomné dané hodnoty.",
      RequiredWithoutAll:
        "Toto pole je povinné, když nejsou přítomny žádné dané hodnoty.",
      Prohibited: "Toto pole je zakázáno.",
      ProhibitedIf: "Toto pole je zakázáno, když je splněna daná podmínka.",
      ProhibitedUnless:
        "Toto pole je zakázáno, pokud není splněna daná podmínka.",
      Same: "Musí odpovídat dané hodnotě.",
      Size: {
        Numeric: "Musí mít specifickou velikost.",
        File: "Musí mít specifickou velikost v kilobytech.",
        String: "Musí mít specifický počet znaků.",
        Array: "Musí obsahovat specifický počet položek.",
      },
      StartsWith: "Musí začínat jednou z uvedených hodnot.",
      String: "Musí být řetězec.",
      Timezone: "Musí být platná časová zóna.",
      Unique: "Uživatel již existuje.",
      Uploaded: "Nahrávání se nezdařilo.",
      Url: "Formát URL je neplatný.",
      Uuid: "Musí být platné UUID.",
      Specific: {
        InvalidIsin: "Zadaný ISIN není podporovaný.",
        InvalidEmail: "E-mail není platný.",
        InvalidBankAccount: "Bankovní účet není platný.",
        InvalidBankAccountId: "Zadaný bankovní účet u smlouvy neexistuje.",
        AmlFillSourceValue: "Vyplňte jinou zdrojovou hodnotu.",
        AmlFillSourceValueAsNumber:
          "Vyplňte objem prodeje majetku, dědictví nebo jiné zdroje v číselné hodnotě bez mezer.",
        AmlFillBeneficient:
          "Vyplňte jméno/příjmení, RČ skutečného majitele osoby.",
        SignatureCodesNotMatch: "Kódy podpisů nesouhlasí.",
        AmlFillSourceOfResourcesOther: "Vyplňte jiný zdroj prostředků.",
        FundPackageIsEmpty: "Nebyl vyplněn název majetkového podúčtu.",
        FundPackageIsTooLong:
          "Název majetkového podúčtu je příliš dlouhý, povolenou je maximálně 200 znaků.",
        InvalidPersonalIdentificationNumber:
          "Osobní identifikační číslo není platné.",
        InvalidInvestmentPeriodicalCheckExists:
          "Další pravidelný program již nelze ke smlouvě uzavřít.",
        InvalidInvestmentPeriodicalPlannedTimeCheckYears:
          "Kvůli věkovému limitu 70 let není možné takto dlouhý pravidelný program uzavřít.",
        InvalidRedemptionPeriodicalCheckExists:
          "Další pravidelný odkup již nelze k produktu uzavřít.",
        InvalidRedemptionPeriodicalPeriodicity:
          "Nepodporovaná frekvence odkupu.",
        SmsTooManyAttempts:
          "Příliš mnoho pokusů o odeslání SMS v krátkém čase.",
        SmsErrorSecondsBetweenSending:
          "Znovu odeslání SMS je možné až za 60 sekund.",
        ContractParametersError: "Některý z parametrů smlouvy není validní.",
        UsCitizenNotAllowed: "Není možné uzavřít smlouvu s občanem USA.",
        CompanyCannotHaveDipContracts:
          "Právnická osoba nemůže zasmluvnit smlouvu na dlouhodobý investiční produkt.",
        PisemnePotvrzeniInvestorZkusenostiNevyplneno:
          "U písemného potvrzení nebyla vyplněna hodnota znalostí.",
        PisemnePotvrzeniInvestorZkusenostiNevyhovujiciHodnota:
          "Investiční profil klienta neodpovídá investici do daného fondu kvalifikovaných investorů.",
        PisemnePotvrzeniInvestorRizikovyProfilNevyhovujiciHodnota:
          "Investiční profil klienta neodpovídá investici do daného fondu kvalifikovaných investorů.",
        PisemnePotvrzeniInvestorZkusenostiNepodporovanaHodnota:
          "U písemného potvrzení byla vyplněna nepodporovaná hodnota znalostí.",
        PisemnePotvrzeniInvestorRizikovyProfilNevyplneno:
          "U písemného potvrzení nebyla vyplněna hodnota rizikového profilu.",
        PisemnePotvrzeniInvestorRizikovyProfilNepodporovanaHodnota:
          "U písemného potvrzení byla vyplněna nepodporovaná hodnota rizikového profilu.",
        InvalidFirstnameForPersonalIdentificationNumber:
          "Pod zvoleným rodným číslem je veden klient s jiným jménem.",
        ContractClientIsUnder18RepresentativeNeeded:
          "Je potřeba definovat zástupce pro klienta mladšího 18 let.",
        PersonalIdentificationNumberMustBeAdult:
          "Je vyžadováno, aby osoba byla plnoletá.",
        PersonalIdentificationNumberTooLong:
          "Rodné číslo nesmí být delší jak 30 znaků",
        InvestmentFeeIsUnderPricelistValue:
          "Sazba vstupního poplatku nesmí být vyšší než ceníková.",
        RedemptionIsinRequired: "Není specifikován ISIN.",
        RedemptionContractIdRequired: "Není k dispozici identifikátor smlouvy.",
        RedemptionContractPortfolioNotFound:
          "Portfolio smlouvy nebylo nalezeno.",
        RedemptionAmountTooMuch: "Zadaná hodnota překračuje limit pro odkup.",
        RedemptionQuantityTooMuch: "Zadaný počet překračuje limit pro odkup.",
        ExchangeRateCurrencyNotExists:
          "Konverze měny není možná nebo neexistuje.",
        FxRateCurrencyNotExists: "Konverze měny není možná nebo neexistuje.",
        ClientAndDelaerPhoneAreNotBeSame:
          "Telefonní číslo poradce a klienta nesmí být stejné.",
        OrderProcessProblem: "Nastal problém při zakládání smlouvy.",
        ContractIsNotVerified: "Smlouva ještě není zverifikována v systému.",
        ContractIsNotSaved: "Nastal problém při ukládání smlouvy.",
        RequestIsNotSaved: "Nastal problém při ukládání žádosti.",
        InvalidBankAccountPrefix: "Předčíslí bankovního účtu není validní.",
        InvalidSignature: "Neplatný podpis.",
        PlannedTimeNoLongerThanHorizont:
          "Plánovaná doba nesmí být delší než zvolený investiční horizont.",
        RedemptionStartDateRequired: "Není specifikováno datum počátku odkupu.",
        RedemptionMinPeriodCount:
          "Pravidelný odkup je možné zadat nejméně na stanovený počet.",
        AssetTransferringTotalTransfersExceeded:
          "Limit zainvestovaných prostředků byl překročen.",
        AssetTransferringFeeValueExceeded:
          "Limit pro výši poplatku byl překročen.",
        AssetTransferringAmountTooMuch:
          "Zadaná hodnota cenných papírů je vyšší než kolik lze provést převod.",
        AssetTransferringBadDataFormat: "Chybná struktura vstupních dat.",
        AssetTransferringContractIdRequired:
          "Není k dispozici identifikátor smlouvy, není možné ověřit hodnotu.",
        AssetTransferringContractPortfolioNotFound:
          "Není k dispozici portfolio smlouvy, není možné ověřit hodnotu.",
        AssetTransferringTargetAmountIsTooLow:
          "Pravidelná částka výměny je příliš nízká.",
        AssetTransferringMinPeriodCount:
          "Pravidelnou výměnu je možné zadat nejméně na {{limit}}",
        AssetTransferringPeriodCountLimits: {
          W: "52 týdnů",
          M: "12 měsíců",
          Q: "4 kvartály",
          Y: "1 rok",
        },
        SignatureCodeNotMatch: "Podpisový kód nesouhlasí.",
        UnsupportedBankCode: "Nepodporovaný kód banky.",
        RedemptionPeriodCountLimits: {
          W: "52 týdnů",
          M: "12 měsíců",
          Q: "4 kvartály",
          Y: "1 rok",
        },
        UserNotFound: "Uživatel nebyl nalezen.",
        DeviceNotFound: "Zařízení nebylo nalezeno.",
        MessageNotFound: "Zpráva nebyla nalezena.",
        AttachmentNotFound: "Příloha nebyla nalezena.",
        InvalidFeeRate: "Neplatná sazba poplatku.",
        InvalidFeeType: "Neplatný typ poplatku.",
        ClientNotFound: "Klient nebyl nalezen.",
        SignatureFileNotFound: "Podpisový soubor nebyl nalezen.",
        ContractNotFound: "Smlouva nebyla nalezena.",
        DealerNotFound: "Poradce nebyl nalezen.",
        DistributionNotFound: "Distribuce nebyla nalezena.",
        ProductNotFound: "Produkt nebyl nalezen.",
        FileNotFound: "Soubor nebyl nalezen.",
        FileContentNotFound: "Obsah souboru nebyl nalezen.",
        RequestNotFound: "Žádost nebyla nalezena.",
        BankAccountNotFound: "Bankovní účet nebyl nalezen.",
        DocumentTypeNotFound: "Typ dokumentu nebyl nalezen.",
        RepresentativeNotFound: "Zástupce nebyl nalezen.",
        OrderNotFound: "Objednávka nebyla nalezena.",
        PortfolioNotFound: "Portfolio nebylo nalezeno.",
        ContractPortfolioNotFound: "Portfolio smlouvy nebylo nalezeno.",
        SignatureHashNotFound: "Podpisový hash nebyl nalezen.",
        ContractModelNotFound: "Model smlouvy nebyl nalezen.",
        ContractTypeNotFound: "Typ smlouvy nebyl nalezen.",
        FileNotSaved: "Soubor nebyl uložen.",
        ShareholderIsNotVerified: "Klient není ověřen.",
        OrderSignatureNotFound: "Podpis smlouvy nebyl nalezen.",
        OrderSignatureNotProcessed: "Podpis smlouvy nebyl zpracován.",
        ProblemWithContractSave: "Nastal problém při ukládání smlouvy.",
        AttachmentNotSaved: "Příloha nebyla uložena.",
        ProblemWithImportingClientData:
          "Nastal problém při importu dat klienta.",
        SignatureStartDate: "Chybný počátek platnosti podpisu.",
        SignatureExpired: "Platnost podpisu vypršela.",
        Iban: "IBAN může obsahovat pouze písmena, číslice a mezery.",
        RequestTypeDip: "Žádost nelze vytvořit pro smlouvu v režimu DIP.",
        DipCancelAggre: "Neudělen souhlas s ukončením režimu DIP.",
        OperationRejectedForSelfClient:
          "Operaci nelze provést nad klientem, který má pouze smlouvy určené pro samozasmluvnění.",
        OperationRejectedForSelfContract:
          "Operaci nelze provést nad smlouvou určenou pro samozasmluvnění.",
        TooManyAttempts: "Příliš mnoho pokusů, zkuste to prosím později.",
        UserFromOldPortal: "Uživatel starého portálu.",
      },
      Password: {
        Incorrect: "Nesprávné heslo.",
        Letters: "Heslo musí obsahovat alespoň jedno písmeno.",
        Mixed: "Heslo musí obsahovat alespoň jedno malé a jedno velké písmeno.",
        Numbers: "Heslo musí obsahovat alespoň jedno číslo.",
        Symbols: "Heslo musí obsahovat alespoň jeden symbol.",
        Uncompromised: "Heslo je kompromitováno.",
      },
    },
    Verification: {
      EmailVerification: "Ověření e-mailu.",
      EmailVerificationCode: "Ověřovací kód e-mailu.",
      InvalidCode: "Neplatný kód.",
      UserIsNotActive: "Uživatel není aktivní.",
      UserIsAlreadyVerified: "Uživatel je již ověřen.",
      UserIsNotVerified: "Uživatel není ověřen.",
      UserEmailAlreadyVerified: "E-mailová adresa je již ověřena.",
      UserPhoneAlreadyVerified: "Telefonní číslo je již ověřeno.",
      TokenIsNotValid: "Token není platný.",
      UserNotActive: "Uživatel není aktivní.",
    },
    Auth: {
      ExpiredSmsCode: "Platnost SMS kódu vypršela.",
      SmsText: "Text SMS zprávy.",
      SmsSendFailed: "Odeslání SMS zprávy selhalo.",
      TooManyAttempts: "Příliš mnoho pokusů.",
      WrongCredentials: "Nesprávné přihlašovací údaje.",
      WrongSmsCode: "Nesprávný SMS kód.",
      WrongTotpKey: "Nesprávný TOTP klíč.",
      WrongUsernameOrPassword: "Nesprávné uživatelské jméno nebo heslo.",
      _2faNotSet: "Dvoufaktorové ověření není nastaveno.",
      InvalidAuthMethod: "Neplatná metoda autentizace.",
    },
    Feusers: {
      Messages: {
        EditProfileFailed: "Úprava profilu selhala.",
        NotForBcas: "Není určeno pro BCAS.",
        OnlyForLoggedUser: "Pouze pro přihlášené uživatele.",
        ProfileEdited: "Profil byl upraven.",
        UserLoggedOut: "Uživatel byl odhlášen.",
        Passwords: {
          Reset: "Heslo bylo resetováno.",
          Sent: "Heslo bylo odesláno.",
          Throttled: "Odeslání hesla bylo omezeno.",
          Token: "Token pro reset hesla.",
          User: "Uživatel.",
        },
      },
      Labels: {
        Back: "Zpět",
        Email: "E-mail",
        ForgotPassword: "Zapomenuté heslo",
        Login: "Přihlášení",
        Name: "Jméno",
        NewPassword: "Nové heslo",
        Password: "Heslo",
        PasswordConfirmation: "Potvrzení hesla",
        ResendSmsCode: "Znovu odeslat SMS kód",
        Save: "Uložit",
        Send: "Odeslat",
        SmsCode: "SMS kód",
        Username: "Uživatelské jméno",
      },
    },
  },
};
