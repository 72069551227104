import { ContractModelingStep } from "State/Contracts/Modeling/Models";
import { useFormData } from "./Investment/NewInvestment/useFormData";

export const useModelingIsin = () => {
  const { defaultValues } = useFormData<ContractModelingStep.Fund>({
    step: ContractModelingStep.Fund,
  });

  return defaultValues?.isin;
};
