import { getType } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { resetUser, setUnauthenticatedUrl } from "State/Auth/AuthReducer";
import { track } from "Utils/TrackingUtils";
import { revokeTokenAsync } from "State/Auth/Tokens/RevokeTokenState";

function* resetUserFn(action: ReturnType<typeof resetUser>): Generator {
  if (action.payload.wasSignedOutManually) {
    yield put(revokeTokenAsync.request());
  }

  window.onbeforeunload = null;

  track({
    category: "Auth",
    event: "SignOut",
  });

  localStorage.clear();
  yield put(setUnauthenticatedUrl(action.payload.authRedirectUrl ?? null));
  //Do not refresh page if user is already sign outed.
  if (navigator.onLine && !action.payload.authRedirectUrl) {
    window.setTimeout(() => window.location.reload(), 100);
  }
}
export function* resetUserSaga() {
  yield takeLatest(getType(resetUser), resetUserFn);
}
