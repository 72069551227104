import * as React from "react";
export const CarIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 5.5C7.91602 5.5 6.53125 5.90234 5.48438 6.81055C4.70898 7.48242 4.16797 8.44922 3.82812 9.64648C3.73047 9.92969 3.64844 10.2324 3.58203 10.5527C3.40625 10.5664 3.23438 10.5684 3.04492 10.6211C2.70898 10.7129 2.35156 10.8652 2.10156 11.1992V11.2012C1.55859 11.9238 0.726562 13.1719 0.541016 14.832C0.515625 15.0469 0.5 15.2695 0.5 15.5C0.5 16.2129 0.93164 16.7734 1.40234 17.1738C1.80078 17.5137 2.24219 17.7539 2.64062 17.9004C3.02539 19.1152 4.16211 20 5.5 20C6.80078 20 7.91016 19.1621 8.32422 18H17.1758C17.5898 19.1621 18.6992 20 20 20C21.4922 20 22.7344 18.8965 22.9609 17.4648C23.3418 17.4082 23.6719 17.2793 23.9316 17.0781C24.291 16.7969 24.5 16.373 24.5 15.9434V13.4258C24.5 13.0879 24.3691 12.8105 24.1328 12.4629C23.8984 12.1172 23.543 11.7383 23.0391 11.3828C22.0762 10.7031 20.5254 10.1445 18.2949 10.041C17.8281 9.37109 17.3613 8.64063 16.416 7.22266C16.2266 6.9375 16.0742 6.68359 15.8672 6.44336C15.6621 6.20312 15.3887 5.98828 15.0371 5.8457C14.3359 5.56055 13.373 5.5 11.5 5.5H9.5ZM9.5 8H13.5C14.0742 8 14.1836 8.02148 14.5 8.5L16 11H9.5V8ZM8.5 8.03906V11H6C6.04883 9.63281 6.84375 8.26758 8.5 8.03906ZM10.5 12.5H12C12.2754 12.5 12.5 12.7246 12.5 13C12.5 13.2754 12.2754 13.5 12 13.5H10.5C10.2246 13.5 10 13.2754 10 13C10 12.7246 10.2246 12.5 10.5 12.5ZM5.5 15C6.61133 15 7.5 15.8887 7.5 17C7.5 18.1113 6.61133 19 5.5 19C4.38867 19 3.5 18.1113 3.5 17C3.5 15.8887 4.38867 15 5.5 15ZM20 15C21.1113 15 22 15.8887 22 17C22 18.1113 21.1113 19 20 19C18.8887 19 18 18.1113 18 17C18 15.8887 18.8887 15 20 15Z"
        fill="currentColor"
      />
    </svg>
  );
};
