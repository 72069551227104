import { Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { StyledFlex } from "Components/Shared/StyledComponents";
import styled from "styled-components";

const StyledLegendDiv = styled.div<{
  $backgroundColor?: string;
  $background?: string;
}>`
  width: 20px;
  height: 5px;
  border-radius: 10px;

  ${({ $backgroundColor }) => `
    background-color: ${$backgroundColor}
  `};

  ${({ $background }) => `
    background: ${$background}
  `};
`;

export type LegendContentProps = {
  lineLabel: string;
  barLabel: string;
  color?: string;
};

export const LegendContent = ({
  lineLabel,
  barLabel,
  color,
}: LegendContentProps) => {
  return (
    <StyledFlex $justifyContent="center" $gap={10}>
      <StyledFlex $alignItems="center" $gap={1.5}>
        <StyledLegendDiv $background="linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.11) 100%)" />
        <Typography variant="subtitle1">{barLabel}</Typography>
      </StyledFlex>
      <StyledFlex $alignItems="center" $gap={1.5}>
        <StyledLegendDiv $backgroundColor={color ?? Colors.PrimaryMain} />
        <Typography variant="subtitle1">{lineLabel}</Typography>
      </StyledFlex>
    </StyledFlex>
  );
};
