import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { toAPICallError } from "Models/Errors/ApiCallError";
import { WebViewMessage, WebViewMessageTypes } from "Models/WebViewModels";
import {
  setBiometrics,
  setBiometricsCanBeUsed,
  setLogin,
} from "State/Biometrics/BiometricsActions";
import { setMobileMetadata } from "State/App/Metadata/MetadataReducer";
import { setIsNotificationsPermissionGranted } from "State/Notifications/NotificationsReducer";

export const handleWebViewInitAsync = createAsyncAction(
  "@auth/HANDLE_WEB_VIEW_INIT_REQUEST",
  "@auth/HANDLE_WEB_VIEW_INIT_SUCCESS",
  "@auth/HANDLE_WEB_VIEW_INIT_FAILURE",
)<
  Extract<
    WebViewMessage,
    { type: WebViewMessageTypes.INIT_RESPONSE }
  >["payload"],
  void,
  Error
>();

function* handleWebViewInit(
  action: ReturnType<typeof handleWebViewInitAsync.request>,
): Generator {
  try {
    yield put(setBiometricsCanBeUsed(action.payload.isBiometricsAvailable));
    yield put(setBiometrics(action.payload.isBiometricsEnabled));
    yield put(
      setLogin(
        action.payload.isBiometricsEnabled ? action.payload.login : null,
      ),
    );

    yield put(
      setIsNotificationsPermissionGranted(action.payload.isNotificationEnabled),
    );

    yield put(
      setMobileMetadata({
        app: { version: action.payload.nativeVersion },
        device: { os: action.payload.os },
      }),
    );

    yield put(handleWebViewInitAsync.success());
  } catch (err) {
    yield put(handleWebViewInitAsync.failure(toAPICallError(err)));
  }
}
export function* handleWebViewInitSaga() {
  yield takeLatest(getType(handleWebViewInitAsync.request), handleWebViewInit);
}
