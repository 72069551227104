import { BankListQueryResult, getCodeListBanks } from "Api/Api";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";

export type GetBanksStateType = FetchStateType<BankListQueryResult>;

export const getBanksState = (): GetBanksStateType =>
  getFetchStateDefaultValue();

export type GetBanksActionType = ActionType<typeof getBanksAsync>;

export const getBanksAsync = createAsyncAction(
  "@codeList/GET_Banks_REQUEST",
  "@codeList/GET_Banks_SUCCESS",
  "@codeList/GET_Banks_FAILURE",
)<void, BankListQueryResult, Error>();

function* getBanks(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getCodeListBanks);

    if (error) {
      yield put(getBanksAsync.failure(error));
      return;
    }

    yield put(getBanksAsync.success(response));
  } catch (err) {
    yield put(getBanksAsync.failure(err as Error));
  }
}

export function* getBanksSaga() {
  yield takeLatest(getType(getBanksAsync.request), getBanks);
}

export const getBanksReducer = createReducer<
  GetBanksStateType,
  GetBanksActionType
>(getBanksState())
  .handleAction(getBanksAsync.request, handleActionRequest)
  .handleAction(getBanksAsync.failure, handleActionFailure)
  .handleAction(getBanksAsync.success, handleActionSuccess);
