import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { DocumentForm } from "Components/ContractCreate/Documents/Components/DocumentForm";
import { Step } from "Components/ContractCreate/Shared/Step";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { PageTitle } from "../Shared/PageTitle";
import { useYupFormSchema } from "Hooks/Contract/Documents/useYupFormSchema";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { useFormData } from "Hooks/Contract/useFormData";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { PageStepProps } from "Pages/Contracts/CreatePage";
import {
  DocumentsFormModel,
  NewContractStep,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const PageResources = Resources.Contract.NewContract.Documents;

type Props = PageStepProps;

export const Documents: FunctionComponent<Props> = ({ isActive }) => {
  const { t } = useResource();
  const { nextStep } = useCreateContractStep();

  const schema = useYupFormSchema();
  const { color, contrastColor } = useModelingColor();
  const { defaultValues } = useFormData<NewContractStep.Documents>({
    step: NewContractStep.Documents,
  });
  const isBankIDVerified = useIsBankIDVerified();

  const form = useForm<DocumentsFormModel>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit } = form;

  const onSubmit = (formData: DocumentsFormModel) => {
    nextStep({ formData });
  };

  return (
    <>
      <div>
        <Step actualStep={2} />

        <PageTitle>{t(PageResources.Title)}</PageTitle>
        <Typography>{t(PageResources.Label)}</Typography>

        <FormProvider {...form}>
          <InputHeaderWrapper header={t(PageResources.FirstDocumentTitle)}>
            <DocumentForm formType="firstDocument" />
          </InputHeaderWrapper>

          {!isBankIDVerified && (
            <InputHeaderWrapper header={t(PageResources.SecondDocumentTitle)}>
              <Typography marginBottom={1}>
                {t(PageResources.SecondDocumentDescription)}
              </Typography>
              <DocumentForm formType="secondDocument" />
            </InputHeaderWrapper>
          )}
        </FormProvider>
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
