import { Typography } from "@mui/material";
import { CurrencyCode } from "Api/Api";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { CurrencyRenderer } from "Components/Shared/CurrencyRenderer";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  label: string;
  value?: number;
  currency?: CurrencyCode;
  tooltip?: string;
  displaySign?: boolean;
}>;

export const LabelWithCurrency: FunctionComponent<Props> = ({
  label,
  tooltip,
  value,
  currency,
  displaySign = false,
  children,
}) => {
  return (
    <div>
      <Typography variant="subtitle1">
        {label}
        {tooltip && <BlInfoPopper color="primary">{tooltip}</BlInfoPopper>}
      </Typography>
      <CurrencyRenderer
        value={value}
        currency={currency}
        decimalPlaces={2}
        variant="body2"
        fontWeight={700}
        displaySign={displaySign}
      />
      {children}
    </div>
  );
};
