import { ContractDetailDto, getContractDetailContractID } from "Api/Api";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  handleCleanupAction,
  safeApiCall,
} from "State/Utils";
import {
  CleanupInvestmentStateActionType,
  cleanupInvestmentState,
} from "State/InvestmentsDetail/Shared/Actions";

export type GetDetailStateType = FetchStateType<ContractDetailDto>;

export const getDetailState = (): GetDetailStateType =>
  getFetchStateDefaultValue();

export type GetDetailActionType =
  | ActionType<typeof getDetailAsync>
  | CleanupInvestmentStateActionType;

export const getDetailAsync = createAsyncAction(
  "@investments-detail/GET_DETAIL_REQUEST",
  "@investments-detail/GET_DETAIL_SUCCESS",
  "@investments-detail/GET_DETAIL_FAILURE",
)<
  Parameters<typeof getContractDetailContractID>[0],
  ContractDetailDto,
  Error
>();

function* getDetail(
  action: ReturnType<typeof getDetailAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getContractDetailContractID,
      action.payload,
    );

    if (error) {
      yield put(getDetailAsync.failure(error));
      return;
    }

    yield put(getDetailAsync.success(response));
  } catch (err) {
    yield put(getDetailAsync.failure(err as Error));
  }
}

export function* getDetailSaga() {
  yield takeLatest(getType(getDetailAsync.request), getDetail);
}

export const getDetailReducer = createReducer<
  GetDetailStateType,
  GetDetailActionType
>(getDetailState())
  .handleAction(getDetailAsync.request, handleActionRequest)
  .handleAction(getDetailAsync.failure, handleActionFailure)
  .handleAction(getDetailAsync.success, handleActionSuccess)
  .handleAction(cleanupInvestmentState, handleCleanupAction(getDetailState));
