import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Link, Typography } from "@mui/material";
import { BankAccountWarning } from "Components/ContractCreate/Shared/BankAccountWarning";
import { Step } from "Components/ContractCreate/Shared/Step";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { PageTitle } from "./Shared/PageTitle";
import { useYupFormSchema } from "Hooks/Contract/LastInformation/useYupFormSchema";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { useFormData } from "Hooks/Contract/useFormData";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import {
  LastInformationFormModel,
  NewContractStep,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { PERSONAL_DATA_ANALYTICS_URL } from "Utils/ExternalLinkUtils";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useModelingColor } from "Hooks/useModelingColor";

const PageResources = Resources.Contract.NewContract.LastInformation;

export const LastInformation: FunctionComponent = () => {
  const { t } = useResource();
  const { nextStep } = useCreateContractStep();

  const { color, contrastColor } = useModelingColor();
  const schema = useYupFormSchema();
  const { defaultValues } = useFormData<NewContractStep.LastInformation>({
    step: NewContractStep.LastInformation,
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<LastInformationFormModel>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (formData: LastInformationFormModel) => {
    nextStep({ formData });
  };

  const isBankIDVerified = useIsBankIDVerified();

  return (
    <>
      <div>
        <Step actualStep={5} />
        <PageTitle>{t(PageResources.Title)}</PageTitle>

        <Box marginTop={6}>
          <BlCheckboxFormField
            control={control}
            name="acceptPersonalDataMarketing"
            label={
              <Typography>
                {t(PageResources.AcceptPersonalDataMarketing)}
                <Link
                  color="secondary"
                  href={PERSONAL_DATA_ANALYTICS_URL}
                  target="_blank"
                >
                  {t(PageResources.AcceptPersonalDataMarketingLink)}
                </Link>
              </Typography>
            }
            value={true}
            errors={errors}
          />

          <BlCheckboxFormField
            control={control}
            name="clientAgreeTakeoverData"
            label={
              <Typography>
                {t(PageResources.ClientAgreeTakeoverData)}
                <Link
                  color="secondary"
                  href={PERSONAL_DATA_ANALYTICS_URL}
                  target="_blank"
                >
                  {t(Resources.Common.PrivacyPolicyLink)}
                </Link>
              </Typography>
            }
            value={true}
            errors={errors}
          />

          <BlCheckboxFormField
            control={control}
            name="voluntarilyDecision"
            label={t(PageResources.VoluntarilyDecision)}
            value={true}
            errors={errors}
          />

          <BlCheckboxFormField
            control={control}
            name="declareContractingDataValidity"
            label={t(PageResources.DeclareContractingDataValidity)}
            value={true}
            errors={errors}
          />
        </Box>
        {!isBankIDVerified && <BankAccountWarning withMargin />}
      </div>

      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
