import { Box, Typography, type SkeletonProps } from "@mui/material";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { Resources, useResource } from "Translations/Resources";
import {
  type PropsWithChildren,
  type FunctionComponent,
  type ReactElement,
  type ReactNode,
  cloneElement,
  isValidElement,
} from "react";

type Props = PropsWithChildren<{
  isLoading?: boolean;
  error?: Error | null | string;
  errorMessages?: string[];
  skeleton?: ReactElement<{
    children?: ReactNode;
    variant?: SkeletonProps["variant"];
  }>;
  wrapChildren?: boolean;
  variant?: SkeletonProps["variant"];
  fullWidth?: boolean;
  fullHeight?: boolean;
  height?: number;
  loadingText?: string;
}>;

export const LoadingWrapper: FunctionComponent<Props> = ({
  children,
  skeleton,
  isLoading,
  wrapChildren,
  variant,
  fullWidth,
  fullHeight,
  height,
  error,
  loadingText,
  errorMessages,
}) => {
  const { t } = useResource();
  const formattedErrorMessages = errorMessages ?? [t(Resources.Common.Error)];

  if (isLoading) {
    if (wrapChildren) {
      return isValidElement(skeleton) ? (
        cloneElement(skeleton, { children, variant })
      ) : (
        <>
          {!!loadingText && <Typography marginY={2}>{loadingText}</Typography>}
          <PrimarySkeleton
            fullWidth={fullWidth}
            fullHeight={fullHeight}
            variant={variant}
            height={height}
          >
            {children}
          </PrimarySkeleton>
        </>
      );
    }

    return (
      <>
        {!!loadingText && <Typography marginY={2}>{loadingText}</Typography>}
        {skeleton ?? (
          <Box display="grid" gridTemplateRows="auto 1fr">
            <PrimarySkeleton
              fullWidth={fullWidth}
              fullHeight={fullHeight}
              variant={variant}
              height={height}
            />
          </Box>
        )}
      </>
    );
  }

  if (!!error) {
    return (
      <>
        {formattedErrorMessages.map(x => (
          <Typography key={x} color="error">
            {x}
          </Typography>
        ))}
      </>
    );
  }
  return <>{children}</>;
};
