import {
  ResendVerificationEmailCommandResult,
  postAuthResendVerificationEmail,
  ResendVerificationEmailCommand,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { toast } from "react-toastify";
import { safeApiCall } from "State/Utils";

export const resendVerificationEmailAsync = createAsyncAction(
  "@auth/RESEND_VERIFICATION_EMAIL_REQUEST",
  "@auth/RESEND_VERIFICATION_EMAIL_SUCCESS",
  "@auth/RESEND_VERIFICATION_EMAIL_FAILURE",
)<
  ResendVerificationEmailCommand,
  ResendVerificationEmailCommandResult,
  Error
>();

function* resendVerificationEmail(
  action: ReturnType<typeof resendVerificationEmailAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postAuthResendVerificationEmail,
      action.payload,
    );

    if (error) {
      yield put(resendVerificationEmailAsync.failure(error));
      return;
    }

    yield put(resendVerificationEmailAsync.success(response));
    toast.success("Verifikační email byl odeslán.");
  } catch (err) {
    yield put(resendVerificationEmailAsync.failure(err as Error));
  }
}
export function* resendVerificationEmailSaga() {
  yield takeLatest(
    getType(resendVerificationEmailAsync.request),
    resendVerificationEmail,
  );
}
