import * as React from "react";
export const PieChartIcon = (
  props: {
    size?: number;
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9062 17.2311C18.2988 17.6799 18.999 17.6892 19.3437 17.2027C19.9558 16.3386 20.4142 15.3684 20.692 14.334C21.0117 13.1437 21.0818 11.9069 20.9056 10.6999C20.8195 10.1099 20.1915 9.8002 19.6379 10.0217L14.9933 11.8795C14.3531 12.1356 14.1581 12.9475 14.6121 13.4665L17.9062 17.2311ZM19.3454 6.79925C19.6899 7.28591 19.4487 7.94325 18.8951 8.1647L14.3714 9.97418C13.7145 10.2369 13 9.75317 13 9.0457V4.17352C13 3.57724 13.5207 3.10915 14.1006 3.24833C15.1931 3.51058 16.2331 3.97657 17.1636 4.62837C18.015 5.22478 18.7515 5.9605 19.3454 6.79925Z"
        fill={props.color || "#FFFFFF"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.15568C11 2.56386 10.4871 2.09743 9.90834 2.22122C8.99701 2.41615 8.11402 2.73824 7.28604 3.18081C5.83571 3.95602 4.59895 5.07696 3.68531 6.44432C2.77167 7.81168 2.20935 9.38326 2.04816 11.0198C1.88697 12.6564 2.13188 14.3075 2.76121 15.8269C3.39053 17.3462 4.38485 18.6869 5.65607 19.7301C6.92729 20.7734 8.43619 21.487 10.0491 21.8079C11.662 22.1287 13.3292 22.0468 14.9028 21.5694C15.5873 21.3618 16.245 21.0821 16.8653 20.7367C17.3823 20.4487 17.4612 19.7599 17.0715 19.3145L11.2845 12.7009C11.1011 12.4913 11 12.2222 11 11.9436V3.15568Z"
        fill={props.color || "#FFFFFF"}
      />
    </svg>
  );
};
