import { HealthStatus } from "Api/Api";
import { getApiHealthStatusAsync } from "State/Health/Api/ApiHealthState";
import { produce } from "immer";
import { createReducer, ActionType } from "typesafe-actions";

export type HealthState = {
  apiHealthStatus: HealthStatus;
  lastChecked?: Date;
};

export type HealthAction = ActionType<typeof getApiHealthStatusAsync>;

export const healthReducer = createReducer<HealthState, HealthAction>({
  apiHealthStatus: HealthStatus.Operational,
  lastChecked: undefined,
}).handleAction(getApiHealthStatusAsync.success, (state, action) => {
  return produce(state, draft => {
    draft.apiHealthStatus = action.payload.healthStatus;
    draft.lastChecked = new Date();
  });
});
