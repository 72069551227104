import { Box, Typography, useTheme } from "@mui/material";
import { UpIcon } from "Components/Shared/Icons";
import { formatNumber } from "Utils/NumberUtils";
import { type FunctionComponent } from "react";
import styled from "styled-components";

type Props = {
  value?: number;
};

const StyledWrapper = styled.div<Required<Pick<Props, "value">>>`
  display: flex;
  color: ${({ value, theme }) => {
    if (value < 0) {
      return theme.palette.error.main;
    }
    if (value > 0) {
      return theme.palette.success.main;
    }
    return theme.palette.secondary.main;
  }};
`;

const StyledUpIcon = styled(UpIcon)<Required<Pick<Props, "value">>>`
  transform: rotate(
    ${({ value }) => {
      if (value < 0) {
        return 180;
      }
      if (value > 0) {
        return 0;
      }
      return 90;
    }}deg
  );
`;

export const PercentageIndicator: FunctionComponent<Props> = ({
  value = 0,
}) => {
  const { typography } = useTheme();

  return (
    <StyledWrapper value={value}>
      <Box paddingTop={0.5} paddingRight={1}>
        <StyledUpIcon
          width={typography.body1.fontSize}
          height={typography.body1.fontSize}
          value={value}
        />{" "}
      </Box>
      <Typography>{formatNumber(Number(value.toFixed(2)))} %</Typography>
    </StyledWrapper>
  );
};
