import { useAppSelector } from "Hooks/useAppSelector";
import { NewContractStep } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

const PageResources = Resources.Contract.NewContract;

export const useCreateContractTitle = () => {
  const { t } = useResource();
  const actualStep = useAppSelector(s => s.contracts.create.actualStep);

  const pageTitle = useMemo(() => {
    switch (actualStep) {
      case NewContractStep.AMLQuestions:
        return t(PageResources.AMLQuestions.Title);
      case NewContractStep.BankConnection:
        return t(PageResources.BankConnection.Title);
      case NewContractStep.ContactInformation:
        return t(PageResources.ContactInformation.Title);
      case NewContractStep.ContantVerification:
        return t(PageResources.ContactVerification.Title);
      case NewContractStep.ContractPreview:
        return t(PageResources.ContractPreview.Title);
      case NewContractStep.ContractSignature:
        return t(PageResources.ContractSignature.Title);
      case NewContractStep.Documents:
        return t(PageResources.Documents.Title);
      case NewContractStep.FinalPage:
        return t(PageResources.FinalPage.Title);
      case NewContractStep.LastInformation:
        return t(PageResources.LastInformation.Title);
      case NewContractStep.PathSelection:
        return t(PageResources.PersonalInformation.Title);
      case NewContractStep.PersonalData:
        return t(PageResources.PersonalInformation.Title);
      case NewContractStep.BankId:
        return t(PageResources.PersonalInformation.Title);
      default:
        return null;
    }
  }, [actualStep, t]);

  return pageTitle;
};
