import { useEnvironment } from "Hooks/useEnvironment";
import { toast } from "react-toastify";

type Props = {
  content: string;
  variant: "info" | "success" | "error";
  autoClose?: number;
};

export function useToast() {
  const { isDevelopment } = useEnvironment();

  const showToast = ({ content, variant, autoClose }: Props) => {
    switch (variant) {
      case "info":
        toast.info(content, { autoClose });
        return;

      case "success":
        toast.success(content, { autoClose });
        return;

      case "error":
        if (isDevelopment) {
          toast.error(content, { autoClose });
        }
        return;
    }
  };

  return { showToast };
}
