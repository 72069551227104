import { ClientDto, getClient as getClientApi } from "Api/Api";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";

export type GetClientStateType = FetchStateType<ClientDto>;

export const getClientState = (): GetClientStateType =>
  getFetchStateDefaultValue();

export type GetClientActionType =
  | ActionType<typeof getClientAsync>
  | ActionType<typeof clearClient>;

export const getClientAsync = createAsyncAction(
  "@client/GET_CLIENT_REQUEST",
  "@client/GET_CLIENT_SUCCESS",
  "@client/GET_CLIENT_FAILURE",
)<void, ClientDto, Error>();

export const clearClient = createAction("@client/CLEAR_CLIENT")();

function* getClient(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getClientApi);

    if (error) {
      yield put(getClientAsync.failure(error));
      return;
    }

    yield put(getClientAsync.success(response));
  } catch (err) {
    yield put(getClientAsync.failure(err as Error));
  }
}

export function* getClientSaga() {
  yield takeLatest(getType(getClientAsync.request), getClient);
}

export const getClientReducer = createReducer<
  GetClientStateType,
  GetClientActionType
>(getClientState())
  .handleAction(getClientAsync.request, handleActionRequest)
  .handleAction(getClientAsync.failure, handleActionFailure)
  .handleAction(getClientAsync.success, handleActionSuccess)
  .handleAction(clearClient, getClientState);
