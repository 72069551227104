import {
  ResetPasswordCommandResult,
  postAuthPasswordReset,
  ResetPasswordCommand,
  ResetPasswordCommandResultStatus,
} from "Api/Api";
import { createAction, createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { ResetPasswordStep } from "State/Auth/Models/AuthStateModels";
import { safeApiCall } from "State/Utils";
import { ApplicationError } from "Models/Errors/ApplicationError";

export const resetPasswordAsync = createAsyncAction(
  "@auth/RESET_PASSWORD_REQUEST",
  "@auth/RESET_PASSWORD_SUCCESS",
  "@auth/RESET_PASSWORD_FAILURE",
)<
  ResetPasswordCommand,
  { email: string; result: ResetPasswordCommandResult },
  Error
>();

export const setPasswordResetStep = createAction(
  "@auth/SET_RESET_PASSWORD_STEP",
)<ResetPasswordStep>();

function* resetPassword(
  action: ReturnType<typeof resetPasswordAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postAuthPasswordReset,
      action.payload,
    );

    if (error) {
      yield put(resetPasswordAsync.failure(error));
      return;
    }

    if (response.status === ResetPasswordCommandResultStatus.Success) {
      yield put(
        resetPasswordAsync.success({
          email: action.payload.login,
          result: response,
        }),
      );
    } else {
      yield put(
        resetPasswordAsync.failure(new ApplicationError(response.status)),
      );
    }
  } catch (err) {
    yield put(resetPasswordAsync.failure(err as Error));
  }
}
export function* resetPasswordSaga() {
  yield takeLatest(getType(resetPasswordAsync.request), resetPassword);
}
