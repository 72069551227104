import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Box, Link } from "@mui/material";
import { AnalyticsDisclaimer } from "Components/Auth/Shared/AnalyticsDisclaimer";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { FullWidthDiv } from "Components/Shared/StyledComponents";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { updateClientAnalyticsStatusAsync } from "State/Auth/AppData/UpdateClientAnalyticsStatusState";
import { resetUser } from "State/Auth/AuthReducer";
import { acceptTermsAndConditionsAsync } from "State/Auth/TermsAndConditions/AcceptTermsAndConditionsState";
import { Resources, useResource } from "Translations/Resources";
import { PERSONAL_DATA_ANALYTICS_URL } from "Utils/ExternalLinkUtils";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { ObjectSchema, boolean, object } from "yup";

export type FormModel = {
  agreeWithAnalytics: boolean;
};

const PageResources = Resources.TermsAndConditions;

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(2)};
`;

export const TermsAndConditionsPage: React.FunctionComponent = _ => {
  const { t } = useResource();
  const { canUseBiometrics } = useBiometricsCanBeUsed();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onBackClick = () => {
    dispatch(
      resetUser({
        wasSignedOutManually: true,
      }),
    );
    navigate(getPath(AppRouting.SignIn));
  };

  const validationSchema: ObjectSchema<FormModel> = object({
    agreeWithAnalytics: boolean().required(),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      agreeWithAnalytics: false,
    },
  });

  const onSubmit = ({ agreeWithAnalytics }: FormModel) => {
    dispatch(
      acceptTermsAndConditionsAsync.request({
        navigate,
        canUseBiometrics,
      }),
    );

    if (agreeWithAnalytics) {
      dispatch(
        updateClientAnalyticsStatusAsync.request({
          currentValue: agreeWithAnalytics,
          previousValue: false,
        }),
      );
    }
  };

  return (
    <>
      <UnauthenticatedLayout
        title={t(PageResources.Title)}
        onBackClick={onBackClick}
      >
        <div>
          <Typography component="h2" variant="h2">
            {t(PageResources.Title)}
          </Typography>
          {/* <Typography paddingTop={2}>{t(PageResources.Label)}</Typography> */}
          <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FullWidthDiv>
              <BlCheckboxFormField
                control={control}
                name="agreeWithAnalytics"
                errors={errors}
                label={
                  <Box display="flex">
                    <Typography color="secondary">
                      {t(Resources.SignUp.Analytics.Text)}&nbsp;
                      <Link
                        target="_blank"
                        href={PERSONAL_DATA_ANALYTICS_URL}
                        fontWeight={600}
                        color="secondary"
                      >
                        {t(Resources.SignUp.Analytics.ConsentCheckBox)}
                      </Link>
                    </Typography>
                  </Box>
                }
              />
            </FullWidthDiv>
          </StyledForm>
        </div>

        <div>
          <AnalyticsDisclaimer />

          <PrimaryButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          >
            {t(Resources.Common.Continue)}
          </PrimaryButton>
        </div>
      </UnauthenticatedLayout>
    </>
  );
};

export default TermsAndConditionsPage;
