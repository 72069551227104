import {
  TermsOfConditionAcceptanceDto,
  putClientTermsAndServices,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { NavigateFunction } from "react-router";
import { safeApiCall } from "State/Utils";
import { getPath, AppRouting } from "Utils/UrlUtils";

export const acceptTermsAndConditionsAsync = createAsyncAction(
  "@auth/ACCEPT_TOS_REQUEST",
  "@auth/ACCEPT_TOS_SUCCESS",
  "@auth/ACCEPT_TOS_FAILURE",
)<
  {
    navigate: NavigateFunction;
    canUseBiometrics: boolean;
  },
  void,
  Error
>();

function* acceptTermsAndConditions(
  action: ReturnType<typeof acceptTermsAndConditionsAsync.request>,
): Generator {
  try {
    const { navigate, canUseBiometrics } = action.payload;

    const { error } = yield* safeApiCall(putClientTermsAndServices, {
      hasAgreedToTermsAndConditions: true,
    } as TermsOfConditionAcceptanceDto);

    if (error) {
      yield put(acceptTermsAndConditionsAsync.failure(error));
      return;
    }

    yield put(acceptTermsAndConditionsAsync.success());

    if (canUseBiometrics) {
      yield* call(() => navigate(getPath(AppRouting.Biometrics)));
    } else {
      yield* call(() => navigate(getPath(AppRouting.Dashboard)));
    }
  } catch (err) {
    yield put(acceptTermsAndConditionsAsync.failure(err as Error));
  }
}
export function* acceptTermsAndConditionsSaga() {
  yield takeLatest(
    getType(acceptTermsAndConditionsAsync.request),
    acceptTermsAndConditions,
  );
}
