import {
  postFcmTokens,
  RegisterFcmTokenCommandResult,
  RegisterFcmTokenRequest,
} from "Api/Api";
import { put } from "redux-saga/effects";
import { safeApiCall } from "State/Utils";
import { debounce } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const registerFcmTokenAsync = createAsyncAction(
  "@notifications/REGISTER_FCM_TOKEN_REQUEST",
  "@notifications/REGISTER_FCM_TOKEN_SUCCESS",
  "@notifications/REGISTER_FCM_TOKEN_FAILURE",
)<
  RegisterFcmTokenRequest,
  RegisterFcmTokenCommandResult & { token: string },
  Error
>();

function* registerFcmToken(
  action: ReturnType<typeof registerFcmTokenAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postFcmTokens,
      action.payload,
    );

    if (error) {
      yield put(registerFcmTokenAsync.failure(error));
      return;
    }

    yield put(
      registerFcmTokenAsync.success({
        ...response,
        token: action.payload.token,
      }),
    );
  } catch (error) {
    yield put(registerFcmTokenAsync.failure(error as Error));
  }
}

export function* registerFcmTokenSaga() {
  yield debounce(500, getType(registerFcmTokenAsync.request), registerFcmToken);
}
