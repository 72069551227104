import { ProductCardProduct } from "Components/ContractPurchase/Purchase/ProductCard";
import { useIsSelfNegotiatedContract } from "Hooks/Contract/Detail/useIsSelfNegotiatedContract";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { getProductsAsync } from "State/Products/Products/GetProductsState";
import { isNoU } from "Utils/ObjectUtils";
import { getProductsClassCode } from "Utils/Products";
import { useEffect } from "react";

export const useProductCardItems = (contractID: number) => {
  const dispatch = useAppDispatch();

  const { isLoading: isLoadingOverview, data: overview } = useAppSelector(
    s => s.investmentsDetail.overview,
  );

  useEffect(() => {
    dispatch(getOverviewAsync.request(contractID));
  }, [contractID, dispatch]);

  const contractTypeCode = overview?.type;
  const isDip = overview?.isDip;
  const isSelfInvestmentType = useIsSelfNegotiatedContract(contractTypeCode);

  useEffect(() => {
    if (isSelfInvestmentType && !!contractTypeCode) {
      dispatch(
        getProductsAsync.request({
          contractTypeCode,
          isDip,
        }),
      );
    }
  }, [dispatch, contractTypeCode, isSelfInvestmentType, isDip]);

  const { data: productsData, isLoading: isLoadingProducts } = useAppSelector(
    s => s.products.products,
  );

  const products = isNoU(isSelfInvestmentType)
    ? []
    : isSelfInvestmentType
    ? productsData?.products
    : overview?.portfolioItems?.map(i => {
        const classCode = getProductsClassCode(i.isin);
        if (isNoU(classCode) || classCode === "") {
          console.log("Unknown isin", i);
        }

        const color = productsData?.products?.find(p => p.isin === i.isin)
          ?.color;

        return {
          classCode: classCode,
          isin: i.isin,
          productName: i.name,
          color,
        } as ProductCardProduct;
      });

  return {
    products: products ?? [],
    isLoading: isLoadingOverview || isLoadingProducts,
  };
};
