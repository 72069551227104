import { all } from "typed-redux-saga";
import {
  contractDeactivateBankConnectionReducer,
  contractDeactivateBankConnectionSaga,
  ContractDeactivateBankConnectionState,
} from "./ContractDeactivateBankConnectionState";
import { combineReducers } from "redux";
import {
  contractCreateBankConnectionReducer,
  contractCreateBankConnectionSaga,
  ContractCreateBankConnectionState,
} from "State/Contracts/Contract/BankConnections/ContractCreateBankConnectionState";

export type ContractBankConnectionsState = {
  create: ContractCreateBankConnectionState;
  deactivate: ContractDeactivateBankConnectionState;
};

export function* watchContractBankConnectionsSaga() {
  yield all([
    contractCreateBankConnectionSaga(),
    contractDeactivateBankConnectionSaga(),
  ]);
}

export const contractBankConnectionsReducer =
  combineReducers<ContractBankConnectionsState>({
    create: contractCreateBankConnectionReducer,
    deactivate: contractDeactivateBankConnectionReducer,
  });
