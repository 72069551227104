import { Box } from "@mui/material";
import { TextButton } from "Components/Shared/Buttons/TextButton";
import { FilterIcon, FilterOffIcon } from "Components/Shared/Icons";
import { LAYOUT_PADDING_X } from "Constants/Layout";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

const StyledSpan = styled.span`
  padding-top: 0.1rem;
  margin-left: ${props => props.theme.spacing(1)};
`;

type Props = {
  isDataFiltered: boolean;
  onClick: () => void;
};

const ComponentResources = Resources.Contract.Detail.Transactions.Filter.Dialog;

export const FilterButton: FunctionComponent<Props> = props => {
  const { t } = useResource();
  const { isDataFiltered, onClick } = props;

  return (
    <>
      <Box display="flex" justifyContent="flex-end" marginX={LAYOUT_PADDING_X}>
        <TextButton
          trackingTag={t(ComponentResources.Open)}
          color="secondary"
          onClick={onClick}
        >
          {isDataFiltered ? <FilterIcon /> : <FilterOffIcon />}
          <StyledSpan>{t(ComponentResources.Open)}</StyledSpan>
        </TextButton>
      </Box>
    </>
  );
};
