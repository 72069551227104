export const formatDate = (
  date: Date | string | undefined,
  withTime = false,
  exact = false,
) => {
  if (!date) {
    return "-";
  }

  if (isNaN(new Date(date).getTime())) {
    return "-";
  }

  const options: Intl.DateTimeFormatOptions = !withTime
    ? {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }
    : !exact
    ? {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }
    : {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
  const format = new Intl.DateTimeFormat("cs", options);

  return format.format(new Date(date));
};

export const getFormattedNowDate = (withTime = false, exact = false) => {
  const dateNow = new Date();

  return formatDate(dateNow, withTime, exact);
};

export const getMinDateInputValueTransformer = (
  inputValueDateString: string | null | undefined,
  inputMaxValueDate: Date,
) => {
  if (inputValueDateString?.length === 6) {
    const monthString = inputValueDateString.slice(0, 2);
    const yearString = inputValueDateString.slice(2, 6);
    const month = Number(monthString);
    const year = Number(yearString);
    const date = new Date(year, month - 1, 1);

    if (date < inputMaxValueDate) {
      return inputValueDateString;
    }

    return `${
      inputMaxValueDate.getMonth() + 1
    }${inputMaxValueDate.getFullYear()}`;
  }

  return inputValueDateString ?? "";
};
