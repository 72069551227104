import { putClientNotificationStatus } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { postMessageFromWebView } from "Utils/WebViewUtils";
import { WebViewMessageTypes } from "Models/WebViewModels";

export const updateClientNotificationStatusAsync = createAsyncAction(
  "@settings/UPDATE_CLIENT_NOTIFICATION_STATUS_REQUEST",
  "@settings/UPDATE_CLIENT_NOTIFICATION_STATUS_SUCCESS",
  "@settings/UPDATE_CLIENT_NOTIFICATION_STATUS_FAILURE",
)<
  {
    previousValue: boolean;
    currentValue: boolean;
    canUseNotifications: boolean;
  },
  boolean,
  Error
>();

function* updateClientNotificationStatus(
  action: ReturnType<typeof updateClientNotificationStatusAsync.request>,
): Generator {
  const setPreviousValue = put(
    updateClientNotificationStatusAsync.success(action.payload.previousValue),
  );

  try {
    yield put(
      updateClientNotificationStatusAsync.success(action.payload.currentValue),
    );

    const { error } = yield* safeApiCall(putClientNotificationStatus, {
      isNotificationEnabled: action.payload.currentValue,
    });

    if (error) {
      yield setPreviousValue;
      yield put(updateClientNotificationStatusAsync.failure(new Error()));
      return;
    }

    if (action.payload.canUseNotifications) {
      postMessageFromWebView({
        type: WebViewMessageTypes.ENABLE_NOTIFICATIONS_REQUEST,
        payload: {},
      });
    }
  } catch (error) {
    yield setPreviousValue;
    yield put(updateClientNotificationStatusAsync.failure(error as Error));
  }
}
export function* updateClientNotificationStatusSaga() {
  yield takeLatest(
    getType(updateClientNotificationStatusAsync.request),
    updateClientNotificationStatus,
  );
}
