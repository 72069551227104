import { CreateContractDraftDocumentCommandResult } from "Api/Api";
import { DraftJsonDataModel } from "Hooks/Contract/useSaveContractDraft";
import { createAction, createAsyncAction } from "typesafe-actions";
import { DocumentScanSide, Documents } from "../Create/Models";

export const rehydrateContractDraft = createAction(
  "@contracts/DRAFT_REHYDRATE",
)<{
  draft: DraftJsonDataModel;
  isContractCreated: boolean;
  isContractSigned: boolean;
} | null>();

export type PostDocumentsMetaType = {
  guid: string;
};

type DocumentsPayload = {
  file: File;
  document: Documents | "BankAccount";
  side: DocumentScanSide;
};

export const postDocumentsAsync = createAsyncAction(
  "@contracts/POST_DOCUMENTS_REQUEST",
  "@contracts/POST_DOCUMENTS_SUCCESS",
  "@contracts/POST_DOCUMENTS_FAILURE",
)<
  [DocumentsPayload, PostDocumentsMetaType],
  [
    CreateContractDraftDocumentCommandResult,
    PostDocumentsMetaType & DocumentsPayload,
  ],
  [Error, PostDocumentsMetaType]
>();

export const deleteDocument = createAction("@contracts/DELETE_DOCUMENT")<{
  document: Documents | "BankAccount";
  side: DocumentScanSide;
}>();
