import {
  GetBanksStateType,
  getBanksReducer,
  getBanksSaga,
} from "State/CodeLists/Banks/GetBanksState";
import {
  GetBusinessSectorStateType,
  getBusinessSectorReducer,
  getBusinessSectorSaga,
} from "State/CodeLists/BusinessSector/GetBusinessSectorState";
import {
  GetCountriesStateType,
  getCountriesReducer,
  getCountriesSaga,
} from "State/CodeLists/Countries/GetCountriesState";
import {
  GetCurrenciesStateType,
  getCurrenciesReducer,
  getCurrenciesSaga,
} from "State/CodeLists/Currencies/GetCurrenciesState";
import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type CodeListState = {
  countries: GetCountriesStateType;
  banks: GetBanksStateType;
  businessSector: GetBusinessSectorStateType;
  currencies: GetCurrenciesStateType;
};

export function* watchCodeListSaga() {
  yield all([
    getCountriesSaga(),
    getBanksSaga(),
    getBusinessSectorSaga(),
    getCurrenciesSaga(),
  ]);
}

export const codeListReducer = combineReducers<CodeListState>({
  countries: getCountriesReducer,
  banks: getBanksReducer,
  businessSector: getBusinessSectorReducer,
  currencies: getCurrenciesReducer,
});
