import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { Navigate, useNavigate, useParams } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useDesktop } from "Hooks/useDesktop";
import { useEffectOnce } from "react-use";
import {
  ContractCreateBankConnectionStep,
  resetContractCreateBankConnectionState,
  setContractCreateBankConnectionContractID,
} from "State/Contracts/Contract/BankConnections/ContractCreateBankConnectionState";
import { BankConnectionModelling } from "Components/ContractBankConnections/Create/Modelling/BankConnectionModelling";
import { BankConnectionSignature } from "Components/ContractBankConnections/Create/Signature/BankConnectionSignature";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { InvestmentDetailTabs } from "Hooks/ContractDetail/useMenuTabs";
import { useBankConnections } from "Hooks/Contract/BankConnections/useBankConnections";
import { getBankAccountsAsync } from "State/InvestmentsDetail/BankAccounts/BankAccountsState";

const PageResources = Resources.Contract.BankConnections.Create;

export const CreateBankConnectionPage: FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const contractID = Number.parseInt(params.id as string);
  const actualStep = useAppSelector(
    s => s.contracts.contract.bankConnections.create.actualStep,
  );

  const { isDesktop } = useDesktop();
  const { canCreate } = useBankConnections();
  const {
    isLoading: isLoadingOverview,
    data: overview,
    error: overviewError,
  } = useAppSelector(state => state.investmentsDetail.overview);

  const { isLoading: isLoadingBankAccounts, data: bankAccounts } =
    useAppSelector(s => s.investmentsDetail.bankAccounts);

  useEffectOnce(() => {
    dispatch(resetContractCreateBankConnectionState());
    dispatch(setContractCreateBankConnectionContractID(contractID));
    dispatch(getOverviewAsync.request(contractID));
    dispatch(getBankAccountsAsync.request(contractID));
  });

  const isLoading =
    isLoadingOverview || !overview || isLoadingBankAccounts || !bankAccounts;

  if (!isLoading && !canCreate) {
    return (
      <Navigate
        to={getPath(
          AppRouting.ContractDetail,
          contractID,
          InvestmentDetailTabs.Accounts,
        )}
      />
    );
  }

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() =>
        navigate(
          getPath(
            AppRouting.ContractDetail,
            contractID,
            InvestmentDetailTabs.Accounts,
          ),
        )
      }
    >
      <LoadingWrapper
        isLoading={isLoading}
        error={overviewError}
        skeleton={LoadingSkeleton}
      >
        {actualStep === ContractCreateBankConnectionStep.Modelling && (
          <BankConnectionModelling contractID={contractID} />
        )}

        {actualStep === ContractCreateBankConnectionStep.Signature && (
          <BankConnectionSignature contractID={contractID} />
        )}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};

const LoadingSkeleton = (
  <>
    <div>
      <PrimarySkeleton
        fullWidth
        fullHeight={false}
        height={40}
        marginBottom={10}
      />
      <PrimarySkeleton
        fullWidth
        fullHeight={false}
        height={60}
        marginBottom={10}
      />
      <PrimarySkeleton
        fullWidth
        fullHeight={false}
        height={250}
        marginBottom={10}
      />
    </div>
    <PrimarySkeleton fullWidth fullHeight={false} height={60} />
  </>
);
