import { ObjectSchema, number, object, string } from "yup";
import { Resources, useResource } from "Translations/Resources";
import { ProductCardProduct } from "Components/ContractPurchase/Purchase/ProductCard";
import { useCurrencySymbol } from "Hooks/useCurrencySymbol";
import { convertApiCurrencyCodeFromString } from "Utils/CurrencyUtils";
import { FormModel } from "Components/ContractPurchase/Purchase/PurchaseForm";
import { convertNanToNull } from "Utils/NumberUtils";

type UsePurchaseFormSchemaProps = {
  currency: string;
  products: ProductCardProduct[];
  maxPeriodicalInvestment: number | null;
  fundISIN: string | null;
  decimalPlaces?: number;
};

export const MAX_INVESTMENT_VALUE = 999_999_999;

export const useYupFormSchema = ({
  currency,
  products,
  maxPeriodicalInvestment,
  fundISIN,
  decimalPlaces = 0,
}: UsePurchaseFormSchemaProps): ObjectSchema<any> => {
  const { t } = useResource();
  const currencySymbol = useCurrencySymbol(
    convertApiCurrencyCodeFromString(currency),
  );

  const defaultMin = currency === "CZK" ? 100 : 4;

  const schema: ObjectSchema<FormModel> = object<FormModel>().shape({
    singleInvestment: number()
      .transform(v =>
        convertNanToNull(
          decimalPlaces ? Number.parseFloat(v) : Number.parseInt(v),
        ),
      )
      .nullable()
      .max(MAX_INVESTMENT_VALUE)
      .test(
        "more or equal than single min investment",
        () => {
          return t(Resources.Forms.Investments.SingleInvestmentMin, {
            value: !!fundISIN
              ? products?.find(p => p.isin === fundISIN)?.singleMinInvestment ??
                defaultMin
              : defaultMin,
            currencySymbol,
          });
        },
        function (value) {
          const singleMinInvestment = value ?? 0;
          const { fundISIN, periodicalInvestment } = this.parent as FormModel;

          if (singleMinInvestment === 0 && (periodicalInvestment ?? 0) > 0) {
            return true;
          }

          if (!fundISIN) {
            return true;
          }

          const product = products?.find(p => p.isin === fundISIN);

          if (!product) {
            return true;
          }

          return (
            singleMinInvestment >= (product.singleMinInvestment ?? defaultMin)
          );
        },
      )
      .test(
        "less or equal than maxPeriodicalInvestment",
        () => {
          const defaultMax = maxPeriodicalInvestment ?? Number.MAX_SAFE_INTEGER;

          return t(Resources.Forms.Investments.SingleInvestmentMax, {
            value: defaultMax,
            currencySymbol,
          });
        },
        function (value) {
          return (
            (value ?? 0) <= (maxPeriodicalInvestment ?? Number.MAX_SAFE_INTEGER)
          );
        },
      ),
    periodicalInvestment: number()
      .transform(v =>
        convertNanToNull(
          decimalPlaces ? Number.parseFloat(v) : Number.parseInt(v),
        ),
      )
      .nullable()
      .max(MAX_INVESTMENT_VALUE)
      .test(
        "more or equal than periodical min investment",
        () => {
          return t(Resources.Forms.Investments.PeriodicalInvestmentMin, {
            value: !!fundISIN
              ? products?.find(p => p.isin === fundISIN)
                  ?.periodicalMinInvestment ?? defaultMin
              : defaultMin,
            currencySymbol,
          });
        },
        function (value) {
          const periodicalMinInvestment = value ?? 0;

          const { fundISIN, singleInvestment } = this.parent as FormModel;

          if (periodicalMinInvestment === 0 && (singleInvestment ?? 0) > 0) {
            return true;
          }

          if (!fundISIN) {
            return true;
          }
          const product = products?.find(p => p.isin === fundISIN);

          if (!product) {
            return true;
          }

          return (
            periodicalMinInvestment >= (product.periodicalMinInvestment ?? 0)
          );
        },
      )
      .test(
        "less or equal than  maxPeriodicalInvestment",
        () => {
          const defaultMax = maxPeriodicalInvestment ?? Number.MAX_SAFE_INTEGER;

          return t(Resources.Forms.Investments.PeriodicalInvestmentMax, {
            value: defaultMax,
            currencySymbol,
          });
        },
        function (value) {
          return (
            (value ?? 0) <= (maxPeriodicalInvestment ?? Number.MAX_SAFE_INTEGER)
          );
        },
      ),
    fundISIN: string().required(),
  });

  return schema;
};
