import { FormHelperText } from "@mui/material";
import { ContractTypeCode } from "Api/Api";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useFeeRate } from "Hooks/ProductFeeRate/useFeeRate";
import { FeeRateType } from "State/Products/FeeRate/GetFeeRateState";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import styled from "styled-components";

type Props = {
  isin: string | null;
  contractTypeCode: ContractTypeCode | null;
  amount: number | null;
  investmentLengthYears: number | null;
  contractID?: number;
  feeRate: FeeRateType;
};

const Container = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const StyledFormHelperText = styled(FormHelperText)`
  font-size: 14px;
`;

const StyledSkeleton = styled(PrimarySkeleton)`
  margin-top: 7px;
  height: 17px;
  width: 40px;
`;

export const RateLabel: FunctionComponent<Props> = props => {
  const {
    isin,
    contractTypeCode,
    investmentLengthYears,
    contractID,
    feeRate,
    amount,
  } = props;

  const { t } = useResource();

  const { isLoading, data } = useFeeRate(
    {
      amount,
      contractID,
      contractTypeCode,
      investmentLengthYears,
      isin,
    },
    feeRate,
  );

  const textPart1 = t(
    feeRate === "periodical"
      ? Resources.Forms.Investments.PeriodicalInvestmentFeePart1
      : Resources.Forms.Investments.SingleInvestmentFeePart1,
  );

  const textPart2 = t(
    feeRate === "periodical"
      ? Resources.Forms.Investments.PeriodicalInvestmentFeePart2
      : Resources.Forms.Investments.SingleInvestmentFeePart2,
    {
      value: data?.feeRate ?? 0,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  );

  return (
    <Container>
      <StyledFlex>
        <StyledFormHelperText>{textPart1}</StyledFormHelperText>
        &nbsp;
        {isLoading ? (
          <StyledSkeleton />
        ) : (
          <StyledFormHelperText>{textPart2}</StyledFormHelperText>
        )}
      </StyledFlex>
    </Container>
  );
};
