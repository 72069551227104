import { RadioGroup, FormControlLabel, Typography, Stack } from "@mui/material";
import {
  Control,
  Controller,
  type Path,
  type FieldError,
  type FieldErrors,
  type FieldPath,
  type FieldValues,
  type PathValue,
  type RegisterOptions,
} from "react-hook-form";
import { get } from "lodash-es";
import { FormInfo } from "Components/Shared/Inputs/FormInfo";
import {
  StyledFormControl,
  StyledFormInput,
  StyledFormLabelWrapper,
  StyledValidationText,
} from "Components/Shared/FormStyles";
import styled from "styled-components";
import { useFieldError } from "Hooks/useFieldError";
import { CodeListItem } from "Models/CodeListItem";
import { ReactNode } from "react";
import { RadioButton } from "Components/Shared/Inputs/RadioButton";
import { track } from "Utils/TrackingUtils";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";

export type Props<FormType extends FieldValues, TCode> = {
  codeList: CodeListItem<TCode, ReactNode>[];
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  rules?: Omit<
    RegisterOptions<FormType, Path<FormType>>,
    "disabled" | "setValueAs" | "valueAsNumber" | "valueAsDate"
  >;
  defaultValue?: PathValue<FormType, Path<FormType>>;
  label?: ReactNode;
  formInfo?: { [key: string]: string };
  useCodesAsLabels?: boolean;
  disabled?: boolean;
  errors: FieldErrors<FormType>;
  row?: boolean;
  spacing?: number;
  onChange?: (value: TCode) => void;
};

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  flex-flow: row;
  align-items: flex-start;

  margin-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }

  .MuiFormControlLabel-label {
    padding-top: 4px;
  }

  .MuiButtonBase-root {
    padding: ${props => props.theme.spacing(1, 2)};
  }
`;

const Description = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.body1.fontSize}px;
`;

export const RadioButtons = <
  T extends object,
  TCode extends boolean | string | number = string | number,
>(
  props: Props<T, TCode>,
) => {
  const {
    codeList,
    name,
    control,
    useCodesAsLabels,
    formInfo,
    label,
    disabled,
    errors,
    row,
    defaultValue,
    spacing,
    onChange: onChangeProp,
  } = props;
  const { hasError, errorMessage } = useFieldError({
    fieldError: get(errors, name) as FieldError,
  });

  return (
    <StyledFormControl $marginBottom={2}>
      <StyledFormLabelWrapper $isError={hasError} $disabled={disabled}>
        <label htmlFor={"name"}>{label}</label>
        <FormInfo name={name} formInfo={formInfo} />
      </StyledFormLabelWrapper>
      <StyledFormInput>
        <Controller
          control={control}
          defaultValue={defaultValue as any}
          name={name}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <RadioGroup
              value={value ?? ""}
              name={name}
              onChange={e => {
                const invokeOnChangeProp = (value: any) =>
                  !!onChangeProp && onChangeProp(value as TCode);

                const valueType =
                  (defaultValue && typeof defaultValue) ||
                  typeof codeList[0]?.code;

                if (valueType === "number") {
                  const value = parseFloat(e.target.value);
                  onChange(value);
                  invokeOnChangeProp(value);
                } else if (valueType === "boolean") {
                  const value = e.target.value === "true";
                  onChange(value);
                  invokeOnChangeProp(value);
                } else {
                  onChange(e.target.value);
                  invokeOnChangeProp(e.target.value);
                }
                track({
                  category: "RadioButtons",
                  event: "Change",
                  action: "Click",
                  value: e.target.value,
                  tag: name,
                });
              }}
              onBlur={onBlur}
              row={row}
            >
              <Stack gap={spacing}>
                {codeList.map(item => (
                  <StyledFormControlLabel
                    disabled={disabled || item.disabled}
                    key={item.code.toString()}
                    value={item.code.toString()}
                    control={<RadioButton />}
                    label={
                      <div>
                        <Typography
                          component="div"
                          fontWeight={item.code === value ? 600 : 400}
                        >
                          {useCodesAsLabels === true ? item.code : item.name}
                          {!!item.popper && (
                            <BlInfoPopper>{item.popper}</BlInfoPopper>
                          )}
                        </Typography>

                        {!!item.description && (
                          <Description>{item.description}</Description>
                        )}
                      </div>
                    }
                  />
                ))}
              </Stack>
            </RadioGroup>
          )}
        />
      </StyledFormInput>
      {hasError && <StyledValidationText>{errorMessage}</StyledValidationText>}
    </StyledFormControl>
  );
};
