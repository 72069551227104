import "./index.css";
import App from "./App";
import { logError } from "ErrorService";
import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";
import { createRoot } from "react-dom/client";
import { ApplicationErrorComponent } from "Components/Errors/ApplicationErrorComponent";
import { SENTRY_CONFIG, initSentry } from "Utils/SentryUtils";

if (import.meta.env.VITE_GOOGLE_TAG_MANAGER_IS_ENABLED === "true") {
  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_CODE as string,
  };
  TagManager.initialize(tagManagerArgs);
}

initSentry();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary
    fallback={errorInfo => (
      <ApplicationErrorComponent error={errorInfo.error} />
    )}
    showDialog={SENTRY_CONFIG.IS_FEEDBACK_ENABLED}
    dialogOptions={{
      lang: "cs",
    }}
  >
    <Sentry.Profiler name={SENTRY_CONFIG.PROJECT}>
      <App />
    </Sentry.Profiler>
  </Sentry.ErrorBoundary>,
);

window.addEventListener("error", function (event) {
  logError(event.error);
});

const setVhUnit = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
setVhUnit();
window.addEventListener("resize", () => {
  setVhUnit();
});
