import * as React from "react";
export const CheckIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.9688 4.31249C20.6367 4.3242 20.332 4.49999 20.1563 4.78124L10.75 19.1719L4.65626 13.9062C4.39454 13.6348 4.00196 13.5332 3.64063 13.6465C3.28126 13.7578 3.01368 14.0625 2.95118 14.4336C2.88868 14.8066 3.03907 15.1816 3.34376 15.4062L10.3125 21.4375C10.5313 21.623 10.8203 21.7051 11.1035 21.664C11.3887 21.6211 11.6406 21.459 11.7969 21.2187L21.8438 5.87499C22.0586 5.56053 22.0762 5.15428 21.8906 4.82225C21.7051 4.49217 21.3477 4.29491 20.9688 4.31249Z"
        fill="currentColor"
      />
    </svg>
  );
};
