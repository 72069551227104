import { ContactConfirmationDto, StatusCommandResultStatus } from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDynamicRef } from "Hooks/useDynamicRef";
import { postContactConfirmationAsync } from "State/Client/ContactConfirmation/PostContactConfirmation";
import { putContactConfirmationAsync } from "State/Client/ContactConfirmation/PutContactConfirmation";
import { useCallback, useEffect } from "react";

type Props = {
  onConfirmAccept: (values: ContactConfirmationDto) => void;
  onConfirmReject: () => void;
  active?: boolean;
};

export const useContactConfirmation = ({
  onConfirmAccept,
  onConfirmReject,
  active,
}: Props) => {
  const dispatch = useAppDispatch();
  const onConfirmAcceptRef = useDynamicRef(onConfirmAccept);
  const onConfirmRejectRef = useDynamicRef(onConfirmReject);
  const { isLoading, data, requested, requestedNumber } = useAppSelector(
    s => s.client.postContactConfirmation,
  );

  const {
    isLoading: isLoadingVerification,
    data: confirmation,
    error,
  } = useAppSelector(s => s.client.putContactConfirmation);

  const sendValidationCode = useCallback(
    (mobilePhoneNumber: string) => {
      dispatch(
        postContactConfirmationAsync.request({
          mobilePhoneNumber,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (!data || !active) {
      return;
    }

    if (confirmation?.status === StatusCommandResultStatus.Success) {
      onConfirmAcceptRef.current(data);
    } else if (
      confirmation?.status === StatusCommandResultStatus.Fail ||
      error
    ) {
      onConfirmRejectRef.current();
    }
  }, [
    dispatch,
    confirmation,
    data,
    onConfirmAcceptRef,
    onConfirmRejectRef,
    error,
    active,
  ]);

  const validateCode = useCallback(
    (code: ContactConfirmationDto["code"]) => {
      if (!data?.verificationTokenID) {
        return;
      }

      dispatch(
        putContactConfirmationAsync.request({
          contactConfirmation: {
            code,
            verificationTokenID: data.verificationTokenID,
          },
        }),
      );
    },
    [dispatch, data?.verificationTokenID],
  );

  return {
    isLoadingSendCommand: isLoading,
    isLoadingVerification,
    sendValidationCode,
    validateCode,
    verifiedCode: data?.code,
    requested,
    requestedNumber,
    validated: confirmation?.status === StatusCommandResultStatus.Success,
  };
};
