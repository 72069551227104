import { ListItemText, Typography } from "@mui/material";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { CZECHIA_COUNTRY_CODE } from "Constants/Countries";
import { type FunctionComponent } from "react";
import styled from "styled-components";

const StyledTypography = styled(ListItemText)`
  margin-left: ${props => props.theme.spacing(1)};

  .MuiListItemText-primary {
    font-size: ${props => props.theme.typography.fontSize}px;
  }

  .MuiListItemText-secondary {
    color: ${props => props.theme.palette.text.primary};
  }
`;

type Props = { iban?: string; bankCode?: string; bankNumber?: string };

export const BankAccount: FunctionComponent<Props> = ({
  iban,
  bankCode,
  bankNumber,
}) => {
  return (
    <StyledFlex $alignItems="center" $gap={2}>
      <div>
        <IconCircleWrapper size={45}>
          <Typography fontWeight={700}>
            {iban?.slice(0, 2) ?? CZECHIA_COUNTRY_CODE}
          </Typography>
        </IconCircleWrapper>
      </div>
      <StyledTypography
        primary={bankNumber ? iban : undefined}
        secondary={bankNumber ? `${bankNumber}/${bankCode}` : iban}
        secondaryTypographyProps={{ variant: "body1", fontWeight: 700 }}
      />
    </StyledFlex>
  );
};
