export const TERMS_AND_CONDITIONS_URL = import.meta.env
  .VITE_TERMS_AND_CONDITIONS_URL;

export const PERSONAL_DATA_ANALYTICS_URL = import.meta.env
  .VITE_PERSONAL_DATA_ANALYTICS_URL;

export const RS_INVCZKSELF_KID_TARIFF_URL = import.meta.env
  .VITE_RS_INVCZKSELF_KID_TARIFF_URL;

export const RS_INVEURSELF_KID_TARIFF_URL = import.meta.env
  .VITE_RS_INVEURSELF_KID_TARIFF_URL;

export const FUND_DETAILS_URL = import.meta.env.VITE_FUND_DETAILS_URL;

export const MAINTAINCE_SERVICE_PHONE = import.meta.env
  .VITE_MAINTAINCE_SERVICE_PHONE;

export const MAINTAINCE_SERVICE_EMAIL = import.meta.env
  .VITE_MAINTAINCE_SERVICE_EMAIL;

export const CONTACT_EMAIL = import.meta.env.VITE_CONTACT_EMAIL;

export const RS_INVCZSELF_PAYMENT_INSTRUCTIONS_DIP_EMPLOYER_URL = import.meta
  .env.VITE_RS_INVCZSELF_PAYMENT_INSTRUCTIONS_DIP_EMPLOYER_URL;

export const RS_INVEURSELF_PAYMENT_INSTRUCTIONS_DIP_EMPLOYER_URL = import.meta
  .env.VITE_RS_INVEURSELF_PAYMENT_INSTRUCTIONS_DIP_EMPLOYER_URL;

export const RS_IRF_CZK_EUR_REDEMPTION_SINGLE_INVESTMENT_URL = import.meta.env
  .VITE_RS_IRF_CZK_EUR_REDEMPTION_SINGLE_INVESTMENT_URL;

export const RS_IRF_CZK_EUR_REDEMPTION_SINGLE_INVESTMENT_DIP_URL = import.meta
  .env.VITE_RS_IRF_CZK_EUR_REDEMPTION_SINGLE_INVESTMENT_DIP_URL;

export const TERMS_OF_COMPLAINT_URL = import.meta.env
  .VITE_TERMS_OF_COMPLAINT_URL;

export const IGNORE_DOCUMENT_CACHE = import.meta.env
  .VITE_IGNORE_DOCUMENT_CACHE as boolean;

const CACHE_KEY_QUERY_PARAM = "nc";
const QUERY_PARAMS_DELIMITER = "?";

export const formatDocumentLink = (
  href: string | undefined,
  ignoreCache: boolean = IGNORE_DOCUMENT_CACHE,
): string | undefined => {
  if (!href || href.indexOf(QUERY_PARAMS_DELIMITER) !== -1) {
    return href;
  }

  if (ignoreCache) {
    return `${href}${QUERY_PARAMS_DELIMITER}${CACHE_KEY_QUERY_PARAM}`;
  }

  return href;
};
