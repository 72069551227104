import { CheckboxInput } from "Components/Shared/Inputs/BlCheckboxField/CheckboxInput";
import { MenuItem } from "Components/Shared/MenuItem";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useUser } from "Hooks/useUser";
import { WebViewMessageTypes } from "Models/WebViewModels";
import { getBiometricSignUpChallengeAsync } from "State/Auth/Biometrics/BiometricSignUpChallengeState";
import { setSettingBiometricsInProgress } from "State/Biometrics/BiometricsActions";
import { Resources, useResource } from "Translations/Resources";
import { postMessageFromWebView } from "Utils/WebViewUtils";
import { type FunctionComponent } from "react";

const ComponentResources = Resources.Profile.Settings;

export const BiometricsItem: FunctionComponent = _ => {
  const { t } = useResource();

  const dispatch = useAppDispatch();
  const {
    isBiometricsEnabled,
    isSettingBiometricsInProgress: isSettingBiometrics,
  } = useAppSelector(s => s.biometrics);

  const user = useUser();

  const actionElement = (
    <CheckboxInput
      name="biometrics"
      disabled={isSettingBiometrics}
      checked={isBiometricsEnabled}
      onChange={() => {
        if (isSettingBiometrics) {
          return;
        }

        if (!isBiometricsEnabled && !!user) {
          dispatch(
            getBiometricSignUpChallengeAsync.request({
              login: user.login,
              redirectUrl: null,
            }),
          );
          return;
        }

        postMessageFromWebView({
          type: WebViewMessageTypes.DISABLE_BIOMETRICS_REQUEST,
          payload: {},
        });
        dispatch(setSettingBiometricsInProgress(true));
      }}
    />
  );

  return (
    <MenuItem
      title={t(ComponentResources.Biometric.Title)}
      description={t(ComponentResources.Biometric.Description)}
      actionElement={actionElement}
    />
  );
};
