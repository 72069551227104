import { AddressDto, AddressType } from "Api/Api";

export function getPermanentAddress(
  addresses: AddressDto[],
  userMailingAddressAsPernamentFallback: boolean,
): AddressDto | undefined {
  const permanentAddress = addresses.find(
    address =>
      address.type === AddressType.Domicile ||
      (userMailingAddressAsPernamentFallback &&
        address.type === AddressType.Postal),
  );

  return permanentAddress;
}

export function getMailingAddress(
  addresses: AddressDto[],
): AddressDto | undefined {
  const mailingAddress = addresses.find(
    address => address.type === AddressType.Postal,
  );

  return mailingAddress;
}
