import { ClickAwayListener, Container, Grow, Popper } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { isNoU } from "Utils/ObjectUtils";

type Props = {
  btnEl: JSX.Element;
  translateY?: number;
  noPadding?: boolean;
  isOpen?: boolean;
  onOpen?: () => void;
  toggleCallback?: () => void;
  calcWidth?: boolean;
};

const StyledMenu = styled.div<{ translateY?: number; $calcWidth?: boolean }>`
  transform: translateY(${props => props.translateY ?? 0}px)
    translateX(${props => props.translateY ?? 20}px) !important;
  width: ${props => (props.$calcWidth ? "calc(100vw - 40px)" : "fit-content")};
  max-width: calc(100vw - 40px);
`;

const StyledContainer = styled(Container)<{ noPadding?: boolean }>`
  padding: ${props => (props.noPadding ? 0 : props.theme.spacing(2, 2))};
  border-radius: 8px;
  background-color: ${props => props.theme.palette.grey[800]};
  color: ${props => props.theme.palette.text.secondary};
  width: fit-content;
`;

export const PrimaryPopper: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const {
    btnEl,
    translateY,
    onOpen,
    noPadding,
    toggleCallback,
    calcWidth = true,
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    onOpen && !isOpen && onOpen();
    setIsOpen(prevOpen => !prevOpen);

    if (toggleCallback) {
      toggleCallback();
    }
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setIsOpen(false);
  };

  React.useEffect(() => {
    if (!isNoU(props.isOpen)) {
      setIsOpen(!!props.isOpen);
    }
  }, [props.isOpen]);

  return (
    <div>
      <div onClick={handleToggle} ref={anchorRef}>
        {btnEl}
      </div>

      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={"bottom-end"}
        transition
        style={{ zIndex: 2000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <StyledMenu translateY={translateY} $calcWidth={calcWidth}>
              <ClickAwayListener onClickAway={e => handleClose(e)}>
                <StyledContainer maxWidth="md" noPadding={noPadding}>
                  {props.children}
                </StyledContainer>
              </ClickAwayListener>
            </StyledMenu>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
