import { Box, Typography } from "@mui/material";
import { HiddenSubmitButton } from "Components/Shared/Buttons/HiddenSubmitButton";
import { TextButton } from "Components/Shared/Buttons/TextButton";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppSelector } from "Hooks/useAppSelector";
import useHandleServerError from "Hooks/useHandleServerError";
import { type PhoneVerificationFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

type Props = {
  codeDetermination: string;
  phone: string;
  mask?: string;
  isLoading?: boolean;
  autoFocus?: boolean;
  onResendCode?(phone: string): void;
  onSubmit(data: PhoneVerificationFormModel): void;
  onCodeChange?(value: string): void;
};

const StyledForm = styled.form`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const ComponentResources =
  Resources.Contract.NewContract.Shared.PhoneVerification;

const addSpacesToString = (str?: string) => {
  if (!str) {
    return "";
  }

  // Add spaces between every 3 characters but ignore if starts with + sign
  return str.replace(/(\d{3})(?=\d)/g, "$1 ");
};

export const PhoneVerification: FunctionComponent<Props> = ({
  codeDetermination,
  phone,
  isLoading,
  mask = "****",
  autoFocus = true,
  onSubmit,
  onResendCode,
  onCodeChange,
}) => {
  const { t } = useResource();

  const serverError = useAppSelector(
    x => x.contracts.create.signatureSms.error,
  );
  const form = useFormContext<PhoneVerificationFormModel>();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  useHandleServerError({
    form,
    serverError,
    resource: Resources.Validation.ServerError,
    key: "code",
  });

  return (
    <>
      <Typography component="span" paddingRight={1}>
        {t(ComponentResources.Label1)}
      </Typography>
      <Typography component="b" fontWeight={700} paddingRight={1}>
        {addSpacesToString(phone)}
      </Typography>
      <Typography component="span">
        {t(ComponentResources.Label2, { codeDetermination })}
      </Typography>

      <LoadingWrapper
        wrapChildren
        isLoading={isLoading}
        fullWidth
        skeleton={<LoadingSkeleton />}
      >
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <BlInputFormField
            control={control}
            name="code"
            errors={errors}
            label={t(ComponentResources.Code)}
            mask={mask}
            maskPlaceholder="_"
            isMaskLazy={false}
            autoFocus={autoFocus}
            onChange={onCodeChange}
          />

          <Box marginTop={2}>
            <Typography paddingRight={1}>
              {t(ComponentResources.CodeDescription)}
            </Typography>
            <TextButton color="secondary" onClick={() => onResendCode?.(phone)}>
              {t(ComponentResources.ResendCode)}
            </TextButton>
          </Box>

          <HiddenSubmitButton />
        </StyledForm>
      </LoadingWrapper>
    </>
  );
};

const LoadingSkeleton = () => (
  <Box gap={3} display="flex" flexDirection="column" marginTop={5}>
    <PrimarySkeleton variant="rectangular" height={60} />
    <PrimarySkeleton variant="rectangular" height={30} />
  </Box>
);
