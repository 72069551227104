import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getBanksAsync } from "State/CodeLists/Banks/GetBanksState";
import { getCountriesAsync } from "State/CodeLists/Countries/GetCountriesState";
import { getCurrenciesAsync } from "State/CodeLists/Currencies/GetCurrenciesState";
import { useEffect } from "react";

/**
 * @description
 * This hook is used to prefetch data for code lists
 */
export const usePrefetcher = () => {
  const dispatch = useAppDispatch();

  const { isLoading: isLoadingCountries, data: countries } = useAppSelector(
    s => s.codeList.countries,
  );
  const { isLoading: isLoadingBanks, data: banks } = useAppSelector(
    s => s.codeList.banks,
  );
  const { isLoading: isLoadingCurrencies, data: currencies } = useAppSelector(
    s => s.codeList.currencies,
  );

  useEffect(() => {
    // pre-fetch countries
    if (!isLoadingCountries && !countries) {
      dispatch(getCountriesAsync.request());
    }
  }, [countries, dispatch, isLoadingCountries]);

  useEffect(() => {
    // pre-fetch banks
    if (!isLoadingBanks && !banks) {
      dispatch(getBanksAsync.request());
    }
  }, [banks, dispatch, isLoadingBanks]);

  useEffect(() => {
    // pre-fetch currencies
    if (!isLoadingCurrencies && !currencies) {
      dispatch(getCurrenciesAsync.request());
    }
  }, [currencies, dispatch, isLoadingCurrencies]);
};
