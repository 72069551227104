import { Typography } from "@mui/material";
import { Chip } from "Components/Shared/Chip";
import { useContractCategories } from "Hooks/codeLists/useContractCategories";
import { ContractCategories } from "Models/Contracts";
import { track } from "Utils/TrackingUtils";
import { type FunctionComponent } from "react";
import styled from "styled-components";

const StyledCategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(3)};
`;

type Props = {
  label: string;
  watch: () => ContractCategories | null | undefined;
  setValue: (category: ContractCategories | null | undefined) => void;
};

export const CategorySelector: FunctionComponent<Props> = ({
  label,
  setValue,
  watch,
}) => {
  const categories = useContractCategories();
  const category = watch();

  const onToggleCategory = (newCategory: ContractCategories) => () => {
    if (category === newCategory) {
      setValue(undefined);
    } else {
      setValue(newCategory);
    }

    track({
      event: "CategoryClick",
      category: "CategoryClick",
      action: "Click",
      tag: newCategory ? String(newCategory) : null,
      value: newCategory ? String(newCategory) : null,
    });
  };

  return (
    <>
      <Typography marginLeft={4} marginBottom={2} marginTop={1}>
        {label}
      </Typography>
      <StyledCategoriesWrapper>
        {categories.map(({ Name: name, Value: value, icon }) => (
          <Chip
            icon={icon}
            key={value}
            label={name}
            $active={value === category}
            $activeBackgroundOpacity={40}
            $iconSize={15}
            $activeIconSize={18}
            $paddingX={1}
            onClick={onToggleCategory(value)}
          />
        ))}
      </StyledCategoriesWrapper>
    </>
  );
};
