import * as React from "react";
export const WarningIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 1.51953C11.9833 1.51953 11.4666 1.76952 11.1816 2.27051L0.703123 20.6992C0.133597 21.7011 0.870526 23 2.02246 23H22.9775C24.1295 23 24.8662 21.7005 24.2969 20.6982L13.8184 2.27051C13.5334 1.76952 13.0167 1.51953 12.5 1.51953ZM12.5 2.48047C12.6705 2.48047 12.8411 2.57561 12.9492 2.76562L23.4277 21.1924H23.4268C23.6425 21.5721 23.3966 22 22.9775 22H2.02246C1.60339 22 1.35679 21.5724 1.57226 21.1934L12.0508 2.76562C12.1588 2.57561 12.3295 2.48047 12.5 2.48047ZM11.999 8.5C11.723 8.5 11.5 8.724 11.5 9V16C11.5 16.276 11.7215 16.5 11.998 16.5H13C13.276 16.5 13.499 16.276 13.499 16L13.5 9C13.5 8.724 13.2775 8.5 13.001 8.5H11.999ZM11.999 18C11.723 18 11.5 18.224 11.5 18.5V19.5C11.5 19.776 11.7225 20 11.999 20H13.001C13.277 20 13.5 19.776 13.5 19.5V18.5C13.5 18.224 13.2775 18 13.001 18H11.999Z"
        fill="currentColor"
      />
    </svg>
  );
};
