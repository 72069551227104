import { ObjPathProxy, createProxy, getPath } from "ts-object-path";
import i18next, { Resource } from "i18next";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { nameof } from "Utils/ObjectUtils";
import { ResourceDictionary } from "Translations/ResourceDictionary";

export const Resources = createProxy<ResourceDictionary>();

export function getResourcePath(
  proxy: ObjPathProxy<ResourceDictionary, string>,
): string {
  const path = getPath(proxy);

  if (!path || path.length === 0) return "";
  if (path.length === 1) return path[0].toString();
  return `${path[0].toString()}:${path.slice(1).join(".")}`;
}

export function useResource() {
  const { t: i18Translation } = useTranslation();

  return useMemo(
    () => ({
      t: (
        resourcePath: ObjPathProxy<ResourceDictionary, string>,
        options?: any,
      ) =>
        i18Translation(
          getResourcePath(resourcePath),
          options,
        ) as unknown as string,
    }),
    [i18Translation],
  );
}

export type UseResourceTType = ReturnType<typeof useResource>["t"];

export function useServerError(
  parentObject: ObjPathProxy<ResourceDictionary, object>,
  fallbackProperty: ObjPathProxy<ResourceDictionary, string>,
) {
  const { t } = useResource();
  return {
    translateError: (code: string | null | undefined) => {
      if (!code) {
        return null;
      }

      const newCode = `${getResourcePath(parentObject as any)}.${code}`;

      const resource = i18next.exists(newCode)
        ? i18next.t(newCode)
        : t(fallbackProperty);

      return resource as string;
    },
  };
}

export function mapValidationErrorToPath(originalPropertyName: string): string {
  function toPascalCase(str: string): string {
    // Replace any non-letter/digit character (including underscores) with a space
    // to isolate words, then capitalize the first character of each word.
    return str
      .replace(/[^a-zA-Z0-9]+(.)/g, (match, group1) => group1.toUpperCase())
      .replace(/^./, match => match.toUpperCase()); // Capitalize the first character of the string
  }

  // Remove placeholders within {} and leading/trailing non-alphanumeric characters
  const cleanedString = originalPropertyName
    .replace(/\{.*?\}/g, "")
    .replace(/^\W+|\W+$/g, "");

  const parts = cleanedString
    .split(".") // Split by dots to separate each part of the property name
    .map(part => toPascalCase(part)); // Convert each part to PascalCase

  if (parts.length === 0) return "";

  // Concatenate all parts with a dot and prepend with the 'ApiValidation' prefix
  // return `ApiValidation.${parts.join(".")}`;

  return `${nameof<Resource>("ApiValidation")}:${parts.join(".")}`;
}
