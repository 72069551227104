import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { FunctionComponent, type Key } from "react";
import { isNoU } from "Utils/ObjectUtils";
import styled from "styled-components";

type Props<TValue extends Key = Key> = {
  label?: string;
  buttons: {
    label: string;
    value: TValue;
    trackingTag?: string;
    trackingValue?: string;
  }[];
  active?: TValue;
  color?: string | null | undefined;
  backgroundColor?: string | null | undefined;
  onChange(value: TValue): void;
};

const StyledWrapper = styled.div`
  display: flex;
  border-radius: 100px;
  border: 1px solid ${props => props.theme.palette.secondary.main}90;
  margin-top: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(4)};
  width: 100%;
`;

const StyledPrimaryButton = styled(PrimaryButton)<{
  $active?: boolean;
  $hexColor?: string | null | undefined;
  $hexBackgroundColor?: string | null | undefined;
}>`
  height: 36px;
  box-shadow: none;
  font-weight: 400;

  min-width: 0;

  font-size: ${props => props.theme.typography.body1.fontSize}px;

  transition: none;

  line-height: 1.2;

  &:hover {
    box-shadow: none;
  }

  ${({ $active, $hexBackgroundColor, $hexColor, theme }) =>
    $active
      ? `
          color: ${isNoU($hexColor) ? theme.palette.background.default : $hexColor};
          font-weight: 600;
          border: 1px solid ${
            isNoU($hexBackgroundColor)
              ? theme.palette.primary.main
              : $hexBackgroundColor
          };
          outline: 1px solid ${
            isNoU($hexBackgroundColor)
              ? theme.palette.primary.main
              : $hexBackgroundColor
          };
          background-color: ${
            isNoU($hexBackgroundColor)
              ? theme.palette.primary.main
              : $hexBackgroundColor
          };
          &:hover {
            background-color: ${
              isNoU($hexBackgroundColor)
                ? theme.palette.primary.main
                : $hexBackgroundColor
            };
            cursor: default;
          }
        `
      : `
          &:hover {
            color: ${theme.palette.primary.main};
          }
          border: none;
        `}
`;

export const SelectButtons = <TValue extends Key>({
  label,
  active,
  buttons,
  color,
  backgroundColor,
  onChange,
}: Props<TValue>): ReturnType<FunctionComponent<Props<TValue>>> => {
  return (
    <>
      {label && <Typography marginLeft={4}>{label}</Typography>}
      <StyledWrapper>
        {buttons.map(({ label, value, trackingTag, trackingValue }) => (
          <StyledPrimaryButton
            key={value}
            color={value === active ? "primary" : "secondary"}
            $active={value === active}
            $hexColor={color}
            $hexBackgroundColor={backgroundColor}
            onClick={() => onChange(value)}
            fullWidth
            trackingTag={trackingTag}
            trackingValue={trackingValue}
          >
            {label}
          </StyledPrimaryButton>
        ))}
      </StyledWrapper>
    </>
  );
};
