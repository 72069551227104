import {
  AddressType,
  AttachmentType,
  ClientAgreement,
  ContractSignType,
  NewContractDto,
  PersonalDocumentType,
  SexType,
} from "Api/Api";
import {
  FormModelType as ContractFormModel,
  NewContractStep,
  TaxResidencyEnum,
} from "State/Contracts/Create/Models";
import {
  ContractModelingStep,
  FormModelType as ModelingFormModel,
} from "State/Contracts/Modeling/Models";
import { formatDocumentsDateToDate } from "Utils/Contracts/NewContract/DateUtils";
import { formatISO } from "date-fns";
import { getTrackingSessionID } from "Utils/TrackingUtils";

const DocTypeFileType: { [key in PersonalDocumentType]: AttachmentType } = {
  [PersonalDocumentType.BirthCertificate]: AttachmentType.BIRTHCERT,
  [PersonalDocumentType.DrivingLicense]: AttachmentType.SECONDARYCERT,
  [PersonalDocumentType.ExctractFromCommercialRegister]:
    AttachmentType.REGCOMMCONFIRMATION,
  [PersonalDocumentType.GunLicense]: AttachmentType.SECONDARYCERT,
  [PersonalDocumentType.IdentityCard]: AttachmentType.CERTMAIN1,
  [PersonalDocumentType.Other]: AttachmentType.OTHER,
  [PersonalDocumentType.Passport]: AttachmentType.CERTMAIN1,
  [PersonalDocumentType.TemporaryResidencePermit]: AttachmentType.RESIDENCE,
  [PersonalDocumentType.TradeLicense]: AttachmentType.OTHER,
};

const getDocTypeFileType = (
  type: PersonalDocumentType,
  side: "front" | "back",
): AttachmentType => {
  if (type === PersonalDocumentType.IdentityCard) {
    return side === "front"
      ? AttachmentType.CERTMAIN1
      : AttachmentType.CERTMAIN2;
  }

  return DocTypeFileType[type];
};

export const mapFormDataToApi = async (
  modelingData: ModelingFormModel,
  contractData: ContractFormModel,
  isBankIDVerified: boolean,
  isDip: boolean,
  isBiometry: boolean,
): Promise<NewContractDto> => {
  const {
    [ContractModelingStep.Fund]: fund,
    [ContractModelingStep.Information]: fundInformation,
    [ContractModelingStep.FundConfiguration]: fundConfiguration,
  } = modelingData;
  const {
    [NewContractStep.AMLQuestions]: aml,
    [NewContractStep.PersonalData]: personalData,
    [NewContractStep.ContactInformation]: contactInformation,
    [NewContractStep.Documents]: documents,
    [NewContractStep.BankConnection]: bankConnection,
    [NewContractStep.LastInformation]: lastInformation,
  } = contractData;
  const { permanentAddress, mailingAddress } =
    contactInformation?.addresses ?? {};
  const [citizenship, ...otherCitizenships] = personalData?.nationalities ?? [];

  if (
    !personalData ||
    !contactInformation ||
    !documents ||
    !aml ||
    !bankConnection ||
    !fundInformation ||
    !fundConfiguration ||
    !fund ||
    !bankConnection.bankCurrency ||
    typeof fundConfiguration.investmentLengthYears !== "number" ||
    !documents.firstDocument ||
    !documents.firstDocument.type ||
    !documents.firstDocument.number ||
    !documents.firstDocument.dateValidFrom ||
    !documents.firstDocument.countryIssue
  ) {
    throw new Error("Missing required data");
  }

  const trackingSessionID = getTrackingSessionID();

  return {
    client: {
      name: {
        titleBefore: personalData?.titleBeforeName ?? "",
        firstName: personalData?.firstName ?? "",
        lastName: personalData?.lastName ?? "",
        titleAfter: personalData?.titleAfterName ?? "",
      },
      addresses: [
        {
          type: AddressType.Domicile,
          streetName: permanentAddress?.street ?? "",
          streetNumber: permanentAddress?.streetNumber ?? "",
          streetConscriptionNumber:
            permanentAddress?.streetConscriptionNumber ?? "",
          city: permanentAddress?.city ?? "",
          country: permanentAddress?.country ?? "",
          zip: permanentAddress?.postalCode ?? "",
        },
        ...(contactInformation?.mailingAddressAsPermanent === false
          ? [
              {
                type: AddressType.Postal,
                streetName: mailingAddress?.street ?? "",
                streetNumber: mailingAddress?.streetNumber ?? "",
                streetConscriptionNumber:
                  mailingAddress?.streetConscriptionNumber ?? "",
                city: mailingAddress?.city ?? "",
                country: mailingAddress?.country ?? "",
                zip: mailingAddress?.postalCode ?? "",
              },
            ]
          : []),
      ],
      email: personalData?.email ?? "",
      mobile: personalData?.phone ?? "",
      citizenship: citizenship?.value ?? "",
      otherCitizenships: otherCitizenships?.map(({ value }) => value),
      personalIdentificationNumber:
        personalData?.personalIdentificationNumber ?? "",
      birthPlace: personalData?.birthCity ?? "",
      birthCountry: personalData?.birthCountry ?? "",
      birthDate: personalData?.birthDate ?? "",
      sex: personalData?.sex === "M" ? SexType.M : SexType.F,
      document: {
        type: documents.firstDocument.type,
        number: documents.firstDocument.number,
        issueDate: formatISO(
          formatDocumentsDateToDate(documents.firstDocument.dateValidFrom) ??
            new Date(),
          { representation: "date" },
        ),
        validTill: !!documents.firstDocument.dateExpiry
          ? formatISO(
              formatDocumentsDateToDate(documents.firstDocument.dateExpiry) ??
                new Date(),
              { representation: "date" },
            )
          : undefined,
        isValidTillIndefinite: !!documents.firstDocument.isExpiryDateIndefinite,
        issuedBy: documents.firstDocument.issuingAuthority ?? "",
        issueCountry: documents.firstDocument.countryIssue,
      },
      ...(documents.secondDocument &&
        documents.secondDocument.dateValidFrom &&
        documents.secondDocument.type &&
        documents.secondDocument.number &&
        documents.secondDocument.issuingAuthority &&
        documents.secondDocument.countryIssue && {
          secondDocument: {
            type: documents.secondDocument.type,
            number: documents.secondDocument.number,
            issueDate: formatISO(
              formatDocumentsDateToDate(
                documents.secondDocument.dateValidFrom,
              ) ?? new Date(),
              { representation: "date" },
            ),
            validTill: !!documents.secondDocument.dateExpiry
              ? formatISO(
                  formatDocumentsDateToDate(
                    documents.secondDocument.dateExpiry,
                  ) ?? new Date(),
                  { representation: "date" },
                )
              : undefined,
            isValidTillIndefinite:
              !!documents.secondDocument.isExpiryDateIndefinite,
            issuedBy: documents.secondDocument.issuingAuthority,
            issueCountry: documents.secondDocument.countryIssue,
          },
        }),
      taxResidencyCountry:
        (aml.taxResidency === TaxResidencyEnum.Other
          ? aml.taxResidencyCountry
          : aml.taxResidency) ?? "CZ",

      taxResidencyDic: aml.vatId ?? "",

      agreements: [
        lastInformation?.clientAgreeTakeoverData &&
          ClientAgreement.ClientAgreeTakeoverData,
        lastInformation?.acceptPersonalDataMarketing &&
          ClientAgreement.ClientAgreeInfo,
      ].filter(Boolean) as ClientAgreement[],
    },
    aml: {
      netIncome: aml.annualIncome,
      sourceOfIncome: aml.sourceOfIncome,
      sourceOfIncomeOther: aml.sourceOfIncomeOther ?? "",
      sourceOfResources: aml.sourceOfFunds,
      sourceOfResourcesOther: aml.sourceOfFundsOther ?? "",
      businessSector: aml.businessSector,
      job: aml.jobPosition,
      sourceValue: aml.fundsVolume ?? 0,
      sourceCurrency: aml.fundsVolumeCurrency,
      pep: !!aml.politicallyExposed,
      pepOriginProperty: aml.politicallyExposedDescription ?? "",
      taxResidenceCountry:
        (aml.taxResidency !== TaxResidencyEnum.Other
          ? aml.taxResidency
          : aml.taxResidencyCountry) ?? "",
      tin: aml.vatId ?? "",
      isDeclaration1: true,
      isDeclaration2: true,
      beneficient: "",
    },
    contract: {
      dealerId: fundConfiguration.dealerID,
      dealerCode: fundConfiguration.dealerCode,
      name: fundInformation.name,
      investmentProspect: fundConfiguration.investmentLengthYears ?? 1,
      currency: fund.currency,
      signType: ContractSignType.SMS, //TODO biometrie
      purposeAmount:
        fundConfiguration.type === "Target"
          ? fundConfiguration.targetAmount
          : undefined,
      type: fund.contractTypeCode,
      purposeCategory: fundInformation.category,
      iban:
        bankConnection.bankConnectionType === "iban"
          ? bankConnection.iban
          : undefined,
      bank:
        bankConnection.bankConnectionType === "bankNumber"
          ? bankConnection.bankCode
          : undefined,
      bankAccountNumber:
        bankConnection.bankConnectionType === "bankNumber"
          ? bankConnection.bankNumber
          : undefined,
      bankAccountCurrency: bankConnection.bankCurrency,
      isDip,
      modelling: {
        isDip: isDip,
        sessionID: trackingSessionID,
        modellingItems: [
          {
            productID: fund?.productID,
            isin: fund?.isin ?? "",
            singleInvestment: fundConfiguration?.singleInvestment ?? 0,
            periodicalInvestment: fundConfiguration?.periodicalInvestment ?? 0,
            investmentLengthYears:
              fundConfiguration?.investmentLengthYears ?? 0,
          },
        ],
      },
    },
    attachments: [
      ...(documents.firstDocument?.frontScan
        ? [
            {
              type: getDocTypeFileType(documents.firstDocument.type, "front"),
              documentDrawGuid: documents.firstDocument.frontScan.guid,
              fileName: documents.firstDocument.frontScan.fileName,
            },
          ]
        : []),
      ...(documents.firstDocument?.backScan
        ? [
            {
              type: getDocTypeFileType(documents.firstDocument.type, "back"),
              documentDrawGuid: documents.firstDocument.backScan.guid,
              fileName: documents.firstDocument.backScan.fileName,
            },
          ]
        : []),
      ...(documents.secondDocument?.frontScan && documents.secondDocument.type
        ? [
            {
              type: getDocTypeFileType(documents.secondDocument.type, "front"),
              documentDrawGuid: documents.secondDocument.frontScan.guid,
              fileName: documents.secondDocument.frontScan.fileName,
            },
          ]
        : []),
    ],
    isBankID: isBankIDVerified,
    isBiometry: isBiometry,
  };
};
