import { ContractTypeCode } from "Api/Api";
import {
  RS_INVCZKSELF_KID_TARIFF_URL,
  RS_INVEURSELF_KID_TARIFF_URL,
} from "Utils/ExternalLinkUtils";

export const getKidTariffDocumentLink = (
  code: ContractTypeCode | undefined | null,
): string | null => {
  if (!code) {
    return null;
  }

  switch (code) {
    case ContractTypeCode.RS_INVCZKSELF:
      return RS_INVCZKSELF_KID_TARIFF_URL;
    case ContractTypeCode.RS_INVEURSELF:
      return RS_INVEURSELF_KID_TARIFF_URL;
    default:
      return null;
  }
};
