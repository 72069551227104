/**
 * https://github.com/kozakdenys/qr-code-styling/issues/72
 * We need tu use a custom QRCodeStyling class to be able to use multiple QRCodeStyling instances on the same page.
 * The problem is that QRCodeStyling uses the same id for all clip-path elements, so we need to change it.
 * This is a workaround until the issue is fixed in the library
 *
 * https://github.com/solana-labs/qr-code-styling/pull/6
 * (fix in library was merge, but not released yet).
 */

import QRCodeStyling, { Options } from "qr-code-styling";

let counter = 0;

export class QRCode extends QRCodeStyling {
  count: number;

  constructor(options?: Partial<Options> | undefined) {
    super(options);
    this.count = counter++;
  }

  update(options?: Partial<Options> | undefined): void {
    super.update(options);

    if (this._svgDrawingPromise) {
      this._svgDrawingPromise.then(() => {
        if (!(this._svg instanceof SVGElement)) {
          return;
        }

        const clipPath = this._svg.querySelector<SVGClipPathElement>(
          "#clip-path-dot-color",
        );
        const rect = this._svg.querySelector<SVGRectElement>(
          `rect[clip-path="url('#clip-path-dot-color')"]`,
        );

        if (clipPath && rect) {
          clipPath.setAttribute("id", `clip-path-dot-color-${this.count}`);
          rect.setAttribute(
            "clip-path",
            `url('#clip-path-dot-color-${this.count}')`,
          );
        }
      });
    }
  }
}
