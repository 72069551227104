import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { resetRedemptionState } from "State/Contracts/Redemption/RedemptionState";
import { Resources, useResource } from "Translations/Resources";
import { getProductColor } from "Utils/Products";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect, type FunctionComponent } from "react";
import { useNavigate } from "react-router";

type Props = {
  contractID: number;
  isin: string;
};

const PageResources = Resources.Contract.Detail.Redemption.Success;

export const SuccessStep: FunctionComponent<Props> = ({ contractID, isin }) => {
  const { t } = useResource();
  const { color, contrastColor } = getProductColor(isin);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetRedemptionState());
    };
  }, [dispatch]);

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{t(PageResources.Title)}</PageTitle>
        <Typography component="span">{t(PageResources.Text)}</Typography>
      </div>
      <PrimaryButton
        color="primary"
        onClick={() => navigate(getPath(AppRouting.ContractDetail, contractID))}
        trackingTag={t(PageResources.Button)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(PageResources.Button)}
      </PrimaryButton>
    </>
  );
};
