import { ProductSectionItemDto } from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  clearProductDetail,
  getProductDetailAsync,
} from "State/Products/ProductDetail/GetProductsState";
import { useEffect, useMemo } from "react";

export enum ProductDetailSections {
  Headers = 1,
  Properties = 2,
  Descriptions = 3,
  Paragraphs = 4,
  Documents = 5,
}

type DetailType = ProductSectionItemDto;

type ProductDetailType = {
  [ProductDetailSections.Headers]?: DetailType[];
  [ProductDetailSections.Properties]?: DetailType[];
  [ProductDetailSections.Descriptions]?: DetailType[];
  [ProductDetailSections.Paragraphs]?: DetailType[];
  [ProductDetailSections.Documents]?: DetailType[];
};

export const useProductDetail = (isin: string | null | undefined) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(e => e.products.productDetail);

  useEffect(() => {
    if (isin) {
      dispatch(getProductDetailAsync.request(isin));
    } else {
      dispatch(clearProductDetail());
    }
  }, [dispatch, isin]);

  const productDetail = useMemo(() => {
    if (!data) {
      return null;
    }

    const result: ProductDetailType = {};

    data.forEach(item => {
      if (!(item.sectionNo in ProductDetailSections)) {
        return;
      }

      const itemSection = item.sectionNo as ProductDetailSections;

      if (!result[itemSection]) {
        result[itemSection] = [];
      }

      result[itemSection]?.push(item);
    });

    return result;
  }, [data]);

  return {
    productDetail,
    isLoading,
  };
};
