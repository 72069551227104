import { Typography } from "@mui/material";
import { ContractGuideWrapper } from "Components/Shared/ContractGuideWrapper";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { PageStepProps } from "Pages/Contracts/CreatePage";
import { checkAndProlongTokenAsync } from "State/Auth/Tokens/CheckAndProlongTokenState";
import { Resources, useResource } from "Translations/Resources";
import { isAfter, parseJSON } from "date-fns";
import { FunctionComponent, useEffect, useState } from "react";

type Props = PageStepProps;

const BANK_ID_URL = import.meta.env.VITE_BANK_ID_URL;
const REDIRECT_URL = `${BANK_ID_URL}&scope=profile.email%20profile.name%20openid&response_type=token&state=sign-up`;

export const BankIDPage: FunctionComponent<Props> = ({ isActive }) => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const isDip = useIsDipSelected();

  const tokenExpiration = useAppSelector(s => s.auth.tokenExpiration);
  const [originalTokenExpiration, setOriginalTokenExpiration] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (!isActive || originalTokenExpiration) {
      return;
    }

    setOriginalTokenExpiration(tokenExpiration);
    dispatch(checkAndProlongTokenAsync.request());
  }, [
    dispatch,
    isActive,
    tokenExpiration,
    originalTokenExpiration,
    setOriginalTokenExpiration,
  ]);

  useEffect(() => {
    if (!originalTokenExpiration || !tokenExpiration) {
      return;
    }

    if (
      isAfter(parseJSON(tokenExpiration), parseJSON(originalTokenExpiration))
    ) {
      // window.open(REDIRECT_URL);
      window.location.href = REDIRECT_URL;
    }
  }, [originalTokenExpiration, tokenExpiration]);

  return (
    <ContractGuideWrapper isDipWrapped={isDip}>
      <Typography>
        {t(Resources.Contract.NewContract.BankID.WaitingForBankID)}
      </Typography>
    </ContractGuideWrapper>
  );
};
