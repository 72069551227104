import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getDraftAsync } from "State/Contracts/Draft/GetDraft/GetDraftState";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";

export const useRehydrateContractDraft = () => {
  const dispatch = useAppDispatch();
  const isRehydrated = useAppSelector(s => s.contracts.rehydrate.isRehydrated);
  const { isLoading } = useAppSelector(s => s.contracts.draft.getDraft);
  const { isLoading: isLoadingDraft, data: draft } = useAppSelector(
    s => s.contracts.draft.getDraft,
  );

  const navigate = useNavigate();

  useMemo(() => {
    if (
      !isLoading &&
      isRehydrated &&
      !isLoadingDraft &&
      !!draft?.isContractCreated &&
      !draft?.isContractSigned
    ) {
      navigate(getPath(AppRouting.ContractCreate));
    }
  }, [
    draft?.isContractSigned,
    draft?.isContractCreated,
    isLoadingDraft,
    isRehydrated,
    isLoading,
    navigate,
  ]);

  const rehydrate = useCallback(() => {
    if (isRehydrated || isLoading) {
      return;
    }

    dispatch(getDraftAsync.request());
  }, [dispatch, isRehydrated, isLoading]);

  return {
    rehydrate,
    isRehydrating: isLoading,
    isRehydrated,
  };
};
