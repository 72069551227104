import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useMenuItems } from "Hooks/useMenuItems";
import { FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";

const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  width: 100%;

  .Mui-disabled {
    svg,
    span {
      opacity: 0.5;
    }
  }
`;

export const BottomMenu: FunctionComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { items } = useMenuItems();

  return (
    <StyledBottomNavigation
      showLabels
      value={pathname}
      onChange={(_, value) => {
        navigate(value);
      }}
    >
      {items.map(item => (
        <BottomNavigationAction key={item.id} {...item} />
      ))}
    </StyledBottomNavigation>
  );
};
