import { useRef, type FunctionComponent, useEffect, useState } from "react";
import { Resources, useResource } from "Translations/Resources";
import { GenerateQrCodeCommand } from "Api/Api";
import { getPaymentDescriptor } from "Utils/PaymentDescriptor";
import { Wrapper } from "Components/Shared/InvoiceQrCode/InvoiceQRCode";
import { SaveButton } from "Components/Shared/InvoiceQrCode/SaveButton";
import { QRCode } from "Utils/QRCodeStyling";

type Props = {
  size?: number;
  showSaveButton?: boolean;
  isPeriodicalPayment?: boolean;
  expiryDate?: Date;
} & Omit<GenerateQrCodeCommand, "invoiceID">;

const ComponentResources = Resources.Components.InvoiceQRCode;

export const CzechInvoiceQRCode: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  size,
  showSaveButton,
  iban,
  price,
  specificSymbol,
  variableCode,
  expiryDate,
  isPeriodicalPayment,
  messageForPayment,
}) => {
  const { t } = useResource();

  const paymentDescriptor = useRef(
    getPaymentDescriptor({
      amount: price,
      iban,
      currencyCode: undefined,
      specificSymbol,
      variableCode,
      messageForPayment,
      expiryDate,
      isPeriodicalPayment,
    }),
  );

  const qrCodeDivRef = useRef<HTMLDivElement>(null);
  const [qrCode] = useState<QRCode>(
    new QRCode({
      width: size ?? 220,
      height: size ?? 220,
      data: paymentDescriptor.current,
      margin: 0,
      qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "H",
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.5,
        margin: 0,
      },
      dotsOptions: {
        type: "square",
        gradient: undefined,
      },
      cornersSquareOptions: { type: "square" },
      cornersDotOptions: { type: "square" },
      type: "canvas",
    }),
  );

  useEffect(() => {
    qrCode.append(qrCodeDivRef.current as any);
  }, [qrCode]);

  const onDownload = () => {
    qrCode.download({
      extension: "png",
      name: t(ComponentResources.QrCodeFileName),
    });
  };

  return (
    <Wrapper>
      <div ref={qrCodeDivRef} />

      {showSaveButton && <SaveButton onDownload={onDownload} />}
    </Wrapper>
  );
};
