import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { ContantForm } from "Components/Shared/Forms/ContantForm";
import { MailingAddressSelect } from "Components/ContractCreate/ContactInformation/MailingAddressSelect";
import { Step } from "Components/ContractCreate/Shared/Step";
import { HiddenSubmitButton } from "Components/Shared/Buttons/HiddenSubmitButton";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { PageTitle } from "../Shared/PageTitle";
import { useYupFormSchema } from "Hooks/Contract/ContantInformation/useYupFormSchema";
import { useFormData } from "Hooks/Contract/useFormData";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { NewContractStep } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { useEffect, type FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { type ContactInformationFormModel } from "Models/Forms";
import { USA_NATIONALITY_FORBID_TYPE } from "Constants/Contracts/Create";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const PageResources = Resources.Contract.NewContract.ContactInformation;

export const ContactInformation: FunctionComponent = () => {
  const { t } = useResource();

  const { defaultValues } = useFormData<NewContractStep.ContactInformation>({
    step: NewContractStep.ContactInformation,
  });
  const validationSchema = useYupFormSchema();
  const isBankIDVerified = useIsBankIDVerified();
  const { color, contrastColor } = useModelingColor();
  const { nextStep } = useCreateContractStep();

  const form = useForm<ContactInformationFormModel>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      mailingAddressAsPermanent: true,
      ...defaultValues,
    },
  });
  const {
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
  } = form;

  const mailingAddressAsPernament = watch("mailingAddressAsPermanent");
  const pernamentCountry = watch("addresses.permanentAddress.country");

  useEffect(() => {
    if (pernamentCountry) {
      trigger("addresses.permanentAddress.country");
    }
  }, [pernamentCountry, trigger]);

  const onSubmit = (formData: ContactInformationFormModel) => {
    nextStep({ formData });
  };

  return (
    <>
      <div>
        <Step actualStep={2} />

        <PageTitle>{t(PageResources.Title)}</PageTitle>
        <Typography>{t(PageResources.Label)}</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...form}>
            <InputHeaderWrapper header={t(PageResources.PermanentAddress)}>
              <ContantForm
                formType="permanentAddress"
                disabled={isBankIDVerified}
              />
            </InputHeaderWrapper>
            <InputHeaderWrapper header={t(PageResources.MailingAddress)}>
              <MailingAddressSelect />
              {!mailingAddressAsPernament && (
                <ContantForm formType="mailingAddress" />
              )}
            </InputHeaderWrapper>
          </FormProvider>
          <HiddenSubmitButton />
        </form>
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={
          errors?.addresses?.permanentAddress?.country?.type ===
          USA_NATIONALITY_FORBID_TYPE
        }
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
